import { useEffect, useState } from "react";

function useDebounce<T = any>(
	value: T,
	delay: number = 500
) {
	const [debouncedValue, setDebouncedValue] =
		useState(value);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);
	return debouncedValue;
}

export default useDebounce;
