import { FC, memo } from "react";
import Slider, {
	CustomArrowProps,
} from "react-slick";
import styled from "styled-components";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import IconRightArrow from "../../components/Icons/IconRightArrow";
import slider1_img from "../../assets/images/slider1_meals.png";
import movement from "../../assets/images/Movement.png";
import meditation from "../../assets/images/meditation.png";
import mantra from "../../assets/images/Mantra.png";
import masti from "../../assets/images/Masti.png";
import { H3, Body1 } from "@workshore/nyaari-ds";

import "./slider1.css";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";

const FiveMContent = styled.div<{
	isTablet: boolean;
	background: string;
}>`
	height: 550px;
	width: 1175px;
	display: flex;
	flex-direction: row;

	border-radius: 5px;

	${({ background }) => `
		background: ${background};
	`}

	${({ isTablet }) =>
		isTablet &&
		`
    width: calc(100% - 40px);
    flex-direction: column-reverse;
    padding: 30px 20px;
    height: 450px;
    margin-top: 210px;
  `};
`;

const FiveMContentHeader = styled.div<{
	isTablet: boolean;
}>`
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	width: 559px;
	padding: 0 67px;

	${({ isTablet }) =>
		isTablet &&
		`
    width: 100%;
    padding: 0;
    text-align: center;
  `};
`;

const SliderImg = styled.img`
	margin-top: -30px;
	margin-right: -50px;
	object-fit: contain;

	@media screen and (max-width: 768px) {
		width: 90vw;
		height: 450px;
	}
`;

// arrow component
const ArrowDiv = styled.div<{
	isMobile: boolean;
}>`
	height: 64px;
	width: 64px;
	border-radius: 32px;
	box-shadow: 0 3px 6px #00000029;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 25px;
    width: 25px;
  `};
`;

const Left = styled.div`
	transform: scaleX(-1);
	> * {
		height: 16px;
		width: 8px;
	}
`;

const Right = styled.div`
	> * {
		height: 16px;
		width: 8px;
	}
`;

const StyledBody1 = styled(Body1)<{
	isMobile: boolean;
}>`
	line-height: 30px;
	font-weight: 400;

	${({ isMobile }) =>
		isMobile &&
		`
    line-height: 23px;
  `};
`;

const StyledDiv = styled.div<{
	isTablet: boolean;
	isMobile: boolean;
}>`
	padding-top: 30px;

	${({ isTablet }) =>
		isTablet &&
		`
    padding-top: 85px;
  `};

	${({ isMobile }) =>
		isMobile &&
		`
    padding-top: 0px;
  `};
`;

interface CustomProps extends CustomArrowProps {
	left?: boolean;
}

const Arrow: FC<CustomProps> = ({
	className,
	style,
	onClick,
	left,
}) => {
	const isMobile = useIsMobile();
	return (
		<ArrowDiv
			isMobile={isMobile}
			className={className}
			style={{
				...style,
				background: "white",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			onClick={onClick}
		>
			{left ? (
				<Left>
					<IconRightArrow />
				</Left>
			) : (
				<Right>
					<IconRightArrow />
				</Right>
			)}
		</ArrowDiv>
	);
};

interface Props {
	changeActiveSlide: (val: number) => void;
}

const Slider1: FC<Props> = ({
	changeActiveSlide,
}) => {
	const settings1 = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: <Arrow left />,
		nextArrow: <Arrow />,
	};
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	return (
		<div className="custom_slide">
			<Slider
				{...settings1}
				afterChange={changeActiveSlide}
			>
				<div>
					<StyledDiv
						isTablet={isTablet}
						isMobile={isMobile}
					>
						<FiveMContent
							background="#ECF7F0"
							isTablet={isTablet}
						>
							<FiveMContentHeader
								isTablet={isTablet}
							>
								<H3>
									Meals - Mindful but Mazedaar
								</H3>
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? { marginTop: 16 }
											: { marginTop: 26 }
									}
								>
									You wanna drop inches but you
									need the desi tadka! We get it.
									You get a Weekly Meal Plan with
									Calorie-counted,
									Portion-controlled, Dietitian
									and Ayurveda expert- vetted,
									Desi-fusion recipes. All that
									comes with a Grocery list + Meal
									Prep tips AND simple tweaks for
									the extra chataka your family
									demands. Kyunki khane ka mazaa
									saath mein aata hai.
								</StyledBody1>
							</FiveMContentHeader>
							<SliderImg
								// isTablet={isTablet}
								src={slider1_img}
								alt=""
							/>
						</FiveMContent>
					</StyledDiv>
				</div>
				<div>
					<StyledDiv
						isTablet={isTablet}
						isMobile={isMobile}
					>
						<FiveMContent
							background="#FDE4D6"
							isTablet={isTablet}
						>
							<FiveMContentHeader
								isTablet={isTablet}
							>
								<H3>
									Movement – Mashakkat for Muscles
								</H3>
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? { marginTop: 16 }
											: { marginTop: 26 }
									}
								>
									Want a toned midriff & amplified
									lungpower? Agility, energy &
									age-defying skin? Follow our
									fun-filled, expert-led,
									physiotherapist-vetted workouts.
									From HIIT, Slow & Intense Yoga
									Workouts, to Pranayama and Dance
									Workouts, we have many ways to
									keep you moving and grooving!
								</StyledBody1>
							</FiveMContentHeader>
							<SliderImg
								// isTablet={isTablet}
								src={movement}
								alt=""
							/>
						</FiveMContent>
					</StyledDiv>
				</div>
				<div>
					<StyledDiv
						isTablet={isTablet}
						isMobile={isMobile}
					>
						<FiveMContent
							background="#E2F4F9"
							isTablet={isTablet}
						>
							<FiveMContentHeader
								isTablet={isTablet}
							>
								<H3>
									Meditation – Mann Ka Maintenance
								</H3>
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? { marginTop: 16 }
											: { marginTop: 26 }
									}
								>
									Disconnect, de-stress, recharge
									& glow with our short,
									theme-based meditations.
									Anxiety, stress, stiff neck,
									tired back to recharging energy
									reserves, our meditation topics
									are guided by the woes of busy
									moms. So, get your dose of daily
									Zen, God knows we need it to
									stay calm, patient, and sane.
								</StyledBody1>
							</FiveMContentHeader>
							<SliderImg
								// isTablet={isTablet}
								src={meditation}
								alt=""
							/>
						</FiveMContent>
					</StyledDiv>
				</div>
				<div>
					<StyledDiv
						isTablet={isTablet}
						isMobile={isMobile}
					>
						<FiveMContent
							background="#FAE2E8"
							isTablet={isTablet}
						>
							<FiveMContentHeader
								isTablet={isTablet}
							>
								<H3>
									Mantra – Zor ka Jhatka, Dhire
									Se!
								</H3>
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? { marginTop: 16 }
											: { marginTop: 26 }
									}
								>
									Let’s turn dreams into actions!
									Expect tiny, but powerful dose
									of motivation, get your
									questions answered and life
									mantras from our Counselling
									Psychologist. Because even the
									strongest need warm words and
									support
								</StyledBody1>
							</FiveMContentHeader>
							<SliderImg
								// isTablet={isTablet}
								src={mantra}
								alt=""
							/>
						</FiveMContent>
					</StyledDiv>
				</div>
				<div>
					<StyledDiv
						isTablet={isTablet}
						isMobile={isMobile}
					>
						<FiveMContent
							background="#FFF5CB"
							isTablet={isTablet}
						>
							<FiveMContentHeader
								isTablet={isTablet}
							>
								<H3>Masti ki paathshaala</H3>
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? { marginTop: 16 }
											: { marginTop: 26 }
									}
								>
									Embrace your inner monkey, be
									that little girl again. Masti is
									great for family bonding and
									your brain. But in a mom’s
									multi-tasking day, even fun
									needs to be scheduled in! We
									remind you to have 15-minutes of
									fun daily. Choose from a bunch
									of mastiful activities to stay
									healthy, sane & happy.
								</StyledBody1>
							</FiveMContentHeader>
							<SliderImg
								// isTablet={isTablet}
								src={masti}
								alt=""
							/>
						</FiveMContent>
					</StyledDiv>
				</div>
			</Slider>
		</div>
	);
};

export default memo(Slider1);
