import { FC, useState } from "react";
import {
	DS,
	Subtitle1,
} from "@workshore/nyaari-ds";
import styled from "styled-components";
import useIsMobile from "../../../hooks/useIsMobile/useIsMobile";

const { colors, spacing, fontSize, fontWeight } =
	DS;

const Wrapper = styled.div`
	display: flex;
`;

const AccordionWrapper = styled.div<{
	width?: string;
}>`
	padding: ${spacing.s8}px;

	${({ width = "100%" }) => `
    width: ${width};
 `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	color: ${colors.mineShaft};
`;

const TitleWrapper = styled.div<{
	last: boolean;
	isMobile: boolean;
}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 35px 0px;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 16px 0px;
  `};
`;

const Content = styled.div`
	transition: 0.3s ease-out;

	&.open {
		height: auto;
		padding: ${spacing.s8}px 0;
	}

	&.closed {
		height: 0;
		overflow: hidden;
	}
`;

const Text = styled.div`
	font-size: ${fontSize.body2}px;
	font-weight: ${fontWeight.regular};
`;

const UnderLine = styled.div`
	width: 100%;
	height: 0;
	border-bottom: 1px solid ${colors.doveGrey};
	opacity: 30%;
`;

type Props = {
	width?: string;
	plusIcon: JSX.Element | object;
	minusIcon: JSX.Element | object;
	AccordionData: {
		title: string;
		view: JSX.Element;
	}[];
	activeIndex?: number;
};

const ContactAccordion: FC<Props> = ({
	width,
	plusIcon,
	minusIcon,
	AccordionData,
	activeIndex,
}) => {
	const [selected, setSelected] = useState<
		number | null
	>(activeIndex || 0);
	const isMobile = useIsMobile();

	const toogleAccordion = (id: any) => {
		if (selected === id) {
			return setSelected(null);
		}

		setSelected(id);
	};
	return (
		<Wrapper>
			<AccordionWrapper width={width}>
				{AccordionData.map((data, index) => (
					<Item key={index}>
						<TitleWrapper
							onClick={() =>
								toogleAccordion(index)
							}
							last={
								index === AccordionData.length
							}
							isMobile={isMobile}
						>
							<Subtitle1
								style={{ cursor: "pointer" }}
								fontColor={
									selected === index
										? "mineShaft"
										: "boulder"
								}
							>
								{data.title}
							</Subtitle1>
							{selected === index
								? minusIcon
								: plusIcon}
						</TitleWrapper>
						<Content
							className={
								selected === index
									? "open"
									: "closed"
							}
						>
							<Text>
								{selected === index
									? data.view
									: ""}
							</Text>
						</Content>
						<UnderLine />
					</Item>
				))}
			</AccordionWrapper>
		</Wrapper>
	);
};
export default ContactAccordion;
