import React from "react";
import styled from "styled-components";

const ReminderStyled = styled.div`
	display: flex;
	align-items: flex-start;
	color: #7b7b7b;

	> div:first-child {
		padding-right: 10px;
	}
`;

export const Reminder: React.FC = ({
	children,
}) => {
	return (
		<ReminderStyled>{children}</ReminderStyled>
	);
};
