import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "../Header/Header";

const HeaderRoutes = () => {
  return (
    <Switch>
      <Route path="/dashboard">
        <Header hideOnDesktop />
      </Route>
      <Route>
        <Header />
      </Route>
    </Switch>
  );
};

export default HeaderRoutes;
