import { FC } from "react";
import {
	H1,
	H2,
	Body2,
} from "@workshore/nyaari-ds";
import styled from "styled-components";
import parse, {
	HTMLReactParserOptions,
	domToReact,
} from "html-react-parser";
import { Element } from "domhandler";
import { Link } from "react-router-dom";

import useIsMobile from "../../hooks/useIsMobile/useIsMobile";

const StyledBody2 = styled(Body2)`
	line-height: 30px;
	font-weight: 400;
	margin: 0;
`;

const ContentH1: FC<{
	children: string | JSX.Element | JSX.Element[];
}> = ({ children }) => {
	const isMobile = useIsMobile();
	return (
		<H1 marginTop={isMobile ? "12px" : "50px"}>
			{children}
		</H1>
	);
};
const ContentH2: FC<{
	children: string | JSX.Element | JSX.Element[];
}> = ({ children }) => {
	const isMobile = useIsMobile();
	return (
		<H2 marginTop={isMobile ? "12px" : "50px"}>
			{children}
		</H2>
	);
};
const ContentP: FC<{
	children: string | JSX.Element | JSX.Element[];
}> = ({ children }) => {
	return (
		<StyledBody2
		// marginTop={isMobile ? "12px" : "50px"}
		>
			{children}
		</StyledBody2>
	);
};

const ContentImg: FC<{ src: string }> = ({
	src,
}) => {
	const isMobile = useIsMobile();
	return (
		<img
			style={
				isMobile
					? {
							width: "100%",
							marginBottom: "20px",
					  }
					: {
							width: "100%",
							marginBottom: "52px",
					  }
			}
			src={src}
			alt=""
		/>
	);
};

const htmlOptions: HTMLReactParserOptions = {
	replace: (domNode) => {
		if ((domNode as Element).tagName) {
			if ((domNode as Element).tagName)
				switch ((domNode as Element).tagName) {
					case "p":
						return (
							<ContentP>
								{domToReact(
									(domNode as Element).children,
									htmlOptions,
								)}
							</ContentP>
						);
					case "li":
						return (
							<Body2
								as="li"
								style={{ lineHeight: "30px" }}
								fontWeight={400}
							>
								{domToReact(
									(domNode as Element).children,
									htmlOptions,
								)}
							</Body2>
						);
					case "a":
						return (
							<Link
								to={{
									pathname: (domNode as Element)
										.attribs.href,
								}}
								target="_blank"
							>
								<Body2
									as="span"
									fontColor="redRibbon"
									fontWeight={400}
								>
									{domToReact(
										(domNode as Element).children,
										htmlOptions,
									)}
								</Body2>
							</Link>
						);
					case "h1":
						return (
							<ContentH1>
								{domToReact(
									(domNode as Element).children,
									htmlOptions,
								)}
							</ContentH1>
						);
					case "h2":
						return (
							<ContentH2>
								{domToReact(
									(domNode as Element).children,
									htmlOptions,
								)}
							</ContentH2>
						);
					case "img":
						return (
							<ContentImg
								src={
									(domNode as Element).attribs.src
								}
							/>
						);
					default:
						return null;
				}
		}
	},
};

interface Props {
	data: string;
}

const HTMLParser: FC<Props> = ({ data }) => {
	return <div>{parse(data, htmlOptions)}</div>;
};

export default HTMLParser;
