import {
	Body2,
	Box,
	H3,
	Spacing,
	Wrapper,
} from "@workshore/nyaari-ds";
import React, {
	useCallback,
	useEffect,
	useState,
} from "react";
import { useUpdateWhatWhyMutation } from "../../hooks/graphql/graphql";
import useRoute from "../../hooks/useRoute/useRoute";
import me, { meType } from "../../services/me";
import PageModal from "../PageModal/PageModal";
import WhatWhy from "../WhatWhy/WhatWhy";

const EditWhatWhy = () => {
	const { goBack } = useRoute();
	const [user, setUser] = useState<meType>();
	const [updateWhatWhyMutation, { loading }] =
		useUpdateWhatWhyMutation();
	useEffect(() => {
		setUser(me.get());
	}, []);
	const update = useCallback(() => {
		if (loading) return;
		updateWhatWhyMutation({
			variables: {
				id: user?.id!,
				what: user?.what!,
				why: user?.why!,
			},
		}).finally(() => {
			me.set(user as any);
			goBack();
		});
	}, [
		goBack,
		updateWhatWhyMutation,
		user,
		loading,
	]);
	return (
		<PageModal
			rightActionText={
				loading ? "Submiting.." : "Commit"
			}
			rightActionType="done"
			onRightAction={update}
		>
			<Spacing type="s24" />

			<Wrapper type="fluid">
				<Box>
					<H3 fontColor="redRibbon">
						What's Your Why?
					</H3>
					<Spacing type="s8" />
					<Body2
						fontWeight="normal"
						fontColor="boulder"
					>
						Knowing why it matters is your first
						step to success.
					</Body2>
				</Box>
				<Spacing type="s24" />
				<Box>
					<WhatWhy
						editable={!loading}
						what={(user || { what: "" }).what}
						why={(user || { why: "" }).why}
						onChange={(data) => {
							console.log(data);
							setUser((user: any) => ({
								...user,
								...data,
							}));
						}}
					/>
				</Box>
			</Wrapper>
		</PageModal>
	);
};

export default EditWhatWhy;
