import { DS } from "@workshore/nyaari-ds";
import styled from "styled-components";

const LayoutContainer = styled.div<{
	backgroundColor?: keyof typeof DS.colors;
}>`
	min-height: 100vh;
	${({ backgroundColor = "white" }) => `
background-color: ${DS.colors[backgroundColor]};
`}
`;

export default LayoutContainer;
