const IconUpArrow = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="8.907"
			height="14.06"
			viewBox="0 0 8.907 14.06"
		>
			<g
				id="Group_11861"
				data-name="Group 11861"
				transform="translate(0 0)"
			>
				<path
					id="Path_2560"
					data-name="Path 2560"
					d="M168.1,61.049l3.232-3.616c.153-.174.307-.343.46-.517h-1.084l3.232,3.616c.153.174.307.343.46.517a.767.767,0,1,0,1.084-1.084l-3.232-3.616c-.153-.174-.307-.343-.46-.517a.772.772,0,0,0-1.084,0l-3.232,3.616c-.153.174-.307.343-.46.517a.781.781,0,0,0,0,1.084A.772.772,0,0,0,168.1,61.049Z"
					transform="translate(-166.798 -55.61)"
					fill="#333"
				/>
				<path
					id="Path_2561"
					data-name="Path 2561"
					d="M247.52,60.4V73.054a.709.709,0,0,0,.767.6c.414-.016.767-.266.767-.6V60.4a.709.709,0,0,0-.767-.6C247.873,59.808,247.52,60.058,247.52,60.4Z"
					transform="translate(-243.836 -59.6)"
					fill="#333"
				/>
			</g>
		</svg>
	);
};

export default IconUpArrow;
