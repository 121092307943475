import {
	useState,
	useRef,
	useEffect,
} from "react";
import styled from "styled-components";
import {
	H3,
	Body1,
	Body2,
	Wrapper,
	DS,
	Spacing,
	FlexCenter,
	Button,
	FlexBetween,
	useOutsideClick,
} from "@workshore/nyaari-ds";
import dayjs from "dayjs";
import { useToasts } from "react-toast-notifications";

import {
	useCreateWeeklyPlannerEventMutation,
	useGetMeditationDetailsQuery,
	WeeklyPlannerType,
} from "../../hooks/graphql/graphql";
import Loader from "../Loader/Loader";
import PageModal from "../PageModal/PageModal";
import LabeledIcon from "../LabeledIcon/LabeledIcon";
// import IconFire from "../Icons/IconFire";
import IconUser from "../Icons/IconUser";
import IconClock from "../Icons/IconClock";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import ShowIf from "../ShowIf/ShowIf";
import {
	useHistory,
	useParams,
} from "react-router";
import HTMLParser from "../HTMLParser";
import VideoPlayer1 from "../VideoPlayer1/VideoPlayer1";
import Seperator from "../Seperator/Seperator";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import AddWeeklyPlanner from "../AddWeeklyPlanner/AddWeeklyPlanner";
import CustomCalendar from "../CustomCalendar/CustomCalendar";
import { AddButtonFixed } from "../AddButton/AddButton";
import { useScreenWidth } from "@tinyd/usescreensize";

const Header = styled.div<{ isTablet: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 20px;
	margin-bottom: 16px;

	${({ isTablet }) =>
		isTablet &&
		`
    flex-direction: column;
    padding-top: 12px;
		margin-bottom: 0px;
  `}
`;

const Details = styled.div<{ isTablet: boolean }>`
	display: flex;
	flex-direction: row;
	margin-bottom: 24px;
	> * {
		border-radius: 5px;
		background: ${DS.colors.wildSand};
		padding: 6px 12px;
	}
	> :not(:last-child) {
		margin-right: 20px;
	}

	${({ isTablet }) =>
		isTablet &&
		`
    margin-top: 12px;
		margin-bottom: 0px;
  `}
`;
const IconContainer = styled.div`
	position: absolute;
	z-index: 10;
	right: 0;
	bottom: 0;
	margin-right: 30px;
	margin-bottom: -16px;
`;
const CalendarContainer = styled.div`
	z-index: 1;
	position: absolute;
	right: 0;
	margin-top: -16px;
`;
const CalendarContainer1 = styled.div<{
	isMobile: boolean;
}>`
	position: absolute;
	margin-right: 50px;
	bottom: 0;
	right: 0;

	${({ isMobile }) =>
		isMobile &&
		`
		position: fixed;
		height: 100vh;
		width: 100%;
		background: ${DS.colors.mineShaft}90;
		margin-right: 0px;
		display: flex;
		justify-content:center;
		align-items:center;

		> * {
			margin: 20px;
		}
	`}
`;

function MeditationDetails() {
	const [isSubmitting, setIsSubmitting] =
		useState(false);
	const [date, setDate] = useState(new Date());
	const [showCalendar, setShowCalendar] =
		useState(false);
	const [showCalendar1, setShowCalendar1] =
		useState(false);
	const isTablet = useIsTablet();
	const history = useHistory();
	const ref = useRef<HTMLDivElement>(null);
	const isMobile = useIsMobile();
	const screenWidth = useScreenWidth();
	const isPhone = screenWidth <= 578;
	const calendarRef = useOutsideClick(() => {
		setShowCalendar(false);
	});
	const calendarRef1 = useOutsideClick(() => {
		setShowCalendar1(false);
	});
	const containerRef = useOutsideClick(() => {
		if (!isPhone) return;
		setShowCalendar1(false);
	});

	const { meditation_id: id } =
		useParams<{ meditation_id: string }>();
	// console.log(id);
	const { addToast } = useToasts();
	const { data, loading, error, refetch } =
		useGetMeditationDetailsQuery({
			variables: {
				id,
			},
		});
	// console.log(data);
	const createWeeklyPlannerEvent =
		useCreateWeeklyPlannerEventMutation();

	useEffect(() => {
		setDate(new Date());
	}, [showCalendar, showCalendar1]);

	const addToWeeklyPlanner = () => {
		setIsSubmitting(true);
		createWeeklyPlannerEvent[0]({
			variables: {
				data: {
					date: dayjs(date).format("YYYY-MM-DD"),
					type: WeeklyPlannerType.Meditation,
					resourceId: id,
				},
			},
		})
			.then((res) => {
				setIsSubmitting(false);
				setShowCalendar(false);
				setShowCalendar1(false);
				addToast("Item added to weekly planner", {
					appearance: "success",
					autoDismiss: true,
				});
			})
			.catch((err) =>
				addToast(
					"Error adding to the weekly planner",
					{
						appearance: "error",
						autoDismiss: true,
					},
				),
			);
	};

	return (
		<>
			<ShowIf value={loading}>
				<PageModal>
					<Loader />
				</PageModal>
			</ShowIf>
			<ShowIf value={error}>
				<PageModal>
					<div
						style={{
							height: "90vh",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{error &&
						error!.message.includes(
							"Cast to ObjectId failed",
						) ? (
							<div>
								<Body1>
									Oops! Page does not exist.
								</Body1>
								<Spacing type="s24" />
								<FlexCenter>
									<Button
										width="200px"
										handleButtonClick={() =>
											history.push(
												"/dashboard/meditation",
											)
										}
									>
										<Body2
											as="span"
											fontColor="white"
										>
											Go to Meditation
										</Body2>
									</Button>
								</FlexCenter>
							</div>
						) : (
							<>
								<FlexCenter>
									<Body1>
										Failed to load Meditation
									</Body1>
								</FlexCenter>
								<Spacing type="s24" />
								<FlexCenter>
									<Button width="100px">
										<Body2
											as="span"
											fontColor="white"
											onClick={() => {
												refetch();
											}}
										>
											Retry
										</Body2>
									</Button>
								</FlexCenter>
							</>
						)}
					</div>
				</PageModal>
			</ShowIf>
			{!loading && !error && (
				<ShowIf value={data?.meditation}>
					<PageModal>
						<div style={{ position: "relative" }}>
							{isTablet && (
								<>
									{data?.meditation
										?.vimeo_url && (
										<VideoPlayer1
											url={
												data?.meditation
													?.vimeo_url
											}
										/>
									)}
									<IconContainer
										ref={calendarRef1}
									>
										<AddButtonFixed
											stroke="meditation"
											onClick={() => {
												setShowCalendar1(
													!showCalendar1,
												);
											}}
										/>
										{showCalendar1 ? (
											<CalendarContainer1
												isMobile={isPhone}
											>
												<CustomCalendar
													ref={containerRef}
													style={{
														background: "white",
													}}
													date={date}
													setDate={setDate}
													accentColor="meditation"
													isSubmitting={
														isSubmitting
													}
													handleButtonClick={
														addToWeeklyPlanner
													}
												/>
											</CalendarContainer1>
										) : null}
									</IconContainer>
								</>
							)}
						</div>
						<Wrapper ref={ref}>
							<Header isTablet={isTablet}>
								<H3>{data?.meditation?.title}</H3>
							</Header>
							<FlexBetween
								style={{ alignItems: "center" }}
							>
								<Details isTablet={isTablet}>
									<LabeledIcon
										icon={
											<IconClock color="meditation" />
										}
										text={
											data?.meditation?.duration!
										}
									/>
									{/* <LabeledIcon
											icon={
												<IconFire color="meditation" />
											}
											text={
												data?.meditation
													?.calories_burn! as unknown as string
											}
										/> */}
									<LabeledIcon
										icon={
											<IconUser color="meditation" />
										}
										text={
											data?.meditation
												?.collaborator?.name!
										}
									/>
								</Details>
								{!isTablet ? (
									<div
										ref={calendarRef}
										style={{
											position: "relative",
										}}
									>
										<AddWeeklyPlanner
											stroke="meditation"
											style={{ marginBottom: 24 }}
											onClick={() =>
												setShowCalendar(
													!showCalendar,
												)
											}
										/>
										{showCalendar ? (
											<CalendarContainer>
												<CustomCalendar
													style={{
														background: "white",
													}}
													date={date}
													setDate={setDate}
													accentColor="meditation"
													isSubmitting={
														isSubmitting
													}
													handleButtonClick={
														addToWeeklyPlanner
													}
												/>
											</CalendarContainer>
										) : null}
									</div>
								) : null}
							</FlexBetween>
							{isMobile && (
								<>
									<Spacing type="s24" />
									<Seperator
										height={2}
										color="meditation"
									/>
								</>
							)}
							<div>
								{!isTablet && (
									<>
										{data?.meditation
											?.vimeo_url && (
											<VideoPlayer1
												url={
													data?.meditation
														?.vimeo_url
												}
											/>
										)}
									</>
								)}
								<HTMLParser
									data={
										data?.meditation?.content!
									}
								/>
								<Spacing
									type={isTablet ? "s8" : "s32"}
								/>
							</div>
						</Wrapper>
					</PageModal>
				</ShowIf>
			)}
		</>
	);
}

export default MeditationDetails;
