import { FC } from "react";
import { DS } from "@workshore/nyaari-ds";

interface Props {
	stroke?: keyof typeof DS.colors;
}

const IconMinus: FC<Props> = ({
	stroke = "meals",
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11.167"
			height="4"
			viewBox="0 0 11.167 4"
		>
			<path
				id="Path_2550"
				data-name="Path 2550"
				d="M0,0H11.167"
				transform="translate(0 2)"
				fill="none"
				stroke={DS.colors[stroke]}
				strokeWidth="4"
			/>
		</svg>
	);
};

export default IconMinus;
