import React, { FC } from "react";
import {
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

import { useMeValue } from "../../services/me";
import getIsExpired from "../../utils/getIsExpired";

export const SubscriptionRoute =
  "/dashboard#sb_subscription";

const ProtectedSwitch: FC = ({ children }) => {
  let user = useMeValue();
  if (
    getIsExpired(
      user.createdAt,
      user.trialDays || 0,
      user.subStatus
    )
  ) {
    return (
      <Switch>
        <Route>
          <Redirect to={SubscriptionRoute} />
        </Route>
      </Switch>
    );
  }

  return <Switch>{children}</Switch>;
};

export default ProtectedSwitch;
