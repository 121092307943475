import React from "react";
import { Route, Switch } from "react-router-dom";
import MastiListing from "../../components/MastiListing";
import MastiDetails from "../../components/MastiDetails";
import ProtectedSwitch from "../../components/ProtectedSwitch/ProtectedSwitch";

const MantraPage = () => {
	return (
		<ProtectedSwitch>
			<Route path="/dashboard/masti">
				<MastiListing />
				<Switch>
					<Route
						path="/dashboard/masti/:id"
						exact
					>
						<MastiDetails />
					</Route>
				</Switch>
			</Route>
		</ProtectedSwitch>
	);
};

export default MantraPage;
