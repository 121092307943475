import {
	Box,
	CardContainer,
	H2,
	H3,
	Spacing,
	Wrapper,
	DS,
	Body2,
	FlexBetween,
	Body1,
	Button,
	FlexCenter,
	Caption,
} from "@workshore/nyaari-ds";
import React, {
	FC,
	useCallback,
	useState,
} from "react";
import { useScreenWidth } from "@tinyd/usescreensize";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";

import PageSidebar from "../PageSidebar/PageSidebar";
import ShowIf from "../ShowIf/ShowIf";
import subBg from "../../assets/images/subscription.png";
import me from "../../services/me";
import getIsExpired from "../../utils/getIsExpired";
import dayjs from "dayjs";
import {
	usePlansQuery,
	useSubscribePlanMutation,
} from "../../hooks/graphql/graphql";
import styled from "styled-components";
import SidebarHeader from "../SidebarHeader/SidebarHeader";

const RadioContainer = styled.div`
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #fff;
	border: 1px solid #7b7b7b;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
`;

const Radio: FC<{ active?: boolean }> = ({
	active = false,
}) => {
	return (
		<RadioContainer
			style={
				active
					? { borderColor: DS.colors.redRibbon }
					: {}
			}
		>
			<ShowIf value={active}>
				<div
					style={{
						backgroundColor: DS.colors.redRibbon,
						width: 11,
						height: 11,
						borderRadius: "50%",
					}}
				></div>
			</ShowIf>
		</RadioContainer>
	);
};

const SubscriptionComponent = () => {
	const [subPlan, { loading }] =
		useSubscribePlanMutation({
			fetchPolicy: "no-cache",
		});
	const user = me.get();
	const [currentPlan, setPlan] = useState(
		user.subPlanId || "trial",
	);
	const [error, setError] = useState("");
	const plans = usePlansQuery();
	const isTablet = useIsTablet();

	const isExpired = getIsExpired(
		user.createdAt,
		user.trialDays || 0,
		user.subStatus,
	);
	const day = dayjs(user.createdAt).add(
		user.trialDays || 0,
		"days",
	);
	const currentDateDiff = day.diff(
		dayjs().format("YYYY-MM-DD"),
		"day",
	);
	const onSub = useCallback(() => {
		if (currentPlan === "trial") {
			setError("Please select a plan");
		} else if (user.subPlanId === currentPlan) {
			setError("Please Select A Plan");
		} else {
			subPlan({
				variables: {
					planId: currentPlan,
				},
			})
				.then((res) => {
					window.location = res.data!
						.subscribePlan.url as any;
				})
				.catch((err) => {
					console.log(err);
					setError("Please Try again");
				});
		}
	}, [currentPlan, subPlan, user]);

	const getSubscribedPlan = useCallback(() => {
		if (user.subPlanId) {
			const p =
				plans.data?.subscriptionPlans?.filter(
					(d) =>
						d!.stripepriceid === user.subPlanId,
				);
			if (p?.length) return p[0];
		}

		return {
			duration: 0,
		};
	}, [plans, user]);

	const getPlans = useCallback(() => {
		const p = getSubscribedPlan();
		return (plans.data?.subscriptionPlans || [])
			.filter(
				(d) =>
					d?.stripepriceid !== user.subPlanId,
			)
			.filter(
				(d) => (p?.duration || 0) < d!.duration,
			);
	}, [plans, user, getSubscribedPlan]);

	const finalPlans = getPlans();
	const screenWidth = useScreenWidth();

	return (
		<Box
			padding={isTablet ? "20px 0px" : "40px 0px"}
		>
			<Wrapper type="fluid">
				<ShowIf value={!isTablet}>
					<SidebarHeader>
						Membership
					</SidebarHeader>
				</ShowIf>
				<ShowIf value={isTablet}>
					<H2>Membership</H2>
				</ShowIf>
				<Spacing type="s24" />
				<CardContainer
					background={`url(${subBg}) no-repeat`}
					height={isTablet ? "232px" : "149px"}
					style={{
						backgroundSize: "cover",
					}}
					boxShadow={DS.elevation.elevation1}
				>
					<FlexBetween
						style={{
							height: isTablet
								? "232px"
								: "149px",
							alignItems: "center",
						}}
					>
						<div></div>
						<Box
							marginRight={0}
							style={{
								maxWidth: "50%",
							}}
						>
							<ShowIf
								value={
									screenWidth >= 578 &&
									screenWidth <= 1024
								}
							>
								<Body2
									style={{
										textAlign: "center",
									}}
									fontColor="white"
								>
									<i>
										How much are you willing to
										pay for good health?
									</i>
								</Body2>
								<Body2
									style={{
										textAlign: "center",
									}}
									fontWeight={600}
								>
									<i>
										It can cost less than 50 cents
										a day.
									</i>
								</Body2>
							</ShowIf>
						</Box>
					</FlexBetween>
				</CardContainer>
				<ShowIf
					value={!isTablet || screenWidth <= 578}
				>
					<Spacing type="s24" />
					<Body2 style={{ textAlign: "center" }}>
						<i>
							How much are you willing to pay for
							good health?
						</i>
					</Body2>
					<Body2
						style={{ textAlign: "center" }}
						fontWeight={600}
						fontColor="redRibbon"
					>
						<i>
							It can cost less than 50 cents a
							day.
						</i>
					</Body2>
				</ShowIf>
				<Spacing type="s24" />
				<Body1 fontWeight={600}>
					Current Plan
				</Body1>
				<Spacing type="s16" />
				<ShowIf value={user.subPlanId === null}>
					<CardContainer
						background={
							isExpired
								? "#FAE2E8"
								: DS.colors.wildSand
						}
						style={{
							display: "flex",
						}}
					>
						<Radio
							active={currentPlan === "trial"}
						/>
						<Spacing
							type="s16"
							multiplier={1.3}
						/>
						<Body2
							style={
								isExpired
									? {
											textAlign: "center",
									  }
									: {}
							}
						>
							{isExpired
								? "Trial period is over, please subscribe to continue"
								: `${Math.max(
										0,
										Math.min(
											currentDateDiff + 1,
											user.trialDays || 0,
										),
								  )} of ${
										user.trialDays || 0
								  } Days Remaining of Free Trial`}
						</Body2>
					</CardContainer>
				</ShowIf>
				<ShowIf value={user.subPlanId !== null}>
					{(plans.data?.subscriptionPlans || [])
						.filter((d) => {
							return (
								d?.stripepriceid ===
								user.subPlanId
							);
						})
						.map((data, index) => {
							return (
								<div
									key={index}
									style={
										isTablet
											? {
													display: "flex",
											  }
											: {}
									}
								>
									<CardContainer
										background={
											DS.colors.wildSand
										}
										padding={
											isTablet
												? "20px 30px"
												: "16px"
										}
										style={{
											cursor: "pointer",
											display: "flex",
											flexDirection: isTablet
												? "column"
												: "row",
											alignItems: isTablet
												? "center"
												: "unset",
											justifyContent: "center",
										}}
										onClick={() => {
											setPlan(
												data!.stripepriceid,
											);
											setError("");
										}}
									>
										<div>
											<Body1
												style={{
													textAlign: "center",
												}}
												fontWeight={600}
											>
												{data?.plantitle}
											</Body1>
											<Caption
												fontColor="boulder"
												style={{
													textAlign: "center",
												}}
											>
												{data!.duration === 1
													? "Billed Monthly"
													: data!.duration === 12
													? "Billed Annually"
													: `${
															data!.duration
													  } Months`}
											</Caption>
											<H3
												fontColor="redRibbon"
												style={{
													textAlign: "center",
												}}
											>
												${data!.price}
											</H3>
											<Caption
												style={{
													textAlign: "center",
												}}
												fontWeight={500}
											>
												( $
												{(
													data!.price /
													(data!.duration * 30)
												).toFixed(2)}{" "}
												a day )
											</Caption>
										</div>
									</CardContainer>
									<ShowIf
										value={
											(
												plans.data
													?.subscriptionPlans ||
												[]
											).length -
												1 !==
											index
										}
									>
										<Spacing type="s16" />
									</ShowIf>
								</div>
							);
						})}
				</ShowIf>
				<Spacing type="s24" />
				<Body1 fontWeight={600}>Plans</Body1>
				<Spacing type="s4" />
				<Body2
					fontColor="boulder"
					fontWeight="normal"
				>
					Exclusive, limited-time only offer for
					our early adopters.
				</Body2>
				<Spacing
					type="s16"
					multiplier={isTablet ? 2 : 1}
				/>
				<ShowIf value={plans.loading}>
					<FlexCenter>
						<Body2>Loading...</Body2>
					</FlexCenter>
				</ShowIf>
				<ShowIf value={plans.data}>
					<div
						style={
							isTablet
								? {
										display: "flex",
										justifyContent: "center",
								  }
								: {}
						}
					>
						{finalPlans.map((data, index) => {
							return (
								<div
									key={index}
									style={
										isTablet
											? {
													display: "flex",
											  }
											: {}
									}
								>
									<CardContainer
										background={
											DS.colors.wildSand
										}
										padding={
											isTablet
												? "20px 30px"
												: "16px"
										}
										style={{
											cursor: "pointer",
											display: "flex",
											flexDirection: isTablet
												? "column"
												: "row",
											alignItems: isTablet
												? "center"
												: "unset",
										}}
										onClick={() => {
											setPlan(
												data!.stripepriceid,
											);
											setError("");
										}}
									>
										<div>
											<Radio
												active={
													currentPlan ===
													data!.stripepriceid
												}
											/>
										</div>

										<Spacing type="s16" />

										<div>
											<Body1
												style={{
													textAlign: "center",
												}}
												fontWeight={600}
											>
												{data?.plantitle}
											</Body1>
											<Caption
												fontColor="boulder"
												style={{
													textAlign: "center",
												}}
											>
												{data!.duration === 1
													? "Billed Monthly"
													: data!.duration === 12
													? "Billed Annually"
													: `${
															data!.duration
													  } Months`}
											</Caption>
											<H3
												fontColor="redRibbon"
												style={{
													textAlign: "center",
												}}
											>
												${data!.price}
											</H3>
											<Caption
												style={{
													textAlign: "center",
												}}
												fontWeight={500}
											>
												( $
												{(
													data!.price /
													(data!.duration * 30)
												).toFixed(2)}{" "}
												a day )
											</Caption>
										</div>
									</CardContainer>
									<ShowIf
										value={
											(
												plans.data
													?.subscriptionPlans ||
												[]
											).length -
												1 !==
											index
										}
									>
										<Spacing type="s16" />
									</ShowIf>
								</div>
							);
						})}
					</div>
					<ShowIf value={finalPlans.length === 0}>
						<Body2
							fontColor="red"
							style={{ textAlign: "center" }}
						>
							No Plans available
						</Body2>
					</ShowIf>
				</ShowIf>
				<Spacing type="s16" multiplier={2} />
				<ShowIf value={error.length}>
					<Body2
						fontColor="red"
						style={{ textAlign: "center" }}
					>
						{error}
					</Body2>
					<Spacing type="s16" multiplier={2} />
				</ShowIf>
				<ShowIf value={finalPlans.length}>
					<Button handleButtonClick={onSub}>
						{loading
							? "Subscribing..."
							: "Subscribe Now"}
					</Button>
				</ShowIf>
			</Wrapper>
		</Box>
	);
};

const Subscription = () => {
	return (
		<PageSidebar name="subscription">
			<SubscriptionComponent />
		</PageSidebar>
	);
};

export default Subscription;
