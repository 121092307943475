import { Body1 } from "@workshore/nyaari-ds";
import DS from "@workshore/nyaari-ds/src/DS/DS";
import React, { FC, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

import IconRightArrow from "../Icons/IconRightArrow";
import ShowIf from "../ShowIf/ShowIf";

const ImageSliderContainer = styled.div<{
	isSmall?: boolean;
}>`
	border-radius: ${({ isSmall = false }) =>
		isSmall ? 0 : DS.borderRadius.body1}px;
	overflow: hidden;
	position: relative;
`;

const ImageSlide = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	overflow: hidden;
	& img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

const ArrowContainer = styled.div<{
	isSmall?: boolean;
}>`
	&:before {
		content: "";
	}
	width: 40px;
	height: 40px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.69);
	border-radius: 50%;
	z-index: 1;
	backdrop-filter: blur(4px);
	${({ isSmall = false }) => `
	  margin: 0px ${isSmall ? 40 : 50}px;
  `}
	&:hover,
	&:active {
		background-color: rgba(255, 255, 255, 1);
	}
`;

const ImageSlider: FC<{
	images?: string[];
	isSmall?: boolean;
	width?: number | string;
	height?: number | string;
	showActiveIndex?: boolean;
}> = ({
	images = [],
	isSmall = false,
	width = 404,
	height = 269,
	showActiveIndex = false,
}) => {
	const [index, setIndex] = useState(0);
	if (images.length)
		return (
			<ImageSliderContainer
				style={{
					width,
					height,
				}}
				isSmall={isSmall}
			>
				<Slider
					dots={false}
					infinite
					speed={300}
					slidesToShow={1}
					slidesToScroll={1}
					afterChange={(index) => {
						setIndex(index);
					}}
					nextArrow={
						<ArrowContainer isSmall={isSmall}>
							<IconRightArrow color="black" />
						</ArrowContainer>
					}
					prevArrow={
						<ArrowContainer isSmall={isSmall}>
							<div
								style={{
									transform: "rotate(180deg)",
								}}
							>
								<IconRightArrow color="black" />
							</div>
						</ArrowContainer>
					}
					arrows
				>
					{images.map((image, index) => {
						return (
							<div key={index}>
								<ImageSlide
									style={{
										width,
										height,
									}}
								>
									<img src={image} alt="slide" />
								</ImageSlide>
							</div>
						);
					})}
				</Slider>
				<ShowIf
					value={
						showActiveIndex && images.length > 1
					}
				>
					<div
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							padding: 16,
							backgroundColor: "rgba(0,0,0,0.5)",
						}}
					>
						<Body1 fontColor="white">
							{index + 1}/{images.length}
						</Body1>
					</div>
				</ShowIf>
			</ImageSliderContainer>
		);

	return null;
};

export default ImageSlider;
