import {
	Wrapper,
	H2,
	H3,
	Body1,
	Tag,
	Subtitle1,
	Spacing,
	FlexCenter,
	Body2,
	Button,
} from "@workshore/nyaari-ds";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";
import {
	useParams,
	useHistory,
} from "react-router-dom";

import {
	useGetBlogQuery,
	useGetLimitedBlogsQuery,
} from "../../hooks/graphql/graphql";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import LabeledIcon from "../../components/LabeledIcon/LabeledIcon";
import IconUser from "../../components/Icons/IconUser";
import IconCalender from "../../components/Icons/IconCalender";
import Seperator from "../../components/Seperator/Seperator";
import Footer from "../../components/Footer/Footer";
import Subscribe from "../../components/Subscribe/Subscribe";
import ShowIf from "../../components/ShowIf/ShowIf";
import getDate from "../../utils/getDate";
import Loader from "../../components/Loader/Loader";
import HTMLParser from "../../components/HTMLParser";

const StyledWrapper = styled(Wrapper)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledImg = styled.img<{
	isMobile: boolean;
}>`
	height: 674px;
	width: 100%;
	object-fit: cover;
	margin-bottom: 41px;
	border-radius: 5px;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 239px;
    margin-bottom: 20px;
  `};
`;

const Container = styled.div<{
	isMobile: boolean;
}>`
	width: 804px;

	${({ isMobile }) =>
		isMobile &&
		`
      width: 100%;
      padding: 0 20px;
  `};
`;

const ArticleInfo = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin-right: 35px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    >*{
			font-size: 12px;
      margin-right: 10px;
    }
  `};
`;

// article
const ArticleContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const Article = styled.div<{ isMobile: boolean }>`
	margin: 35px 73px 84px 0px;
	width: 350px;

	${({ isMobile }) =>
		isMobile &&
		`
    margin: 20px 22px 56px 0px;
    width: 265px;
  `};
`;

const ArticleImg = styled.img<{
	isMobile: boolean;
}>`
	height: 185px;
	width: 350px;
	object-fit: cover;
	margin-bottom: 25px;
	border-radius: 5px;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 152px;
    width: 265px;
    margin-bottom: 16px;
  `};
`;

const ArticleInfo1 = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin-right: 21px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    >*{
			font-size: 12px;
      margin-right: 10px;
    }
  `};
`;

const BlogDetail = () => {
	const isMobile = useIsMobile();
	const history = useHistory();

	const { id } = useParams<{ id: string }>();
	// console.log(id);
	const { data, loading, error, refetch } =
		useGetBlogQuery({
			variables: {
				id,
			},
		});
	// console.log({ data, loading, error });

	const {
		data: data1,
		loading: loading1,
		error: error1,
	} = useGetLimitedBlogsQuery({
		variables: {
			limit: 2,
		},
	});

	const blog = data?.blog;
	return (
		<>
			<ShowIf value={loading}>
				<div
					style={{
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{error &&
					error!.message.includes(
						"Cast to ObjectId failed",
					) ? (
						<div>
							<Body1>
								Oops! Page does not exist.
							</Body1>
							<Spacing type="s24" />
							<FlexCenter>
								<Button
									width="200px"
									handleButtonClick={() =>
										history.push("/freemium-gyan")
									}
								>
									<Body2
										as="span"
										fontColor="white"
									>
										Go to Freemium
									</Body2>
								</Button>
							</FlexCenter>
						</div>
					) : (
						<>
							<FlexCenter>
								<Body1>
									Failed to load Blogs
								</Body1>
							</FlexCenter>
							<Spacing type="s24" />
							<FlexCenter>
								<Button width="100px">
									<Body2
										as="span"
										fontColor="white"
										onClick={() => {
											refetch();
										}}
									>
										Retry
									</Body2>
								</Button>
							</FlexCenter>
						</>
					)}
				</div>
			</ShowIf>
			<StyledWrapper>
				{!loading && !error && blog && (
					<ShowIf value={data?.blog}>
						<StyledImg
							isMobile={isMobile}
							src={blog!.banner_image!.url}
							alt=""
						/>
						<Container isMobile={isMobile}>
							<ArticleInfo isMobile={isMobile}>
								<Tag background="#FAE2E8">
									{
										(blog as any).blog_category
											.title
									}
								</Tag>
								<LabeledIcon
									icon={
										<IconUser color="redRibbon" />
									}
									text={
										"By " +
										blog!.collaborator!.name
									}
								/>
								<LabeledIcon
									icon={<IconCalender />}
									text={getDate(blog!.createdAt)}
								/>
							</ArticleInfo>
							<H2
								style={
									isMobile
										? {
												marginTop: "12px",
												marginBottom: "11px",
										  }
										: {
												marginTop: "35px",
												marginBottom: "37px",
										  }
								}
							>
								{blog!.title}
							</H2>
							<Seperator
								height={2}
								color="mantra"
							/>
							<HTMLParser data={blog!.content} />
							{!isMobile && (
								<Seperator
									height={2}
									color="boulder"
								/>
							)}
							<H3
								style={
									isMobile
										? {}
										: { marginTop: "50px" }
								}
							>
								Related Topics
							</H3>
							<ShowIf value={loading1}>
								<Loader />
							</ShowIf>
							{!loading1 && !error1 && (
								<ShowIf
									value={data1?.blogs?.length}
								>
									<ScrollContainer
										className="scroll-container"
										horizontal
									>
										<ArticleContainer>
											{data1?.blogs?.map(
												(blog) => (
													<Article
														key={blog!.id}
														isMobile={isMobile}
													>
														<ArticleImg
															isMobile={isMobile}
															src={
																blog?.banner_image
																	?.url
															}
															alt=""
														/>
														<ArticleInfo1
															isMobile={isMobile}
														>
															<Tag background="#FAE2E8">
																{
																	blog
																		?.blog_category
																		?.title
																}
															</Tag>
															<LabeledIcon
																icon={
																	<IconUser color="redRibbon" />
																}
																text={
																	blog
																		?.collaborator
																		?.name!
																}
															/>
															<LabeledIcon
																icon={
																	<IconCalender />
																}
																text={getDate(
																	blog!.createdAt,
																)}
															/>
														</ArticleInfo1>
														<Subtitle1
															style={{
																cursor: "pointer",
															}}
															marginTop="16px"
															onClick={() =>
																history.push(
																	`/freemium-gyan/${blog?.id}`,
																)
															}
														>
															{blog?.title}
														</Subtitle1>
													</Article>
												),
											)}
										</ArticleContainer>
									</ScrollContainer>
								</ShowIf>
							)}
						</Container>
					</ShowIf>
				)}
			</StyledWrapper>
			<Subscribe />
			<Footer />
		</>
	);
};

export default BlogDetail;
