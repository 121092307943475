import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PubSub from "pubsub-js";
let stack: string[] = [];

function useSidebarStack() {
  const { listen } = useHistory();

  useEffect(() => {
    const sub = listen((location, action) => {
      // console.log(location, action);

      switch (action) {
        case "PUSH":
          if (location.hash?.includes("#sb_")) {
            PubSub.publish(
              `@${location.hash.replace(
                "#sb_",
                ""
              )}`,
              true
            );
          } else {
            let getLast =
              stack[stack.length - 1] || "";
            if (getLast.includes("#sb_")) {
              PubSub.publish(
                `@${getLast.replace("#sb_", "")}`,
                false
              );
              stack.pop();
            }
          }
          stack.push(location.hash);
          break;
        case "POP":
          let lastScreen = stack.pop() || "";
          if (lastScreen?.includes("#sb_")) {
            PubSub.publish(
              `@${lastScreen.replace(
                "#sb_",
                ""
              )}`,
              false
            );
          }
          if (
            lastScreen === "" &&
            location.hash.includes("#sb_")
          ) {
            stack.push(location.hash);
            if (location.hash?.includes("#sb_")) {
              PubSub.publish(
                `@${location.hash.replace(
                  "#sb_",
                  ""
                )}`,
                true
              );
            }
          }
          break;
        case "REPLACE":
          let _lastScreen = stack.pop() || "";
          if (_lastScreen?.includes("#sb_")) {
            PubSub.publish(
              `@${_lastScreen.replace(
                "#sb_",
                ""
              )}`,
              false
            );
          }
          stack.push(location.hash);
          if (location.hash?.includes("#sb_")) {
            PubSub.publish(
              `@${location.hash.replace(
                "#sb_",
                ""
              )}`,
              true
            );
          }
          break;
      }
    });

    return () => sub();
  }, [listen]);
}

export default useSidebarStack;
