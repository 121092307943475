import React, { FC } from "react";

const IconSearch: FC<{ size?: number }> = ({
	size = 15,
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 15.487 15.178"
		>
			<g id="search" transform="translate(1 1)">
				<circle
					id="Ellipse_9"
					data-name="Ellipse 9"
					cx="5.731"
					cy="5.731"
					r="5.731"
					fill="rgba(0,0,0,0)"
					stroke="#7b7b7b"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<line
					id="Line_2"
					data-name="Line 2"
					x1="2.998"
					y1="2.998"
					transform="translate(10.075 9.766)"
					fill="none"
					stroke="#7b7b7b"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
			</g>
		</svg>
	);
};

export default IconSearch;
