import React, { FC } from "react";

const IconCheckBox: FC<{ checked?: boolean }> = ({
	checked = false,
}) => {
	if (checked) {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="17"
				height="17"
				viewBox="0 0 17 17"
			>
				<g
					id="Group_11371"
					data-name="Group 11371"
					transform="translate(-20 -293)"
				>
					<g
						id="Rectangle_1199"
						data-name="Rectangle 1199"
						transform="translate(20 293)"
						fill="#fff"
						stroke="#83c59d"
						strokeWidth="1.5"
					>
						<rect
							width="17"
							height="17"
							rx="3"
							stroke="none"
						/>
						<rect
							x="0.75"
							y="0.75"
							width="15.5"
							height="15.5"
							rx="2.25"
							fill="none"
						/>
					</g>
					<path
						id="Icon_feather-check"
						data-name="Icon feather-check"
						d="M14,9,8.5,14.5,6,12"
						transform="translate(18.5 290.5)"
						fill="none"
						stroke="#83c59d"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
				</g>
			</svg>
		);
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="17"
			viewBox="0 0 17 17"
		>
			<g
				id="Rectangle_1153"
				data-name="Rectangle 1153"
				fill="#fff"
				stroke="#7b7b7b"
				strokeWidth="1.5"
			>
				<rect
					width="17"
					height="17"
					rx="3"
					stroke="none"
				/>
				<rect
					x="0.75"
					y="0.75"
					width="15.5"
					height="15.5"
					rx="2.25"
					fill="none"
				/>
			</g>
		</svg>
	);
};

export default IconCheckBox;
