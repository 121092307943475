const IconPrint = () => {
	return (
		<svg
			id="print"
			xmlns="http://www.w3.org/2000/svg"
			width="20.442"
			height="19"
			viewBox="0 0 20.442 19"
		>
			<g id="Group_11619" data-name="Group 11619">
				<g
					id="Group_11618"
					data-name="Group 11618"
					transform="translate(0 0)"
				>
					<path
						id="Path_2534"
						data-name="Path 2534"
						d="M18.853,22.69H16.684V18.746A.681.681,0,0,0,16,18.065H4.44a.681.681,0,0,0-.681.681V22.69H1.59A1.592,1.592,0,0,0,0,24.28v6.856a1.592,1.592,0,0,0,1.59,1.59H3.758v3.658a.681.681,0,0,0,.681.681H16a.681.681,0,0,0,.681-.681V32.726h2.168a1.592,1.592,0,0,0,1.59-1.59V24.28A1.592,1.592,0,0,0,18.853,22.69ZM5.121,19.428h10.2V22.69H5.121ZM15.321,35.7H5.121V30.127h10.2C15.321,30.3,15.321,35.574,15.321,35.7Zm3.758-4.566a.227.227,0,0,1-.227.227H16.684V29.446A.681.681,0,0,0,16,28.764H4.44a.681.681,0,0,0-.681.681v1.917H1.59a.227.227,0,0,1-.227-.227V24.28a.227.227,0,0,1,.227-.227H18.853a.227.227,0,0,1,.227.227v6.856Z"
						transform="translate(0 -18.065)"
						fill="#333"
					/>
				</g>
			</g>
			<g
				id="Group_11621"
				data-name="Group 11621"
				transform="translate(13.587 7.264)"
			>
				<g
					id="Group_11620"
					data-name="Group 11620"
					transform="translate(0 0)"
				>
					<path
						id="Path_2535"
						data-name="Path 2535"
						d="M342.714,199.988h-1.735a.681.681,0,1,0,0,1.363h1.735a.681.681,0,1,0,0-1.363Z"
						transform="translate(-340.298 -199.988)"
						fill="#333"
					/>
				</g>
			</g>
			<g
				id="Group_11623"
				data-name="Group 11623"
				transform="translate(6.587 15.243)"
			>
				<g
					id="Group_11622"
					data-name="Group 11622"
					transform="translate(0 0)"
				>
					<path
						id="Path_2536"
						data-name="Path 2536"
						d="M171.564,399.834h-5.906a.681.681,0,1,0,0,1.363h5.906a.681.681,0,0,0,0-1.363Z"
						transform="translate(-164.977 -399.834)"
						fill="#333"
					/>
				</g>
			</g>
			<g
				id="Group_11625"
				data-name="Group 11625"
				transform="translate(6.587 13.094)"
			>
				<g
					id="Group_11624"
					data-name="Group 11624"
					transform="translate(0 0)"
				>
					<path
						id="Path_2537"
						data-name="Path 2537"
						d="M171.564,346.006h-5.906a.681.681,0,1,0,0,1.363h5.906a.681.681,0,0,0,0-1.363Z"
						transform="translate(-164.977 -346.006)"
						fill="#333"
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconPrint;
