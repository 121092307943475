import useScreenSize from "@tinyd/usescreensize";
import {
  RefObject,
  useEffect,
  useState,
} from "react";

function useElementSize(
  ref: RefObject<HTMLElement>
) {
  const { width, height } = useScreenSize();
  const [size, setSize] = useState({
    width: width,
    height: height,
  });
  useEffect(() => {
    if (
      width !== null &&
      height !== null &&
      ref.current
    ) {
      setSize({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      });
    }
  }, [width, height, ref]);
  return size;
}

export function useElementWidth(
  ref: RefObject<HTMLElement>
) {
  return useElementSize(ref).width;
}

export function useElementHeight(
  ref: RefObject<HTMLElement>
) {
  return useElementSize(ref).height;
}

export default useElementSize;
