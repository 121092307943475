// import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import PubSub from "pubsub-js";
import { removeLocalPriority } from "../../utils/priorityHelper";
import me from "../me";
import storage from "../storage";

// import { REFRESH_TOKEN_URL } from "../../config";
const AUTH_TOKEN_KEY = "x-a-token";
export const SharedStateAuthKey = "@auth";
export const SharedStateAuthLogoutKey =
  "@auth-logout";
let authToken =
  localStorage.getItem(AUTH_TOKEN_KEY) || "";

export const setAuthToken = (
  token: string = ""
) => {
  PubSub.publish(
    SharedStateAuthKey,
    token.length > 0
  );

  localStorage.setItem(AUTH_TOKEN_KEY, token);
  authToken = token;
};

(window as any)["a"] = setAuthToken;

export const getAuthToken = () => authToken;

export const hasAuthToken = () =>
  authToken.length > 0;

export const isAuthTokenExpired = () => {
  if (!hasAuthToken()) {
    return true;
  }
  try {
    let token = jwtDecode<{
      id: string;
      exp: number;
    }>(getAuthToken());
    // console.log(token, dayjs().unix());
    if (token.exp < dayjs().unix()) {
      return true;
    }

    return false;
  } catch (e) {
    console.log(e);
    return true;
  }
};

//TODO fix this refresh token
export const refreshAuthToken = async () => {
  return new Promise((resolve, reject) => {
    removeAuthToken();
    reject(null);
  });

  // let response = await axios.get<{
  //   status: boolean;
  //   msg: string;
  //   token: string;
  // }>(REFRESH_TOKEN_URL, {
  //   withCredentials: true,
  // });
  // console.log(response);
  // if (response.data.status) {
  //   setAuthToken(response.data.token);
  // } else {
  //   setAuthToken("");
  // }
  // return response.data;
};

export const removeAuthToken = () => {
  me.remove();
  setAuthToken("");
  removeLocalPriority();
  storage.remove("dailyQuote");
  PubSub.publish(SharedStateAuthLogoutKey, true);
  console.log("logout");
};
