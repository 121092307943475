import { Route, Switch } from "react-router-dom";
import ProtectedSwitch from "../../components/ProtectedSwitch/ProtectedSwitch";
import MeditationCategory from "../../components/MeditationCategory/MeditationCategory";
import MeditationListing from "../../components/MeditationListing/MeditationListing";
import MeditationDetails from "../../components/MeditationDetails/MeditationDetails";

const MeditationPage = () => {
	return (
		<ProtectedSwitch>
			<Route exact path="/dashboard/meditation">
				<MeditationCategory />
			</Route>
			<Route path="/dashboard/meditation/:category_id">
				<MeditationListing />
				<Switch>
					<Route
						path="/dashboard/meditation/:category_id/:meditation_id"
						exact
					>
						<MeditationDetails />
					</Route>
				</Switch>
			</Route>
		</ProtectedSwitch>
	);
};

export default MeditationPage;
