import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconFire: FC<{
	color: keyof typeof DS.colors;
}> = ({ color = "boulder" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11.038"
			height="16.102"
			viewBox="0 0 11.038 16.102"
		>
			<g
				id="fire"
				transform="translate(-85.9 0.507)"
			>
				<path
					id="Path_1792"
					data-name="Path 1792"
					d="M95.966,7.962c-.179-.415-.424-.89-.684-1.393A17.852,17.852,0,0,1,93.9,3.4,3.178,3.178,0,0,1,94.077.689a.442.442,0,0,0-.471-.676A5.266,5.266,0,0,0,90.59,2.126a3.529,3.529,0,0,0-.5,1.765,2.36,2.36,0,0,0-.907-.46.442.442,0,0,0-.523.492A3.924,3.924,0,0,1,87.667,6.8a11.1,11.1,0,0,0-.837,1.378,4.4,4.4,0,0,0,.317,4.307A5.193,5.193,0,0,0,91.64,15.1a5.1,5.1,0,0,0,1.318-.173l.1-.028a4.612,4.612,0,0,0,2.861-2.517,5.488,5.488,0,0,0,.047-4.415Zm-.849,4.043c-.286.619-1.679,2.515-3.477,2.253a4.909,4.909,0,0,1-2.829-1.118,4.325,4.325,0,0,1-.9-1.1,3.519,3.519,0,0,1-.289-3.479A10.412,10.412,0,0,1,88.4,7.3a5.412,5.412,0,0,0,1.163-2.7,5.459,5.459,0,0,1,.78.746.442.442,0,0,0,.766-.4,3,3,0,0,1,.238-2.365A3.74,3.74,0,0,1,92.9,1.232a4.864,4.864,0,0,0,.152,2.412A18.5,18.5,0,0,0,94.5,6.975c.252.489.491.951.658,1.338a4.591,4.591,0,0,1-.037,3.693Z"
					transform="translate(0 0)"
					fill={DS.colors[color]}
					stroke={DS.colors[color]}
					strokeWidth="1"
				/>
			</g>
		</svg>
	);
};

export default IconFire;
