const IconRight = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10.518"
			height="18.536"
			viewBox="0 0 10.518 18.536"
		>
			<g
				id="Group_11465"
				data-name="Group 11465"
				transform="translate(1.768 1.768)"
			>
				<g
					id="Icon_feather-arrow-right"
					data-name="Icon feather-arrow-right"
					transform="translate(0)"
				>
					<path
						id="Path_56"
						data-name="Path 56"
						d="M18,7.5,25.5,15,18,22.5"
						transform="translate(-18 -7.5)"
						fill="none"
						stroke="#333"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2.5"
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconRight;
