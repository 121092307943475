import React from "react";
import { Route, Switch } from "react-router-dom";
import MantraListing from "../../components/MantraListing";
import MantraDetails from "../../components/MantraDetails";
import ProtectedSwitch from "../../components/ProtectedSwitch/ProtectedSwitch";
import JournalListing from "../../components/JournalListing";

const MantraPage = () => {
	return (
		<ProtectedSwitch>
			<Route path="/dashboard/mantra">
				<MantraListing />
				<Switch>
					<Route
						path="/dashboard/mantra/:id"
						exact
					>
						<MantraDetails />
					</Route>
				</Switch>
			</Route>
			<Route path="/dashboard/journal">
				<JournalListing />
			</Route>
		</ProtectedSwitch>
	);
};

export default MantraPage;
