import {
  ApolloClient,
  // HttpLink,
  InMemoryCache,
  from,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { GQL_URL } from "../../config";
import {
  getAuthToken,
  hasAuthToken,
  isAuthTokenExpired,
  refreshAuthToken,
} from "../authToken";

const httpLink = createUploadLink({
  uri: GQL_URL,
  // credentials: "include",
});

const RefreshToken = setContext((req) => {
  return new Promise((resolve, reject) => {
    console.log(req);
    if (!hasAuthToken()) {
      resolve(null);
      return;
    }
    if (isAuthTokenExpired()) {
      console.log("TOKEN EXPIRED ⛔️");
      refreshAuthToken()
        .then((status) => {
          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      resolve(null);
    }
  });
});

const AddToken = new ApolloLink(
  (operation, forward) => {
    console.log(operation);
    if (hasAuthToken()) {
      operation.setContext(
        ({ headers = {} }) => ({
          headers: {
            ...headers,
            authorization: `Bearer ${getAuthToken()}`,
          },
        })
      );
    } else {
      console.log(
        "token not found",
        getAuthToken().length
      );
    }
    return forward(operation);
  }
);

export const client = new ApolloClient({
  link: from([
    RefreshToken,
    AddToken,
    httpLink as any,
  ]),
  cache: new InMemoryCache(),
});
