import { FC, useState } from "react";
import styled from "styled-components";
import {
	Body1,
	Body2,
	Box,
	Caption,
	DS,
} from "@workshore/nyaari-ds";

import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import IconUser from "../Icons/IconUser";
import IconFire from "../Icons/IconFire";
import IconClock from "../Icons/IconClock";
import MinusButton from "../MinusButton/MinusButton";

const StyledBox = styled(Box)`
	height: 74px;
	width: 100%;
	background: ${DS.colors.white};
	border-radius: 5px;
	overflow: hidden;
`;
const ItemWrapper = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	position: relative;

	${({ isMobile }) =>
		isMobile &&
		`
    align-items: start;
    justify-content: flex-start;
  `}
`;
const StyledImg = styled.img<{
	isMobile: boolean;
}>`
	height: 74px;
	width: 124px;
	object-fit: cover;
	margin-right: 20px;

	${({ isMobile }) =>
		isMobile &&
		`
    width: 74px;
    margin-right: 10px;
  `}
`;
const DetailsWrapper = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	align-items: center;
	> * {
		margin-right: 24px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    margin-top: 8px;
    > * {
      margin-right: 10px;
    }
  `}
`;
const IconWrapper = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	align-items: center;
	> :first-child {
		margin-right: 5px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    > svg{
      width: 12px;
      height: 12px;
    }
  `}
`;
const Tag = styled.div`
	background: ${DS.colors.wildSand};
	color: ${DS.colors.black};
	padding: 6px 16px;
	border-radius: 5px;
`;

interface Props {
	imgSrc: string | undefined;
	title: string;
	person: string;
	time?: string;
	calorie?: string;
	mealTime?: string;
	accentColor: keyof typeof DS.colors;
	onMinusClick?:
		| React.MouseEventHandler<HTMLDivElement>
		| undefined;
}

const WeeklyMItem: FC<Props> = ({
	imgSrc,
	mealTime,
	title,
	person,
	time,
	calorie,
	accentColor,
	onMinusClick,
}) => {
	const [isHovering, setIsHovering] =
		useState(false);
	const isMobile = useIsMobile();
	return (
		<StyledBox>
			{!isMobile ? (
				<ItemWrapper
					isMobile={isMobile}
					onMouseEnter={() => setIsHovering(true)}
					onMouseLeave={() =>
						setIsHovering(false)
					}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							overflow: "hidden",
						}}
					>
						<StyledImg
							isMobile={isMobile}
							src={imgSrc}
						/>
						<Body1
							style={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{title}
						</Body1>
					</div>

					<DetailsWrapper isMobile={isMobile}>
						{!isHovering ? (
							<>
								<IconWrapper isMobile={isMobile}>
									<IconUser color={accentColor} />
									<Body2>By {person}</Body2>
								</IconWrapper>
								{time ? (
									<IconWrapper
										isMobile={isMobile}
									>
										<IconClock
											color={accentColor}
										/>
										<Body2>{time}</Body2>
									</IconWrapper>
								) : null}
								{calorie ? (
									<IconWrapper
										isMobile={isMobile}
									>
										<IconFire
											color={accentColor}
										/>
										<Body2>{calorie}</Body2>
									</IconWrapper>
								) : null}
								{mealTime ? (
									mealTime === "PREBREAKFAST" ? (
										<Tag>PRE-BREAKFAST</Tag>
									) : mealTime === "AMSNACK" ? (
										<Tag>AM SNACK</Tag>
									) : mealTime === "PMSNACK" ? (
										<Tag>PM SNACK</Tag>
									) : (
										<Tag>{mealTime}</Tag>
									)
								) : null}
							</>
						) : (
							<MinusButton
								stroke={accentColor}
								onClick={onMinusClick}
							/>
						)}
					</DetailsWrapper>
				</ItemWrapper>
			) : (
				<ItemWrapper isMobile={isMobile}>
					<StyledImg
						isMobile={isMobile}
						src={imgSrc}
					/>
					<div
						style={
							mealTime
								? {
										display: "flex",
										flexDirection: "column",
										margin: "8px 0",
										overflow: "hidden",
								  }
								: {
										display: "flex",
										flexDirection: "column",
										margin: "10px 0",
										overflow: "hidden",
								  }
						}
					>
						{mealTime ? (
							<Caption
								style={{
									fontSize: 10,
									lineHeight: "18px",
								}}
								fontColor="meals"
							>
								{mealTime}
							</Caption>
						) : null}
						<Body1
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
								fontWeight: 600,
								marginRight: 35,
							}}
						>
							{title}
						</Body1>

						<DetailsWrapper
							style={
								mealTime
									? { marginTop: "1px" }
									: { marginTop: "10px" }
							}
							isMobile={isMobile}
						>
							<>
								<IconWrapper isMobile={isMobile}>
									<IconUser color={accentColor} />
									<Caption
										style={{
											fontSize: 10,
											lineHeight: "20px",
										}}
									>
										By {person}
									</Caption>
								</IconWrapper>
								{time ? (
									<IconWrapper
										isMobile={isMobile}
									>
										<IconClock
											color={accentColor}
										/>
										<Caption
											style={{
												fontSize: 10,
												lineHeight: "20px",
											}}
										>
											{time}
										</Caption>
									</IconWrapper>
								) : null}
								{calorie ? (
									<IconWrapper
										isMobile={isMobile}
									>
										<IconFire
											color={accentColor}
										/>
										<Caption
											style={{
												fontSize: 10,
												lineHeight: "20px",
											}}
										>
											{calorie}
										</Caption>
									</IconWrapper>
								) : null}
							</>
						</DetailsWrapper>
					</div>
					<div
						style={{
							position: "absolute",
							right: 10,
							top: 10,
						}}
					>
						<MinusButton
							stroke={accentColor}
							onClick={onMinusClick}
						/>
					</div>
				</ItemWrapper>
			)}
		</StyledBox>
	);
};

export default WeeklyMItem;
