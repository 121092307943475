import dayjs from "dayjs";

const storage_prefix = "__storage_";

function get<T = any>(
  key: string,
  defaultValue: T = null as any
): { value: T; type: string; timestamp: number } {
  let data = localStorage.getItem(
    storage_prefix + key
  );
  if (data) {
    let json: {
      value: T;
      type: string;
      timestamp: number;
    } = JSON.parse(data);
    if (json.value) {
      return json;
    }

    return {
      value: defaultValue,
      timestamp: -1,
      type: "NA",
    };
  }

  return {
    value: defaultValue,
    timestamp: -1,
    type: "NA",
  };
}

function set<T = any>(
  key: string,
  value: T
): void {
  localStorage.setItem(
    storage_prefix + key,
    JSON.stringify({
      value,
      type: typeof value,
      timestamp: dayjs().unix(),
    })
  );
}

function remove(key: string): void {
  localStorage.removeItem(storage_prefix + key);
}

const storage = { get, set, remove };

export default storage;
