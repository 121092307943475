import React from "react";

const IconChecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.621"
      height="21.25"
      viewBox="0 0 28.621 21.25"
    >
      <g
        id="Group_10119"
        data-name="Group 10119"
        transform="translate(-318 -224.375)"
      >
        <path
          id="Icon_feather-check"
          data-name="Icon feather-check"
          d="M20,9l-9.625,9.625L6,14.25"
          transform="translate(324.5 225.5)"
          fill="none"
          stroke="#83c59d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <g
          id="Group_10118"
          data-name="Group 10118"
          transform="translate(443.006 -2922.875)"
        >
          <path
            id="Path_1467"
            data-name="Path 1467"
            d="M11.425,22.851H4.095A2.1,2.1,0,0,1,2,20.756V6.095A2.1,2.1,0,0,1,4.095,4H18.756a2.1,2.1,0,0,1,2.095,2.095v7.331"
            transform="translate(-126.006 3144.25)"
            fill="rgba(0,0,0,0)"
            stroke="#83c59d"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconChecked;
