import {
	Fragment,
	useState,
	useEffect,
} from "react";
import {
	Wrapper,
	H2,
	H3,
	Body1,
	Tag,
	DS,
	Spacing,
	FlexCenter,
	Body2,
	Button,
} from "@workshore/nyaari-ds";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";

import {
	useGetAllBlogsQuery,
	useGetBlogCategoriesQuery,
	GetAllBlogsQuery,
} from "../../hooks/graphql/graphql";
// import why_nyaari_2 from "../../assets/images/why_nyaari_2.jpg";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import LabeledIcon from "../../components/LabeledIcon/LabeledIcon";
import IconUser from "../../components/Icons/IconUser";
import IconCalender from "../../components/Icons/IconCalender";
import Footer from "../../components/Footer/Footer";
import Subscribe from "../../components/Subscribe/Subscribe";
import Seperator from "../../components/Seperator/Seperator";
import ShowIf from "../../components/ShowIf/ShowIf";
import getDate from "../../utils/getDate";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const Header = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 80px;
`;

// main article
const MainArticle = styled.div`
	width: 603px;
	margin-right: 50px;

	@media screen and (max-width: ${DS.breakPoints
			.l}px) {
		width: 548px;
	}
`;

const StyledImg = styled.img`
	height: 356px;
	width: 603px;
	margin-bottom: 25px;
	object-fit: cover;
	border-radius: 5px;

	@media screen and (max-width: ${DS.breakPoints
			.l}px) {
		width: 548px;
	}
`;

// secondary article
const SecondaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const SecondaryArticle = styled.div`
	display: flex;
	flex-direction: row;
`;

const SecondaryImageDiv = styled.div`
	height: 165px;
	width: 232px;
	margin-right: 28px;
`;

const StyledImg1 = styled.img`
	height: 165px;
	width: 232px;
	object-fit: cover;
	border-radius: 5px;
`;

const SecondaryContent = styled.div`
	display: flex;
	flex-direction: column;
`;

const SecondaryArticleInfo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	> * {
		margin-right: 27px;
	}
`;

const SecondaryBody1 = styled(Body1)<{
	isMobile: boolean;
}>`
	line-height: 24px;

	${({ isMobile }) =>
		isMobile &&
		`
    line-height: 23px;
  `};
`;

const StyledBody2 = styled(Body2)`
	line-height: 26px;
	font-weight: 400;
`;

// article
const Article = styled.div<{ isMobile: boolean }>`
	display: flex;
	flex-direction: row;
	margin: 64px 0;

	${({ isMobile }) =>
		isMobile &&
		`
    margin: 20px 0;
    flex-direction: column;
  `};
`;

const ArticleContent = styled.div<{
	isMobile: boolean;
}>`
	width: 658px;

	${({ isMobile }) =>
		isMobile &&
		`
    width: 100%;
  `};
`;

const ArticleInfo = styled.div<{
	isMobile: boolean;
	isTablet: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin-right: 35px;
	}

	${({ isTablet }) =>
		isTablet &&
		`
    > * {
      margin-right: 15px;
    }
  `};

	${({ isMobile }) =>
		isMobile &&
		`
    margin-top: 17px;
    >*{
			font-size: 12px;
      margin-right: 10px;
    }
  `};
`;

const ArticleImageDiv = styled.div<{
	isTablet: boolean;
	isMobile: boolean;
}>`
	width: 508px;
	margin-right: 72px;

	${({ isTablet }) =>
		isTablet &&
		`
    margin-right: 40px;
  `};

	${({ isMobile }) =>
		isMobile &&
		`
		height: 197px;
    margin-right: 0px;
    width: 100%;
  `};
`;

const StyledImg2 = styled.img<{
	isMobile: boolean;
}>`
	border-radius: 5px;
	width: 508px;
	/* height: 298px; */
	object-fit: cover;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 197px;
    width: 100%;
  `};
`;

const StyledBody1 = styled(Body1)<{
	isMobile: boolean;
}>`
	line-height: 36px;
	font-weight: 400;

	${({ isMobile }) =>
		isMobile &&
		`
    line-height: 23px;
  `};
`;

// tags
const Tags = styled.div<{ isMobile: boolean }>`
	display: flex;
	justify-content: row;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 50px;
	> * {
		padding: 7px 24px;
		margin-right: 38px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    margin-top: 14px;
    margin-bottom: 23px;
    > *{
			font-size: 12px;
			padding: 4px 10px;
      margin-right: 11px;
    }
  `};
`;

const Blog = () => {
	const [initialCategory, setInitialCategory] =
		useState<string[] | undefined>([]);
	const [
		selectedCategories,
		setSelectedCategories,
	] = useState<string[] | undefined>([]);
	const [filteredData, setFilteredData] =
		useState<GetAllBlogsQuery | undefined>();
	const [allActive, setAllActive] =
		useState(true);

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const history = useHistory();

	const { data, loading, error, refetch } =
		useGetAllBlogsQuery();
	const blogCategories =
		useGetBlogCategoriesQuery();

	useEffect(() => {
		setFilteredData(data);
	}, [data]);

	// initial categories
	useEffect(() => {
		const categories =
			blogCategories?.data?.blogCategories?.map(
				(blog) => blog!.id,
			);
		setInitialCategory(categories);
		setSelectedCategories(categories);
	}, [blogCategories]);

	// filtering data based on suggestions
	useEffect(() => {
		if (selectedCategories && data?.blogs) {
			const blogs = (data?.blogs as any).filter(
				(blog: any) =>
					selectedCategories.includes(
						blog?.blog_category.id,
					),
			);
			// console.log(blogs);
			const filtered = { blogs };
			setFilteredData(filtered);
		}
	}, [selectedCategories, data]);

	// all tag select
	const handleAllSelect = () => {
		setAllActive(true);
		setSelectedCategories(initialCategory);
		setFilteredData(data);
	};

	// tag select
	const handleSelect = (id: any) => {
		setAllActive(false);
		if (allActive) {
			setSelectedCategories([id]);
		} else if (selectedCategories) {
			if (
				selectedCategories.includes(id) &&
				selectedCategories.length === 1
			) {
				setAllActive(true);
				setSelectedCategories(initialCategory);
			} else if (
				selectedCategories.includes(id)
			) {
				const updated = selectedCategories.filter(
					(category) => category !== id,
				);
				setSelectedCategories(updated);
			} else {
				setSelectedCategories([
					...selectedCategories,
					id,
				]);
			}
		}
	};

	if (!filteredData) return null;

	return (
		<>
			<ShowIf value={loading}>
				<div
					style={{
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<FlexCenter>
						<Body1>Failed to load Blogs</Body1>
					</FlexCenter>
					<Spacing type="s24" />
					<FlexCenter>
						<Button width="100px">
							<Body2
								as="span"
								fontColor="white"
								onClick={() => {
									refetch();
								}}
							>
								Retry
							</Body2>
						</Button>
					</FlexCenter>
				</div>
			</ShowIf>
			<ShowIf value={!loading && !error}>
				<Wrapper>
					<H2
						marginTop={isMobile ? "16px" : "90px"}
					>
						Freemium Gyaan
					</H2>
					<ScrollContainer
						className="scroll-container"
						horizontal
					>
						<ShowIf
							value={
								blogCategories?.data
									?.blogCategories?.length
							}
						>
							<Tags isMobile={isMobile}>
								<Tag
									background={
										allActive
											? "#FAE2E8"
											: "#F6F6F6"
									}
									onClick={() => {
										handleAllSelect();
									}}
								>
									All
								</Tag>
								{blogCategories?.data?.blogCategories?.map(
									(category) => (
										<Tag
											key={
												(category as any).title
											}
											onClick={() =>
												handleSelect(
													(category as any).id,
												)
											}
											background={
												selectedCategories?.includes(
													(category as any).id,
												) && allActive === false
													? "#FAE2E8"
													: "#F6F6F6"
											}
										>
											{(category as any).title}
										</Tag>
									),
								)}
							</Tags>
						</ShowIf>
					</ScrollContainer>
					{!isTablet && (
						<>
							<Header>
								{!loading && !error && (
									<ShowIf
										value={
											filteredData?.blogs?.length
										}
									>
										{filteredData
											?.blogs!.slice(0, 1)
											?.map((blog: any) => (
												<MainArticle>
													<StyledImg
														src={
															blog.banner_image
																.url
														}
														alt=""
													/>
													<ArticleInfo
														isTablet={isTablet}
														isMobile={isMobile}
													>
														<Tag background="#FAE2E8">
															{
																blog.blog_category
																	.title
															}
														</Tag>
														<LabeledIcon
															icon={
																<IconUser color="redRibbon" />
															}
															text={
																"By " +
																blog.collaborator
																	.name
															}
														/>
														<LabeledIcon
															icon={
																<IconCalender />
															}
															text={getDate(
																blog.createdAt,
															)}
														/>
													</ArticleInfo>
													<H3
														style={{
															marginTop: "22px",
															marginBottom:
																"26px",
															cursor: " pointer",
														}}
														onClick={() =>
															history.push(
																`/freemium-gyan/${blog.id}`,
															)
														}
													>
														{blog.title}
													</H3>
													<StyledBody1
														isMobile={isMobile}
													>
														{
															blog.short_description
														}
													</StyledBody1>
												</MainArticle>
											))}
									</ShowIf>
								)}
								<SecondaryContainer>
									{filteredData
										?.blogs!.slice(1, 4)
										?.map((blog: any) => (
											<SecondaryArticle
												key={blog.id}
											>
												<SecondaryImageDiv>
													<StyledImg1
														src={
															blog.banner_image
																.url
														}
														alt=""
													/>
												</SecondaryImageDiv>
												<SecondaryContent>
													<SecondaryArticleInfo>
														<Tag background="#FAE2E8">
															{
																blog.blog_category
																	.title
															}
														</Tag>
														<LabeledIcon
															icon={
																<IconUser color="redRibbon" />
															}
															text={
																"By " +
																blog.collaborator
																	.name
															}
														/>
														<LabeledIcon
															icon={
																<IconCalender />
															}
															text={getDate(
																blog.createdAt,
															)}
														/>
													</SecondaryArticleInfo>
													<SecondaryBody1
														isMobile={isMobile}
														style={{
															marginTop: "17px",
															marginBottom: "9px",
															cursor: "pointer",
														}}
														onClick={() =>
															history.push(
																`/freemium-gyan/${blog.id}`,
															)
														}
													>
														{blog.title}
													</SecondaryBody1>
													<StyledBody2>
														{blog.short_description.substr(
															0,
															55,
														) + "..."}
													</StyledBody2>
												</SecondaryContent>
											</SecondaryArticle>
										))}
								</SecondaryContainer>
							</Header>
							{filteredData?.blogs?.length &&
							filteredData?.blogs?.length > 4 ? (
								<Seperator />
							) : (
								<></>
							)}
						</>
					)}
					{filteredData
						?.blogs!.slice(isTablet ? 0 : 4)
						?.map((blog: any, index) => (
							<Fragment key={blog.id}>
								<Article isMobile={isMobile}>
									<ArticleImageDiv
										isTablet={isTablet}
										isMobile={isMobile}
									>
										<StyledImg2
											isMobile={isMobile}
											src={blog.banner_image.url}
											alt=""
										/>
									</ArticleImageDiv>
									<ArticleContent
										isMobile={isMobile}
									>
										<ArticleInfo
											isTablet={isTablet}
											isMobile={isMobile}
										>
											<Tag background="#FAE2E8">
												{blog.blog_category.title}
											</Tag>
											<LabeledIcon
												icon={
													<IconUser color="redRibbon" />
												}
												text={
													"By " +
													blog.collaborator.name
												}
											/>
											<LabeledIcon
												icon={<IconCalender />}
												text={getDate(
													blog.createdAt,
												)}
											/>
										</ArticleInfo>
										<H3
											style={
												isMobile
													? { marginTop: "17px" }
													: {
															marginTop: "23px",
															marginBottom:
																"21px",
															cursor: "pointer",
													  }
											}
											onClick={() =>
												history.push(
													`/freemium-gyan/${blog.id}`,
												)
											}
										>
											{blog.title}
										</H3>
										{!isMobile && (
											<StyledBody1
												isMobile={isMobile}
											>
												{blog.short_description}
											</StyledBody1>
										)}
									</ArticleContent>
								</Article>
								{!isTablet &&
									filteredData!.blogs!.slice(
										isTablet ? 0 : 4,
									).length -
										1 !==
										index && <Seperator />}
								{isTablet &&
									filteredData!.blogs!.length -
										1 !==
										index && <Seperator />}
							</Fragment>
						))}
					<Spacing type="s32" multiplier={2} />
				</Wrapper>
			</ShowIf>
			<Subscribe />
			<Footer />
		</>
	);
};

export default Blog;
