import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconTick: FC<{
	color: keyof typeof DS.colors;
}> = ({ color = "boulder" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12.995"
			height="9.404"
			viewBox="0 0 12.995 9.404"
		>
			<path
				id="Icon_feather-check"
				data-name="Icon feather-check"
				d="M16.166,9,9.177,15.989,6,12.812"
				transform="translate(-4.586 -7.586)"
				fill="none"
				stroke={DS.colors[color]}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
			/>
		</svg>
	);
};

export default IconTick;
