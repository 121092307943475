import React, {
	useState,
	useEffect,
} from "react";
import { useParams } from "react-router-dom";
import {
	Subtitle1,
	Body1,
	Caption,
	DS,
	Box,
	FlexCenter,
	Spacing,
} from "@workshore/nyaari-ds";
import { Link } from "react-router-dom";

import SplitView from "../components/SplitView/SplitView";
import styled from "styled-components";
import useIsMobile from "../hooks/useIsMobile/useIsMobile";
import getFirstError from "../utils/getFirstError";
import { useEmailConfirmationMutation } from "../hooks/graphql/graphql";

import water_mark from "../assets/images/water_mark.svg";
import bg from "../assets/images/login_bg.png";
import ShowIf from "./ShowIf/ShowIf";
import Loader from "./Loader/Loader";
import useRoute from "../hooks/useRoute/useRoute";
import { setAuthToken } from "../services/authToken";

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	max-width: 500px;
	width: 100%;
`;

const StyledBox = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	& > div {
		display: flex;
		p:first-child {
			margin-right: 5px;
		}
	}
`;

const StyledWaterMark = styled.img`
	position: absolute;
	right: -15px;
	top: 89px;
	z-index: -1;
	@media screen and (max-width: ${DS.breakPoints
			.m}px) {
		display: none;
	}
`;

const EmailConfirmation = () => {
	const { code } = useParams<{ code: string }>();
	const [emailConfirmation] =
		useEmailConfirmationMutation();
	const [status, setStatus] = useState({
		success: false,
		loading: true,
	});
	const isMobile = useIsMobile();
	const [serverError, setServerError] =
		useState("");
	const { goTo } = useRoute();

	useEffect(() => {
		emailConfirmation({
			variables: {
				code,
			},
		})
			.then((result) => {
				setStatus({
					success:
						!!result?.data?.emailConfirmation
							?.user.id,
					loading: false,
				});
				setAuthToken(
					result.data!.emailConfirmation!.jwt ||
						"",
				);
				goTo("/dashboard", "replace");
			})
			.catch((err) => {
				console.log({ err });
				setServerError(getFirstError(err));
				setStatus({ ...status, loading: false });
			});
	}, []);

	return (
		<SplitView bgImgUrl={bg}>
			<StyledWaterMark
				src={water_mark}
				alt="water mark"
			/>
			<FormWrapper
				style={{
					height: isMobile ? "100%" : "unset",
				}}
			>
				{/* Loading */}
				<ShowIf value={status.loading}>
					<Loader>
						<Body1>Confirming..</Body1>
					</Loader>
				</ShowIf>

				<ShowIf value={status.success}>
					<>
						<div>
							<Subtitle1 fontColor="redRibbon">
								You’re Welcome! That’s what we’re
								here for ☺
							</Subtitle1>
							<br />
							<Body1>
								I sincerely wish that Team Nyaari
								makes it easier for you to crack
								your health & wellness goals. That
								our experts and their support help
								you conquer the challenges you
								face in prioritizing your health &
								wellness. And that, together,
								we’re able to create, learn and
								grow.
							</Body1>
							<br />
							<Body1>
								Instead of boring you with our
								mission and vision statements,
								here are some words that define
								who we are and what our plan is
								all about!
								<ol>
									<li>Committed</li>
									<li>Easy going</li>
									<li>Flexible</li>
									<li>Non-judgmental</li>
									<li>Supportive</li>
								</ol>
								- Nirzari Desai <br /> Creator &
								Jack of All Trades
							</Body1>
							<br />
							<Subtitle1>
								“When women support each other
								incredible things happen.”
							</Subtitle1>
							<br />
							<Subtitle1
								fontColor="boulder"
								fontWeight={500}
								// marginBottom={isMobile ? 93 : 67}
								marginTop={DS.spacing.s4}
							>
								Sign In to continue!
							</Subtitle1>
						</div>

						<StyledBox marginTop={40}>
							<div>
								<Link to="/signin">
									<Body1
										fontColor="redRibbon"
										fontWeight={600}
									>
										Sign In
									</Body1>
								</Link>
							</div>
						</StyledBox>
					</>
				</ShowIf>

				<ShowIf
					value={
						serverError.length && !status.success
					}
				>
					<>
						<Spacing />
						<FlexCenter>
							<Caption fontColor="red">
								{serverError}
							</Caption>
						</FlexCenter>
					</>
				</ShowIf>
			</FormWrapper>
		</SplitView>
	);
};

export default EmailConfirmation;
