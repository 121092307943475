import React, { FC } from "react";

const ShowIf: FC<{ value?: any }> = ({
  value = null,
  children,
}) => {
  if (value) {
    return <>{children}</>;
  }

  return null;
};

export default ShowIf;
