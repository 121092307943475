const IconCalender = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15.452"
			height="13.846"
			viewBox="0 0 15.452 13.846"
		>
			<g
				id="calendar_2_"
				data-name="calendar (2)"
				transform="translate(0.35 0.35)"
			>
				<g
					id="Group_10219"
					data-name="Group 10219"
					transform="translate(0 0)"
				>
					<g
						id="Group_10218"
						data-name="Group 10218"
						transform="translate(0 0)"
					>
						<path
							id="Path_1874"
							data-name="Path 1874"
							d="M13.223,22.018H12.3v-.734a.384.384,0,0,0-.769,0v.734H7.762v-.734a.384.384,0,0,0-.769,0v.734h-3.8v-.734a.384.384,0,0,0-.769,0v.734H1.533A1.555,1.555,0,0,0,0,23.575v8.934a1.533,1.533,0,0,0,1.533,1.537H13.219a1.533,1.533,0,0,0,1.533-1.533V23.575A1.549,1.549,0,0,0,13.223,22.018Zm.765,10.491a.767.767,0,0,1-.761.769H1.533a.764.764,0,0,1-.765-.765V23.575a.782.782,0,0,1,.765-.788h.888v.557a.384.384,0,1,0,.769,0v-.557h3.8v.557a.384.384,0,1,0,.769,0v-.557h3.766v.557a.384.384,0,1,0,.769,0v-.557h.926a.782.782,0,0,1,.765.788Z"
							transform="translate(0 -20.9)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10221"
					data-name="Group 10221"
					transform="translate(5.311 4.385)"
				>
					<g
						id="Group_10220"
						data-name="Group 10220"
					>
						<path
							id="Path_1875"
							data-name="Path 1875"
							d="M139.257,135h-.672a.384.384,0,1,0,0,.769h.672a.384.384,0,0,0,0-.769Z"
							transform="translate(-138.2 -135)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10223"
					data-name="Group 10223"
					transform="translate(7.985 4.385)"
				>
					<g
						id="Group_10222"
						data-name="Group 10222"
					>
						<path
							id="Path_1876"
							data-name="Path 1876"
							d="M208.857,135h-.672a.384.384,0,0,0,0,.769h.672a.384.384,0,1,0,0-.769Z"
							transform="translate(-207.8 -135)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10225"
					data-name="Group 10225"
					transform="translate(10.683 4.385)"
				>
					<g
						id="Group_10224"
						data-name="Group 10224"
					>
						<path
							id="Path_1877"
							data-name="Path 1877"
							d="M279.057,135h-.672a.384.384,0,0,0,0,.769h.672a.384.384,0,1,0,0-.769Z"
							transform="translate(-278 -135)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10227"
					data-name="Group 10227"
					transform="translate(5.311 7.036)"
				>
					<g
						id="Group_10226"
						data-name="Group 10226"
						transform="translate(0 0)"
					>
						<path
							id="Path_1878"
							data-name="Path 1878"
							d="M139.257,204h-.672a.384.384,0,1,0,0,.769h.672a.384.384,0,1,0,0-.769Z"
							transform="translate(-138.2 -204)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10229"
					data-name="Group 10229"
					transform="translate(2.613 7.036)"
				>
					<g
						id="Group_10228"
						data-name="Group 10228"
						transform="translate(0 0)"
					>
						<path
							id="Path_1879"
							data-name="Path 1879"
							d="M69.061,204h-.676a.384.384,0,0,0,0,.769h.672a.384.384,0,0,0,0-.769Z"
							transform="translate(-68 -204)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10231"
					data-name="Group 10231"
					transform="translate(7.985 7.036)"
				>
					<g
						id="Group_10230"
						data-name="Group 10230"
						transform="translate(0 0)"
					>
						<path
							id="Path_1880"
							data-name="Path 1880"
							d="M208.857,204h-.672a.384.384,0,0,0,0,.769h.672a.384.384,0,1,0,0-.769Z"
							transform="translate(-207.8 -204)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10233"
					data-name="Group 10233"
					transform="translate(10.683 7.036)"
				>
					<g
						id="Group_10232"
						data-name="Group 10232"
						transform="translate(0 0)"
					>
						<path
							id="Path_1881"
							data-name="Path 1881"
							d="M279.057,204h-.672a.384.384,0,0,0,0,.769h.672a.384.384,0,1,0,0-.769Z"
							transform="translate(-278 -204)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10235"
					data-name="Group 10235"
					transform="translate(5.311 9.649)"
				>
					<g
						id="Group_10234"
						data-name="Group 10234"
					>
						<path
							id="Path_1882"
							data-name="Path 1882"
							d="M139.257,272h-.672a.384.384,0,0,0,0,.769h.672a.384.384,0,0,0,0-.769Z"
							transform="translate(-138.2 -272)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10237"
					data-name="Group 10237"
					transform="translate(2.613 9.649)"
				>
					<g
						id="Group_10236"
						data-name="Group 10236"
					>
						<path
							id="Path_1883"
							data-name="Path 1883"
							d="M69.061,272h-.676a.384.384,0,0,0,0,.769h.672a.384.384,0,0,0,0-.769Z"
							transform="translate(-68 -272)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
				<g
					id="Group_10239"
					data-name="Group 10239"
					transform="translate(7.985 9.649)"
				>
					<g
						id="Group_10238"
						data-name="Group 10238"
					>
						<path
							id="Path_1884"
							data-name="Path 1884"
							d="M208.857,272h-.672a.384.384,0,0,0,0,.769h.672a.384.384,0,0,0,0-.769Z"
							transform="translate(-207.8 -272)"
							fill="#e4003f"
							stroke="#e4003f"
							strokeWidth="0.7"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default IconCalender;
