import { useState } from "react";
import {
	Wrapper,
	H2,
	Body2,
	Button,
	Input,
} from "@workshore/nyaari-ds";
import styled from "styled-components";

import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import subscribe from "../../assets/images/subscribe.png";
import IconArrow from "../../components/Icons/IconArrow";
import { useNewsletterMutation } from "../../hooks/graphql/graphql";
import ShowIf from "../ShowIf/ShowIf";

// subscribe
const SubscribeDiv = styled.div<{
	isMobile: boolean;
}>`
	position: relative;
	display: flex;
	align-items: center;
	height: 600px;
	width: 100%;
	background-size: cover;
	background-color: #fae2e8;

	${({ isMobile }) =>
		isMobile &&
		`
      height: 275px;
  `};
`;

const SubscribeWrapper = styled.div<{
	isMobile: boolean;
}>`
	padding: 96px 74px 96px 0px;
	display: inline-flex;
	flex-direction: column;
	border-radius: 5px;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 0px;
  `};
`;

const InputWrapper = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: row;
	margin-top: 46px;
	button {
		border-top-left-radius: revert;
		border-bottom-left-radius: revert;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    margin-top: 36px;
  `};
`;

const StyledImage3 = styled.img<{
	isMobile: boolean;
	isTablet: boolean;
}>`
	width: 55vw;
	height: 600px;
	position: absolute;
	right: 0;
	top: 0;
	object-fit: cover;

	${({ isTablet }) =>
		isTablet &&
		`
    width: calc(100% - 638px);
  `};

	${({ isMobile }) =>
		isMobile &&
		`
    width: 100%;
    height: 308px;
  `};
`;

function Subscribe() {
	const [email, setEmail] = useState("");
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const [doSubscribe, { data, loading }] =
		useNewsletterMutation();

	return (
		<SubscribeDiv
			style={
				isMobile
					? {
							background: `linear-gradient(to right,#00000095,#33333378), url(${subscribe}) no-repeat`,
							backgroundSize: "cover",
					  }
					: {}
			}
			isMobile={isMobile}
		>
			<Wrapper>
				<SubscribeWrapper isMobile={isMobile}>
					<H2
						fontColor={
							isMobile ? "white" : "mineShaft"
						}
					>
						Aaj Ki Tazza Khabar
					</H2>
					<ShowIf value={!data}>
						<Body2
							fontColor={
								isMobile ? "white" : "mineShaft"
							}
							style={
								isMobile
									? { marginTop: 9 }
									: { marginTop: 11 }
							}
						>
							Sign up for our girly-gossip mailing
							list for the juiciest health
							tidbits.
						</Body2>
						<InputWrapper isMobile={isMobile}>
							<Input
								disabled={loading}
								placeholder="Enter your email address"
								handleInputChange={(e) => {
									setEmail(e.target.value);
								}}
								width={
									isMobile ? "240px" : "289px"
								}
								value={email}
								type="email"
								// error="Enter you email"
							/>
							<Button
								width={
									isMobile ? "54px" : "146px"
								}
								disabled={loading}
								height="50px"
								handleButtonClick={() => {
									doSubscribe({
										variables: {
											email,
										},
									});
								}}
							>
								{isMobile ? (
									<IconArrow />
								) : (
									<>
										{loading
											? "Submitting.."
											: "Subscribe"}
									</>
								)}
							</Button>
						</InputWrapper>
					</ShowIf>
					<ShowIf value={data}>
						<Body2>
							Successfully subscribe to newsletter
						</Body2>
					</ShowIf>
				</SubscribeWrapper>
			</Wrapper>
			{!isMobile && (
				<StyledImage3
					isTablet={isTablet}
					isMobile={isMobile}
					src={subscribe}
					alt=""
				/>
			)}
		</SubscribeDiv>
	);
}

export default Subscribe;
