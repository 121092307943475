import React, { FC } from "react";
import {
	Body1,
	Button,
	IconHamburger,
	Navbar,
	DS,
	IconAccount,
} from "@workshore/nyaari-ds";
import {
	Link,
	NavLink,
	Route,
	Switch,
} from "react-router-dom";
import styled from "styled-components";
import Color from "color";
import { useScreenWidth } from "@tinyd/usescreensize";
import useSharedState from "@tinyd/usesharedstate";
import useAuth from "../../hooks/useAuth/useAuth";

const StyledNavLink = styled(NavLink)`
	height: 95px;
	width: 100%;
	display: flex;
	align-items: center;
	box-shadow: 0px 3px 0 transparent;
	transition: all ${DS.transition.fast};
	&:hover {
		box-shadow: 0px 3px 0
			${new Color(DS.colors.redRibbon)
				.alpha(0.5)
				.rgb()
				.toString()};
	}

	&.active {
		box-shadow: 0px 3px 0 ${DS.colors.redRibbon};
	}
`;
const Wrapper = styled.div`
	> * {
		@media print {
			display: none;
		}
	}
`;

const RightIcon = () => {
	const [rightDrawer, openRightDrawer] =
		useSharedState("@right_menu", false);
	return (
		<Switch>
			<Route path="/dashboard">
				<div
					onClick={() => {
						openRightDrawer(!rightDrawer);
					}}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<IconAccount />
				</div>
			</Route>
			<Route path="/" exact>
				<Link to="/signup">
					<Body1 fontColor="redRibbon">
						Try For Free
					</Body1>
				</Link>
			</Route>
		</Switch>
	);
};

const LeftIcon = () => {
	const [publicDrawer, openPublicDrawer] =
		useSharedState("@public_menu", false);
	const [leftDrawer, openLeftDrawer] =
		useSharedState("@left_menu", false);

	return (
		<Switch>
			<Route path="/dashboard">
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					onClick={() => {
						openLeftDrawer(!leftDrawer);
					}}
				>
					<IconHamburger />
				</div>
			</Route>
			<Route>
				<div
					onClick={() => {
						openPublicDrawer(!publicDrawer);
					}}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<IconHamburger />
				</div>
			</Route>
		</Switch>
	);
};

const Header: FC<{ hideOnDesktop?: boolean }> = ({
	hideOnDesktop = false,
}) => {
	const auth = useAuth();
	const screenWidth = useScreenWidth();
	const isSmall =
		screenWidth <=
		(hideOnDesktop
			? DS.customBreakpoints.drawerBreakpoint
			: DS.breakPoints.m);
	return !hideOnDesktop || isSmall ? (
		<Wrapper>
			<Navbar
				leftIcon={<LeftIcon />}
				rightIcon={<RightIcon />}
				mobileBreakpoint={
					hideOnDesktop && isSmall
						? DS.customBreakpoints
								.drawerBreakpoint
						: DS.breakPoints.m
				}
				logoClickLink={
					hideOnDesktop && isSmall
						? "/dashboard"
						: "/"
				}
				menuItems={[
					<StyledNavLink
						to="/freemium-gyan"
						activeClassName="active"
						exact
					>
						<Body1>Freemium</Body1>
					</StyledNavLink>,
					<StyledNavLink
						to="/collaborators"
						exact
						activeClassName="active"
					>
						<Body1>Our Team</Body1>
					</StyledNavLink>,
					<StyledNavLink
						to="/contactus"
						exact
						activeClassName="active"
					>
						<Body1>Contact us</Body1>
					</StyledNavLink>,
					...(!auth
						? [
								<StyledNavLink
									to="/signin"
									exact
									activeClassName="active"
								>
									<Body1 fontColor="redRibbon">
										Sign In
									</Body1>
								</StyledNavLink>,
								<Link to="/signup">
									<Button
										handleButtonClick={() => {}}
										width="164px"
										height="50px"
									>
										Try for free
									</Button>
								</Link>,
						  ]
						: [
								<StyledNavLink
									to="/dashboard"
									exact
									activeClassName="active"
								>
									<Body1 fontColor="redRibbon">
										Account
									</Body1>
								</StyledNavLink>,
						  ]),
				]}
			/>
		</Wrapper>
	) : null;
};
export default Header;
