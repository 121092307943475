const IconLink = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="27"
			height="27"
			viewBox="0 0 27 27"
		>
			<g
				id="Group_9902"
				data-name="Group 9902"
				transform="translate(0 0)"
			>
				<g
					id="Group_9788"
					data-name="Group 9788"
					transform="translate(0 0)"
				>
					<g
						id="Ellipse_18"
						data-name="Ellipse 18"
						transform="translate(0 0)"
						fill="#fcfcfc"
						stroke="#ffd100"
						strokeWidth="1.5"
					>
						<circle
							cx="13.5"
							cy="13.5"
							r="13.5"
							stroke="none"
						/>
						<circle
							cx="13.5"
							cy="13.5"
							r="12.75"
							fill="none"
						/>
					</g>
				</g>
				<g
					id="link"
					transform="translate(8.115 8.079)"
				>
					<path
						id="Path_1481"
						data-name="Path 1481"
						d="M10,7.785a2.629,2.629,0,0,0,3.964.284l1.577-1.577a2.628,2.628,0,1,0-3.717-3.717l-.9.9"
						transform="translate(-5.792 -2.005)"
						fill="#fcfcfc"
						stroke="#ffd100"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
					<path
						id="Path_1482"
						data-name="Path 1482"
						d="M8.306,10.049a2.629,2.629,0,0,0-3.964-.284L2.765,11.342a2.628,2.628,0,1,0,3.717,3.717l.9-.9"
						transform="translate(-1.995 -5.32)"
						fill="#fcfcfc"
						stroke="#ffd100"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconLink;
