import React, {
	useState,
	useRef,
	useEffect,
} from "react";
import { useParams } from "react-router-dom";
import {
	Body1,
	Body2,
	CardContainer,
	DS,
	FlexCenter,
	H3,
	Spacing,
	FlexBetween,
	useOutsideClick,
} from "@workshore/nyaari-ds";
import { useScreenWidth } from "@tinyd/usescreensize";
import dayjs from "dayjs";
import { useToasts } from "react-toast-notifications";

import PageModal from "../PageModal/PageModal";
import {
	useCreateWeeklyPlannerEventMutation,
	useGetMastiDetailQuery,
	WeeklyPlannerType,
} from "../../hooks/graphql/graphql";
import ShowIf from "../ShowIf/ShowIf";
import Loader from "../Loader/Loader";
import IconClock from "../Icons/IconClock";
import IconUser from "../Icons/IconUser";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import styled from "styled-components";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import HTMLParser from "../HTMLParser";
import VideoPlayer1 from "../VideoPlayer1/VideoPlayer1";
import AddWeeklyPlanner from "../AddWeeklyPlanner/AddWeeklyPlanner";
import CustomCalendar from "../CustomCalendar/CustomCalendar";
import { AddButtonFixed } from "../AddButton/AddButton";

const Container = styled.div``;

const TopContainer = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	${({ isMobile }) => `
		padding: ${isMobile ? "0" : "20px 30px"};
		flex-direction: ${
			isMobile ? "column-reverse" : "column"
		};
	`}
`;

const Header = styled.div<{ isMobile: boolean }>`
	position: relative;
	/* display: grid;
	grid-gap: 10px; */
	background-color: ${DS.colors.white};
	${({ isMobile }) => `
		grid-template-columns: ${
			isMobile ? "1fr" : "2fr 1fr"
		};	
		padding-inline: ${
			isMobile ? DS.spacing.s24 + "px" : "0"
		};
		padding-block:  ${
			isMobile
				? DS.spacing.s24 + "px"
				: "10px 30px"
		};
		${
			isMobile
				? `&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: ${DS.spacing.s24 + "px"};
					height: 2px;
					width: calc(100% - (${
						DS.spacing.s24 + "px"
					} * 2));
					border-radius: 4px;
					background-color: ${DS.colors.masti};
				};
				transform: translateY(-6px);
				box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.3);
				border-radius: 5px;
				`
				: null
		}
	`};
`;

const MetaInfo = styled(FlexCenter)`
	background-color: ${DS.colors.wildSand};
	border-radius: 4px;
	padding: 0.2em 0.4em;

	&:not(:last-child) {
		margin-right: 10px;
	}
`;

const ContentWrapper = styled.div<{
	isMobile: boolean;
}>`
	${({ isMobile }) => `padding: 5% ${
		isMobile ? "20px" : "10%"
	};
	`};

	img {
		width: 100%;
	}
`;

const IconContainer = styled.div`
	position: absolute;
	z-index: 10;
	right: 0;
	bottom: 0;
	margin-right: 30px;
	margin-bottom: -16px;
`;
const CalendarContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	margin-top: 50px;
`;
const CalendarContainer1 = styled.div<{
	isMobile: boolean;
}>`
	position: absolute;
	margin-right: 50px;
	bottom: 0;
	right: 0;

	${({ isMobile }) =>
		isMobile &&
		`
		position: fixed;
		height: 100vh;
		width: 100%;
		background: ${DS.colors.mineShaft}90;
		margin-right: 0px;
		display: flex;
		justify-content:center;
		align-items:center;

		> * {
			margin: 20px;
		}
	`}
`;

const MastiDetails = () => {
	const [isSubmitting, setIsSubmitting] =
		useState(false);
	const [date, setDate] = useState(new Date());
	const [showCalendar, setShowCalendar] =
		useState(false);
	const [showCalendar1, setShowCalendar1] =
		useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const isMobile = useIsMobile();
	const elementWidth = useElementWidth(ref);
	const isSmall = elementWidth <= 750;
	const { id } = useParams<{ id: string }>();
	const { data: mastiDetail, loading } =
		useGetMastiDetailQuery({
			variables: {
				id,
			},
		});
	const screenWidth = useScreenWidth();
	const isPhone = screenWidth <= 578;
	const calendarRef = useOutsideClick(() => {
		setShowCalendar(false);
	});
	const calendarRef1 = useOutsideClick(() => {
		setShowCalendar1(false);
	});
	const containerRef = useOutsideClick(() => {
		if (!isPhone) return;
		setShowCalendar1(false);
	});
	const { addToast } = useToasts();
	const createWeeklyPlannerEvent =
		useCreateWeeklyPlannerEventMutation();

	useEffect(() => {
		setDate(new Date());
	}, [showCalendar, showCalendar1]);

	const addToWeeklyPlanner = () => {
		setIsSubmitting(true);
		createWeeklyPlannerEvent[0]({
			variables: {
				data: {
					date: dayjs(date).format("YYYY-MM-DD"),
					type: WeeklyPlannerType.Masti,
					resourceId: id,
				},
			},
		})
			.then((res) => {
				setIsSubmitting(false);
				setShowCalendar(false);
				setShowCalendar1(false);
				addToast("Item added to weekly planner", {
					appearance: "success",
					autoDismiss: true,
				});
			})
			.catch((err) =>
				addToast(
					"Error adding to the weekly planner",
					{
						appearance: "error",
						autoDismiss: true,
					},
				),
			);
	};

	return (
		<PageModal>
			{/* Loading */}
			<ShowIf value={loading}>
				<Loader>
					<Body1>Loading Masti..</Body1>
				</Loader>
			</ShowIf>

			<ShowIf value={!!mastiDetail}>
				<Container ref={ref}>
					<TopContainer isMobile={isMobile}>
						{/* Header */}
						<Header isMobile={isMobile}>
							<H3 style={{ marginBottom: 16 }}>
								{mastiDetail?.masti?.title}
							</H3>
							<FlexBetween
								style={{ alignItems: "center" }}
							>
								<div style={{ display: "flex" }}>
									{mastiDetail?.masti
										?.reading_time ? (
										<MetaInfo>
											<IconClock color="masti" />
											<Spacing type="s4" />
											<Body2 fontWeight={500}>
												{
													mastiDetail?.masti
														?.reading_time
												}
											</Body2>
										</MetaInfo>
									) : null}
									{mastiDetail?.masti
										?.collaborator?.name ? (
										<MetaInfo>
											<IconUser color="masti" />
											<Spacing type="s4" />
											<Body2 fontWeight={500}>
												By{" "}
												{
													mastiDetail?.masti
														?.collaborator?.name
												}
											</Body2>
										</MetaInfo>
									) : null}
								</div>
								{!isMobile ? (
									<div
										ref={calendarRef}
										style={{
											position: "relative",
										}}
									>
										<AddWeeklyPlanner
											stroke="masti"
											onClick={() =>
												setShowCalendar(
													!showCalendar,
												)
											}
										/>
										{showCalendar ? (
											<CalendarContainer>
												<CustomCalendar
													style={{
														background: "white",
													}}
													date={date}
													setDate={setDate}
													accentColor="masti"
													isSubmitting={
														isSubmitting
													}
													handleButtonClick={
														addToWeeklyPlanner
													}
												/>
											</CalendarContainer>
										) : null}
									</div>
								) : null}
							</FlexBetween>
						</Header>

						{/* Banner */}
						{mastiDetail?.masti?.vimeo_url ? (
							<div
								style={{ position: "relative" }}
							>
								<VideoPlayer1
									url={
										mastiDetail?.masti?.vimeo_url
									}
								/>
								{isMobile ? (
									<IconContainer
										ref={calendarRef1}
									>
										<AddButtonFixed
											stroke="masti"
											onClick={() => {
												setShowCalendar1(
													!showCalendar1,
												);
											}}
										/>
										{showCalendar1 ? (
											<CalendarContainer1
												isMobile={isPhone}
											>
												<CustomCalendar
													ref={containerRef}
													style={{
														background: "white",
													}}
													date={date}
													setDate={setDate}
													accentColor="masti"
													isSubmitting={
														isSubmitting
													}
													handleButtonClick={
														addToWeeklyPlanner
													}
												/>
											</CalendarContainer1>
										) : null}
									</IconContainer>
								) : null}
							</div>
						) : (
							<div
								style={{ position: "relative" }}
							>
								<CardContainer
									padding={"0px"}
									background={`url(${mastiDetail?.masti?.cover_image?.url}) no-repeat`}
									height={
										isSmall ? "260px" : "569px"
									}
									style={{
										backgroundPosition:
											"center center",
										backgroundSize: "cover",
									}}
								></CardContainer>
								{isMobile ? (
									<IconContainer
										ref={calendarRef1}
									>
										<AddButtonFixed
											stroke="masti"
											onClick={() => {
												setShowCalendar1(
													!showCalendar1,
												);
											}}
										/>
										{showCalendar1 ? (
											<CalendarContainer1
												isMobile={isPhone}
											>
												<CustomCalendar
													ref={containerRef}
													style={{
														background: "white",
													}}
													date={date}
													setDate={setDate}
													accentColor="masti"
													isSubmitting={
														isSubmitting
													}
													handleButtonClick={
														addToWeeklyPlanner
													}
												/>
											</CalendarContainer1>
										) : null}
									</IconContainer>
								) : null}
							</div>
						)}
					</TopContainer>

					{/* Content */}
					<ContentWrapper isMobile={isMobile}>
						<HTMLParser
							data={
								mastiDetail?.masti?.content || ""
							}
						/>
					</ContentWrapper>
				</Container>
			</ShowIf>
		</PageModal>
	);
};

export default MastiDetails;
