import { useScreenClass } from "react-grid-system";

const useIsMobile = () => {
  const screenClass = useScreenClass();
  const isSmall = ["md", "sm", "xs"].includes(
    screenClass
  );

  return isSmall;
};

export default useIsMobile;
