const IconCalorie = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22.695"
			height="23.3"
			viewBox="0 0 22.695 23.3"
		>
			<g
				id="calories_consumed"
				data-name="calories consumed"
				transform="translate(0.15 0.15)"
			>
				<path
					id="Path_2544"
					data-name="Path 2544"
					d="M26.615,46.8a8.146,8.146,0,0,1-5.9,2.5,8.231,8.231,0,0,1-6.069-2.681.373.373,0,1,0-.552.5,8.98,8.98,0,0,0,6.621,2.924,8.886,8.886,0,0,0,6.435-2.725.374.374,0,0,0-.538-.521Z"
					transform="translate(-9.521 -29.358)"
					fill="#333"
					stroke="#333"
					strokeWidth="0.3"
				/>
				<path
					id="Path_2545"
					data-name="Path 2545"
					d="M27.3,10.472a.373.373,0,0,0-.06-.525,8.958,8.958,0,0,0-11.61.39.373.373,0,1,0,.5.552,8.211,8.211,0,0,1,10.644-.357A.373.373,0,0,0,27.3,10.472Z"
					transform="translate(-10.465 -5.76)"
					fill="#333"
					stroke="#333"
					strokeWidth="0.3"
				/>
				<path
					id="Path_2546"
					data-name="Path 2546"
					d="M52.852,11a.373.373,0,0,0-.373.373v4.479h-.746V11.373a.373.373,0,0,0-.746,0v4.479h-.746V11.373a.373.373,0,0,0-.746,0v4.479h-.746V11.373a.373.373,0,0,0-.746,0v4.852a1.493,1.493,0,0,0,1.47,1.491l-.632,10.433a1.761,1.761,0,0,0,.48,1.326,1.779,1.779,0,0,0,2.591,0,1.761,1.761,0,0,0,.48-1.326l-.632-10.433a1.493,1.493,0,0,0,1.47-1.491V11.373A.373.373,0,0,0,52.852,11ZM51.364,28.964a1.057,1.057,0,0,1-1.5,0,1.021,1.021,0,0,1-.278-.77l.635-10.476h.791l.635,10.476A1.021,1.021,0,0,1,51.364,28.964Zm.368-11.992H49.493a.746.746,0,0,1-.646-.373h3.532A.746.746,0,0,1,51.732,16.972Z"
					transform="translate(-30.831 -7.641)"
					fill="#333"
					stroke="#333"
					strokeWidth="0.3"
				/>
				<path
					id="Path_2547"
					data-name="Path 2547"
					d="M3.4,17.812l-.567,10a1.761,1.761,0,0,0,.48,1.326,1.779,1.779,0,0,0,2.591,0,1.759,1.759,0,0,0,.48-1.325l-.567-10a2.771,2.771,0,0,0,1.4-2.435v-.709a5.13,5.13,0,0,0-1.776-3.938,1.433,1.433,0,0,0-1.673,0A5.133,5.133,0,0,0,2,14.668v.709a2.771,2.771,0,0,0,1.4,2.434ZM5.644,27.856a1.021,1.021,0,0,1-.278.77,1.057,1.057,0,0,1-1.5,0,1.022,1.022,0,0,1-.278-.771l.553-9.776a2.394,2.394,0,0,0,.952,0Zm-2.9-13.187a4.407,4.407,0,0,1,1.46-3.329.7.7,0,0,1,.811,0,4.408,4.408,0,0,1,1.46,3.329v.709a1.941,1.941,0,0,1-1.866,2,1.941,1.941,0,0,1-1.866-2Z"
					transform="translate(-2 -7.302)"
					fill="#333"
					stroke="#333"
					strokeWidth="0.3"
				/>
				<path
					id="Path_2548"
					data-name="Path 2548"
					d="M26.844,54.569a10.455,10.455,0,0,1-12.252,0,.373.373,0,1,0-.438.6,11.2,11.2,0,0,0,13.128,0,.373.373,0,1,0-.438-.6Z"
					transform="translate(-9.521 -34.298)"
					fill="#333"
					stroke="#333"
					strokeWidth="0.3"
				/>
				<path
					id="Path_2549"
					data-name="Path 2549"
					d="M12.373,5.393a.373.373,0,0,0,.244-.091,10.445,10.445,0,0,1,6.847-2.555A10.339,10.339,0,0,1,25.951,5a.373.373,0,1,0,.464-.585A11.079,11.079,0,0,0,19.464,2a11.192,11.192,0,0,0-7.338,2.739.373.373,0,0,0,.247.654Z"
					transform="translate(-8.267 -2)"
					fill="#333"
					stroke="#333"
					strokeWidth="0.3"
				/>
				<g
					id="fire"
					transform="translate(8.037 6.924)"
				>
					<path
						id="Path_1792"
						data-name="Path 1792"
						d="M92.448,5.033c-.113-.262-.268-.563-.432-.881a11.284,11.284,0,0,1-.874-2A2.009,2.009,0,0,1,91.255.436a.28.28,0,0,0-.3-.427A3.328,3.328,0,0,0,89.05,1.344a2.231,2.231,0,0,0-.316,1.116,1.492,1.492,0,0,0-.574-.291.28.28,0,0,0-.331.311A2.48,2.48,0,0,1,87.2,4.3a7.017,7.017,0,0,0-.529.871,2.779,2.779,0,0,0,.2,2.723,3.283,3.283,0,0,0,2.84,1.65,3.221,3.221,0,0,0,.833-.11l.064-.018a2.915,2.915,0,0,0,1.808-1.591,3.469,3.469,0,0,0,.029-2.791Zm-.537,2.556c-.181.391-1.061,1.59-2.2,1.424a3.1,3.1,0,0,1-1.788-.707,2.734,2.734,0,0,1-.567-.693,2.224,2.224,0,0,1-.183-2.2,6.58,6.58,0,0,1,.489-.8,3.421,3.421,0,0,0,.735-1.7,3.451,3.451,0,0,1,.493.472.28.28,0,0,0,.484-.251,1.894,1.894,0,0,1,.151-1.495,2.364,2.364,0,0,1,.979-.855A3.075,3.075,0,0,0,90.6,2.3a11.694,11.694,0,0,0,.915,2.105c.16.309.31.6.416.846a2.9,2.9,0,0,1-.024,2.334Z"
						transform="translate(-86.406 0)"
						fill="#333"
						stroke="#333"
						strokeWidth="0.5"
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconCalorie;
