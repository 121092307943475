import React, {
	FC,
	useState,
	useRef,
} from "react";
import {
	Box,
	DS,
	IconMeals,
	IconMeditation,
	IconMovement,
	IconMantra,
	Body2,
	IconMasti,
	FlexBetween,
	useOutsideClick,
	Body1,
	H3,
	Table,
	Caption,
} from "@workshore/nyaari-ds";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
} from "chart.js";

import { AddButtonFixed } from "../AddButton/AddButton";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import IconCalorie from "../Icons/IconCalorie";
import IconClose from "../Icons/IconClose";
import { CaloriesDataType } from "../../hooks/graphql/graphql";
import { useScreenWidth } from "@tinyd/usescreensize";
ChartJS.register(ArcElement, Tooltip, Legend);

const StyledBox = styled(Box)<{
	isMobile: boolean;
}>`
	// display: flex;
	// justify-content: space-between;
	position: relative;
	background: ${DS.colors.wildSand};
	border: 1px dashed ${DS.colors.boulder};
	border-radius: 5px;
	padding: 24px;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 18px;
  `}
`;
const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	> :first-child {
		margin-right: 10px;
	}
`;
const AddWrapper = styled.div<{
	isMobile: boolean;
}>`
	position: absolute;
	display: flex;
	align-items: center;
	left: 186px;
	top: 22px;
	> div {
		height: 30px;
		width: 30px;
		margin-right: 10px;
		svg {
			height: 25px;
			width: 12px;
		}
	}

	${({ isMobile }) =>
		isMobile &&
		`
      > div {
        height: 27px;
        width: 27px;
      }
      left: 140px;
      top: 18px;
  `}
`;
// calorie box
const ChartContainer = styled.div<{
	isMobile: boolean;
}>`
	position: fixed;
	height: 100vh;
	width: 100%;
	background: ${DS.colors.mineShaft}90;
	left: 0;
	top: 0;
	z-index: 20;
	overflow-y: scroll;

	${({ isMobile }) =>
		isMobile &&
		`
		position: fixed;
		height: 100vh;
		width: 100%;
		background: ${DS.colors.mineShaft}90;
		left: 0;
		top: 0;
		z-index: 20;

		> * {
			margin: 0 10px 0 12px;
		}
	`}
`;
const ChartWrapper = styled.div<{
	isMobile: boolean;
}>`
	position: absolute;
	z-index: 1;
	padding: 20px 30px;
	background-color: white;
	// top: 210px;
	// right: 160px;
	border-radius: 5px;
	box-shadow: 0 3px 6px #00000029;
	width: 347px;

	${({ isMobile }) =>
		isMobile &&
		`
		width: calc(100% - 85px);
	`}
`;
const CloseWrapper = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
`;
const CalorieWrapper = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;
const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`;
const TypeWrapper = styled.div`
	display: flex;
	align-items: center;
`;
const Dot = styled.div<{
	stroke: keyof typeof DS.colors;
}>`
	height: 12px;
	width: 12px;
	border-radius: 6px;
	margin-right: 8px;
	background-color: ${({ stroke }) =>
		DS.colors[stroke]};
`;

interface Props {
	type:
		| "meals"
		| "movement"
		| "meditation"
		| "mantra"
		| "masti";
	stroke: keyof typeof DS.colors;
	showAdd?: boolean;
	dayCaloriesCount?: number;
	caloriesData?: CaloriesDataType;
	onAddClick?:
		| React.MouseEventHandler<HTMLDivElement>
		| undefined;
}

const WeeklyMWrapper: FC<Props> = ({
	type,
	stroke,
	showAdd,
	dayCaloriesCount,
	caloriesData,
	onAddClick,
	children,
}) => {
	const [showChart, setShowChart] =
		useState(false);
	const isMobile = useIsMobile();
	const screenWidth = useScreenWidth();
	const calorieCountRef =
		useRef<HTMLDivElement>(null);
	const chartRef = useOutsideClick(() => {
		setShowChart(false);
	});

	return (
		<StyledBox isMobile={isMobile}>
			<FlexBetween>
				<IconWrapper>
					{type === "meals" && (
						<IconMeals active />
					)}
					{type === "movement" && (
						<IconMovement active />
					)}
					{type === "meditation" && (
						<IconMeditation active />
					)}
					{type === "mantra" && (
						<IconMantra active />
					)}
					{type === "masti" && (
						<IconMasti active />
					)}
					<Body2>
						{type.charAt(0).toUpperCase() +
							type.slice(1)}
					</Body2>
				</IconWrapper>
				{showAdd ? (
					<AddWrapper isMobile={isMobile}>
						<AddButtonFixed
							onClick={onAddClick}
							stroke={stroke}
						/>
						<Body2
							fontWeight={400}
							fontColor="boulder"
						>
							Add{" "}
							{type === "meals"
								? "recipies"
								: type}{" "}
							{!isMobile
								? "to your weekly planner"
								: ""}
						</Body2>
					</AddWrapper>
				) : null}
				{type === "meals" && (
					<div
						ref={calorieCountRef}
						style={{
							position: "relative",
							float: "right",
							display: "flex",
							cursor: "pointer",
						}}
						onClick={() =>
							setShowChart(!showChart)
						}
					>
						<IconCalorie />
						{!isMobile ? (
							<Body2 style={{ marginLeft: 10 }}>
								Calorie Count
							</Body2>
						) : null}
						{showChart ? (
							<ChartContainer
								isMobile={screenWidth < 768}
							>
								<ChartWrapper
									style={
										screenWidth < 768
											? { top: 80 }
											: {
													top:
														calorieCountRef!.current!.getBoundingClientRect()
															.top + 30,
													right:
														screenWidth <
														DS.breakPoints.m
															? 60
															: 80,
											  }
									}
									isMobile={screenWidth < 768}
									ref={chartRef}
								>
									<CloseWrapper
										onClick={() =>
											setShowChart(false)
										}
									>
										<IconClose />
									</CloseWrapper>
									<Body1
										fontWeight={600}
										style={{
											display: "flex",
											justifyContent: "center",
											marginBottom: 14,
										}}
									>
										Day Calorie Count
									</Body1>
									<div
										style={{
											position: "relative",
										}}
									>
										<Doughnut
											height={198}
											width={198}
											data={{
												labels: [
													"Fat",
													"Protein",
													"Carbs",
												],
												datasets: [
													{
														data: [
															caloriesData?.totalFat ===
															0
																? 0
																: ((caloriesData?.totalFat! +
																		caloriesData?.proteins! +
																		caloriesData?.totalCarbs!) *
																		100) /
																  caloriesData?.totalFat!,
															caloriesData?.proteins ===
															0
																? 0
																: ((caloriesData?.totalFat! +
																		caloriesData?.proteins! +
																		caloriesData?.totalCarbs!) *
																		100) /
																  caloriesData?.proteins!,
															caloriesData?.totalCarbs ===
															0
																? 0
																: ((caloriesData?.totalFat! +
																		caloriesData?.proteins! +
																		caloriesData?.totalCarbs!) *
																		100) /
																  caloriesData?.totalCarbs!,
														],
														backgroundColor: [
															DS.colors.masti,
															DS.colors
																.meditation,
															DS.colors.meals,
														],
														borderColor: [
															DS.colors.masti,
															DS.colors
																.meditation,
															DS.colors.meals,
														],
														borderWidth: 1,
													},
												],
											}}
											options={{
												responsive: true,
												plugins: {
													legend: {
														display: false,
													},
												},
												maintainAspectRatio:
													false,
												cutout: 70,
											}}
										/>
										<CalorieWrapper>
											<H3>{dayCaloriesCount}</H3>
											<Body1>CALORIES</Body1>
										</CalorieWrapper>
									</div>
									<FlexBetween
										style={{
											margin: "20px 0px",
										}}
									>
										<FlexColumn>
											<TypeWrapper>
												<Dot stroke="masti" />
												<Body2>Fat</Body2>
											</TypeWrapper>
											<Body2>
												{caloriesData?.totalFat ===
												0
													? 0
													: ((caloriesData?.totalFat! +
															caloriesData?.proteins! +
															caloriesData?.totalCarbs!) *
															100) /
													  caloriesData?.totalFat!}
												% /{" "}
												{caloriesData?.totalFat}g
											</Body2>
										</FlexColumn>
										<FlexColumn>
											<TypeWrapper>
												<Dot stroke="meditation" />
												<Body2>Protien</Body2>
											</TypeWrapper>
											<Body2>
												{caloriesData?.proteins ===
												0
													? 0
													: ((caloriesData?.totalFat! +
															caloriesData?.proteins! +
															caloriesData?.totalCarbs!) *
															100) /
													  caloriesData?.proteins!}
												% /{" "}
												{caloriesData?.proteins}g
											</Body2>
										</FlexColumn>
										<FlexColumn>
											<TypeWrapper>
												<Dot stroke="meals" />
												<Body2>Carbs</Body2>
											</TypeWrapper>
											<Body2>
												{caloriesData?.totalCarbs ===
												0
													? 0
													: ((caloriesData?.totalFat! +
															caloriesData?.proteins! +
															caloriesData?.totalCarbs!) *
															100) /
													  caloriesData?.totalCarbs!}
												% /{" "}
												{caloriesData?.totalCarbs}
												g
											</Body2>
										</FlexColumn>
									</FlexBetween>
									<Table
										tabItems={[
											{
												title: (
													<Caption
														style={{
															fontWeight: "bold",
														}}
													>
														Total Fat
													</Caption>
												),
												value: (
													<Caption>
														{
															caloriesData?.totalFat
														}
													</Caption>
												),
												subItems: [
													{
														title: (
															<Caption>
																Sat Fat
															</Caption>
														),
														value: (
															<Caption>
																{
																	caloriesData?.satFat
																}
															</Caption>
														),
													},
												],
											},
											{
												title: (
													<Caption
														style={{
															fontWeight: "bold",
														}}
													>
														Cholesterol
													</Caption>
												),
												value: (
													<Caption>
														{
															caloriesData?.cholesterol
														}
													</Caption>
												),
											},
											{
												title: (
													<Caption
														style={{
															fontWeight: "bold",
														}}
													>
														Sodium
													</Caption>
												),
												value: (
													<Caption>
														{caloriesData?.sodium}
													</Caption>
												),
											},
											{
												title: (
													<Caption
														style={{
															fontWeight: "bold",
														}}
													>
														Total Carbs
													</Caption>
												),
												value: (
													<Caption>
														{
															caloriesData?.totalCarbs
														}
													</Caption>
												),
												subItems: [
													{
														title: (
															<Caption>
																Fiber
															</Caption>
														),
														value: (
															<Caption>
																{
																	caloriesData?.fiber
																}
															</Caption>
														),
													},
													{
														title: (
															<Caption>
																Sugar
															</Caption>
														),
														value: (
															<Caption>
																{
																	caloriesData?.sugar
																}
															</Caption>
														),
													},
												],
											},
											{
												title: (
													<Caption
														style={{
															fontWeight: "bold",
														}}
													>
														Protiens
													</Caption>
												),
												value: (
													<Caption>
														{
															caloriesData?.proteins
														}
													</Caption>
												),
											},
										]}
									/>
								</ChartWrapper>
							</ChartContainer>
						) : null}
					</div>
				)}
			</FlexBetween>
			{children ? (
				<div
					style={
						isMobile
							? { marginTop: 16 }
							: { marginTop: 24 }
					}
				>
					{children}
				</div>
			) : null}
		</StyledBox>
	);
};

export default WeeklyMWrapper;
