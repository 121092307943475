import { FC, useEffect } from "react";
import {
	DS,
	IconBack,
	Navbar,
	useOutsideClick,
} from "@workshore/nyaari-ds";
import styled from "styled-components";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import useRoute from "../../hooks/useRoute/useRoute";
import IconClose from "../Icons/IconClose";

let currentStack = 0;

const PageModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: ${96 + currentStack};
	overflow-x: hidden;
	overflow-y: auto;
	overflow-y: overlay;

	@media screen and (max-width: ${DS
			.customBreakpoints.drawerBreakpoint}px) {
		background-color: #fff;
	}
`;

export const PageModalHeader = () => {
	const { goTo } = useRoute();
	const isTablet = useIsTablet();

	if (isTablet) {
		return (
			<Navbar
				leftIcon={
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						onClick={() => {
							goTo("/collaborators");
						}}
					>
						<IconBack />
					</div>
				}
				mobileBreakpoint={
					DS.customBreakpoints.drawerBreakpoint
				}
			/>
		);
	}

	return <></>;
};

const PageModalBodyContainer = styled.div<{
	isTablet: boolean;
}>`
	padding: 30px 50px 50px 50px;
	border-radius: 5px;
	background-color: #fff;
	margin: 138px auto;
	width: 700px;
	@media screen and (max-width: ${DS
			.customBreakpoints.drawerBreakpoint}px) {
		margin: 0px;
	}

	${({ isTablet }) =>
		isTablet &&
		`
    padding: 0px;
    width: 100%;
  `}
`;

const CollabPageModal: FC = ({ children }) => {
	useEffect(() => {
		currentStack++;
		return () => {
			currentStack--;
		};
	}, []);

	const isTablet = useIsTablet();
	const { goBack } = useRoute();

	const modalRef = useOutsideClick(() => {
		goBack();
	});
	return (
		<PageModalContainer>
			<PageModalHeader />
			<PageModalBodyContainer
				isTablet={isTablet}
				ref={modalRef}
			>
				{!isTablet && (
					<>
						<div
							style={{
								float: "right",
								cursor: "pointer",
							}}
							onClick={() => {
								goBack();
							}}
						>
							<IconClose />
						</div>
						<br />
					</>
				)}
				{children}
			</PageModalBodyContainer>
		</PageModalContainer>
	);
};

export default CollabPageModal;
