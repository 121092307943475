import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
	Input,
	Button,
	H2,
	DS,
	Spacing,
	FlexCenter,
	Caption,
	ClickableIcon,
	IconEye,
} from "@workshore/nyaari-ds";

import styled from "styled-components";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import getFirstError from "../../utils/getFirstError";
import { useResetPasswordMutation } from "../../hooks/graphql/graphql";
import useRoute from "../../hooks/useRoute/useRoute";
import SplitView1 from "../../components/SplitView1/SplitView1";

const StyledH2 = styled(H2)`
	padding-top: 212px;

	@media screen and (max-width: 480px) {
		padding-top: 32px;
	}
`;

const Subtitle = styled.div<{
	isMobile: boolean;
}>`
	font-size: ${DS.fontSize.body2}px;
	color: ${DS.colors.boulder};
	margin: ${DS.spacing.s2}px 0 ${DS.spacing.s16}px
		0;

	${({ isMobile }) => `
    font-size: ${DS.fontSize.body3}px;
    `}
`;

const ResetPassword = () => {
	const isMobile = useIsMobile();
	const { code } = useParams<{ code: string }>();
	const { goTo } = useRoute();
	const [resetPasswordMutation, { loading }] =
		useResetPasswordMutation();
	const [serverError, setServerError] =
		useState("");
	const [showPassword, setShowPassword] =
		useState(false);
	const [
		showPasswordConfirmation,
		setShowPasswordConfirmation,
	] = useState(false);

	const [inputs, setInputs] = useState({
		password: "",
		passwordConfirmation: "",
	});

	const [errors, setErrors] = useState({
		password: "",
		passwordConfirmation: "",
	});

	//here is submit function
	const handleSubmit = async (
		e: React.MouseEvent<
			HTMLButtonElement | MouseEvent
		>,
	) => {
		e.preventDefault();

		//initializing blank errors object
		const errors = {
			password: "",
			passwordConfirmation: "",
		};

		if ((inputs.password || "").length < 6) {
			errors.password =
				"Password must be at least 6 characters long";
		}

		if (
			inputs.password !==
			inputs.passwordConfirmation
		) {
			errors.passwordConfirmation =
				"Password must be same as filled above";
		}

		if (
			Object.values(errors).some((d) => d.length)
		) {
			console.log(errors);
			setErrors(errors);
		} else {
			try {
				const result =
					await resetPasswordMutation({
						variables: {
							password: inputs.password,
							passwordConfirmation:
								inputs.passwordConfirmation,
							code,
						},
					});
				result.data?.resetPassword?.user?.email &&
					goTo("/signin", "replace");
			} catch (err) {
				console.log({ err });
				setServerError(getFirstError(err));
			}
		}
	};
	return (
		<SplitView1>
			<StyledH2>Reset your Password</StyledH2>

			<Subtitle isMobile={isMobile}>
				Enter new password.
			</Subtitle>
			<form>
				<div>
					<Input
						placeholder="Password"
						handleInputChange={(e) => {
							setErrors((e) => ({
								...e,
								password: "",
							}));
							setInputs((inputs) => ({
								...inputs,
								password: e.target.value,
							}));
						}}
						value={inputs.password}
						rightIcon={
							<ClickableIcon
								onClick={() =>
									setShowPassword(
										(value) => !value,
									)
								}
							>
								<IconEye active={showPassword} />
							</ClickableIcon>
						}
						type={
							showPassword ? "text" : "password"
						}
						marginBottom={30}
						error={errors.password}
						disabled={loading}
					/>
				</div>

				<div>
					<Input
						placeholder="Password Confirmation"
						handleInputChange={(e) => {
							setErrors((e) => ({
								...e,
								passwordConfirmation: "",
							}));
							setInputs((inputs) => ({
								...inputs,
								passwordConfirmation:
									e.target.value,
							}));
						}}
						value={inputs.passwordConfirmation}
						rightIcon={
							<ClickableIcon
								onClick={() =>
									setShowPasswordConfirmation(
										(value) => !value,
									)
								}
							>
								<IconEye
									active={
										showPasswordConfirmation
									}
								/>
							</ClickableIcon>
						}
						type={
							showPasswordConfirmation
								? "text"
								: "password"
						}
						marginBottom={30}
						error={errors.passwordConfirmation}
						disabled={loading}
					/>
				</div>

				<div>
					<Button
						handleButtonClick={handleSubmit}
						marginBottom={isMobile ? 48 : 30}
						disabled={loading}
					>
						{loading ? "Submitting..." : "Submit"}
					</Button>
					{serverError.length ? (
						<>
							<Spacing />
							<FlexCenter>
								<Caption fontColor="red">
									{serverError}
								</Caption>
							</FlexCenter>
						</>
					) : null}
				</div>
			</form>
		</SplitView1>
	);
};

export default ResetPassword;
