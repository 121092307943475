import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconUser: FC<{
	color?: keyof typeof DS.colors;
}> = ({ color = "meals" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18.377"
			height="18.377"
			viewBox="0 0 18.377 18.377"
		>
			<g
				id="user"
				transform="translate(0.35 0.35)"
			>
				<path
					id="Path_1914"
					data-name="Path 1914"
					d="M8.838,17.677A8.837,8.837,0,1,1,15.6,14.535a8.931,8.931,0,0,1-1.043,1.046,8.841,8.841,0,0,1-5.715,2.1Zm0-16.572A7.734,7.734,0,0,0,2.925,13.822a7.824,7.824,0,0,0,.913.915,7.734,7.734,0,0,0,10,0,7.824,7.824,0,0,0,.913-.916A7.734,7.734,0,0,0,8.838,1.1Z"
					transform="translate(0 0)"
					fill={DS.colors[color]}
					stroke={DS.colors[color]}
					strokeWidth="0.7"
				/>
				<path
					id="Path_1915"
					data-name="Path 1915"
					d="M162.734,69.467a2.734,2.734,0,1,1,2.734-2.734A2.737,2.737,0,0,1,162.734,69.467Zm0-4.556a1.822,1.822,0,1,0,1.822,1.822A1.824,1.824,0,0,0,162.734,64.911Z"
					transform="translate(-153.895 -59.641)"
					fill={DS.colors[color]}
					stroke={DS.colors[color]}
					strokeWidth="0.7"
				/>
				<path
					id="Path_1916"
					data-name="Path 1916"
					d="M89.98,230.58a.513.513,0,0,1-.118-.014.455.455,0,0,1-.35-.553,3.905,3.905,0,0,0,.13-1,4.237,4.237,0,0,0-4.275-4.1H85.24a4.237,4.237,0,0,0-4.275,4.1,3.905,3.905,0,0,0,.13,1,.454.454,0,0,1-.35.553.485.485,0,0,1-.585-.331A4.768,4.768,0,0,1,80,229.012a4.845,4.845,0,0,1,1.529-3.52,5.425,5.425,0,0,1,3.7-1.491h.15a5.425,5.425,0,0,1,3.7,1.491,4.845,4.845,0,0,1,1.529,3.52,4.767,4.767,0,0,1-.159,1.224A.478.478,0,0,1,89.98,230.58Z"
					transform="translate(-76.465 -214.502)"
					fill={DS.colors[color]}
					stroke={DS.colors[color]}
					strokeWidth="0.7"
				/>
			</g>
		</svg>
	);
};

export default IconUser;
