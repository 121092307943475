import {
	Body2,
	Box,
	Button,
	Caption,
	FlexCenter,
	H2,
	Input,
	ProfileImagePicker,
	Select,
	Spacing,
	TextArea,
	Wrapper,
} from "@workshore/nyaari-ds";
import React, {
	useCallback,
	useState,
} from "react";
import {
	useGetCitiesMutation,
	useGetCountriesQuery,
	useGetStatesMutation,
	useUpdateProfileMutation,
	useUpdateProfileWoImageMutation,
} from "../../hooks/graphql/graphql";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import me from "../../services/me";
import { PROFILE_PLACEHOLDER } from "../../utils/constant";
import PageSidebar from "../PageSidebar/PageSidebar";
import ShowIf from "../ShowIf/ShowIf";
import SidebarHeader from "../SidebarHeader/SidebarHeader";
import imageCompression from "browser-image-compression";
import dayjs from "dayjs";
import getFirstError from "../../utils/getFirstError";
import useRoute from "../../hooks/useRoute/useRoute";
import { useToasts } from "react-toast-notifications";
const ProfileEditComponent = () => {
	const { addToast } = useToasts();
	const { goBack } = useRoute();
	const user = me.get();
	const updateProfile =
		useUpdateProfileMutation();
	const updateProfileWOImg =
		useUpdateProfileWoImageMutation();
	const country = useGetCountriesQuery();
	const state = useGetStatesMutation();
	const city = useGetCitiesMutation();
	const [serverError, setServerError] =
		useState("");
	const [errors, setErrors] = useState({
		name: "",
		dob: "",
		country: "",
		state: "",
		city: "",
		bio: "",
		profession: "",
		interests: "",
	});
	const [userDetails, setUserDetails] = useState<{
		name: string;
		dob?: string;
		country?: string;
		state?: string;
		city?: string;
		timezone?: string;
		bio?: string;
		file?: File;
		image?: string;
		interests?: string;
		profession?: string;
	}>({
		name: user.name,
		dob: user.dob,
		country: user.country,
		state: user.state,
		city: user.city,
		timezone: user.timezone,
		bio: user.bio,
		image: user.avatar?.url,
		interests: user.interests,
		profession: user.profession,
	});
	const isTablet = useIsTablet();
	const compressFile = useCallback((file) => {
		if (file) {
			imageCompression(file, {
				maxWidthOrHeight: 400,
				useWebWorker: true,
			})
				.then((res) => {
					const reader = new FileReader();
					reader.addEventListener(
						"load",
						(image) => {
							setUserDetails((user) => ({
								...user,
								file: res,
								image: image.target?.result,
							}));
						},
					);
					reader.readAsDataURL(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, []);
	const stateData = (
		state[1].data || {
			states: [],
		}
	).states!.map(({ name }) => ({
		id: name,
		name,
	}));
	const cityData = (
		city[1].data || {
			cities: { cities: [] },
		}
	).cities.cities!.map(({ name }) => ({
		id: name,
		name,
	}));

	const updateData = useCallback(() => {
		setServerError("");
		const errors = {
			name: "",
			dob: "",
			country: "",
			state: "",
			city: "",
			bio: "",
			interests: "",
			profession: "",
		};
		if (userDetails.name.length < 1) {
			errors.name = "Full Name is required";
		}
		if ((userDetails.dob || "").length < 1) {
			errors.dob = "Please select a valid date";
		}
		if ((userDetails.country || "").length < 1) {
			errors.country =
				"Please select a valid country";
		}
		if ((userDetails.state || "").length < 1) {
			errors.state =
				"Please select a valid state";
		}
		if ((userDetails.city || "").length < 1) {
			errors.city = "Please select a valid city";
		}
		if ((userDetails.bio || "").length < 1) {
			errors.bio = "Please select a valid bio";
		}
		if (
			Object.values(errors).filter(
				(d) => d.length,
			).length
		) {
			setErrors(errors);
			return;
		}
		if (!userDetails.file) {
			updateProfileWOImg[0]({
				variables: {
					bio: userDetails.bio!,
					city: userDetails.city!,
					country: userDetails.country!,
					dob: userDetails.dob,
					id: user.id,
					name: userDetails.name,
					state: userDetails.state!,
					timezone: userDetails.timezone!,
					interests: userDetails.interests,
					profession: userDetails.profession,
				},
			})
				.then((res) => {
					console.log(res);
					const updatedUser =
						res.data?.updateUser?.user!;
					me.set({
						...me.get(),
						...(updatedUser as any),
					});
					goBack();
					addToast("Profile has been updated", {
						autoDismiss: true,
						appearance: "success",
					});
				})
				.catch((err) => {
					setServerError(getFirstError(err));
				});
		} else {
			updateProfile[0]({
				variables: {
					bio: userDetails.bio!,
					city: userDetails.city!,
					country: userDetails.country!,
					dob: userDetails.dob,
					id: user.id,
					name: userDetails.name,
					state: userDetails.state!,
					timezone: userDetails.timezone!,
					file: userDetails.file,
					interests: userDetails.interests,
					profession: userDetails.profession,
				},
			})
				.then((res) => {
					console.log(res);
					const updatedUser =
						res.data?.updateUser?.user!;
					me.set({
						...me.get(),
						...(updatedUser as any),
					});
					goBack();
					addToast("Profile has been updated", {
						autoDismiss: true,
						appearance: "success",
					});
				})
				.catch((err) => {
					setServerError(getFirstError(err));
				});
		}
	}, [
		updateProfile,
		updateProfileWOImg,
		userDetails,
		user,
		goBack,
		addToast,
	]);

	const loading =
		updateProfile[1].loading ||
		updateProfileWOImg[1].loading;

	return (
		<Box
			padding={isTablet ? "20px 0px" : "40px 0px"}
		>
			<Wrapper type="fluid">
				<ShowIf value={!isTablet}>
					<SidebarHeader>Profile</SidebarHeader>
				</ShowIf>
				<ShowIf value={isTablet}>
					<H2>Profile</H2>
				</ShowIf>
				<Caption
					fontColor="boulder"
					style={{
						textAlign: isTablet
							? "left"
							: "center",
					}}
				>
					To know you better is to serve you
					better.
				</Caption>
				<Spacing type="s24" />
				<ShowIf value={!country.loading}>
					<FlexCenter>
						<ProfileImagePicker
							handleImageChange={compressFile}
							imageSrc={
								userDetails.image ||
								PROFILE_PLACEHOLDER
							}
							loading={false}
						/>
					</FlexCenter>
					<Spacing type="s24" />
					<Body2>Name</Body2>
					<Spacing type="s8" />
					<Input
						disabled={loading}
						placeholder="Full Name"
						value={userDetails.name || ""}
						handleInputChange={(e) => {
							setUserDetails((user) => ({
								...user,
								name: e.target.value,
							}));
							setErrors((errors) => ({
								...errors,
								name: "",
							}));
						}}
						error={errors.name}
					/>
					<Spacing type="s16" />
					<Body2>DOB</Body2>
					<Spacing type="s8" />
					<Input
						disabled={loading}
						placeholder=""
						type="date"
						max={dayjs().format("YYYY-MM-DD")}
						value={userDetails.dob || ""}
						handleInputChange={(e) => {
							setUserDetails((user) => ({
								...user,
								dob: e.target.value,
							}));
							setErrors((errors) => ({
								...errors,
								dob: "",
							}));
						}}
						error={errors.dob}
					/>
					<Spacing type="s16" />
					<Body2>Country</Body2>
					<Spacing type="s8" />
					<Select
						disabled={loading}
						placeholder="Country"
						options={(
							country.data || { countries: [] }
						).countries?.map(({ name }) => ({
							id: name,
							name,
						}))}
						value={
							userDetails.country
								? {
										id: userDetails.country,
										name: userDetails.country,
								  }
								: (null as any)
						}
						onSelect={(value) => {
							if (value) {
								setUserDetails((user) => ({
									...user,
									country: value.name,
									state: null,
									city: null,
								}));
								state[1].client.stop();
								city[1].client.stop();
								state[0]({
									variables: {
										country: value.name,
									},
								});
							}
							setErrors((errors) => ({
								...errors,
								country: "",
							}));
						}}
						error={errors.country}
					/>
					<Spacing type="s16" />
					<Body2>State</Body2>
					<Spacing type="s8" />
					<Select
						placeholder={
							state[1].loading
								? "Loading..."
								: "Select State"
						}
						disabled={state[1].loading || loading}
						value={
							userDetails.state
								? {
										id: userDetails.state,
										name: userDetails.state,
								  }
								: (null as any)
						}
						options={
							state[1].loading
								? []
								: stateData.length
								? stateData
								: [
										{
											name:
												userDetails.country || "",
											id:
												userDetails.country || "",
										},
								  ]
						}
						onSelect={(value) => {
							if (value) {
								setUserDetails((user) => ({
									...user,
									state: value.name,
									city: null,
								}));
								city[1].client.stop();
								city[0]({
									variables: {
										country:
											userDetails.country || "",
										state: value.name,
									},
								});
							}
							setErrors((errors) => ({
								...errors,
								state: "",
							}));
						}}
						error={errors.state}
					/>
					<Spacing type="s16" />
					<Body2>City</Body2>
					<Spacing type="s8" />
					<Select
						placeholder={
							city[1].loading
								? "Loading.."
								: "City"
						}
						disabled={city[1].loading || loading}
						options={
							city[1].loading
								? []
								: cityData.length
								? cityData
								: userDetails.state
								? [
										{
											name:
												userDetails.state || "",
											id: userDetails.state || "",
										},
								  ]
								: [
										{
											name:
												userDetails.country || "",
											id:
												userDetails.country || "",
										},
								  ]
						}
						onSelect={(value) => {
							if (value) {
								let predictTimeZone = () => {
									let availableTimeZones =
										city[1].data?.cities
											.timezone || [];
									if (
										availableTimeZones.length > 0
									) {
										let filterTimeZone =
											availableTimeZones.filter(
												(d) =>
													d.zoneName.includes(
														value.name,
													),
											);
										if (
											filterTimeZone.length > 0
										) {
											return filterTimeZone[0].gmtOffsetName.replace(
												"UTC",
												"",
											);
										}
										return availableTimeZones[0].gmtOffsetName.replace(
											"UTC",
											"",
										);
									}
									return "+0:00";
								};
								setUserDetails((user) => ({
									...user,
									city: value.name,
									timezone: predictTimeZone(),
								}));
							}
							setErrors((errors) => ({
								...errors,
								city: "",
							}));
						}}
						error={errors.city}
						value={
							userDetails.city
								? {
										id: userDetails.city,
										name: userDetails.city,
								  }
								: (null as any)
						}
					/>
					<Spacing type="s16" />
					<Body2>Bio</Body2>
					<Spacing type="s8" />
					<TextArea
						placeholder="How would you like to introduce yourself?"
						value={userDetails.bio || ""}
						handleTextAreaChange={(bio) => {
							setUserDetails((user) => ({
								...user,
								bio,
							}));
							setErrors((errors) => ({
								...errors,
								bio: "",
							}));
						}}
						error={errors.bio}
					/>
					<Spacing type="s16" />
					<Body2>Profession</Body2>
					<Spacing type="s8" />
					<Input
						disabled={loading}
						placeholder="Profession"
						value={userDetails.profession || ""}
						handleInputChange={(e) => {
							setUserDetails((user) => ({
								...user,
								profession: e.target.value,
							}));
							setErrors((errors) => ({
								...errors,
								profession: "",
							}));
						}}
						error={errors.profession}
					/>
					<Spacing type="s16" />
					<Body2>Interests</Body2>
					<Spacing type="s8" />
					<Input
						disabled={loading}
						placeholder="Interests"
						value={userDetails.interests || ""}
						handleInputChange={(e) => {
							setUserDetails((user) => ({
								...user,
								interests: e.target.value,
							}));
							setErrors((errors) => ({
								...errors,
								interests: "",
							}));
						}}
						error={errors.interests}
					/>
					<Spacing type="s16" />
					<Button
						handleButtonClick={updateData}
						disabled={loading}
					>
						{loading ? "Updating.." : "Update"}
					</Button>
					<Spacing type="s24" multiplier={4} />
				</ShowIf>
				<ShowIf value={serverError.length}>
					<Caption fontColor="red">
						{serverError}
					</Caption>
				</ShowIf>
				<ShowIf value={country.loading}>
					<FlexCenter>
						<Body2>Loading...</Body2>
					</FlexCenter>
				</ShowIf>
			</Wrapper>
		</Box>
	);
};

const ProfileEdit = () => {
	return (
		<PageSidebar name="profile_edit">
			<ProfileEditComponent />
		</PageSidebar>
	);
};

export default ProfileEdit;
