import React, {
	useCallback,
	useState,
} from "react";
import {
	H2,
	H3,
	Input,
	Button,
	Body1,
	Body2,
	Caption,
	DS,
	Box,
	ClickableIcon,
	IconEye,
	Spacing,
	FlexCenter,
	Modal,
} from "@workshore/nyaari-ds";
import {
	Link,
	useHistory,
} from "react-router-dom";

import fb from "../../assets/icons/fb.svg";
import google from "../../assets/icons/google.svg";
import styled from "styled-components";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import validateEmail from "../../utils/validateEmail";
import { useRegisterMutation } from "../../hooks/graphql/graphql";
// import useRoute from "../../hooks/useRoute/useRoute";
import getFirstError from "../../utils/getFirstError";
// import { setAuthToken } from "../../services/authToken";
import SplitView1 from "../SplitView1/SplitView1";
import contact_success from "../../assets/images/contact_success.png";
import { ENDPOINT_URL } from "../../config";
import Footer from "../Footer/Footer";

const DividerContainer = styled.div`
	display: flex;
	align-items: center;
	& > .divider-line {
		flex: 1;
		height: 1px;
		background-color: ${DS.colors.lightGray};
	}

	p {
		margin: 0px ${DS.spacing.s8}px;
	}
`;

const IconContainer = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	& > div {
		cursor: pointer;
		${({ isMobile }) => `
      img{
      width:${isMobile ? "28px" : "40px"};
      height:${isMobile ? "28px" : "40px"};
    }
    `}
	}
	& > div:last-child {
		margin-left: 40px;
	}
`;

const StyledBox = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	& > div {
		display: flex;
		p:first-child {
			margin-right: 5px;
		}
	}
`;

const StyledH2 = styled(H2)`
	padding-top: 212px;

	@media screen and (max-width: 480px) {
		padding-top: 32px;
	}
`;

// modal
const ModalDiv = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 64px 98px;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 35px 55px;
  `};
`;

const ModalImg = styled.img<{
	isMobile: boolean;
}>`
	height: 316px;
	width: 316px;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 223px;
    width: 223px;
  `};
`;

const SignUp = () => {
	// const { goTo } = useRoute();
	const [signUp, { loading }] =
		useRegisterMutation();
	const isMobile = useIsMobile();
	const [showPassword, setShowPassword] =
		useState(false);
	const [serverError, setServerError] =
		useState("");

	const [inputs, setInputs] = useState({
		email: "",
		password: "",
		name: "",
	});
	const [errors, setErrors] = useState({
		email: "",
		password: "",
		name: "",
	});
	const [modal, setModal] = useState(false);
	const history = useHistory();

	const handleModalToggle = useCallback(() => {
		setModal(!modal);
	}, [modal]);

	//here is submit function
	const handleSubmit = useCallback(() => {
		//initializing blank errors object
		const errors = {
			email: "",
			password: "",
			name: "",
		};

		if (!validateEmail(inputs.email)) {
			errors.email = "Invalid email address";
		}

		if ((inputs.password || "").length < 6) {
			errors.password =
				"Password must be at least 6 characters long";
		}

		if ((inputs.name || "").length < 1) {
			errors.name = "Invalid Name";
		}

		//checking if any error(s) occurs
		if (
			Object.values(errors).filter(
				(d) => d.length,
			).length
		) {
			console.log(errors);
			setErrors(errors);
		} else {
			//call api
			signUp({
				variables: {
					email: inputs.email,
					password: inputs.password,
					name: inputs.name,
				},
			})
				.then((response) => {
					console.log(response.data?.newRegister);
					// setAuthToken(
					// 	response.data!.newRegister!.jwt || "",
					// );
					// goTo("/dashboard", "replace");
					handleModalToggle();
				})
				.catch((err) => {
					console.log({ err });
					setServerError(getFirstError(err));
				});
		}
	}, [inputs, signUp, handleModalToggle]);

	return (
		<>
			<SplitView1 isHeart>
				<div>
					<StyledH2>Become A Member</StyledH2>
					<Body1
						fontColor="boulder"
						fontWeight={500}
						marginBottom={isMobile ? 49 : 30}
						marginTop={6}
					>
						Start your FREE week (No credit card
						required)
					</Body1>
				</div>

				<div>
					<Input
						placeholder="Name"
						handleInputChange={(e) => {
							setErrors((e) => ({
								...e,
								name: "",
							}));
							setInputs((inputs) => ({
								...inputs,
								name: e.target.value,
							}));
						}}
						value={inputs.name}
						type="text"
						marginBottom={isMobile ? 22 : 30}
						error={errors.name}
						disabled={loading}
					/>
				</div>
				<div>
					<Input
						placeholder="Email ID"
						handleInputChange={(e) => {
							setErrors((e) => ({
								...e,
								email: "",
							}));
							setInputs((inputs) => ({
								...inputs,
								email: e.target.value,
							}));
						}}
						value={inputs.email}
						type="email"
						marginBottom={isMobile ? 22 : 30}
						error={errors.email}
						disabled={loading}
					/>
				</div>
				<div>
					<Input
						placeholder="Password"
						handleInputChange={(e) => {
							setErrors((e) => ({
								...e,
								password: "",
							}));
							setInputs((inputs) => ({
								...inputs,
								password: e.target.value,
							}));
						}}
						value={inputs.password}
						rightIcon={
							<ClickableIcon
								onClick={() =>
									setShowPassword(
										(value) => !value,
									)
								}
							>
								<IconEye active={showPassword} />
							</ClickableIcon>
						}
						type={
							showPassword ? "text" : "password"
						}
						marginBottom={30}
						error={errors.password}
						disabled={loading}
					/>
				</div>

				<div>
					<Button
						handleButtonClick={handleSubmit}
						disabled={loading}
					>
						{loading
							? "Submitting..."
							: "Join Nyaari"}
					</Button>
					{serverError.length ? (
						<>
							<Spacing />
							<FlexCenter>
								<Caption fontColor="red">
									{serverError}
								</Caption>
							</FlexCenter>
						</>
					) : null}
				</div>

				<div
					style={{
						textAlign: "center",
						marginBottom: isMobile ? 35 : 30,
						marginTop: isMobile ? 13 : 30,
					}}
				>
					<Body2
						fontWeight="normal"
						fontColor="redRibbon"
					>
						Prioritize your health with our easy{" "}
						<br /> 5-in-1 plan & support.
					</Body2>
				</div>

				<DividerContainer
					style={{
						marginBottom: isMobile ? 35 : 30,
					}}
				>
					<div className="divider-line" />
					<Caption fontColor="boulder">
						or Sign up with
					</Caption>
					<div className="divider-line" />
				</DividerContainer>
				<IconContainer isMobile={isMobile}>
					<div>
						<a
							href={`${ENDPOINT_URL}/connect/facebook`}
						>
							<img src={fb} alt="facebook" />
						</a>
					</div>
					<div>
						<a
							href={`${ENDPOINT_URL}/connect/google`}
						>
							<img src={google} alt="google" />
						</a>
					</div>
				</IconContainer>
				<StyledBox
					marginTop={40}
					marginBottom={32}
				>
					<div>
						<Body2 fontWeight="normal">
							Existing member?
						</Body2>
						<Link to="/signin">
							<Body1
								fontColor="redRibbon"
								fontWeight={600}
							>
								Sign In
							</Body1>
						</Link>
					</div>
				</StyledBox>
			</SplitView1>
			<Footer />
			<Modal
				show={modal}
				handleClose={handleModalToggle}
				borderRadius="5"
				width={isMobile ? "80vw" : "565px"}
				placement="center"
				dismissible
			>
				<ModalDiv isMobile={isMobile}>
					<ModalImg
						isMobile={isMobile}
						src={contact_success}
						alt=""
					/>
					<H3
						marginTop={isMobile ? "26px" : "30px"}
					>
						We Got You!
					</H3>
					<Body2
						style={{ textAlign: "center" }}
						marginTop={isMobile ? "9px" : "15px"}
						marginBottom={
							isMobile ? "24px" : "41px"
						}
					>
						Please verify your account by clicking
						the confirmation link in your email
					</Body2>
					<Button
						handleButtonClick={() => {
							handleModalToggle();
							history.push("/");
						}}
						width={isMobile ? "225px" : "335px"}
						height="50px"
					>
						Done
					</Button>
				</ModalDiv>
			</Modal>
		</>
	);
};

export default SignUp;
