import React from "react";

const IconRemove = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="3"
      viewBox="0 0 17 3"
    >
      <g
        id="Group_10104"
        data-name="Group 10104"
        transform="translate(0)"
      >
        <rect
          id="Rectangle_1152"
          data-name="Rectangle 1152"
          width="17"
          height="3"
          rx="1.5"
          transform="translate(0)"
          fill="#e4003f"
        />
      </g>
    </svg>
  );
};

export default IconRemove;
