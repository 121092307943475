const IconSort = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13.968"
			height="14"
			viewBox="0 0 13.968 14"
		>
			<g
				id="Group_11829"
				data-name="Group 11829"
				transform="translate(-0.001 0)"
			>
				<path
					id="Path_2560"
					data-name="Path 2560"
					d="M167.885,60.151l2.7-3.019c.128-.145.256-.286.384-.431h-.905l2.7,3.019c.128.145.256.286.384.431a.64.64,0,0,0,.905-.905l-2.7-3.019c-.128-.145-.256-.286-.384-.431a.644.644,0,0,0-.905,0l-2.7,3.019c-.128.145-.256.286-.384.431a.652.652,0,0,0,0,.905A.644.644,0,0,0,167.885,60.151Z"
					transform="translate(-166.797 -55.61)"
					fill="#333"
				/>
				<path
					id="Path_2561"
					data-name="Path 2561"
					d="M247.52,60.3V70.866a.592.592,0,0,0,.641.5c.346-.014.641-.222.641-.5V60.3a.592.592,0,0,0-.641-.5C247.815,59.805,247.52,60.014,247.52,60.3Z"
					transform="translate(-244.443 -59.632)"
					fill="#333"
				/>
				<path
					id="Path_2562"
					data-name="Path 2562"
					d="M338.339,381.494l2.7,3.019c.128.145.256.286.384.431a.644.644,0,0,0,.905,0l2.7-3.019c.128-.145.256-.286.384-.431a.652.652,0,0,0,0-.905.644.644,0,0,0-.905,0l-2.7,3.019c-.128.145-.256.286-.384.431h.905l-2.7-3.019c-.128-.145-.256-.286-.384-.431a.64.64,0,0,0-.905.905Z"
					transform="translate(-331.625 -371.13)"
					fill="#333"
				/>
				<path
					id="Path_2563"
					data-name="Path 2563"
					d="M420.159,125.976V115.407a.592.592,0,0,0-.641-.5c-.346.014-.641.222-.641.5v10.569a.592.592,0,0,0,.641.5C419.865,126.467,420.159,126.258,420.159,125.976Z"
					transform="translate(-409.268 -112.641)"
					fill="#333"
				/>
			</g>
		</svg>
	);
};

export default IconSort;
