import React, {
	FC,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import LayoutContainer from "../LayoutContainer/LayoutContainer";
import ShowIf from "../ShowIf/ShowIf";
import MealsHeader from "../DashboardSubHeader/DashboardSubHeader";
import {
	BaseTypoCSS,
	Body1,
	Body2,
	Box,
	BreakPointsFontsMedia,
	Button,
	flattenBreakpoints,
	FlexBetween,
	FlexCenter,
	H3,
	Spacing,
	Wrapper,
} from "@workshore/nyaari-ds";
import Loader from "../Loader/Loader";
import {
	GroceryListCustom,
	Maybe,
	useCheckMyListItemMutation,
	useCreateMyListItemMutation,
	useDeleteAllMyListQuery,
	useDeleteMyListItemByCategoryMutation,
	useGetMyGroceryListQuery,
	UsersPermissionsMe,
} from "../../hooks/graphql/graphql";
import DS from "@workshore/nyaari-ds/src/DS/DS";
import Color from "color";
import IconAdd from "../Icons/IconAdd";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import IconCheckBox from "../Icons/IconCheckBox";
import IconReset from "../Icons/IconReset";

const Categories = {
	type1: "Dry Goods",
	type2: "Spices, Condiments, Sweeteners",
	type3: "Fresh Produce",
	type4: "Dairy, Eggs, Fish & Poultry",
	type5: "Frozen",
	type6: "Oil & Fats",
};

const listFactory = (
	data?: Maybe<
		Array<
			{ __typename?: "groceryListCustom" } & Pick<
				GroceryListCustom,
				| "id"
				| "description"
				| "checked"
				| "category"
			> & {
					user: {
						__typename?: "UsersPermissionsMe";
					} & Pick<
						UsersPermissionsMe,
						"id" | "username"
					>;
				}
		>
	>,
) => {
	return (data || []).map((d) => {
		return {
			id: d.id,
			description: d.description,
			checked: d.checked || false,
			category: d.category,
		};
	});
};

const BorderLessInput = styled.input`
	flex: 1;
	${BaseTypoCSS({
		fontFamily: "primary",
		fontWeight: 400,
	})}
	font-size: ${DS.typography.body2.min}px;
	line-height: ${DS.typography.body2.min +
	DS.typography.body2.lineHeight}px;
	letter-spacing: 0;

	${BreakPointsFontsMedia(
		flattenBreakpoints,
		DS.typography.body2.max,
		DS.typography.body2.min,
		DS.typography.body2.lineHeight,
	)};
	border: none;
	background-color: transparent;
	outline: none;
`;

const AddInput: FC<{
	onAdd: (text: string) => void;
}> = ({ onAdd }) => {
	const [value, setValue] = useState("");
	const ref = useRef<HTMLInputElement>(null);
	return (
		<div
			style={{
				display: "flex",
			}}
		>
			<div
				style={{
					transform: "scale(0.9)",
					display: "flex",
					alignItems: "center",
				}}
				onClick={() => {
					if (ref.current) ref.current.focus();
				}}
			>
				<IconAdd />
			</div>
			<Spacing />
			<form
				style={{
					display: "flex",
					flex: 1,
				}}
				onSubmit={(e) => {
					e.preventDefault();
					onAdd(value);
					setValue("");
					return false;
				}}
			>
				<BorderLessInput
					ref={ref}
					placeholder="Add an item to the list"
					maxLength={60}
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
					}}
				/>
			</form>
		</div>
	);
};

const Category: FC<{
	reset: boolean;
	list: {
		id: string;
		description: string;
		checked: boolean;
		category: string;
	}[];
	showBorder?: boolean;
	categoryType: keyof typeof Categories;
	onListUpdated: (newList: list) => void;
}> = ({
	reset,
	list,
	categoryType,
	showBorder = false,
	onListUpdated,
}) => {
	const isTablet = useIsTablet();

	const { addToast } = useToasts();
	const [itemToUpdate, setItemToUpdate] =
		useState("");
	const [tempItemText, setTempItemText] =
		useState("");
	const [addItemToList, { loading }] =
		useCreateMyListItemMutation();
	const [checkItemToList, checkItemMutation] =
		useCheckMyListItemMutation();
	const [
		resetCategoryList,
		resetCategoryMutation,
	] = useDeleteMyListItemByCategoryMutation();
	const addItem = useCallback(
		(text: string) => {
			addItemToList({
				variables: {
					category: categoryType,
					decription: text,
				},
			})
				.then(({ data }) => {
					if (data) {
						let newList =
							data.createMyListItem?.filter(
								(d) => {
									return (
										d.category === categoryType
									);
								},
							);
						onListUpdated(listFactory(newList));
						addToast(
							"Item is added to the list",
							{
								autoDismiss: true,
								appearance: "success",
							},
						);
					}
				})
				.catch((error) => {
					console.log(error);
					addToast(
						"Failed to add item to the list",
						{
							autoDismiss: true,
							appearance: "error",
						},
					);
				});
		},
		[
			addItemToList,
			categoryType,
			onListUpdated,
			addToast,
		],
	);

	const toggleItem = useCallback(
		(id: string, status: boolean) => {
			checkItemToList({
				variables: {
					checked: status,
					id: id,
				},
			})
				.then(({ data }) => {
					if (data) {
						let newList =
							data.checkedMyListItem?.filter(
								(d) => {
									return (
										d.category === categoryType
									);
								},
							);
						onListUpdated(listFactory(newList));
					}
					addToast("Item is update to the list", {
						autoDismiss: true,
						appearance: "success",
					});
				})
				.catch((error) => {
					console.log(error);
					addToast(
						"Failed to update item to the list",
						{
							autoDismiss: true,
							appearance: "error",
						},
					);
				});
		},
		[
			checkItemToList,
			addToast,
			onListUpdated,
			categoryType,
		],
	);

	const resetCategory = useCallback(() => {
		resetCategoryList({
			variables: {
				category: categoryType,
			},
		})
			.then(() => {
				onListUpdated([]);
				addToast("Your list has been cleared", {
					autoDismiss: true,
					appearance: "info",
				});
			})
			.catch((error) => {
				console.log(error);
				addToast(
					"Failed to delete items on list",
					{
						autoDismiss: true,
						appearance: "error",
					},
				);
			});
	}, [
		resetCategoryList,
		categoryType,
		onListUpdated,
		addToast,
	]);

	return (
		<Box
			paddingTop={DS.spacing.s2 * 7}
			paddingBottom={DS.spacing.s2 * 7}
			paddingLeft={isTablet ? 0 : DS.spacing.s24}
			paddingRight={isTablet ? 0 : DS.spacing.s24}
			style={
				showBorder && !isTablet
					? {
							borderBottom: `1px solid ${new Color(
								DS.colors.doveGrey,
							)
								.alpha(0.5)
								.rgb()
								.toString()}`,
					  }
					: {}
			}
		>
			<FlexBetween>
				<Body1>{Categories[categoryType]}</Body1>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
					}}
					onClick={() => {
						if (!resetCategoryMutation.loading) {
							resetCategory();
						}
					}}
				>
					<IconReset color="boulder" />
					<Spacing />
					<Body2 fontColor="boulder">
						{resetCategoryMutation.loading
							? "Resetting.."
							: ""}
					</Body2>
				</div>
			</FlexBetween>
			<Spacing type="s16" />
			{list
				.filter(
					(d) => d.category === categoryType,
				)
				.map((item, index) => {
					return (
						<div
							key={index}
							style={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
								flex: 1,
							}}
						>
							<Box
								style={{
									display: "flex",
									alignItems: "center",
									flex: 1,
									cursor: "pointer",
								}}
								onClick={() => {
									if (
										checkItemMutation.loading ||
										reset ||
										resetCategoryMutation.loading
									) {
										addToast(
											"Please wait to other items to update",
											{
												autoDismiss: true,
												appearance: "warning",
											},
										);
										return;
									}
									setItemToUpdate(item.id);
									toggleItem(
										item.id,
										!item.checked,
									);
								}}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<IconCheckBox
										checked={
											checkItemMutation.loading &&
											itemToUpdate === item.id
												? !item.checked
												: item.checked
										}
									/>
								</div>
								<Spacing type="s8" />
								<Body2 fontWeight={400}>
									{item.description}{" "}
									{checkItemMutation.loading &&
									itemToUpdate === item.id
										? "(updating..)"
										: reset ||
										  resetCategoryMutation.loading
										? "(deleting..)"
										: ""}
								</Body2>
							</Box>
							<Spacing type="s8" />
						</div>
					);
				})}
			<ShowIf value={loading}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						flex: 1,
					}}
				>
					<Box
						style={{
							display: "flex",
							alignItems: "center",
							flex: 1,
							cursor: "pointer",
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<IconCheckBox />
						</div>
						<Spacing type="s8" />
						<Body2
							fontColor="boulder"
							fontWeight={400}
						>
							{tempItemText} (adding...)
						</Body2>
					</Box>
					<Spacing type="s8" />
				</div>
			</ShowIf>
			<AddInput
				onAdd={(text) => {
					if (
						reset ||
						resetCategoryMutation.loading
					) {
						addToast(
							"Please wait to other items to update",
							{
								autoDismiss: true,
								appearance: "warning",
							},
						);
						return;
					}
					addItem(text);
					setTempItemText(text);
				}}
			/>
		</Box>
	);
};

type list = {
	id: string;
	description: string;
	checked: boolean;
	category: string;
}[];

const GroceryList: FC = () => {
	const { addToast } = useToasts();

	const isTablet = useIsTablet();
	const [mainReset, setMainReset] =
		useState(false);
	const [list, setList] = useState<
		{
			id: string;
			description: string;
			checked: boolean;
			category: string;
		}[]
	>([]);
	const { data, loading, error, refetch } =
		useGetMyGroceryListQuery({
			fetchPolicy: "no-cache",
		});
	const resetMyList = useDeleteAllMyListQuery({
		skip: true,
	});
	useEffect(() => {
		if (!loading && data?.getMyGroceryList) {
			setList(listFactory(data.getMyGroceryList));
		}
	}, [loading, data]);

	const clearList = useCallback(() => {
		resetMyList
			.fetchMore({})
			.then((data) => {
				setList([]);
				addToast(
					"Your Grocery List has been cleared",
					{
						appearance: "info",
						autoDismiss: true,
					},
				);
			})
			.catch((err) => {
				console.log(err);
				addToast("Failed to reset list", {
					appearance: "error",
					autoDismiss: true,
				});
			})
			.finally(() => {
				setMainReset(false);
			});
	}, [resetMyList, addToast]);

	return (
		<LayoutContainer
			backgroundColor={
				isTablet ? "white" : "wildSand"
			}
		>
			<ShowIf value={isTablet}>
				<MealsHeader hideSearch />
			</ShowIf>

			<Box
				paddingTop={isTablet ? 15 : 50}
				paddingBottom={26}
			>
				<Wrapper type="fluid">
					<FlexBetween>
						<H3>Meals {">"} Grocery List</H3>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={() => {
								if (!mainReset) {
									setMainReset(true);
									clearList();
								}
							}}
						>
							<IconReset />
							<Spacing />
							<Body2 fontColor="redRibbon">
								{mainReset
									? "Resetting.."
									: "Reset All"}
							</Body2>
						</div>
					</FlexBetween>
				</Wrapper>
			</Box>
			<ShowIf value={!loading}>
				<Wrapper type="fluid">
					<Box
						padding={
							isTablet ? "0px" : "26px 0px"
						}
						style={{
							backgroundColor: isTablet
								? "transparent"
								: DS.colors.white,
							borderRadius: DS.borderRadius.body1,
						}}
					>
						{Object.keys(Categories).map(
							(type, index) => {
								return (
									<Category
										reset={mainReset}
										categoryType={
											type as keyof typeof Categories
										}
										key={index}
										list={list}
										onListUpdated={(subList) => {
											setList((list) => {
												return [
													...list.filter(
														(d) =>
															d.category !== type,
													),
													...subList,
												];
											});
										}}
										showBorder={
											Object.keys(Categories)
												.length -
												1 !==
											index
										}
									/>
								);
							},
						)}
					</Box>
				</Wrapper>
			</ShowIf>
			<ShowIf value={loading}>
				<FlexCenter>
					<Loader>
						<Body1>Loading Grocery List...</Body1>
					</Loader>
				</FlexCenter>
			</ShowIf>
			<ShowIf value={error}>
				<FlexCenter>
					<Body1>
						Failed to load Grocery List
					</Body1>
				</FlexCenter>
				<Spacing type="s24" />
				<FlexCenter>
					<Button width="100px">
						<Body2
							as="span"
							fontColor="white"
							onClick={() => {
								refetch();
							}}
						>
							Retry
						</Body2>
					</Button>
				</FlexCenter>
			</ShowIf>
			<ShowIf value={isTablet}>
				<div
					style={{
						height: 200,
					}}
				/>
			</ShowIf>
		</LayoutContainer>
	);
};

export default GroceryList;
