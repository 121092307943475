import { useEffect } from "react";
import useRoute from "../../hooks/useRoute/useRoute";
import { removeAuthToken } from "../../services/authToken";

const LogoutPage = () => {
  const { goTo } = useRoute();
  useEffect(() => {
    removeAuthToken();
    goTo("/signin", "replace");
  });
  return null;
};

export default LogoutPage;
