import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconReset: FC<{
	color?: keyof typeof DS.colors;
}> = ({ color = "redRibbon" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12.5"
			height="12.145"
			viewBox="0 0 12.5 12.145"
		>
			<path
				id="refresh"
				d="M11.248,6.693A6.079,6.079,0,0,0,2.734,5.562,6.1,6.1,0,0,0,1.695,6.577L1.423,5.041a.309.309,0,0,0-.358-.25l-.811.143A.309.309,0,0,0,0,5.292L.648,8.941a.308.308,0,0,0,.358.25l3.649-.644a.309.309,0,0,0,.25-.358l-.143-.811a.309.309,0,0,0-.358-.25L2.884,7.4a4.651,4.651,0,0,1,.726-.69,4.632,4.632,0,1,1,2.826,8.311,4.728,4.728,0,0,1-.62-.041A4.635,4.635,0,0,1,1.858,11.16a.309.309,0,0,0-.356-.253l-.812.137a.309.309,0,0,0-.253.356A6.076,6.076,0,0,0,5.626,16.4a6.184,6.184,0,0,0,.811.054,6.074,6.074,0,0,0,4.81-9.765Z"
				transform="translate(0 -4.314)"
				fill={DS.colors[color]}
			/>
		</svg>
	);
};

export default IconReset;
