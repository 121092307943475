import React, { useState } from "react";
import {
	Input,
	Button,
	H2,
	DS,
	Spacing,
	FlexCenter,
	Caption,
} from "@workshore/nyaari-ds";

import styled from "styled-components";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import validateEmail from "../../utils/validateEmail";
import getFirstError from "../../utils/getFirstError";
import { useForgotPasswordMutation } from "../../hooks/graphql/graphql";
import SplitView1 from "../../components/SplitView1/SplitView1";

const StyledH2 = styled(H2)`
	padding-top: 212px;

	@media screen and (max-width: 480px) {
		padding-top: 32px;
	}
`;

const Subtitle = styled.div<{
	isMobile: boolean;
}>`
	font-size: ${DS.fontSize.body2}px;
	color: ${DS.colors.boulder};
	margin: ${DS.spacing.s2}px 0 ${DS.spacing.s16}px
		0;

	${({ isMobile }) => `
    font-size: ${DS.fontSize.body3}px;
    `}
`;

const Label = styled.h4`
	font-size: ${DS.fontSize.body2}px;
	font-weight: ${DS.fontWeight.medium};
`;

const ForgotPassword = () => {
	const isMobile = useIsMobile();
	const [forgotPasswordMutation, { loading }] =
		useForgotPasswordMutation();
	const [serverError, setServerError] =
		useState("");
	const [successMessage, setSuccessMessage] =
		useState("");

	const [inputs, setInputs] = useState({
		email: "",
	});

	const [errors, setErrors] = useState({
		email: "",
	});

	//here is submit function
	const handleSubmit = async (
		e: React.MouseEvent<
			HTMLButtonElement | MouseEvent
		>,
	) => {
		e.preventDefault();

		//initializing blank errors object
		const errors = {
			email: "",
		};

		if (!validateEmail(inputs.email)) {
			errors.email = "Invalid email address";
		}

		if (
			Object.values(errors).some((d) => d.length)
		) {
			console.log(errors);
			setErrors(errors);
		} else {
			try {
				const result =
					await forgotPasswordMutation({
						variables: {
							email: inputs.email,
						},
					});
				if (result.data?.forgotPassword?.ok) {
					setSuccessMessage(
						`We have sent a recovery email to ${inputs.email}. Check your inbox and follow the link to reset your password.`,
					);
					setInputs({ email: "" });
				}
			} catch (err) {
				console.log({ err });
				setServerError(getFirstError(err));
			}
		}
	};
	return (
		<SplitView1>
			<StyledH2>Enter Your Email</StyledH2>

			<Subtitle isMobile={isMobile}>
				Enter the email associated with your
				account and we’ll send an email with
				instructions to reset your password.
			</Subtitle>
			<form>
				<Label>Email Address</Label>
				<div>
					<Input
						placeholder="Roberweber@gmail.com"
						handleInputBlur={() => {}}
						handleInputChange={(e) => {
							setErrors((e) => ({
								...e,
								email: "",
							}));
							setInputs((inputs) => ({
								...inputs,
								email: e.target.value,
							}));
						}}
						handleInputFocus={() => {}}
						value={inputs.email}
						type="email"
						marginBottom={isMobile ? 22 : 30}
						error={errors.email}
					/>
				</div>

				<div>
					<Button
						handleButtonClick={handleSubmit}
						marginBottom={isMobile ? 48 : 30}
						disabled={loading}
					>
						{loading
							? "Sending..."
							: "Send Instructions"}
					</Button>
					{serverError.length ? (
						<>
							<Spacing />
							<FlexCenter>
								<Caption fontColor="red">
									{serverError}
								</Caption>
							</FlexCenter>
						</>
					) : null}
					{successMessage.length ? (
						<>
							<Spacing />
							<FlexCenter>
								<Caption fontColor="meals">
									{successMessage}
								</Caption>
							</FlexCenter>
						</>
					) : null}
				</div>
			</form>
		</SplitView1>
	);
};

export default ForgotPassword;
