import {
	useState,
	useEffect,
	useCallback,
	RefObject,
} from "react";

export type elementScroll = {
	scrollY: number;
	scrollX: number;
	scrollDirection: "down" | "up" | "";
};

export const useElementScroll = (
	ref: RefObject<HTMLElement>,
) => {
	const [state, setState] = useState<{
		lastScrollTop: number;
		scrollY: number;
		scrollX: number;
		scrollDirection: "down" | "up" | "";
	}>({
		lastScrollTop: 0,
		scrollY:
			ref.current?.getBoundingClientRect().top ||
			0,
		scrollX:
			ref.current?.getBoundingClientRect().left ||
			0,
		scrollDirection: "",
	});
	const __ref = ref.current;

	const handleScrollEvent = useCallback((_) => {
		setState((prevState: any) => {
			const prevLastScrollTop =
				prevState.lastScrollTop;

			return {
				scrollY: -_.target.scrollTop,
				scrollX: _.target.scrollLeft,
				scrollDirection:
					prevLastScrollTop > -_.target.scrollTop
						? "down"
						: "up",
				lastScrollTop: -_.target.scrollTop,
			};
		});
	}, []);

	useEffect(() => {
		const _ref = __ref;
		const scrollListener = (e: any) => {
			handleScrollEvent(e);
		};
		_ref?.addEventListener(
			"scroll",
			scrollListener,
		);

		return () => {
			_ref?.removeEventListener(
				"scroll",
				scrollListener,
			);
		};
	}, [handleScrollEvent, __ref]);

	return {
		scrollY: state.scrollY,
		scrollX: state.scrollX,
		scrollDirection: state.scrollDirection,
	};
};

export default useElementScroll;
