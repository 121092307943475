import {
	Box,
	H3,
	Wrapper,
	Spacing,
	CardContainer,
	Subtitle1,
	ListItem,
	Body1,
	Body2,
	FlexBetween,
	FlexCenter,
	H1,
} from "@workshore/nyaari-ds";
import DS from "@workshore/nyaari-ds/src/DS/DS";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import bannerImg from "../../assets/images/Dashboard.png";
import { useMeValue } from "../../services/me";
import IconBannerEdit from "../Icons/IconBannerEdit";
import IconEdit from "../Icons/IconEdit";
import IconRightArrow from "../Icons/IconRightArrow";
import ShowIf from "../ShowIf/ShowIf";
import WhatWhy from "../WhatWhy/WhatWhy";
import calander from "../../assets/icons/calendar.svg";
// import brain from "../../assets/images/brain.png";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import { useGetMyPrioritiesQuery } from "../../hooks/graphql/graphql";
import useSharedState from "@tinyd/usesharedstate";

const BannerTextContainer = styled.div`
	margin: 0px;
	/* padding: 0px 150px 0px 36px; */
	padding: 0px 50px;
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #e2f4f9;
`;

const LinkStyled = styled(Link)`
	flex: 1;
	display: flex;
`;

const CompleteProfile = () => {
	return (
		<Link to="#sb_profile_edit">
			<Box marginTop={DS.spacing.s32 * 1.3}>
				<ListItem
					outerPadding={0}
					leftIcon={<IconBannerEdit />}
					title={
						<Body1 fontWeight={600}>
							May we know you better?
						</Body1>
					}
					subTitle={
						<Body2 fontColor="boulder">
							To know you better is to serve you
							better.
						</Body2>
					}
					rightIcon={<IconRightArrow />}
				/>
			</Box>
		</Link>
	);
};

const FlexBetweenContainer = styled(FlexBetween)<{
	isSmall: boolean;
}>`
	${({ isSmall }) => `
  height: ${isSmall ? "200px" : "231px"};
  `}
`;

const CardContainerWrapper = styled(
	CardContainer,
)<{ isSmall: boolean }>`
	flex: 1;
	display: flex;
	height: 100%;
	${({ isSmall }) => `
  flex-direction: ${isSmall ? "column" : "row"};
  ${
		isSmall
			? "align-items: center"
			: "align-items: center;"
	};
  & img {
    object-fit: contain;
    width: ${isSmall ? "70px" : "30%"};
  }
  `}
`;

const MainCTA = () => {
	const ref = useRef<HTMLDivElement>(null);
	const width = useElementWidth(ref);
	const myPriorities = useGetMyPrioritiesQuery();

	const [totalTicks, setTotalTicks] =
		useSharedState("priorities", 0);

	useEffect(() => {
		let ticks = 0;
		if (myPriorities?.data) {
			const priorities =
				myPriorities?.data?.getMyPriorities
					.priorities;
			priorities?.forEach((prio) => {
				if (
					prio?.checkin ===
					dayjs().format("YYYY-MM-DD")
				) {
					ticks++;
				}
			});
			setTotalTicks(ticks);
		}
	}, [myPriorities, setTotalTicks]);

	// const screenWidth = useScreenWidth();
	const isSmall = width <= 750;
	const isXSmall = width <= 500;
	const isXXSmall = width <= 400;

	return (
		<div>
			<FlexBetweenContainer
				ref={ref}
				isSmall={isSmall}
			>
				<LinkStyled to="/dashboard/weekly-planner">
					<CardContainerWrapper
						background="#FAE2E8"
						boxShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
						isSmall={isSmall}
						padding={
							isSmall
								? isXXSmall
									? "10px"
									: "20px"
								: "30px"
						}
					>
						<H3
							style={{
								textAlign: isSmall
									? "center"
									: "left",
								paddingLeft: isSmall
									? isXSmall
										? 0
										: 20
									: 0,
								paddingRight: isSmall
									? isXSmall
										? 0
										: 20
									: 0,
							}}
						>
							Weekly Planner
						</H3>
						<Spacing type="s16" />

						<img
							src={calander}
							alt="Weekly 5M Planner"
						/>
						<Spacing type="s16" />
						{/* <Body1
							style={{
								textTransform: "uppercase",
								textAlign: isSmall
									? "center"
									: "left",
							}}
							fontWeight={600}
						>
							COMING SOON
						</Body1> */}
					</CardContainerWrapper>
				</LinkStyled>
				<Spacing
					type="s16"
					multiplier={isXXSmall ? 1 : 2}
				/>
				<LinkStyled to="/dashboard/priorities">
					<CardContainerWrapper
						background="#FFF8D9"
						boxShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
						isSmall={isSmall}
						padding={
							isSmall
								? isXXSmall
									? "10px"
									: "20px"
								: "30px"
						}
					>
						<H3
							style={{
								textAlign: isSmall
									? "center"
									: "left",
								paddingLeft: isSmall ? 20 : 0,
								paddingRight: isSmall ? 20 : 0,
							}}
						>
							Daily Priorities
						</H3>
						<Spacing type="s16" />

						<H1>{totalTicks}/3</H1>
						<Spacing type="s16" />

						<Body1
							style={{
								textTransform: "uppercase",
								textAlign: isSmall
									? "center"
									: "left",
							}}
							fontWeight={600}
						>
							Hit that Dopamine!
						</Body1>
					</CardContainerWrapper>
				</LinkStyled>
			</FlexBetweenContainer>
		</div>
	);
};

const DashboardLanding = () => {
	const user = useMeValue();
	const ref = useRef<HTMLDivElement>(null);
	const screenWidth = useElementWidth(ref);

	const isSmall = screenWidth <= 750;
	return (
		<>
			<Box marginTop={50} marginBottom={50}>
				<Wrapper type="fluid" ref={ref}>
					<ShowIf value={user.bio === null}>
						<ShowIf value={isSmall === false}>
							<H3>Dashboard</H3>
							<Spacing type="s24" />
							<CardContainer
								padding={"0px"}
								// background={`url(${bannerImg}) no-repeat`}
								height={"280px"}
								style={{
									backgroundSize: "cover",
									backgroundPosition:
										"center right",
									display: "flex",
									alignItems: "center",
									overflow: "hidden",
								}}
							>
								<BannerTextContainer>
									<Subtitle1>
										Welcome Back{" "}
										{user.name.split(" ")[0]}!
									</Subtitle1>
									<CompleteProfile />
								</BannerTextContainer>
								<img
									style={{
										width: "50%",
										height: "280px",
										objectFit: "cover",
									}}
									src={bannerImg}
									alt=""
								/>
							</CardContainer>
						</ShowIf>
						<ShowIf value={isSmall}>
							<CompleteProfile />
							<Spacing type="s16" />
							<div
								style={{
									height: 1,
									backgroundColor:
										DS.colors.mercury,
								}}
							/>
						</ShowIf>
						<Spacing
							type="s24"
							multiplier={isSmall ? 1 : 2}
						/>
					</ShowIf>
					<FlexBetween
						style={{
							alignItems: "flex-end",
						}}
					>
						<Box>
							<H3 fontColor="redRibbon">
								What's Your Why?
							</H3>
							<Spacing type="s8" />
							<Body2
								fontWeight="normal"
								fontColor="boulder"
							>
								Knowing why it matters is the
								first step to success.
							</Body2>
						</Box>
						<Box>
							<Link to="/dashboard/profile/whats-your-why">
								<FlexCenter>
									<IconEdit />
									<Spacing type="s8" />
									<Body1
										fontWeight={600}
										fontColor="redRibbon"
									>
										Edit
									</Body1>
								</FlexCenter>
							</Link>
						</Box>
					</FlexBetween>
					<Spacing type="s24" />

					<WhatWhy
						what={user.what}
						why={user.why}
					/>
					<Spacing type="s24" />
					<Spacing type="s16" />
					<MainCTA />
				</Wrapper>
			</Box>
		</>
	);
};

export default DashboardLanding;
