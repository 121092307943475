import React from "react";

const IconNonChecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g
        id="Rectangle_1153"
        data-name="Rectangle 1153"
        fill="#fff"
        stroke="#7b7b7b"
        strokeWidth="1.5"
      >
        <rect
          width="20"
          height="20"
          rx="3"
          stroke="none"
        />
        <rect
          x="0.75"
          y="0.75"
          width="18.5"
          height="18.5"
          rx="2.25"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default IconNonChecked;
