import {
	BaseTypoCSS,
	Body1,
	Box,
	BreakPointsFontsMedia,
	Caption,
	DS,
	flattenBreakpoints,
	Spacing,
} from "@workshore/nyaari-ds";
import React, { FC, useRef } from "react";
import styled from "styled-components";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";

const WhatWhyContainer = styled.div<{
	isSmall: boolean;
	editable: boolean;
}>`
	border-radius: ${DS.borderRadius.body1}px;

	min-height: 200px;
	display: flex;
	${({ isSmall, editable }) => `
    flex-direction: ${isSmall ? "column" : "row"};
    background-color: ${
			isSmall && editable
				? "transparent"
				: DS.colors.wildSand
		};
  `}
	& > .content {
		flex: 1;
		overflow: hidden;
		${({ isSmall }) => `
    ${
			isSmall
				? " max-width: calc(100%);"
				: "max-width: calc(50% - 2px);"
		};
  `}
	}

	& > .divider {
		${({ isSmall }) => `
    ${isSmall ? "height:4px" : "width: 4px;"};
  `}

		background-color: #fff;
	}
`;

const ContentText = styled(Body1)`
	line-break: anywhere;
	font-weight: ${DS.typography.subtitle1
		.fontWeight};
	/* font-style: italic; */
`;

const TextArea = styled.textarea<{
	isSmall: boolean;
}>`
	${BaseTypoCSS({
		fontFamily: "primary",
		fontWeight: DS.typography.body1.fontWeight,
	})}
	font-size: ${DS.typography.body1.min}px;
	line-height: ${DS.typography.body1.min +
	DS.typography.body1.lineHeight}px;
	letter-spacing: 0;

	${BreakPointsFontsMedia(
		flattenBreakpoints,
		DS.typography.body1.max,
		DS.typography.body1.min,
		DS.typography.body1.lineHeight,
	)};
	${({ isSmall }) => `
    ${isSmall ? "padding:10px" : ""};
    background-color: ${
			!isSmall
				? "transparent"
				: DS.colors.wildSand
		};
  `}
	border-radius:5px;
	outline: none;
	border: none;
	resize: none;
	width: 100%;
	height: 150px;
`;

const emptyText =
	"..............................................................................................................................................................................................................................................................................................................................";

const WhatWhyContent: FC<{
	editable?: boolean;
	prefix: string;
	value?: string | null;
	onChange?: (text: string) => void;
	isSmall: boolean;
}> = ({
	prefix,
	value,
	editable = false,
	onChange = () => {},
	isSmall,
}) => {
	return (
		<div className="content">
			<Box
				margin={
					isSmall
						? editable
							? "0px"
							: "20px"
						: "40px 35px"
				}
			>
				{editable ? (
					<>
						<ContentText>{prefix}</ContentText>
						<Spacing type="s4" />
						<TextArea
							style={{ fontStyle: "italic" }}
							isSmall={isSmall && editable}
							maxLength={500}
							placeholder={`Enter text here`}
							value={value || ""}
							onChange={(e) => {
								const text = e.target.value
									.replace(/ +/g, " ")
									.split(" ")
									.slice(0, 30)
									.join(" ");

								onChange(text);
							}}
						/>
						<div
							style={{
								flexDirection: "row-reverse",
								display: "flex",
							}}
						>
							<Caption fontColor="boulder">
								(30 words maximum)
							</Caption>
						</div>
					</>
				) : (
					<ContentText>
						{prefix}{" "}
						{value ? (
							<i
								style={{
									fontWeight:
										DS.typography.body1
											.fontWeight,
								}}
							>
								{value}
							</i>
						) : (
							emptyText
						)}
					</ContentText>
				)}
			</Box>
		</div>
	);
};

const WhatWhy: FC<{
	editable?: boolean;
	what?: string | null;
	why?: string | null;
	onChange?: (data: {
		what: string;
		why: string;
	}) => void;
}> = ({
	editable = false,
	onChange = () => {},
	what = "",
	why = "",
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const screenWidth = useElementWidth(ref);
	const isSmall = screenWidth <= 750;
	return (
		<WhatWhyContainer
			ref={ref}
			isSmall={isSmall}
			editable={editable}
		>
			<WhatWhyContent
				isSmall={isSmall}
				prefix="I want to"
				value={what}
				editable={editable}
				onChange={(text) => {
					onChange({
						why: why || "",
						what: text,
					});
				}}
			/>
			<div className="divider" />
			<WhatWhyContent
				isSmall={isSmall}
				prefix="So that I can"
				value={why}
				editable={editable}
				onChange={(text) => {
					onChange({
						why: text,
						what: what || "",
					});
				}}
			/>
		</WhatWhyContainer>
	);
};

export default WhatWhy;
