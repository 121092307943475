import {
	Body1,
	Body2,
	Button,
	FlexCenter,
	H2,
	Spacing,
	Tag,
	Wrapper,
} from "@workshore/nyaari-ds";
import React from "react";
import {
	useHistory,
	useParams,
} from "react-router-dom";
import styled from "styled-components";
import { useGetPremiumBlogQuery } from "../../hooks/graphql/graphql";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import getDate from "../../utils/getDate";
import HTMLParser from "../HTMLParser";
import IconCalender from "../Icons/IconCalender";
import IconUser from "../Icons/IconUser";
import LabeledIcon from "../LabeledIcon/LabeledIcon";
import Loader from "../Loader/Loader";
import PageModal from "../PageModal/PageModal";
import Seperator from "../Seperator/Seperator";
import ShowIf from "../ShowIf/ShowIf";

const StyledWrapper = styled(Wrapper)`
	display: flex;
	flex-direction: column;
`;

const StyledImg = styled.img<{
	isMobile: boolean;
}>`
	height: 674px;
	width: 100%;
	object-fit: cover;
	margin-bottom: 41px;
	border-radius: 5px;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 239px;
    margin-bottom: 20px;
  `};
`;

const Container = styled.div<{
	isMobile: boolean;
}>`
	width: 804px;

	${({ isMobile }) =>
		isMobile &&
		`
      width: 100%;
      padding: 0 20px;
  `};
`;

const ArticleInfo = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin-right: 35px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    >*{
			font-size: 12px;
      margin-right: 10px;
    }
  `};
`;

// article
const ArticleContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const Article = styled.div<{ isMobile: boolean }>`
	margin: 35px 73px 84px 0px;
	width: 350px;

	${({ isMobile }) =>
		isMobile &&
		`
    margin: 20px 22px 56px 0px;
    width: 265px;
  `};
`;

const ArticleImg = styled.img<{
	isMobile: boolean;
}>`
	height: 185px;
	width: 350px;
	object-fit: cover;
	margin-bottom: 25px;
	border-radius: 5px;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 152px;
    width: 265px;
    margin-bottom: 16px;
  `};
`;

const ArticleInfo1 = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin-right: 21px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    >*{
			font-size: 12px;
      margin-right: 10px;
    }
  `};
`;

const PremiumBlogDetails = () => {
	const isMobile = useIsMobile();
	const history = useHistory();

	const { id } = useParams<{ id: string }>();
	// console.log(id);
	const { data, loading, error, refetch } =
		useGetPremiumBlogQuery({
			variables: {
				id,
			},
		});
	// console.log({ data, loading, error });

	const blog = data?.premiumBlog;
	return (
		<PageModal>
			<ShowIf value={loading}>
				<div
					style={{
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{error &&
					error!.message.includes(
						"Cast to ObjectId failed",
					) ? (
						<div>
							<Body1>
								Oops! Page does not exist.
							</Body1>
							<Spacing type="s24" />
							<FlexCenter>
								<Button
									width="200px"
									handleButtonClick={() =>
										history.push("/freemium-gyan")
									}
								>
									<Body2
										as="span"
										fontColor="white"
									>
										Go to Freemium
									</Body2>
								</Button>
							</FlexCenter>
						</div>
					) : (
						<>
							<FlexCenter>
								<Body1>
									Failed to load Blogs
								</Body1>
							</FlexCenter>
							<Spacing type="s24" />
							<FlexCenter>
								<Button width="100px">
									<Body2
										as="span"
										fontColor="white"
										onClick={() => {
											refetch();
										}}
									>
										Retry
									</Body2>
								</Button>
							</FlexCenter>
						</>
					)}
				</div>
			</ShowIf>
			<StyledWrapper>
				{!loading && !error && blog && (
					<ShowIf value={data?.premiumBlog}>
						<H2
							style={
								isMobile
									? {
											marginTop: "12px",
											marginBottom: "11px",
									  }
									: {
											marginBottom: "37px",
									  }
							}
						>
							{blog!.title}
						</H2>
						<ArticleInfo isMobile={isMobile}>
							{blog.premium_blog_category ? (
								<Tag background="#FAE2E8">
									{
										blog.premium_blog_category
											.title
									}
								</Tag>
							) : null}
							{blog!.collaborator ? (
								<LabeledIcon
									icon={
										<IconUser color="redRibbon" />
									}
									text={
										"By " +
										blog!.collaborator!.name
									}
								/>
							) : null}
							<LabeledIcon
								icon={<IconCalender />}
								text={getDate(blog!.createdAt)}
							/>
						</ArticleInfo>
						<Spacing type="s32" />
						<StyledImg
							isMobile={isMobile}
							src={blog!.banner_image!.url}
							alt=""
						/>

						<HTMLParser data={blog!.content} />
						<Spacing type="s32" />
						<Spacing type="s32" />
					</ShowIf>
				)}
			</StyledWrapper>
		</PageModal>
	);
};

export default PremiumBlogDetails;
