import { useSharedStateValue } from "@tinyd/usesharedstate";
import {
	DS,
	IconBack,
	Navbar,
} from "@workshore/nyaari-ds";
import React, { FC } from "react";
import styled from "styled-components";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import useRoute from "../../hooks/useRoute/useRoute";

let currentStack = 0;

export const PageModalHeader: FC<{
	name: string;
}> = ({ name }) => {
	const isTablet = useIsTablet();
	const { goBack } = useRoute();
	// const change = useSharedStateChange(`@${name}`);

	if (isTablet) {
		return (
			<Navbar
				leftIcon={
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						onClick={() => {
							goBack();
						}}
					>
						<IconBack />
					</div>
				}
				mobileBreakpoint={
					DS.customBreakpoints.drawerBreakpoint
				}
			/>
		);
	}

	return null;
};

const PageSidebarContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: ${96 + currentStack};
	overflow-x: hidden;
	overflow-y: auto;
	overflow-y: overlay;

	@media screen and (max-width: ${DS
			.customBreakpoints.drawerBreakpoint}px) {
		background-color: #fff;
	}
`;

const PageSidebarBodyContainer = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	background-color: #fff;
	width: 350px;
	min-height: calc(100vh);
	box-shadow: ${DS.elevation.elevation4};
	@media screen and (max-width: ${DS
			.customBreakpoints.drawerBreakpoint}px) {
		margin: 0px;
		position: unset;
		width: 100vw;
	}
`;

export const PageSidebarBody: FC = ({
	children,
}) => {
	return (
		<PageSidebarBodyContainer>
			{children}
		</PageSidebarBodyContainer>
	);
};

const PageSidebar: FC<{ name: string }> = ({
	name,
	children,
}) => {
	const value = useSharedStateValue<boolean>(
		`@${name}`,
		false,
	);

	if (!value) {
		return null;
	}

	return (
		<PageSidebarContainer>
			<PageModalHeader name={name} />
			<PageSidebarBody>
				{children}
			</PageSidebarBody>
		</PageSidebarContainer>
	);
};

export default PageSidebar;
