import { Caption } from "@workshore/nyaari-ds";
import React, { useEffect } from "react";

import styled from "styled-components";
import { VERSION } from "../../utils/version";

const StyledAppVersion = styled.div`
	position: fixed;
	bottom: 10px;
	right: 20px;
	pointer-events: none;
	opacity: 0.5;
	z-index: 99999;
`;

const AppVersion = () => {
	const isDev =
		window.location.hostname.includes("dev.") ||
		window.location.hostname.includes(
			"localhost",
		);
	useEffect(() => {
		if (!isDev) {
			console.log("APP VERSION", VERSION);
		}
	}, [isDev]);
	if (isDev)
		return (
			<StyledAppVersion>
				<Caption>v{VERSION}</Caption>
			</StyledAppVersion>
		);

	return null;
};

export default AppVersion;
