import {
	Box,
	ClickableIcon,
	IconBack,
	Spacing,
	Subtitle1,
} from "@workshore/nyaari-ds";
import React, { FC } from "react";
import useRoute from "../../hooks/useRoute/useRoute";

const SidebarHeader: FC<{
	children: string;
}> = ({ children }) => {
	const { goBack } = useRoute();

	return (
		<Box
			style={{
				display: "flex",
				alignItems: "center",
			}}
		>
			<Box marginLeft={-5}>
				<ClickableIcon onClick={goBack}>
					<IconBack />
				</ClickableIcon>
			</Box>
			<Spacing type="s24" />
			<Subtitle1>{children}</Subtitle1>
		</Box>
	);
};

export default SidebarHeader;
