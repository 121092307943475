const IconDownArrow = () => {
	return (
		<svg
			id="Group_11862"
			data-name="Group 11862"
			xmlns="http://www.w3.org/2000/svg"
			width="8.909"
			height="14.059"
			viewBox="0 0 8.909 14.059"
		>
			<path
				id="Path_2562"
				data-name="Path 2562"
				d="M338.376,381.709l3.233,3.616c.153.174.307.343.46.517a.772.772,0,0,0,1.084,0l3.233-3.616c.153-.174.307-.343.46-.517a.781.781,0,0,0,0-1.084.772.772,0,0,0-1.084,0l-3.233,3.616c-.154.174-.307.343-.46.517h1.084l-3.233-3.616c-.153-.174-.307-.343-.46-.516a.767.767,0,0,0-1.084,1.084Z"
				transform="translate(-338.157 -372.005)"
				fill="#333"
			/>
			<path
				id="Path_2563"
				data-name="Path 2563"
				d="M420.413,128.164V115.507a.71.71,0,0,0-.768-.6c-.414.016-.767.266-.767.6v12.657a.71.71,0,0,0,.767.6C420.06,128.753,420.413,128.5,420.413,128.164Z"
				transform="translate(-415.189 -114.901)"
				fill="#333"
			/>
		</svg>
	);
};

export default IconDownArrow;
