import React, {
	useCallback,
	useState,
} from "react";
import {
	Input,
	Button,
	H2,
	Subtitle1,
	Body1,
	Body2,
	Caption,
	DS,
	Box,
	ClickableIcon,
	IconEye,
	FlexCenter,
	Spacing,
} from "@workshore/nyaari-ds";
import { Link } from "react-router-dom";

import SplitView1 from "../SplitView1/SplitView1";
import fb from "../../assets/icons/fb.svg";
import google from "../../assets/icons/google.svg";
import styled from "styled-components";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import { useLoginMutation } from "../../hooks/graphql/graphql";
import useRoute from "../../hooks/useRoute/useRoute";
import getFirstError from "../../utils/getFirstError";
import validateEmail from "../../utils/validateEmail";
import { setAuthToken } from "../../services/authToken";
import { ENDPOINT_URL } from "../../config";
import Footer from "../Footer/Footer";

const ForgotPasswordContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const DividerContainer = styled.div`
	display: flex;
	align-items: center;
	& > .divider-line {
		flex: 1;
		height: 1px;
		background-color: ${DS.colors.lightGray};
	}

	p {
		margin: 0px ${DS.spacing.s8}px;
	}
`;

const IconContainer = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	& > div {
		cursor: pointer;
		${({ isMobile }) => `
      img{
      width:${isMobile ? "28px" : "40px"};
      height:${isMobile ? "28px" : "40px"};
    }
    `}
	}
	& > div:last-child {
		margin-left: 40px;
	}
`;

const StyledBox = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	& > div {
		display: flex;
		p:first-child {
			margin-right: 5px;
		}
	}
`;

const StyledH2 = styled(H2)`
	padding-top: 212px;

	@media screen and (max-width: 480px) {
		padding-top: 32px;
	}
`;

const SignIn = () => {
	const { goTo } = useRoute();
	const [signIn, { loading }] =
		useLoginMutation();
	const isMobile = useIsMobile();
	const [showPassword, setShowPassword] =
		useState(false);
	const [errors, setErrors] = useState({
		email: "",
		password: "",
	});
	const [serverError, setServerError] =
		useState("");

	const [inputs, setInputs] = useState({
		email: "",
		password: "",
	});

	//here is submit function
	const handleSubmit = useCallback(() => {
		const errors = {
			email: "",
			password: "",
		};

		if (!validateEmail(inputs.email)) {
			errors.email = "Invalid email address";
		}

		if ((inputs.password || "").length < 6) {
			errors.password =
				"Password must be at least 6 characters long";
		}

		//checking if any error(s) occurs
		if (
			Object.values(errors).filter(
				(d) => d.length,
			).length
		) {
			console.log(errors);
			setErrors(errors);
		} else {
			//call api
			signIn({
				variables: {
					email: inputs.email,
					password: inputs.password,
				},
			})
				.then((response) => {
					console.log(response.data);
					setAuthToken(
						response.data!.login!.jwt || "",
					);
					goTo("/dashboard", "replace");
				})
				.catch((err) => {
					console.log({ err });
					if (
						getFirstError(err) ===
						"Identifier or password invalid."
					)
						return setServerError(
							"Incorrect email address or password, please try again.",
						);
					setServerError(getFirstError(err));
				});
		}
	}, [inputs, signIn, goTo]);
	return (
		<>
			<SplitView1 isHeart>
				<StyledH2>Hey, howdy, hi !</StyledH2>
				<Subtitle1
					fontColor="boulder"
					fontWeight={500}
					marginBottom={isMobile ? 93 : 67}
					marginTop={DS.spacing.s4}
				>
					Good to see you, again.
				</Subtitle1>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						return false;
					}}
				>
					<div>
						<Input
							placeholder="Email ID"
							handleInputChange={(e) => {
								setInputs({
									...inputs,
									email: e.target.value,
								});
							}}
							value={inputs.email}
							type="email"
							marginBottom={isMobile ? 22 : 30}
							error={errors.email}
							disabled={loading}
						/>
					</div>
					<div>
						<Input
							placeholder="Password"
							handleInputChange={(e) => {
								setInputs({
									...inputs,
									password: e.target.value,
								});
							}}
							value={inputs.password}
							rightIcon={
								<ClickableIcon
									onClick={() =>
										setShowPassword(
											(value) => !value,
										)
									}
								>
									<IconEye
										active={showPassword}
									/>
								</ClickableIcon>
							}
							type={
								showPassword ? "text" : "password"
							}
							marginBottom={isMobile ? 7 : 12}
							error={errors.password}
							disabled={loading}
						/>
						<ForgotPasswordContainer
							style={{
								marginBottom: isMobile ? 23 : 25,
							}}
						>
							<Link to="/forgotpassword">
								<Body2
									fontColor="boulder"
									fontWeight="normal"
								>
									Forgot Password ?
								</Body2>
							</Link>
						</ForgotPasswordContainer>
					</div>
					<div>
						<Button
							handleButtonClick={handleSubmit}
							disabled={loading}
						>
							{loading
								? "Submitting.."
								: "Sign In"}
						</Button>
					</div>
					{serverError.length ? (
						<>
							<Spacing />
							<FlexCenter>
								<Caption fontColor="red">
									{serverError}
								</Caption>
							</FlexCenter>
						</>
					) : null}
				</form>

				<DividerContainer
					style={{
						marginBottom: isMobile ? 35 : 30,
						marginTop: isMobile ? 48 : 30,
					}}
				>
					<div className="divider-line" />
					<Caption fontColor="boulder">
						or Sign in with
					</Caption>
					<div className="divider-line" />
				</DividerContainer>
				<IconContainer isMobile={isMobile}>
					<div>
						<a
							href={`${ENDPOINT_URL}/connect/facebook`}
						>
							<img src={fb} alt="facebook" />
						</a>
					</div>
					<div>
						<a
							href={`${ENDPOINT_URL}/connect/google`}
						>
							<img src={google} alt="google" />
						</a>
					</div>
				</IconContainer>
				<StyledBox
					marginTop={40}
					marginBottom={32}
				>
					<div>
						<Body2 fontWeight="normal">
							New User?{" "}
						</Body2>
						<Link to="/signup">
							<Body1
								fontColor="redRibbon"
								fontWeight={600}
							>
								Sign Up
							</Body1>
						</Link>
						{/* New User?{" "}
            <StyledLink to="/signup">
              Sign Up
            </StyledLink> */}
					</div>
				</StyledBox>
			</SplitView1>
			<Footer />
		</>
	);
};

export default SignIn;
