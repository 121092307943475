import {
	Body1,
	Body2,
	Box,
	Caption,
	Drawer,
	DS,
	FlexBetween,
	FlexCenter,
	H3,
	Spacing,
	Tag,
	useOutsideClick,
	Wrapper,
} from "@workshore/nyaari-ds";
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import styled from "styled-components";
import { useScreenWidth } from "@tinyd/usescreensize";
import dayjs from "dayjs";
import { useToasts } from "react-toast-notifications";
import queryString from "query-string";

import {
	Collaborator,
	ComponentMealsDuration,
	Maybe,
	MealCategory,
	PublicationState,
	Recipes,
	UploadFile,
	useCreateWeeklyPlannerEventMutation,
	useGetAllRecipesListingQuery,
	WeeklyPlannerEventSlot,
	WeeklyPlannerType,
} from "../../hooks/graphql/graphql";
import useDebounce from "../../hooks/useDebounce/useDebounce";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import LayoutContainer from "../LayoutContainer/LayoutContainer";
import Loader from "../Loader/Loader";
import MealsHeader from "../DashboardSubHeader/DashboardSubHeader";
import SearchBox, {
	searchResultsTypes,
} from "../SearchBox/SearchBox";
import ShowIf from "../ShowIf/ShowIf";
import ScrollContainer from "react-indiana-drag-scroll";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import Color from "color";
import DashboardItemCard from "../DashboardItemCard/DashboardItemCard";
import {
	Link,
	useLocation,
} from "react-router-dom";
import IconClock from "../Icons/IconClock";
import IconFire from "../Icons/IconFire";
import storage from "../../services/storage";
import AddButton from "../AddButton/AddButton";
import CustomCalendar from "../CustomCalendar/CustomCalendar";
import { RefProps } from "@workshore/nyaari-ds/src/components/Drawer/Drawer";
import IconClose from "../Icons/IconClose";
import IconUpArrow from "../Icons/IconUpArrow";
import IconDownArrow from "../Icons/IconDownArrow";
import IconSort from "../Icons/IconSort";

const StyledCaption = styled(Caption)`
	@media screen and (max-width: 360px) {
		font-size: 10px;
	}
`;

const IconContainer = styled.div<{
	isMobile: boolean;
}>`
	position: absolute;
	top: 0;
	right: 0;
	margin-top: -24px;

	${({ isMobile }) =>
		isMobile &&
		`
			margin-top: -38px;
	`}
`;

const CalendarContainer = styled.div<{
	isMobile: boolean;
	pos: string;
}>`
	position: absolute;
	z-index: 10;
	margin-right: 20px;
	bottom: -60px;

	${({ pos }) =>
		pos === "left" &&
		`
			right: 0;
	`}
	${({ pos }) =>
		pos === "right" &&
		`
			left:0;
			margin-left: 20px;
	`}
	${({ isMobile }) =>
		isMobile &&
		`
		position: fixed;
		height: 100vh;
		width: 100%;
		background: ${DS.colors.mineShaft}90;
		margin-right: 0px;
		display: flex;
		justify-content:center;
		align-items:center;
		margin-left: 0px;

		> * {
			margin: 20px;
		}
	`}

	@media screen and (max-width: 1240px) {
		bottom: -160px;
	}
`;

const Tag1 = styled(Body2)<{ active?: boolean }>`
	padding: 4px 8px;
	border-radius: 5px;
	background: ${DS.colors.wildSand};
	cursor: pointer;
	font-size: 10px;
	font-family: ${DS.fontFamilies.primary};
	color: ${DS.colors.boulder};

	${({ active }) =>
		active &&
		`
    background: ${DS.colors.meals};
		color: ${DS.colors.mineShaft};
  `};
`;

const SortWrapper = styled.div<{
	isMobile: boolean;
}>`
	height: 36px;
	padding: 0 30px;
	cursor: pointer;
	border-radius: 5px;
	background: ${DS.colors.white};
	display: flex;
	justify-content: center;
	align-items: center;

	${({ isMobile }) =>
		isMobile &&
		`
			height: 30px;
			margin-left: 8px;
			padding: 0 7px;
	`}
`;

const DrawerContainer = styled.div`
	padding: 35px 27px;
`;

const ItemsWraper = styled.div<{
	isMobile: boolean;
}>`
	> * {
		padding-bottom: 20px;
		border-bottom: 1px solid ${DS.colors.wildSand};
	}
	> :not(:first-child) {
		padding-top: 20px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
			> * {
				padding-bottom: 10px;
			}
			> :not(:first-child) {
				padding-top: 10px;
			}
	`}
`;

const Category = styled(Caption)<{
	active?: boolean;
}>`
	padding: 10px 22px;
	background: ${DS.colors.wildSand};
	border-radius: 4px;
	display: flex;
	align-items: center;
	cursor: pointer;

	> :first-child {
		margin-right: 5px;
	}

	${({ active }) =>
		active &&
		`
    background: ${DS.colors.meals};
  `};
`;

const RadioWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	> :first-child {
		margin-right: 10px;
		margin-top: 4px;
	}
	input[type="radio"] {
		margin-top: 4px;
		cursor: pointer;
		position: relative;
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 100%;
		outline: none !important;
		-webkit-appearance: none;
	}
	input[type="radio"]::before {
		position: relative;
		top: -1px;
		left: -1px;
		display: block;
		content: "";
		border: 1px solid ${DS.colors.doveGrey};
		border-radius: 100%;
		width: 18px;
		height: 18px;
	}
	input[type="radio"]:checked::before {
		border-color: ${DS.colors.meals};
	}
	input[type="radio"]::after {
		position: relative;
		display: block;
		content: "";
		background: ${DS.colors.meals};
		border-radius: 100%;
	}
	input[type="radio"]:checked::after {
		transition: opacity 0.5s ease;
		top: -16px;
		left: 4px;
		width: 10px;
		height: 10px;
	}
`;

const convertUnit = (
	type: string,
	number: number = 0,
) => {
	switch (type) {
		case "d":
			return number > 1 ? "days" : "day";
		case "m":
			return number > 1 ? "mins" : "min";
		case "hr":
			return number > 1 ? "hrs" : "hr";
		default:
			return type;
	}
};

const getRankOfUnit = (type: string) => {
	switch (type) {
		case "d":
			return 3;
		case "hr":
			return 2;
		case "m":
			return 1;
		default:
			return 0;
	}
};

const isFirstUnitIsBig = (
	type1: string,
	type2: string,
) => {
	return (
		getRankOfUnit(type1) > getRankOfUnit(type2)
	);
};

const getCookTime = (
	time1: { unit: string; duration: number },
	time2: { unit: string; duration: number },
) => {
	let cookTime = "";
	let totalTime = time1.duration + time2.duration;
	if (time1.unit === time2.unit) {
		cookTime = `${totalTime} ${convertUnit(
			time1.unit || "m",
			totalTime,
		)}`;
	} else {
		cookTime = isFirstUnitIsBig(
			time1.unit,
			time2.unit,
		)
			? `${time1.duration} ${convertUnit(
					time1.unit,
					time1.duration,
			  )} ${time2.duration} ${convertUnit(
					time2.unit,
					time2.duration,
			  )}`
			: `${time2.duration} ${convertUnit(
					time2.unit,
					time2.duration,
			  )} ${time1.duration} ${convertUnit(
					time1.unit,
					time1.duration,
			  )}`;
	}
	return cookTime;
};

type recipeType = Maybe<
	Array<
		Maybe<
			{ __typename?: "Recipes" } & Pick<
				Recipes,
				| "id"
				| "createdAt"
				| "No_Of_Servings"
				| "Calories"
				| "Title"
				| "keywords"
			> & {
					Prep_Duration?: Maybe<
						{
							__typename?: "ComponentMealsDuration";
						} & Pick<
							ComponentMealsDuration,
							"id" | "duration" | "unit"
						>
					>;
					Cook_Duration?: Maybe<
						{
							__typename?: "ComponentMealsDuration";
						} & Pick<
							ComponentMealsDuration,
							"id" | "duration" | "unit"
						>
					>;
					collaborator?: Maybe<
						{
							__typename?: "Collaborator";
						} & Pick<
							Collaborator,
							"id" | "name"
						> & {
								image?: Maybe<
									{
										__typename?: "UploadFile";
									} & Pick<
										UploadFile,
										"id" | "url"
									>
								>;
							}
					>;
					thumbnail?: Maybe<
						{
							__typename?: "UploadFile";
						} & Pick<UploadFile, "url" | "id">
					>;
					meal_categories?: Maybe<
						Array<
							Maybe<
								{
									__typename?: "MealCategory";
								} & Pick<
									MealCategory,
									"id" | "Name"
								>
							>
						>
					>;
				}
		>
	>
>;

const RecipesListing = () => {
	const [sortDir, setSortDir] = useState<
		"htl" | "lth"
	>();
	const [sortValue, setSortValue] = useState("");
	const [isSubmitting, setIsSubmitting] =
		useState(false);
	const [selectedCategory, setSelectedCategory] =
		useState<string[]>([]);
	const [filterKeyword, setFilterKeyword] =
		useState("");
	const [category, setCategory] = useState<
		{ id: string; name: string }[]
	>([]);
	const [showChildren, setShowChildren] =
		useState(false);
	const [keyword, setKeyword] = useState("");
	const [keywordList, setKeywordList] = useState<
		string[]
	>([]);
	const ref = useRef<HTMLDivElement>(null);
	const isTablet = useIsTablet();
	const width = useElementWidth(ref);
	const isMobile = useIsMobile();
	const isSmall = width <= 750;
	const [showResults, setShowResults] =
		useState(false);
	const [searchResults, setSearchResults] =
		useState<searchResultsTypes[]>([]);
	const searchKeyword = useDebounce(keyword);
	const { addToast } = useToasts();
	const createWeeklyPlannerEvent =
		useCreateWeeklyPlannerEventMutation();
	const allRecipes = useGetAllRecipesListingQuery(
		{
			variables: {
				mode:
					storage.get<string>("@mode").value ===
					"yes"
						? PublicationState.Preview
						: PublicationState.Live,
			},
		},
	);
	const [finalRecipes, setFinalRecipes] =
		useState<recipeType>([]);
	// calendar
	const [showCalendar, setShowCalendar] =
		useState(
			Array(finalRecipes?.length).fill(false),
		);
	const [date, setDate] = useState(new Date());
	const [slot, setSlot] =
		useState<WeeklyPlannerEventSlot>(
			WeeklyPlannerEventSlot.Prebreakfast,
		);

	const screenWidth = useScreenWidth();
	const isPhone = screenWidth <= 578;
	const indexRef = useRef(-1);
	const posRef = useRef("left");
	const calendarRef = useOutsideClick(() => {
		const showCalendarCopy = [...showCalendar];
		showCalendarCopy[indexRef.current] = false;
		setShowCalendar(showCalendarCopy);
	});
	const allRecipesData = useMemo(
		() => allRecipes.data?.recipes || [],
		[allRecipes],
	);
	const { search } = useLocation();
	useEffect(() => {
		const parsed = queryString.parse(search);
		if (
			parsed.date &&
			typeof parsed.date === "string"
		) {
			setDate(dayjs(parsed.date).toDate());
		}
	}, [search]);
	useEffect(() => {
		if (allRecipesData) {
			setFinalRecipes(allRecipesData);
		}
	}, [allRecipesData]);
	useEffect(() => {
		if (searchKeyword.length > 1) {
			let data: searchResultsTypes[] = [];
			if (filterKeyword.length === 0) {
				let _keywordList = (
					searchKeyword.includes(",")
						? searchKeyword.split(",")
						: searchKeyword.includes(" and ")
						? searchKeyword.split(" and ")
						: searchKeyword.split(" ")
				).filter((d) => d);
				console.log(
					_keywordList,
					"_keywordList",
					searchKeyword,
				);
				let hasIngredient =
					allRecipesData.filter((d) => {
						return d?.Ingredients?.IngredientsWithTitle?.filter(
							(item) => {
								return (
									item?.Ingredients?.filter(
										(d) => {
											console.log(
												d?.Ingredients_Name,
												_keywordList,
											);
											return _keywordList.filter(
												(e) =>
													d?.Ingredients_Name.toLocaleLowerCase().includes(
														e.toLocaleLowerCase(),
													),
											).length;
										},
									).length === _keywordList.length
								);
							},
						);
					}) || [];

				if (hasIngredient.length > 0) {
					hasIngredient.forEach((recipe) => {
						data.push({
							image: recipe?.thumbnail?.url || "",
							text:
								`${recipe?.Title} has above ingredients` ||
								"",
							url: `/dashboard/meals/recipes/${recipe?.id}`,
							isKeyword: false,
						});
					});
				}
				keywordList.forEach((keyword) => {
					if (
						keyword
							.toLocaleLowerCase()
							.includes(
								searchKeyword.toLocaleLowerCase(),
							)
					) {
						data.push({
							image: "",
							text: keyword,
							url: "",
							isKeyword: true,
						});
					}
				});
				allRecipesData.forEach((recipe) => {
					if (
						recipe?.Title.toLocaleLowerCase().includes(
							searchKeyword.toLocaleLowerCase(),
						)
					) {
						data.push({
							image: recipe.thumbnail?.url || "",
							text: recipe?.Title || "",
							url: `/dashboard/meals/recipes/${recipe?.id}`,
							isKeyword: false,
						});
					}
				});
				setSearchResults(data);
				setShowResults(true);
			}
		} else {
			setShowResults(false);
			setSearchResults([]);
			setFilterKeyword("");
		}
	}, [
		searchKeyword,
		keywordList,
		filterKeyword,
		allRecipesData,
	]);
	useEffect(() => {
		let recipes = allRecipesData;
		if (selectedCategory.length) {
			recipes = recipes.filter((r) => {
				return (
					r?.meal_categories?.filter((d) => {
						return selectedCategory.filter(
							(c) => d?.id === c,
						).length;
					}).length === selectedCategory.length
				);
			});
		}
		if (filterKeyword) {
			recipes = recipes?.filter((d) => {
				return d?.keywords.includes(
					filterKeyword,
				);
			});
			if (recipes) {
				setKeyword(filterKeyword);
			}
		}

		setFinalRecipes(recipes);
	}, [
		filterKeyword,
		allRecipesData,
		selectedCategory,
	]);

	useEffect(() => {
		if (allRecipesData.length) {
			setCategory([
				...allRecipesData
					.map(
						(recipe) =>
							recipe?.meal_categories || [],
					)

					.reduce((a: any, b: any) => {
						let data = b
							.map((item: any) => {
								return {
									id: item!.id,
									name: item!.Name,
									order: item!.order,
								};
							})
							.filter((item: any) => {
								return (
									a.filter(
										(_item: any) =>
											_item.id === item.id,
									).length === 0
								);
							});
						return [...a, ...data];
					}, [])
					.sort((a, b) => {
						return a.order > b.order ? -1 : 1;
					}),
			]);
			const keywords = new Set<string>();
			allRecipesData.forEach((recipes) => {
				(recipes?.keywords || "")
					.split(",")
					.forEach((keyword) => {
						keywords.add(keyword.trim());
					});
			});
			setKeywordList(
				Array.from(keywords.values()),
			);
		}
	}, [allRecipesData]);
	const gridSize =
		width < 890 ? (width < 710 ? 2 : 3) : 4;

	useEffect(() => {
		// setDate(new Date());
		setSlot(WeeklyPlannerEventSlot.Prebreakfast);
	}, [showCalendar]);

	const addToWeeklyPlanner = () => {
		setIsSubmitting(true);
		createWeeklyPlannerEvent[0]({
			variables: {
				data: {
					date: dayjs(date).format("YYYY-MM-DD"),
					type: WeeklyPlannerType.Meals,
					slot: slot,
					resourceId: (finalRecipes as any)[
						indexRef.current!
					].id,
				},
			},
		})
			.then((res) => {
				setIsSubmitting(false);
				setShowCalendar(
					Array(finalRecipes?.length).fill(false),
				);
				addToast("Item added to weekly planner", {
					appearance: "success",
					autoDismiss: true,
				});
			})
			.catch((err) =>
				addToast(
					"Error adding to the weekly planner",
					{
						appearance: "error",
						autoDismiss: true,
					},
				),
			);
	};
	const drawerRef = useRef<RefProps>({
		open: () => {},
		close: () => {},
		toggle: () => {},
	});
	useEffect(() => {
		drawerRef.current.close();
	}, [allRecipes.data?.recipes?.length]);

	return (
		<LayoutContainer
			ref={ref}
			backgroundColor="wildSand"
		>
			<ShowIf value={isTablet}>
				<MealsHeader
					hideSearch={true}
					autoHide={showChildren}
					onChildrenStateChange={(show) => {
						setShowChildren(!show);
					}}
				>
					<SearchBox
						placeholder="Search by Ingredients or Recipe name"
						searchResults={searchResults}
						value={keyword}
						onTextChange={(text) => {
							setKeyword(text);
						}}
						onKeywordSelect={(keyword) => {
							setFilterKeyword(keyword);
						}}
						autoFocus={true}
						showResults={showResults}
						onSearchResultsCloseRequest={() => {
							console.log("search close");
							setShowResults(false);
						}}
					/>
				</MealsHeader>
			</ShowIf>
			<Box
				paddingTop={isTablet ? 15 : 50}
				paddingBottom={90}
			>
				<Wrapper type="fluid">
					<div
						style={{
							display: isSmall ? "block" : "flex",
							alignItems: "center",
						}}
					>
						<H3>Meals {">"} Recipes</H3>
						<ShowIf value={!isTablet}>
							<Spacing
								type={isSmall ? "s24" : "s64"}
							/>
							<SearchBox
								placeholder="Search by Ingredients or Recipe Name"
								searchResults={searchResults}
								value={keyword}
								onTextChange={(text) => {
									setKeyword(text);
									setFilterKeyword("");
								}}
								onKeywordSelect={(keyword) => {
									setFilterKeyword(keyword);
								}}
								showResults={showResults}
								onSearchResultsCloseRequest={() => {
									console.log("search close");
									setShowResults(false);
								}}
							/>
						</ShowIf>
						<ShowIf value={isTablet}>
							<Spacing
								type="s8"
								multiplier={1.5}
							/>
							<div style={{ display: "flex" }}>
								<SearchBox
									placeholder="Search by Ingredients or Recipe name"
									searchResults={searchResults}
									value={keyword}
									onTextChange={(text) => {
										setKeyword(text);
									}}
									onKeywordSelect={(keyword) => {
										setFilterKeyword(keyword);
									}}
									autoFocus={true}
									showResults={showResults}
									onSearchResultsCloseRequest={() => {
										console.log("search close");
										setShowResults(false);
									}}
								/>
								{screenWidth < 768 ? (
									<SortWrapper
										isMobile={screenWidth < 768}
										onClick={() =>
											drawerRef.current.open()
										}
									>
										<IconSort />
									</SortWrapper>
								) : null}
							</div>
						</ShowIf>
					</div>
					<ShowIf value={allRecipes.loading}>
						<Loader>
							<Body1>
								Loading Fresh Recipes..
							</Body1>
						</Loader>
					</ShowIf>
				</Wrapper>
				<ShowIf
					value={
						!allRecipes.loading &&
						allRecipes.data?.recipes?.length
					}
				>
					<Drawer
						placement="right"
						ref={drawerRef}
						allowOnDesktop
						zIndex={96}
					>
						<DrawerContainer>
							<FlexBetween
								style={{ alignItems: "center" }}
								marginBottom={
									screenWidth < 768 ? 16 : 26
								}
							>
								<Body1>Sort by</Body1>
								<div
									style={{ cursor: "pointer" }}
									onClick={() =>
										drawerRef.current.close()
									}
								>
									<IconClose />
								</div>
							</FlexBetween>
							<FlexBetween
								style={{ alignItems: "center" }}
								marginBottom={
									screenWidth < 768 ? 30 : 45
								}
							>
								<Category
									active={sortDir === "htl"}
									onClick={() =>
										setSortDir("htl")
									}
								>
									<IconDownArrow /> High to Low
								</Category>
								<Category
									active={sortDir === "lth"}
									onClick={() =>
										setSortDir("lth")
									}
								>
									<IconUpArrow /> Low to High
								</Category>
							</FlexBetween>
							<ItemsWraper
								isMobile={screenWidth < 768}
							>
								<RadioWrapper
									onClick={() =>
										setSortValue("calories")
									}
								>
									<label className="custom-radio-btn">
										<input
											type="radio"
											name="sort"
											onChange={() =>
												setSortValue("calories")
											}
											checked={
												sortValue === "calories"
													? true
													: false
											}
										/>
										<span />
									</label>
									<Caption>Calories</Caption>
								</RadioWrapper>
								<RadioWrapper
									onClick={() =>
										setSortValue("fat")
									}
								>
									<label className="custom-radio-btn">
										<input
											type="radio"
											name="sort"
											onChange={() =>
												setSortValue("fat")
											}
											checked={
												sortValue === "fat"
													? true
													: false
											}
										/>
										<span />
									</label>
									<Caption>Fat</Caption>
								</RadioWrapper>
								<RadioWrapper
									onClick={() =>
										setSortValue("cholesterol")
									}
								>
									<label className="custom-radio-btn">
										<input
											type="radio"
											name="sort"
											onChange={() =>
												setSortValue(
													"cholesterol",
												)
											}
											checked={
												sortValue ===
												"cholesterol"
													? true
													: false
											}
										/>
										<span />
									</label>
									<Caption>Cholesterol</Caption>
								</RadioWrapper>
								<RadioWrapper
									onClick={() =>
										setSortValue("carbs")
									}
								>
									<label className="custom-radio-btn">
										<input
											type="radio"
											name="sort"
											onChange={() =>
												setSortValue("carbs")
											}
											checked={
												sortValue === "carbs"
													? true
													: false
											}
										/>
										<span />
									</label>
									<Caption>Carbs</Caption>
								</RadioWrapper>
								<RadioWrapper
									onClick={() =>
										setSortValue("protiens")
									}
								>
									<label className="custom-radio-btn">
										<input
											type="radio"
											name="sort"
											onChange={() =>
												setSortValue("protiens")
											}
											checked={
												sortValue === "protiens"
													? true
													: false
											}
										/>
										<span />
									</label>
									<Caption>Protiens</Caption>
								</RadioWrapper>
								<RadioWrapper
									onClick={() =>
										setSortValue("sodium")
									}
								>
									<label className="custom-radio-btn">
										<input
											type="radio"
											name="sort"
											onChange={() =>
												setSortValue("sodium")
											}
											checked={
												sortValue === "sodium"
													? true
													: false
											}
										/>
										<span />
									</label>
									<Caption>Sodium</Caption>
								</RadioWrapper>
								<RadioWrapper
									onClick={() =>
										setSortValue("sugar")
									}
								>
									<label className="custom-radio-btn">
										<input
											type="radio"
											name="sort"
											onChange={() =>
												setSortValue("sugar")
											}
											checked={
												sortValue === "sugar"
													? true
													: false
											}
										/>
										<span />
									</label>
									<Caption>Sugar</Caption>
								</RadioWrapper>
							</ItemsWraper>
						</DrawerContainer>
					</Drawer>
					<Spacing type="s16" />
					<ScrollContainer horizontal>
						<div
							style={{
								display: "flex",
							}}
						>
							<Spacing
								type="s2"
								multiplier={isMobile ? 10 : 25}
								style={{
									flexShrink: 0,
								}}
							/>
							{category.map((category, index) => {
								return (
									<div
										key={index}
										style={{
											display: "flex",
											flexShrink: 0,
										}}
									>
										<Tag
											background={
												selectedCategory.includes(
													category.id,
												)
													? DS.colors.deYork
													: DS.colors.white
											}
											hoverColor={
												isTablet
													? undefined
													: new Color(
															DS.colors.deYork,
													  )
															.lighten(0.2)
															.hex()
											}
											onClick={() => {
												if (
													selectedCategory.includes(
														category.id,
													)
												) {
													setSelectedCategory(
														selectedCategory.filter(
															(x) =>
																x !== category.id,
														),
													);
												} else {
													setSelectedCategory([
														...selectedCategory,
														category.id,
													]);
												}
											}}
										>
											<Box
												margin={
													isMobile
														? "0px 10px"
														: "4.5px 20px"
												}
											>
												<Body2>
													{category.name}
												</Body2>
											</Box>
										</Tag>
										<Spacing
											type="s2"
											multiplier={10}
										/>
									</div>
								);
							})}
							<Spacing
								type="s2"
								multiplier={isMobile ? 0 : 20}
								style={{
									flexShrink: 0,
								}}
							/>
						</div>
					</ScrollContainer>
					{screenWidth >= 768 ? (
						<>
							<Spacing type="s24" />
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									margin: isMobile
										? "0px 20px"
										: "0px 50px",
								}}
							>
								<SortWrapper
									isMobile={screenWidth < 768}
									onClick={() =>
										drawerRef.current.open()
									}
								>
									<IconSort />
									<Caption
										marginLeft={10}
										style={{ fontWeight: 500 }}
									>
										Sort By
									</Caption>
								</SortWrapper>
							</div>
						</>
					) : null}
					<Spacing type="s24" multiplier={1.5} />
					<Box
						marginLeft={
							isSmall && isMobile ? 20 : 50
						}
					>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
							}}
						>
							{finalRecipes?.map(
								(recipe, index) => {
									const newWidth =
										width / gridSize -
										((isSmall && isMobile
											? 20
											: 50) +
											(4 - (gridSize - 1)) * 5);

									let cookTime = getCookTime(
										{
											unit:
												recipe?.Prep_Duration
													?.unit || "m",
											duration:
												recipe?.Prep_Duration
													?.duration || 0,
										},
										{
											unit:
												recipe?.Cook_Duration
													?.unit || "m",
											duration:
												recipe?.Cook_Duration
													?.duration || 0,
										},
									);

									return (
										<div key={index}>
											<Box
												marginRight={
													isSmall ? 25 : 40
												}
											>
												<Link
													to={`/dashboard/meals/recipes/${recipe?.id}`}
													title={`${recipe?.Title} by ${recipe?.collaborator?.name}`}
												>
													<div
														style={{
															position:
																"relative",
														}}
													>
														<DashboardItemCard
															height={
																isMobile
																	? 240
																	: 290
															}
															width={newWidth}
															imageUrl={
																recipe?.thumbnail
																	?.url || ""
															}
															imageHeight={
																isMobile
																	? 155
																	: 200
															}
														>
															<Box
																margin="5px 12px"
																style={{
																	flex: 1,
																	display: "flex",
																	flexDirection:
																		"column",
																	position:
																		"relative",
																}}
															>
																<IconContainer
																	isMobile={
																		isMobile
																	}
																>
																	<AddButton
																		onClick={(
																			e,
																		) => {
																			e.preventDefault();
																			const xBoundingClientRect =
																				e.currentTarget.getBoundingClientRect()
																					.x;
																			if (
																				xBoundingClientRect <=
																					335 ||
																				screenWidth >
																					DS
																						.customBreakpoints
																						.drawerBreakpoint
																			) {
																				if (
																					xBoundingClientRect <=
																					688
																				) {
																					posRef.current =
																						"right";
																				} else {
																					posRef.current =
																						"left";
																				}
																			} else {
																				posRef.current =
																					"left";
																			}
																			indexRef.current =
																				index;
																			const showCalendarCopy =
																				[
																					...showCalendar,
																				];
																			showCalendarCopy[
																				index
																			] =
																				!showCalendar[
																					index
																				];
																			setShowCalendar(
																				showCalendarCopy,
																			);
																		}}
																	/>
																	{showCalendar[
																		index
																	] ? (
																		<CalendarContainer
																			onClick={(
																				e,
																			) =>
																				e.preventDefault()
																			}
																			pos={
																				posRef.current
																			}
																			isMobile={
																				isPhone
																			}
																		>
																			<CustomCalendar
																				ref={
																					calendarRef
																				}
																				style={{
																					background:
																						"white",
																				}}
																				date={
																					date
																				}
																				setDate={
																					setDate
																				}
																				accentColor="meals"
																				isSubmitting={
																					isSubmitting
																				}
																				handleButtonClick={
																					addToWeeklyPlanner
																				}
																			>
																				<div
																					style={{
																						display:
																							"flex",
																						flexWrap:
																							"wrap",
																						gap: 6,
																					}}
																				>
																					<Tag1
																						active={
																							slot ===
																							WeeklyPlannerEventSlot.Prebreakfast
																						}
																						onClick={() =>
																							setSlot(
																								WeeklyPlannerEventSlot.Prebreakfast,
																							)
																						}
																					>
																						PRE -
																						BREAKFAST
																					</Tag1>
																					<Tag1
																						active={
																							slot ===
																							WeeklyPlannerEventSlot.Breakfast
																						}
																						onClick={() =>
																							setSlot(
																								WeeklyPlannerEventSlot.Breakfast,
																							)
																						}
																					>
																						BREAKFAST
																					</Tag1>
																					<Tag1
																						active={
																							slot ===
																							WeeklyPlannerEventSlot.Amsnack
																						}
																						onClick={() =>
																							setSlot(
																								WeeklyPlannerEventSlot.Amsnack,
																							)
																						}
																					>
																						AM
																						SNACK
																					</Tag1>
																					<Tag1
																						active={
																							slot ===
																							WeeklyPlannerEventSlot.Lunch
																						}
																						onClick={() =>
																							setSlot(
																								WeeklyPlannerEventSlot.Lunch,
																							)
																						}
																					>
																						LUNCH
																					</Tag1>
																					<Tag1
																						active={
																							slot ===
																							WeeklyPlannerEventSlot.Pmsnack
																						}
																						onClick={() =>
																							setSlot(
																								WeeklyPlannerEventSlot.Pmsnack,
																							)
																						}
																					>
																						PM
																						SNACK
																					</Tag1>
																					<Tag1
																						active={
																							slot ===
																							WeeklyPlannerEventSlot.Dinner
																						}
																						onClick={() =>
																							setSlot(
																								WeeklyPlannerEventSlot.Dinner,
																							)
																						}
																					>
																						DINNER
																					</Tag1>
																				</div>
																			</CustomCalendar>
																		</CalendarContainer>
																	) : null}
																</IconContainer>
																<Body2
																	fontWeight={600}
																	title={
																		recipe?.Title
																	}
																	style={{
																		textOverflow:
																			"ellipsis",
																		overflow:
																			"hidden",
																		whiteSpace:
																			"nowrap",
																	}}
																>
																	{recipe?.Title
																		.length! > 16
																		? recipe?.Title.slice(
																				0,
																				16,
																		  ) + "..."
																		: recipe?.Title}
																</Body2>
																<Caption
																	fontWeight={500}
																	fontColor="boulder"
																	style={{
																		textOverflow:
																			"ellipsis",
																		overflow:
																			"hidden",
																		whiteSpace:
																			"nowrap",
																	}}
																>
																	By{" "}
																	{
																		recipe
																			?.collaborator
																			?.name
																	}
																</Caption>
																<div
																	style={{
																		flex: 1,
																	}}
																/>
																<FlexBetween>
																	<FlexCenter>
																		<IconClock color="meals" />
																		<Spacing type="s4" />
																		<StyledCaption
																			fontWeight={
																				500
																			}
																		>
																			{cookTime}
																		</StyledCaption>
																	</FlexCenter>
																	<FlexCenter>
																		<IconFire color="meals" />
																		<Spacing type="s4" />
																		<Caption
																			fontWeight={
																				500
																			}
																		>
																			{recipe?.Calories ||
																				0}
																		</Caption>
																	</FlexCenter>
																</FlexBetween>
															</Box>
														</DashboardItemCard>
													</div>
												</Link>
											</Box>
											<Spacing
												type="s24"
												multiplier={
													isMobile ? 1.1 : 1.7
												}
											/>
										</div>
									);
								},
							)}
						</div>
					</Box>
					{finalRecipes &&
					finalRecipes.length === 0 ? (
						<Wrapper>
							<FlexCenter>
								<Body1>No Recipes Found</Body1>
							</FlexCenter>
						</Wrapper>
					) : null}
				</ShowIf>
			</Box>
		</LayoutContainer>
	);
};

export default RecipesListing;
