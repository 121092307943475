import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconVideo: FC<{
	color: keyof typeof DS.colors;
	width?: string;
	height?: string;
}> = ({
	color = "boulder",
	width = "24.857",
	height = "24.857",
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24.857 24.857"
		>
			<circle
				id="Ellipse_15"
				data-name="Ellipse 15"
				cx="11.429"
				cy="11.429"
				r="11.429"
				transform="translate(1 1)"
				fill="none"
				stroke={DS.colors[color]}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
			/>
			<path
				id="Path_1401"
				data-name="Path 1401"
				d="M10,8l6.857,4.571L10,17.143Z"
				transform="translate(0.143 -0.143)"
				fill="none"
				stroke={DS.colors[color]}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
			/>
		</svg>
	);
};

export default IconVideo;
