import { FC } from "react";
import {
	Body2,
	FlexCenter,
	Spacing,
	Tag,
} from "@workshore/nyaari-ds";
import styled from "styled-components";

const Icon = styled.div`
	* {
		margin-top: 6px;
		height: 16px;
		width: 16px;
	}
`;

interface Props {
	icon: JSX.Element;
	text: string;
}

const LabeledIcon: FC<Props> = ({
	icon,
	text,
}) => {
	return (
		<Tag>
			<FlexCenter>
				<Icon>{icon}</Icon>
				<Spacing type="s8" />
				<Body2>{text}</Body2>
			</FlexCenter>
		</Tag>
	);
};

export default LabeledIcon;
