import {
	ForwardRefRenderFunction,
	forwardRef,
	CSSProperties,
} from "react";
import styled from "styled-components";
import { DS, Body2 } from "@workshore/nyaari-ds";

import IconAdd from "../Icons/IconAdd";

interface Props {
	style?: CSSProperties;
	stroke?: keyof typeof DS.colors;
	onClick?:
		| React.MouseEventHandler<HTMLDivElement>
		| undefined;
}

const StyledDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 8px 16px;
	box-shadow: 0 3px 6px #00000016;
	cursor: pointer;
`;

const AddWeeklyPlanner: ForwardRefRenderFunction<
	HTMLDivElement,
	Props
> = ({ style, stroke, onClick }, ref) => {
	return (
		<StyledDiv
			style={style}
			ref={ref}
			onClick={onClick}
		>
			<IconAdd stroke={stroke} />
			<Body2>Add to Weekly Planner</Body2>
		</StyledDiv>
	);
};

export default forwardRef(AddWeeklyPlanner);
