import {
	useRef,
	useState,
	useEffect,
} from "react";
import {
	H3,
	Body1,
	Body2,
	BulletText,
	Wrapper,
	Box,
	Spacing,
	Subtitle1,
	DS,
	FlexCenter,
	Button,
} from "@workshore/nyaari-ds";
import styled from "styled-components";

import { useGetMeditationCategoriesQuery } from "../../hooks/graphql/graphql";
import Loader from "../Loader/Loader";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import BulletList from "../BulletList/BulletList";
import LayoutContainer from "../LayoutContainer/LayoutContainer";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import ShowIf from "../ShowIf/ShowIf";
import SearchBox, {
	searchResultsTypes,
} from "../SearchBox/SearchBox";
import {
	useHistory,
	useLocation,
} from "react-router";
import useDebounce from "../../hooks/useDebounce/useDebounce";

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 39px;
	grid-row-gap: 38px;
	margin: 52px 0;

	@media screen and (max-width: 1400px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media screen and (max-width: ${DS.breakPoints
			.l}px) {
		grid-template-columns: repeat(3, 1fr);
		justify-content: space-between;
		margin: 22px 0;
	}

	@media screen and (max-width: ${DS.breakPoints
			.m}px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}

	@media screen and (max-width: 480px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

const Card = styled.div<{
	isMobile: boolean;
}>`
	height: 250px;
	border-radius: 5px;
	display: flex;
	align-items: flex-end;
	cursor: pointer;
	> * {
		margin: 16px;
	}

	@media screen and (max-width: 1400px) {
		height: 150px;
	}

	@media screen and (max-width: 1024px) {
		height: 230px;
	}

	@media screen and (max-width: 768px) {
		height: 200px;
	}

	@media screen and (max-width: 480px) {
		height: 155px;
		> * {
			margin: 13px 15px;
		}
	}
`;

const MeditationCategory = () => {
	const [keyword, setKeyword] = useState("");
	const ref = useRef<HTMLDivElement>(null);
	const [searchResults, setSearchResults] =
		useState<searchResultsTypes[]>([]);
	const [showResults, setShowResults] =
		useState(false);
	const searchKeyword = useDebounce(keyword);

	const history = useHistory();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const { search } = useLocation();
	const elementWidth = useElementWidth(ref);
	const isSmall = elementWidth <= 750;

	const { data, loading, error, refetch } =
		useGetMeditationCategoriesQuery();
	// console.log(data);

	useEffect(() => {
		if (searchKeyword.length > 1) {
			let searchRes: searchResultsTypes[] = [];
			data?.meditationCategories?.forEach(
				(category) => {
					if (
						category?.name
							.toLocaleLowerCase()
							.includes(
								searchKeyword.toLocaleLowerCase(),
							)
					) {
						searchRes.push({
							image: category?.image?.url || "",
							text: category?.name || "",
							url: `/dashboard/meditation/${category?.id}`,
							isKeyword: false,
						});
					}
				},
			);
			setSearchResults(searchRes);
			setShowResults(true);
		} else {
			setSearchResults([]);
			setShowResults(false);
		}
	}, [data, searchKeyword]);

	return (
		<LayoutContainer
			ref={ref}
			backgroundColor="wildSand"
		>
			<ShowIf value={loading}>
				<div
					style={{
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<FlexCenter>
						<Body1>
							Failed to load Meditation
						</Body1>
					</FlexCenter>
					<Spacing type="s24" />
					<FlexCenter>
						<Button width="100px">
							<Body2
								as="span"
								fontColor="white"
								onClick={() => {
									refetch();
								}}
							>
								Retry
							</Body2>
						</Button>
					</FlexCenter>
				</div>
			</ShowIf>
			{!loading && !error && (
				<ShowIf
					value={
						data?.meditationCategories?.length
					}
				>
					<Box
						paddingTop={isTablet ? 15 : 50}
						paddingBottom={isTablet ? 100 : 50}
					>
						<Wrapper type="fluid">
							<div
								style={{
									display: isSmall
										? "block"
										: "flex",
									alignItems: "center",
								}}
							>
								<H3>Meditation</H3>
								<ShowIf value={!isTablet}>
									<Spacing
										type={isSmall ? "s24" : "s64"}
									/>
									<SearchBox
										placeholder="Search"
										autoFocus
										value={keyword}
										onTextChange={(text) => {
											setKeyword(text);
										}}
										searchResults={searchResults}
										showResults={showResults}
										onSearchResultsCloseRequest={() => {
											setShowResults(false);
										}}
									/>
								</ShowIf>
							</div>
							<Spacing
								type={isTablet ? "s4" : "s24"}
							/>
							<BulletList
								num={3}
								items={[
									<BulletText tickColor="meditation">
										Meditate daily. Even 5 minutes
										of Zen energizes your day.
									</BulletText>,
									<BulletText tickColor="meditation">
										Go for a Gratitude Zen, at
										least once a week.
									</BulletText>,
									<BulletText tickColor="meditation">
										Breathe, follow a voice or
										close your eyes and pray.
									</BulletText>,
								]}
							/>
							<Container>
								{data?.meditationCategories?.map(
									(cateogory) => (
										<Card
											key={cateogory!.id}
											isMobile={isMobile}
											style={{
												background: `linear-gradient(to right,#00000060,#00000060), url(${cateogory?.image?.url}) no-repeat`,
												backgroundSize: "cover",
											}}
											onClick={() => {
												history.push(
													`/dashboard/meditation/${cateogory?.id}/${search}`,
												);
											}}
										>
											<Subtitle1 fontColor="white">
												{cateogory!.name}
											</Subtitle1>
										</Card>
									),
								)}
							</Container>
						</Wrapper>
					</Box>
				</ShowIf>
			)}
		</LayoutContainer>
	);
};

export default MeditationCategory;
