import React, {
	FC,
	useEffect,
	useRef,
} from "react";
import useSharedState from "@tinyd/usesharedstate";
import {
	Box,
	Drawer,
	ListItem,
	Logo,
	Subtitle1,
} from "@workshore/nyaari-ds";
import { RefProps } from "@workshore/nyaari-ds/src/components/Drawer/Drawer";
import {
	Link,
	Route,
	Switch,
	useHistory,
} from "react-router-dom";
import IconRightArrow from "../Icons/IconRightArrow";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";

export const PublicMenuComponent: FC<{
	setShow: (show: boolean) => void;
	hideSignIn?: boolean;
}> = ({ setShow, hideSignIn, children }) => {
	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Link
					to="/"
					onClick={() => {
						setShow(false);
					}}
				>
					<Logo newBig />
				</Link>
				{!hideSignIn && (
					<Link
						to="/signin"
						onClick={() => {
							setShow(false);
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								height: 24,
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="15"
								height="15"
								viewBox="0 0 15 15"
							>
								<g
									id="Solid"
									transform="translate(-24 -24)"
								>
									<path
										id="Path_1865"
										data-name="Path 1865"
										d="M31.646,147.57a.776.776,0,1,0,1.1,1.1l2.926-2.926a.776.776,0,0,0,0-1.1l-2.926-2.926a.776.776,0,0,0-1.1,1.1l1.6,1.6H24.776a.776.776,0,0,0,0,1.552h8.472Z"
										transform="translate(0 -113.692)"
										fill="#333"
									/>
									<path
										id="Path_1866"
										data-name="Path 1866"
										d="M192.534,24h-7.241A1.293,1.293,0,0,0,184,25.293v1.034a.776.776,0,0,0,1.552,0v-.776h6.724v11.9h-6.724v-.776a.776.776,0,0,0-1.552,0v1.034A1.293,1.293,0,0,0,185.293,39h7.241a1.293,1.293,0,0,0,1.293-1.293V25.293A1.293,1.293,0,0,0,192.534,24Z"
										transform="translate(-154.828 0)"
										fill="#333"
									/>
								</g>
							</svg>
							<Subtitle1 marginLeft={10}>
								Sign In
							</Subtitle1>
						</div>
					</Link>
				)}
			</div>
			{children}
			<Box marginTop={44}>
				<Link
					to="/freemium-gyan"
					onClick={() => {
						setShow(false);
					}}
				>
					<ListItem
						leftIcon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="15"
								height="14.413"
								viewBox="0 0 15 14.413"
							>
								<path
									id="blogging"
									d="M13.242.5H1.758A1.76,1.76,0,0,0,0,2.258v9.727a2.346,2.346,0,0,0,2.344,2.344H8.965a.586.586,0,1,0,0-1.172H2.344a1.173,1.173,0,0,1-1.172-1.172V4.045H13.828V9.436a.586.586,0,0,0,1.172,0V2.258A1.76,1.76,0,0,0,13.242.5ZM10.869,1.672a.586.586,0,1,1-.586.586A.587.587,0,0,1,10.869,1.672Zm2.93.586a.586.586,0,1,1-.586-.586A.587.587,0,0,1,13.8,2.258Zm-12.627,0a.587.587,0,0,1,.586-.586H9.212a1.754,1.754,0,0,0,.011,1.2H1.172ZM11.276,8.7a.586.586,0,0,0-.245-.147l-2.306-.7a.586.586,0,0,0-.735.717l.655,2.365a.585.585,0,0,0,.15.257L12,14.4a1.758,1.758,0,0,0,2.486-2.485ZM9.4,9.28l1.146.348L12.716,11.8l-.829.829L9.733,10.47Zm4.253,4.29a.586.586,0,0,1-.828,0l-.113-.114.829-.829.113.113A.586.586,0,0,1,13.656,13.571Zm0,0"
									transform="translate(0 -0.5)"
									fill="#333"
								/>
							</svg>
						}
						title={
							<Subtitle1>Freemium</Subtitle1>
						}
						rightIcon={<IconRightArrow />}
					/>
				</Link>
			</Box>
			<Box marginTop={44}>
				<Link
					to="/collaborators"
					onClick={() => {
						setShow(false);
					}}
				>
					<ListItem
						leftIcon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="17.3"
								height="15.435"
								viewBox="0 0 17.3 15.435"
							>
								<path
									id="fair-trade"
									d="M15.717,31.141a4.431,4.431,0,0,0-6.234,0l-.763.741-.853-.853A4.613,4.613,0,0,0,1.343,37.55l.086.086-.169.169a1.667,1.667,0,0,0,0,2.346l.015.015a1.648,1.648,0,0,0,1.156.486,1.648,1.648,0,0,0,.486,1.156l.015.015a1.647,1.647,0,0,0,1.182.486,1.647,1.647,0,0,0,.485,1.13l.015.015a1.667,1.667,0,0,0,2.346,0l.222-.222.993.993a1.638,1.638,0,0,0,2.8-1.134,1.644,1.644,0,0,0,1.612-1.579,1.564,1.564,0,0,0,1.3-2.393l1.83-1.731a4.431,4.431,0,0,0,.01-6.247Zm-12.8,8.321a.665.665,0,0,1-.936,0l-.015-.015a.665.665,0,0,1,0-.936l.916-.916a.662.662,0,0,1,.936,0l.015.015a.662.662,0,0,1,0,.936Zm1.656,1.656a.665.665,0,0,1-.936,0L3.621,41.1a.665.665,0,0,1,0-.936l1.5-1.5a.665.665,0,0,1,.936,0l.015.015a.665.665,0,0,1,0,.936Zm2.275,1.037-.594.594a.665.665,0,0,1-.936,0L5.3,42.733a.665.665,0,0,1,0-.936L5.9,41.2a.665.665,0,0,1,.936,0l.015.015A.665.665,0,0,1,6.847,42.154Zm6.108-1.8a.563.563,0,0,1-.8,0l-.99-.99a.5.5,0,0,0-.705.705L11.4,41a.641.641,0,0,1-.907.907l-.929-.929a.5.5,0,1,0-.705.705l.924.924,0,.005a.641.641,0,0,1-.907.907L7.838,42.478a1.667,1.667,0,0,0-.286-1.965L7.537,40.5a1.654,1.654,0,0,0-.588-.38,1.667,1.667,0,0,0-.176-2.14l-.015-.015A1.666,1.666,0,0,0,4.953,37.6a1.65,1.65,0,0,0-.416-.7l-.015-.015a1.661,1.661,0,0,0-2.347,0l-.042.042-.086-.086a3.616,3.616,0,1,1,5.113-5.113L8,32.575l-1.6,1.554a1.79,1.79,0,1,0,2.511,2.553l.552-.552a.045.045,0,0,1,.063,0l3.424,3.424a.565.565,0,0,1,0,.8Zm2.062-3.682L13.2,38.387l-2.963-2.963a1.047,1.047,0,0,0-1.474,0l-.552.552A.793.793,0,1,1,7.1,34.845l3.081-2.993a3.413,3.413,0,0,1,4.836,4.816Z"
									transform="translate(0.15 -29.527)"
									fill="#333"
									stroke="#333"
									strokeWidth="0.3"
								/>
							</svg>
						}
						title={
							<Subtitle1>Our Team</Subtitle1>
						}
						rightIcon={<IconRightArrow />}
					/>
				</Link>
			</Box>
			<Box marginTop={44}>
				<Link
					to="/contactus"
					onClick={() => {
						setShow(false);
					}}
				>
					<ListItem
						leftIcon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.446"
								height="16.446"
								viewBox="0 0 16.446 16.446"
							>
								<g
									id="Group_11277"
									data-name="Group 11277"
									transform="translate(-38.289 -390.495)"
								>
									<path
										id="Path_1573"
										data-name="Path 1573"
										d="M3.845,17.765a1.136,1.136,0,0,0,1.408,1.408L7.565,18.5a8.016,8.016,0,1,0-3.05-3.048Zm2.6-11.307a7.154,7.154,0,1,1,1.4,11.205A.431.431,0,0,0,7.5,17.62l-2.484.726A.274.274,0,0,1,4.667,18l.722-2.488a.431.431,0,0,0-.043-.345A7.144,7.144,0,0,1,6.441,6.458Z"
										transform="translate(35.018 387.195)"
										fill="#333"
										stroke="#333"
										strokeWidth="0.4"
									/>
									<path
										id="Path_1574"
										data-name="Path 1574"
										d="M25.261,34.935h0a3.384,3.384,0,0,0,3.577-.772l.405-.405a1.081,1.081,0,0,0-.295-1.738L26.8,30.985a.431.431,0,0,0-.5.09l-.667.69h-.017A3.517,3.517,0,0,1,23.655,29.8l.69-.69a.431.431,0,0,0,.083-.491l-1.034-2.145a1.081,1.081,0,0,0-1.738-.295l-.4.4a3.387,3.387,0,0,0-.776,3.574,8.156,8.156,0,0,0,4.784,4.784Zm-3.4-7.745.405-.405a.219.219,0,0,1,.345.06l.907,1.87-.509.509a.8.8,0,0,0-.172.85A4.38,4.38,0,0,0,25.3,32.561l.05.019a.807.807,0,0,0,.883-.191l.474-.493,1.864.9a.219.219,0,0,1,.06.345l-.405.405a2.524,2.524,0,0,1-2.662.584h0a7.3,7.3,0,0,1-4.275-4.275A2.524,2.524,0,0,1,21.865,27.189Z"
										transform="translate(21.129 368.688)"
										fill="#333"
										stroke="#333"
										strokeWidth="0.4"
									/>
									<path
										id="Path_1575"
										data-name="Path 1575"
										d="M59.706,32.616a.429.429,0,0,0,.3-.117l.826-.826a.432.432,0,0,0-.61-.61l-.833.819a.431.431,0,0,0,.305.736Z"
										transform="translate(-11.149 364.489)"
										fill="#333"
										stroke="#333"
										strokeWidth="0.4"
									/>
									<path
										id="Path_1576"
										data-name="Path 1576"
										d="M63.9,46.255a.431.431,0,0,0,.407.453l1.053.059h.024a.431.431,0,1,0,.024-.862l-1.053-.059a.431.431,0,0,0-.455.409Z"
										transform="translate(-14.981 352.151)"
										fill="#333"
										stroke="#333"
										strokeWidth="0.4"
									/>
								</g>
							</svg>
						}
						title={
							<Subtitle1>Contact Us</Subtitle1>
						}
						rightIcon={<IconRightArrow />}
					/>
				</Link>
			</Box>
		</>
	);
};

const PublicMenu = () => {
	const isTablet = useIsTablet();

	const history = useHistory();
	const ref = useRef<RefProps>({
		open: () => {},
		close: () => {},
		toggle: () => {},
	});
	const [show, setShow] = useSharedState(
		"@public_menu",
		false,
	);

	useEffect(() => {
		if (
			!history.location.pathname.includes(
				"/dashboard",
			)
		)
			if (show) {
				ref.current.open();
			} else {
				ref.current.close();
			}
	}, [show, history]);
	if (!isTablet) {
		return null;
	}
	return (
		<Switch>
			<Route path="/dashboard"></Route>
			<Route>
				<Drawer
					placement="left"
					ref={ref}
					onOutsideClick={() => {
						setShow(false);
					}}
				>
					<Box margin="28px 40px 20px 20px">
						<PublicMenuComponent
							setShow={setShow}
						/>
					</Box>
				</Drawer>
			</Route>
		</Switch>
	);
};

export default PublicMenu;
