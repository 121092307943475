import storage from "../services/storage";

const TemplateData = [1, 2, 3];

export type minimalPriorityType = {
	type: string;
	title: string;
	description: string;
	checkin?: string | null;
};

export const priorityFactory = (
	data: minimalPriorityType[] = [],
	templateData: any[] = TemplateData,
): minimalPriorityType[] => {
	const finalData = templateData.map(
		(_, index) => {
			if (data) {
				let _data = data[index];
				if (_data) {
					return {
						type: _data.type,
						title: _data.title,
						description: _data.description,
						checkin: _data.checkin,
					};
				}
			}

			return {
				type: "",
				title: "",
				description: "",
				checkin: null,
			};
		},
	);
	return finalData;
};

export const replacePriority = (
	index: number,
	data: minimalPriorityType,
	dataArray: minimalPriorityType[] = [],
) => {
	let newIndex = Math.min(
		Math.max(0, index),
		TemplateData.length - 1,
	);
	let finalData = priorityFactory(dataArray);
	finalData[newIndex] = data;

	return finalData;
};

export const isPriorityOverFlow = (
	dataArray: minimalPriorityType[],
) => {
	let finalData = priorityFactory(
		dataArray,
	).filter((d) => d.title);

	return finalData.length === TemplateData.length;
};

export const getEmptyIndexOnPriorities = (
	dataArray: minimalPriorityType[],
) => {
	let emptyIndex = -1;
	let finalData = priorityFactory(dataArray);
	finalData.forEach((d, index) => {
		if (!d.title && emptyIndex === -1) {
			emptyIndex = index;
		}
	});
	return emptyIndex;
};

export const removeLocalPriority = () => {
	storage.remove("myPriorities");
};
