import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<
	T extends { [key: string]: unknown },
> = { [K in keyof T]: T[K] };
export type MakeOptional<
	T,
	K extends keyof T,
> = Omit<T, K> &
	{ [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<
	T,
	K extends keyof T,
> = Omit<T, K> &
	{ [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
	Date: any;
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
	DateTime: any;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSON: any;
	/** The `Long` scalar type represents 52-bit integers */
	Long: any;
	/** A time string with format: HH:mm:ss.SSS */
	Time: any;
	/** The `Upload` scalar type represents a file upload. */
	Upload: any;
};

export type AdminUser = {
	__typename?: "AdminUser";
	id: Scalars["ID"];
	username?: Maybe<Scalars["String"]>;
	firstname: Scalars["String"];
	lastname: Scalars["String"];
};

export type Blog = {
	__typename?: "Blog";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
	banner_image?: Maybe<UploadFile>;
	content: Scalars["String"];
	collaborator?: Maybe<Collaborator>;
	blog_category?: Maybe<BlogCategory>;
	short_description: Scalars["String"];
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type BlogAggregator = {
	__typename?: "BlogAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<BlogAggregatorSum>;
	avg?: Maybe<BlogAggregatorAvg>;
	min?: Maybe<BlogAggregatorMin>;
	max?: Maybe<BlogAggregatorMax>;
};

export type BlogAggregatorAvg = {
	__typename?: "BlogAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type BlogAggregatorMax = {
	__typename?: "BlogAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type BlogAggregatorMin = {
	__typename?: "BlogAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type BlogAggregatorSum = {
	__typename?: "BlogAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type BlogCategory = {
	__typename?: "BlogCategory";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type BlogCategoryAggregator = {
	__typename?: "BlogCategoryAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type BlogCategoryConnection = {
	__typename?: "BlogCategoryConnection";
	values?: Maybe<Array<Maybe<BlogCategory>>>;
	groupBy?: Maybe<BlogCategoryGroupBy>;
	aggregate?: Maybe<BlogCategoryAggregator>;
};

export type BlogCategoryConnectionCreatedAt = {
	__typename?: "BlogCategoryConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<BlogCategoryConnection>;
};

export type BlogCategoryConnectionId = {
	__typename?: "BlogCategoryConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<BlogCategoryConnection>;
};

export type BlogCategoryConnectionPublished_At = {
	__typename?: "BlogCategoryConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<BlogCategoryConnection>;
};

export type BlogCategoryConnectionTitle = {
	__typename?: "BlogCategoryConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<BlogCategoryConnection>;
};

export type BlogCategoryConnectionUpdatedAt = {
	__typename?: "BlogCategoryConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<BlogCategoryConnection>;
};

export type BlogCategoryConnection_Id = {
	__typename?: "BlogCategoryConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<BlogCategoryConnection>;
};

export type BlogCategoryGroupBy = {
	__typename?: "BlogCategoryGroupBy";
	id?: Maybe<
		Array<Maybe<BlogCategoryConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<BlogCategoryConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<BlogCategoryConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<BlogCategoryConnectionUpdatedAt>>
	>;
	title?: Maybe<
		Array<Maybe<BlogCategoryConnectionTitle>>
	>;
	published_at?: Maybe<
		Array<
			Maybe<BlogCategoryConnectionPublished_At>
		>
	>;
};

export type BlogCategoryInput = {
	title: Scalars["String"];
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type BlogConnection = {
	__typename?: "BlogConnection";
	values?: Maybe<Array<Maybe<Blog>>>;
	groupBy?: Maybe<BlogGroupBy>;
	aggregate?: Maybe<BlogAggregator>;
};

export type BlogConnectionBanner_Image = {
	__typename?: "BlogConnectionBanner_image";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionBlog_Category = {
	__typename?: "BlogConnectionBlog_category";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionCollaborator = {
	__typename?: "BlogConnectionCollaborator";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionContent = {
	__typename?: "BlogConnectionContent";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionCreatedAt = {
	__typename?: "BlogConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionId = {
	__typename?: "BlogConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionOrder = {
	__typename?: "BlogConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionPublished_At = {
	__typename?: "BlogConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionShort_Description = {
	__typename?: "BlogConnectionShort_description";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionTitle = {
	__typename?: "BlogConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnectionUpdatedAt = {
	__typename?: "BlogConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogConnection_Id = {
	__typename?: "BlogConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<BlogConnection>;
};

export type BlogGroupBy = {
	__typename?: "BlogGroupBy";
	id?: Maybe<Array<Maybe<BlogConnectionId>>>;
	_id?: Maybe<Array<Maybe<BlogConnection_Id>>>;
	createdAt?: Maybe<
		Array<Maybe<BlogConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<BlogConnectionUpdatedAt>>
	>;
	title?: Maybe<
		Array<Maybe<BlogConnectionTitle>>
	>;
	banner_image?: Maybe<
		Array<Maybe<BlogConnectionBanner_Image>>
	>;
	content?: Maybe<
		Array<Maybe<BlogConnectionContent>>
	>;
	collaborator?: Maybe<
		Array<Maybe<BlogConnectionCollaborator>>
	>;
	blog_category?: Maybe<
		Array<Maybe<BlogConnectionBlog_Category>>
	>;
	short_description?: Maybe<
		Array<Maybe<BlogConnectionShort_Description>>
	>;
	order?: Maybe<
		Array<Maybe<BlogConnectionOrder>>
	>;
	published_at?: Maybe<
		Array<Maybe<BlogConnectionPublished_At>>
	>;
};

export type BlogInput = {
	title: Scalars["String"];
	banner_image?: Maybe<Scalars["ID"]>;
	content: Scalars["String"];
	collaborator?: Maybe<Scalars["ID"]>;
	blog_category?: Maybe<Scalars["ID"]>;
	short_description: Scalars["String"];
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Collaborator = {
	__typename?: "Collaborator";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	name: Scalars["String"];
	occupation: Scalars["String"];
	location: Scalars["String"];
	expertise: Scalars["String"];
	why: Scalars["String"];
	links?: Maybe<
		Array<Maybe<ComponentLinksLinks>>
	>;
	image?: Maybe<UploadFile>;
	expertise_rich: Scalars["String"];
	why_rich?: Maybe<Scalars["String"]>;
	order: Scalars["Float"];
	recipes?: Maybe<Array<Maybe<Recipes>>>;
	mantras?: Maybe<Array<Maybe<Mantra>>>;
};

export type CollaboratorRecipesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type CollaboratorMantrasArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type CollaboratorAggregator = {
	__typename?: "CollaboratorAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<CollaboratorAggregatorSum>;
	avg?: Maybe<CollaboratorAggregatorAvg>;
	min?: Maybe<CollaboratorAggregatorMin>;
	max?: Maybe<CollaboratorAggregatorMax>;
};

export type CollaboratorAggregatorAvg = {
	__typename?: "CollaboratorAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type CollaboratorAggregatorMax = {
	__typename?: "CollaboratorAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type CollaboratorAggregatorMin = {
	__typename?: "CollaboratorAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type CollaboratorAggregatorSum = {
	__typename?: "CollaboratorAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type CollaboratorConnection = {
	__typename?: "CollaboratorConnection";
	values?: Maybe<Array<Maybe<Collaborator>>>;
	groupBy?: Maybe<CollaboratorGroupBy>;
	aggregate?: Maybe<CollaboratorAggregator>;
};

export type CollaboratorConnectionCreatedAt = {
	__typename?: "CollaboratorConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionExpertise = {
	__typename?: "CollaboratorConnectionExpertise";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionExpertise_Rich =
	{
		__typename?: "CollaboratorConnectionExpertise_rich";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<CollaboratorConnection>;
	};

export type CollaboratorConnectionId = {
	__typename?: "CollaboratorConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionImage = {
	__typename?: "CollaboratorConnectionImage";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionLocation = {
	__typename?: "CollaboratorConnectionLocation";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionName = {
	__typename?: "CollaboratorConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionOccupation = {
	__typename?: "CollaboratorConnectionOccupation";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionOrder = {
	__typename?: "CollaboratorConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionUpdatedAt = {
	__typename?: "CollaboratorConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionWhy = {
	__typename?: "CollaboratorConnectionWhy";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnectionWhy_Rich = {
	__typename?: "CollaboratorConnectionWhy_rich";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorConnection_Id = {
	__typename?: "CollaboratorConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<CollaboratorConnection>;
};

export type CollaboratorGroupBy = {
	__typename?: "CollaboratorGroupBy";
	id?: Maybe<
		Array<Maybe<CollaboratorConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<CollaboratorConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<CollaboratorConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<CollaboratorConnectionUpdatedAt>>
	>;
	name?: Maybe<
		Array<Maybe<CollaboratorConnectionName>>
	>;
	occupation?: Maybe<
		Array<Maybe<CollaboratorConnectionOccupation>>
	>;
	location?: Maybe<
		Array<Maybe<CollaboratorConnectionLocation>>
	>;
	expertise?: Maybe<
		Array<Maybe<CollaboratorConnectionExpertise>>
	>;
	why?: Maybe<
		Array<Maybe<CollaboratorConnectionWhy>>
	>;
	image?: Maybe<
		Array<Maybe<CollaboratorConnectionImage>>
	>;
	expertise_rich?: Maybe<
		Array<
			Maybe<CollaboratorConnectionExpertise_Rich>
		>
	>;
	why_rich?: Maybe<
		Array<Maybe<CollaboratorConnectionWhy_Rich>>
	>;
	order?: Maybe<
		Array<Maybe<CollaboratorConnectionOrder>>
	>;
};

export type CollaboratorInput = {
	name: Scalars["String"];
	occupation: Scalars["String"];
	location: Scalars["String"];
	expertise: Scalars["String"];
	why: Scalars["String"];
	links?: Maybe<
		Array<Maybe<ComponentLinksLinkInput>>
	>;
	image?: Maybe<Scalars["ID"]>;
	recipes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	mantras?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	expertise_rich: Scalars["String"];
	why_rich?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type ComponentLinksLinkInput = {
	type: Enum_Componentlinkslinks_Type;
	url: Scalars["String"];
};

export type ComponentLinksLinks = {
	__typename?: "ComponentLinksLinks";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	type: Enum_Componentlinkslinks_Type;
	url: Scalars["String"];
};

export type ComponentMealsAyurvedaInsight = {
	__typename?: "ComponentMealsAyurvedaInsight";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Description: Scalars["String"];
};

export type ComponentMealsAyurvedaInsightInput = {
	Description: Scalars["String"];
};

export type ComponentMealsDuration = {
	__typename?: "ComponentMealsDuration";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	duration: Scalars["Int"];
	unit: Enum_Componentmealsduration_Unit;
};

export type ComponentMealsDurationInput = {
	duration?: Maybe<Scalars["Int"]>;
	unit?: Maybe<Enum_Componentmealsduration_Unit>;
};

export type ComponentMealsIngredientInput = {
	Ingredients_Name: Scalars["String"];
	Unit: Scalars["String"];
};

export type ComponentMealsIngredients = {
	__typename?: "ComponentMealsIngredients";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Ingredients_Name: Scalars["String"];
	Unit: Scalars["String"];
};

export type ComponentMealsIngredientsWithImageInput =
	{
		Ingredients?: Maybe<
			Array<ComponentMealsIngredientInput>
		>;
		Image?: Maybe<Scalars["ID"]>;
		IngredientsWithTitle?: Maybe<
			Array<
				Maybe<ComponentMealsIngredientsWithTitleInput>
			>
		>;
	};

export type ComponentMealsIngredientsWithImages =
	{
		__typename?: "ComponentMealsIngredientsWithImages";
		id: Scalars["ID"];
		_id: Scalars["ID"];
		Ingredients?: Maybe<
			Array<Maybe<ComponentMealsIngredients>>
		>;
		Image?: Maybe<UploadFile>;
		IngredientsWithTitle?: Maybe<
			Array<
				Maybe<ComponentMealsIngredientsWithTitle>
			>
		>;
	};

export type ComponentMealsIngredientsWithTitle = {
	__typename?: "ComponentMealsIngredientsWithTitle";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Title?: Maybe<Scalars["String"]>;
	Ingredients?: Maybe<
		Array<Maybe<ComponentMealsIngredients>>
	>;
};

export type ComponentMealsIngredientsWithTitleInput =
	{
		Title?: Maybe<Scalars["String"]>;
		Ingredients?: Maybe<
			Array<Maybe<ComponentMealsIngredientInput>>
		>;
	};

export type ComponentMealsInsightInput = {
	Nutritional_Insight?: Maybe<
		Array<ComponentMealsNutritionalInsightInput>
	>;
	Ayurveda_Insight?: Maybe<
		Array<ComponentMealsAyurvedaInsightInput>
	>;
	Nutritional_Insight_rich?: Maybe<
		Scalars["String"]
	>;
	Ayurveda_Insight_rich?: Maybe<
		Scalars["String"]
	>;
};

export type ComponentMealsInsights = {
	__typename?: "ComponentMealsInsights";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Nutritional_Insight?: Maybe<
		Array<Maybe<ComponentMealsNutritionalInsight>>
	>;
	Ayurveda_Insight?: Maybe<
		Array<Maybe<ComponentMealsAyurvedaInsight>>
	>;
	Nutritional_Insight_rich?: Maybe<
		Scalars["String"]
	>;
	Ayurveda_Insight_rich?: Maybe<
		Scalars["String"]
	>;
};

export type ComponentMealsInstructionInput = {
	Variations?: Maybe<Scalars["String"]>;
	Steps?: Maybe<Array<ComponentMealsStepInput>>;
};

export type ComponentMealsInstructions = {
	__typename?: "ComponentMealsInstructions";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Variations?: Maybe<Scalars["String"]>;
	Steps?: Maybe<
		Array<Maybe<ComponentMealsSteps>>
	>;
};

export type ComponentMealsNutritionFactInput = {
	Title: Scalars["String"];
	Unit: Scalars["String"];
	Sub_Nutrition_facts?: Maybe<
		Array<
			Maybe<ComponentMealsSubNutritionFactInput>
		>
	>;
	value?: Maybe<Scalars["Float"]>;
};

export type ComponentMealsNutritionFacts = {
	__typename?: "ComponentMealsNutritionFacts";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Title: Scalars["String"];
	Unit: Scalars["String"];
	Sub_Nutrition_facts?: Maybe<
		Array<Maybe<ComponentMealsSubNutritionFacts>>
	>;
	value: Scalars["Float"];
};

export type ComponentMealsNutritionalInsight = {
	__typename?: "ComponentMealsNutritionalInsight";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Description: Scalars["String"];
};

export type ComponentMealsNutritionalInsightInput =
	{
		Description: Scalars["String"];
	};

export type ComponentMealsRecipeInput = {
	images?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type ComponentMealsRecipes = {
	__typename?: "ComponentMealsRecipes";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	images?: Maybe<Array<Maybe<UploadFile>>>;
};

export type ComponentMealsRecipesImagesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type ComponentMealsStepInput = {
	Step_Description: Scalars["String"];
	Step_Images?: Maybe<
		Array<Maybe<Scalars["ID"]>>
	>;
};

export type ComponentMealsSteps = {
	__typename?: "ComponentMealsSteps";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Step_Description: Scalars["String"];
	Step_Images?: Maybe<Array<Maybe<UploadFile>>>;
};

export type ComponentMealsStepsStep_ImagesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type ComponentMealsSubNutritionFactInput =
	{
		Title: Scalars["String"];
		Unit: Scalars["String"];
		value?: Maybe<Scalars["Float"]>;
	};

export type ComponentMealsSubNutritionFacts = {
	__typename?: "ComponentMealsSubNutritionFacts";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Title: Scalars["String"];
	Unit: Scalars["String"];
	value: Scalars["Float"];
};

export type ComponentMealsTagInput = {
	Tag?: Maybe<Scalars["String"]>;
};

export type ComponentMealsTags = {
	__typename?: "ComponentMealsTags";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	Tag?: Maybe<Scalars["String"]>;
};

export type ComponentPrioritiesPriorities = {
	__typename?: "ComponentPrioritiesPriorities";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	type?: Maybe<Scalars["String"]>;
	title?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	checkin?: Maybe<Scalars["Date"]>;
};

export type ComponentPrioritiesPriorityInput = {
	type?: Maybe<Scalars["String"]>;
	title?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	checkin?: Maybe<Scalars["Date"]>;
};

export type ContactQuery = {
	__typename?: "ContactQuery";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	name: Scalars["String"];
	email: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
	type: Scalars["String"];
	message: Scalars["String"];
	expertise?: Maybe<Scalars["String"]>;
};

export type ContactQueryAggregator = {
	__typename?: "ContactQueryAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type ContactQueryConnection = {
	__typename?: "ContactQueryConnection";
	values?: Maybe<Array<Maybe<ContactQuery>>>;
	groupBy?: Maybe<ContactQueryGroupBy>;
	aggregate?: Maybe<ContactQueryAggregator>;
};

export type ContactQueryConnectionCreatedAt = {
	__typename?: "ContactQueryConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnectionEmail = {
	__typename?: "ContactQueryConnectionEmail";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnectionExpertise = {
	__typename?: "ContactQueryConnectionExpertise";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnectionId = {
	__typename?: "ContactQueryConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnectionMessage = {
	__typename?: "ContactQueryConnectionMessage";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnectionName = {
	__typename?: "ContactQueryConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnectionPhone = {
	__typename?: "ContactQueryConnectionPhone";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnectionType = {
	__typename?: "ContactQueryConnectionType";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnectionUpdatedAt = {
	__typename?: "ContactQueryConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryConnection_Id = {
	__typename?: "ContactQueryConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<ContactQueryConnection>;
};

export type ContactQueryGroupBy = {
	__typename?: "ContactQueryGroupBy";
	id?: Maybe<
		Array<Maybe<ContactQueryConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<ContactQueryConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<ContactQueryConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<ContactQueryConnectionUpdatedAt>>
	>;
	name?: Maybe<
		Array<Maybe<ContactQueryConnectionName>>
	>;
	email?: Maybe<
		Array<Maybe<ContactQueryConnectionEmail>>
	>;
	phone?: Maybe<
		Array<Maybe<ContactQueryConnectionPhone>>
	>;
	type?: Maybe<
		Array<Maybe<ContactQueryConnectionType>>
	>;
	message?: Maybe<
		Array<Maybe<ContactQueryConnectionMessage>>
	>;
	expertise?: Maybe<
		Array<Maybe<ContactQueryConnectionExpertise>>
	>;
};

export type ContactQueryInput = {
	name: Scalars["String"];
	email: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
	type: Scalars["String"];
	message: Scalars["String"];
	expertise?: Maybe<Scalars["String"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type DailyQuote = {
	__typename?: "DailyQuote";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	quote?: Maybe<Scalars["String"]>;
	author?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type DailyQuoteAggregator = {
	__typename?: "DailyQuoteAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type DailyQuoteConnection = {
	__typename?: "DailyQuoteConnection";
	values?: Maybe<Array<Maybe<DailyQuote>>>;
	groupBy?: Maybe<DailyQuoteGroupBy>;
	aggregate?: Maybe<DailyQuoteAggregator>;
};

export type DailyQuoteConnectionAuthor = {
	__typename?: "DailyQuoteConnectionAuthor";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<DailyQuoteConnection>;
};

export type DailyQuoteConnectionCreatedAt = {
	__typename?: "DailyQuoteConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<DailyQuoteConnection>;
};

export type DailyQuoteConnectionId = {
	__typename?: "DailyQuoteConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<DailyQuoteConnection>;
};

export type DailyQuoteConnectionPublished_At = {
	__typename?: "DailyQuoteConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<DailyQuoteConnection>;
};

export type DailyQuoteConnectionQuote = {
	__typename?: "DailyQuoteConnectionQuote";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<DailyQuoteConnection>;
};

export type DailyQuoteConnectionUpdatedAt = {
	__typename?: "DailyQuoteConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<DailyQuoteConnection>;
};

export type DailyQuoteConnection_Id = {
	__typename?: "DailyQuoteConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<DailyQuoteConnection>;
};

export type DailyQuoteGroupBy = {
	__typename?: "DailyQuoteGroupBy";
	id?: Maybe<
		Array<Maybe<DailyQuoteConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<DailyQuoteConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<DailyQuoteConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<DailyQuoteConnectionUpdatedAt>>
	>;
	quote?: Maybe<
		Array<Maybe<DailyQuoteConnectionQuote>>
	>;
	author?: Maybe<
		Array<Maybe<DailyQuoteConnectionAuthor>>
	>;
	published_at?: Maybe<
		Array<Maybe<DailyQuoteConnectionPublished_At>>
	>;
};

export type DailyQuoteInput = {
	quote?: Maybe<Scalars["String"]>;
	author?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export enum Enum_Componentlinkslinks_Type {
	Facebook = "Facebook",
	Instagram = "Instagram",
	Twitter = "Twitter",
	Youtube = "Youtube",
	Other = "Other",
}

export enum Enum_Componentmealsduration_Unit {
	M = "m",
	Hr = "hr",
	D = "d",
}

export enum Enum_Priority_Category {
	Meals = "Meals",
	Movement = "Movement",
	Meditation = "Meditation",
	Mantra = "Mantra",
	Masti = "Masti",
}

export type FileInfoInput = {
	name?: Maybe<Scalars["String"]>;
	alternativeText?: Maybe<Scalars["String"]>;
	caption?: Maybe<Scalars["String"]>;
};

export type FileInput = {
	name: Scalars["String"];
	alternativeText?: Maybe<Scalars["String"]>;
	caption?: Maybe<Scalars["String"]>;
	width?: Maybe<Scalars["Int"]>;
	height?: Maybe<Scalars["Int"]>;
	formats?: Maybe<Scalars["JSON"]>;
	hash: Scalars["String"];
	ext?: Maybe<Scalars["String"]>;
	mime: Scalars["String"];
	size: Scalars["Float"];
	url: Scalars["String"];
	previewUrl?: Maybe<Scalars["String"]>;
	provider: Scalars["String"];
	provider_metadata?: Maybe<Scalars["JSON"]>;
	related?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type FoodIndex = {
	__typename?: "FoodIndex";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	name: Scalars["String"];
	image?: Maybe<UploadFile>;
	insights?: Maybe<ComponentMealsInsights>;
	values?: Maybe<
		Array<Maybe<ComponentMealsNutritionFacts>>
	>;
	qty: Scalars["String"];
	ValueDisclaimer?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type FoodIndexAggregator = {
	__typename?: "FoodIndexAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type FoodIndexConnection = {
	__typename?: "FoodIndexConnection";
	values?: Maybe<Array<Maybe<FoodIndex>>>;
	groupBy?: Maybe<FoodIndexGroupBy>;
	aggregate?: Maybe<FoodIndexAggregator>;
};

export type FoodIndexConnectionCreatedAt = {
	__typename?: "FoodIndexConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnectionId = {
	__typename?: "FoodIndexConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnectionImage = {
	__typename?: "FoodIndexConnectionImage";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnectionInsights = {
	__typename?: "FoodIndexConnectionInsights";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnectionName = {
	__typename?: "FoodIndexConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnectionPublished_At = {
	__typename?: "FoodIndexConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnectionQty = {
	__typename?: "FoodIndexConnectionQty";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnectionUpdatedAt = {
	__typename?: "FoodIndexConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnectionValueDisclaimer = {
	__typename?: "FoodIndexConnectionValueDisclaimer";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexConnection_Id = {
	__typename?: "FoodIndexConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<FoodIndexConnection>;
};

export type FoodIndexGroupBy = {
	__typename?: "FoodIndexGroupBy";
	id?: Maybe<Array<Maybe<FoodIndexConnectionId>>>;
	_id?: Maybe<
		Array<Maybe<FoodIndexConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<FoodIndexConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<FoodIndexConnectionUpdatedAt>>
	>;
	name?: Maybe<
		Array<Maybe<FoodIndexConnectionName>>
	>;
	image?: Maybe<
		Array<Maybe<FoodIndexConnectionImage>>
	>;
	insights?: Maybe<
		Array<Maybe<FoodIndexConnectionInsights>>
	>;
	qty?: Maybe<
		Array<Maybe<FoodIndexConnectionQty>>
	>;
	ValueDisclaimer?: Maybe<
		Array<
			Maybe<FoodIndexConnectionValueDisclaimer>
		>
	>;
	published_at?: Maybe<
		Array<Maybe<FoodIndexConnectionPublished_At>>
	>;
};

export type FoodIndexInput = {
	name: Scalars["String"];
	image?: Maybe<Scalars["ID"]>;
	insights: ComponentMealsInsightInput;
	values?: Maybe<
		Array<ComponentMealsNutritionFactInput>
	>;
	qty?: Maybe<Scalars["String"]>;
	ValueDisclaimer?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type GroceryList = {
	__typename?: "GroceryList";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	description: Scalars["String"];
	checked: Scalars["Boolean"];
	category: Scalars["String"];
	user?: Maybe<UsersPermissionsUser>;
};

export type GroceryListAggregator = {
	__typename?: "GroceryListAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type GroceryListConnection = {
	__typename?: "GroceryListConnection";
	values?: Maybe<Array<Maybe<GroceryList>>>;
	groupBy?: Maybe<GroceryListGroupBy>;
	aggregate?: Maybe<GroceryListAggregator>;
};

export type GroceryListConnectionCategory = {
	__typename?: "GroceryListConnectionCategory";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<GroceryListConnection>;
};

export type GroceryListConnectionChecked = {
	__typename?: "GroceryListConnectionChecked";
	key?: Maybe<Scalars["Boolean"]>;
	connection?: Maybe<GroceryListConnection>;
};

export type GroceryListConnectionCreatedAt = {
	__typename?: "GroceryListConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<GroceryListConnection>;
};

export type GroceryListConnectionDescription = {
	__typename?: "GroceryListConnectionDescription";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<GroceryListConnection>;
};

export type GroceryListConnectionId = {
	__typename?: "GroceryListConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<GroceryListConnection>;
};

export type GroceryListConnectionUpdatedAt = {
	__typename?: "GroceryListConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<GroceryListConnection>;
};

export type GroceryListConnectionUser = {
	__typename?: "GroceryListConnectionUser";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<GroceryListConnection>;
};

export type GroceryListConnection_Id = {
	__typename?: "GroceryListConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<GroceryListConnection>;
};

export type GroceryListGroupBy = {
	__typename?: "GroceryListGroupBy";
	id?: Maybe<
		Array<Maybe<GroceryListConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<GroceryListConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<GroceryListConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<GroceryListConnectionUpdatedAt>>
	>;
	description?: Maybe<
		Array<Maybe<GroceryListConnectionDescription>>
	>;
	checked?: Maybe<
		Array<Maybe<GroceryListConnectionChecked>>
	>;
	category?: Maybe<
		Array<Maybe<GroceryListConnectionCategory>>
	>;
	user?: Maybe<
		Array<Maybe<GroceryListConnectionUser>>
	>;
};

export type GroceryListInput = {
	description: Scalars["String"];
	checked?: Maybe<Scalars["Boolean"]>;
	category: Scalars["String"];
	user?: Maybe<Scalars["ID"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type I18NLocale = {
	__typename?: "I18NLocale";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	name?: Maybe<Scalars["String"]>;
	code?: Maybe<Scalars["String"]>;
};

export type InputId = {
	id: Scalars["ID"];
};

export type JournalPromptInput = {
	description?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type JournalPrompts = {
	__typename?: "JournalPrompts";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	description?: Maybe<Scalars["String"]>;
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type JournalPromptsAggregator = {
	__typename?: "JournalPromptsAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<JournalPromptsAggregatorSum>;
	avg?: Maybe<JournalPromptsAggregatorAvg>;
	min?: Maybe<JournalPromptsAggregatorMin>;
	max?: Maybe<JournalPromptsAggregatorMax>;
};

export type JournalPromptsAggregatorAvg = {
	__typename?: "JournalPromptsAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type JournalPromptsAggregatorMax = {
	__typename?: "JournalPromptsAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type JournalPromptsAggregatorMin = {
	__typename?: "JournalPromptsAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type JournalPromptsAggregatorSum = {
	__typename?: "JournalPromptsAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type JournalPromptsConnection = {
	__typename?: "JournalPromptsConnection";
	values?: Maybe<Array<Maybe<JournalPrompts>>>;
	groupBy?: Maybe<JournalPromptsGroupBy>;
	aggregate?: Maybe<JournalPromptsAggregator>;
};

export type JournalPromptsConnectionCreatedAt = {
	__typename?: "JournalPromptsConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<JournalPromptsConnection>;
};

export type JournalPromptsConnectionDescription =
	{
		__typename?: "JournalPromptsConnectionDescription";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<JournalPromptsConnection>;
	};

export type JournalPromptsConnectionId = {
	__typename?: "JournalPromptsConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<JournalPromptsConnection>;
};

export type JournalPromptsConnectionOrder = {
	__typename?: "JournalPromptsConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<JournalPromptsConnection>;
};

export type JournalPromptsConnectionPublished_At =
	{
		__typename?: "JournalPromptsConnectionPublished_at";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<JournalPromptsConnection>;
	};

export type JournalPromptsConnectionUpdatedAt = {
	__typename?: "JournalPromptsConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<JournalPromptsConnection>;
};

export type JournalPromptsConnection_Id = {
	__typename?: "JournalPromptsConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<JournalPromptsConnection>;
};

export type JournalPromptsGroupBy = {
	__typename?: "JournalPromptsGroupBy";
	id?: Maybe<
		Array<Maybe<JournalPromptsConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<JournalPromptsConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<
			Maybe<JournalPromptsConnectionCreatedAt>
		>
	>;
	updatedAt?: Maybe<
		Array<
			Maybe<JournalPromptsConnectionUpdatedAt>
		>
	>;
	description?: Maybe<
		Array<
			Maybe<JournalPromptsConnectionDescription>
		>
	>;
	order?: Maybe<
		Array<Maybe<JournalPromptsConnectionOrder>>
	>;
	published_at?: Maybe<
		Array<
			Maybe<JournalPromptsConnectionPublished_At>
		>
	>;
};

export type LocaleInput = {
	name?: Maybe<Scalars["String"]>;
	code?: Maybe<Scalars["String"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Mantra = {
	__typename?: "Mantra";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
	short_description: Scalars["String"];
	cover_image?: Maybe<UploadFile>;
	reading_time?: Maybe<Scalars["String"]>;
	collaborator?: Maybe<Collaborator>;
	content?: Maybe<Scalars["String"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type MantraAggregator = {
	__typename?: "MantraAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<MantraAggregatorSum>;
	avg?: Maybe<MantraAggregatorAvg>;
	min?: Maybe<MantraAggregatorMin>;
	max?: Maybe<MantraAggregatorMax>;
};

export type MantraAggregatorAvg = {
	__typename?: "MantraAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type MantraAggregatorMax = {
	__typename?: "MantraAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type MantraAggregatorMin = {
	__typename?: "MantraAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type MantraAggregatorSum = {
	__typename?: "MantraAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type MantraConnection = {
	__typename?: "MantraConnection";
	values?: Maybe<Array<Maybe<Mantra>>>;
	groupBy?: Maybe<MantraGroupBy>;
	aggregate?: Maybe<MantraAggregator>;
};

export type MantraConnectionCollaborator = {
	__typename?: "MantraConnectionCollaborator";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionContent = {
	__typename?: "MantraConnectionContent";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionCover_Image = {
	__typename?: "MantraConnectionCover_image";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionCreatedAt = {
	__typename?: "MantraConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionId = {
	__typename?: "MantraConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionOrder = {
	__typename?: "MantraConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionPublished_At = {
	__typename?: "MantraConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionReading_Time = {
	__typename?: "MantraConnectionReading_time";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionShort_Description = {
	__typename?: "MantraConnectionShort_description";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionTitle = {
	__typename?: "MantraConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionUpdatedAt = {
	__typename?: "MantraConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnectionVimeo_Url = {
	__typename?: "MantraConnectionVimeo_url";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraConnection_Id = {
	__typename?: "MantraConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MantraConnection>;
};

export type MantraGroupBy = {
	__typename?: "MantraGroupBy";
	id?: Maybe<Array<Maybe<MantraConnectionId>>>;
	_id?: Maybe<Array<Maybe<MantraConnection_Id>>>;
	createdAt?: Maybe<
		Array<Maybe<MantraConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<MantraConnectionUpdatedAt>>
	>;
	title?: Maybe<
		Array<Maybe<MantraConnectionTitle>>
	>;
	short_description?: Maybe<
		Array<
			Maybe<MantraConnectionShort_Description>
		>
	>;
	cover_image?: Maybe<
		Array<Maybe<MantraConnectionCover_Image>>
	>;
	reading_time?: Maybe<
		Array<Maybe<MantraConnectionReading_Time>>
	>;
	collaborator?: Maybe<
		Array<Maybe<MantraConnectionCollaborator>>
	>;
	content?: Maybe<
		Array<Maybe<MantraConnectionContent>>
	>;
	vimeo_url?: Maybe<
		Array<Maybe<MantraConnectionVimeo_Url>>
	>;
	order?: Maybe<
		Array<Maybe<MantraConnectionOrder>>
	>;
	published_at?: Maybe<
		Array<Maybe<MantraConnectionPublished_At>>
	>;
};

export type MantraInput = {
	title: Scalars["String"];
	short_description: Scalars["String"];
	cover_image?: Maybe<Scalars["ID"]>;
	reading_time?: Maybe<Scalars["String"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	content?: Maybe<Scalars["String"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Masti = {
	__typename?: "Masti";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
	short_description: Scalars["String"];
	cover_image?: Maybe<UploadFile>;
	reading_time?: Maybe<Scalars["String"]>;
	collaborator?: Maybe<Collaborator>;
	content?: Maybe<Scalars["String"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type MastiAggregator = {
	__typename?: "MastiAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<MastiAggregatorSum>;
	avg?: Maybe<MastiAggregatorAvg>;
	min?: Maybe<MastiAggregatorMin>;
	max?: Maybe<MastiAggregatorMax>;
};

export type MastiAggregatorAvg = {
	__typename?: "MastiAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type MastiAggregatorMax = {
	__typename?: "MastiAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type MastiAggregatorMin = {
	__typename?: "MastiAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type MastiAggregatorSum = {
	__typename?: "MastiAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type MastiConnection = {
	__typename?: "MastiConnection";
	values?: Maybe<Array<Maybe<Masti>>>;
	groupBy?: Maybe<MastiGroupBy>;
	aggregate?: Maybe<MastiAggregator>;
};

export type MastiConnectionCollaborator = {
	__typename?: "MastiConnectionCollaborator";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionContent = {
	__typename?: "MastiConnectionContent";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionCover_Image = {
	__typename?: "MastiConnectionCover_image";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionCreatedAt = {
	__typename?: "MastiConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionId = {
	__typename?: "MastiConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionOrder = {
	__typename?: "MastiConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionPublished_At = {
	__typename?: "MastiConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionReading_Time = {
	__typename?: "MastiConnectionReading_time";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionShort_Description = {
	__typename?: "MastiConnectionShort_description";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionTitle = {
	__typename?: "MastiConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionUpdatedAt = {
	__typename?: "MastiConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnectionVimeo_Url = {
	__typename?: "MastiConnectionVimeo_url";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiConnection_Id = {
	__typename?: "MastiConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MastiConnection>;
};

export type MastiGroupBy = {
	__typename?: "MastiGroupBy";
	id?: Maybe<Array<Maybe<MastiConnectionId>>>;
	_id?: Maybe<Array<Maybe<MastiConnection_Id>>>;
	createdAt?: Maybe<
		Array<Maybe<MastiConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<MastiConnectionUpdatedAt>>
	>;
	title?: Maybe<
		Array<Maybe<MastiConnectionTitle>>
	>;
	short_description?: Maybe<
		Array<Maybe<MastiConnectionShort_Description>>
	>;
	cover_image?: Maybe<
		Array<Maybe<MastiConnectionCover_Image>>
	>;
	reading_time?: Maybe<
		Array<Maybe<MastiConnectionReading_Time>>
	>;
	collaborator?: Maybe<
		Array<Maybe<MastiConnectionCollaborator>>
	>;
	content?: Maybe<
		Array<Maybe<MastiConnectionContent>>
	>;
	vimeo_url?: Maybe<
		Array<Maybe<MastiConnectionVimeo_Url>>
	>;
	order?: Maybe<
		Array<Maybe<MastiConnectionOrder>>
	>;
	published_at?: Maybe<
		Array<Maybe<MastiConnectionPublished_At>>
	>;
};

export type MastiInput = {
	title: Scalars["String"];
	short_description: Scalars["String"];
	cover_image?: Maybe<Scalars["ID"]>;
	reading_time?: Maybe<Scalars["String"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	content?: Maybe<Scalars["String"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type MealCategory = {
	__typename?: "MealCategory";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	Name: Scalars["String"];
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
	recipes?: Maybe<Array<Maybe<Recipes>>>;
};

export type MealCategoryRecipesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type MealCategoryAggregator = {
	__typename?: "MealCategoryAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<MealCategoryAggregatorSum>;
	avg?: Maybe<MealCategoryAggregatorAvg>;
	min?: Maybe<MealCategoryAggregatorMin>;
	max?: Maybe<MealCategoryAggregatorMax>;
};

export type MealCategoryAggregatorAvg = {
	__typename?: "MealCategoryAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type MealCategoryAggregatorMax = {
	__typename?: "MealCategoryAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type MealCategoryAggregatorMin = {
	__typename?: "MealCategoryAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type MealCategoryAggregatorSum = {
	__typename?: "MealCategoryAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type MealCategoryConnection = {
	__typename?: "MealCategoryConnection";
	values?: Maybe<Array<Maybe<MealCategory>>>;
	groupBy?: Maybe<MealCategoryGroupBy>;
	aggregate?: Maybe<MealCategoryAggregator>;
};

export type MealCategoryConnectionCreatedAt = {
	__typename?: "MealCategoryConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MealCategoryConnection>;
};

export type MealCategoryConnectionId = {
	__typename?: "MealCategoryConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MealCategoryConnection>;
};

export type MealCategoryConnectionName = {
	__typename?: "MealCategoryConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MealCategoryConnection>;
};

export type MealCategoryConnectionOrder = {
	__typename?: "MealCategoryConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<MealCategoryConnection>;
};

export type MealCategoryConnectionPublished_At = {
	__typename?: "MealCategoryConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MealCategoryConnection>;
};

export type MealCategoryConnectionUpdatedAt = {
	__typename?: "MealCategoryConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MealCategoryConnection>;
};

export type MealCategoryConnection_Id = {
	__typename?: "MealCategoryConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MealCategoryConnection>;
};

export type MealCategoryGroupBy = {
	__typename?: "MealCategoryGroupBy";
	id?: Maybe<
		Array<Maybe<MealCategoryConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<MealCategoryConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<MealCategoryConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<MealCategoryConnectionUpdatedAt>>
	>;
	Name?: Maybe<
		Array<Maybe<MealCategoryConnectionName>>
	>;
	order?: Maybe<
		Array<Maybe<MealCategoryConnectionOrder>>
	>;
	published_at?: Maybe<
		Array<
			Maybe<MealCategoryConnectionPublished_At>
		>
	>;
};

export type MealCategoryInput = {
	Name: Scalars["String"];
	order?: Maybe<Scalars["Float"]>;
	recipes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Meditation = {
	__typename?: "Meditation";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
	thumbnail?: Maybe<UploadFile>;
	duration: Scalars["String"];
	content: Scalars["String"];
	meditation_category?: Maybe<MeditationCategory>;
	collaborator?: Maybe<Collaborator>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type MeditationAggregator = {
	__typename?: "MeditationAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<MeditationAggregatorSum>;
	avg?: Maybe<MeditationAggregatorAvg>;
	min?: Maybe<MeditationAggregatorMin>;
	max?: Maybe<MeditationAggregatorMax>;
};

export type MeditationAggregatorAvg = {
	__typename?: "MeditationAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type MeditationAggregatorMax = {
	__typename?: "MeditationAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type MeditationAggregatorMin = {
	__typename?: "MeditationAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type MeditationAggregatorSum = {
	__typename?: "MeditationAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type MeditationCategory = {
	__typename?: "MeditationCategory";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	name: Scalars["String"];
	image?: Maybe<UploadFile>;
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type MeditationCategoryAggregator = {
	__typename?: "MeditationCategoryAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<MeditationCategoryAggregatorSum>;
	avg?: Maybe<MeditationCategoryAggregatorAvg>;
	min?: Maybe<MeditationCategoryAggregatorMin>;
	max?: Maybe<MeditationCategoryAggregatorMax>;
};

export type MeditationCategoryAggregatorAvg = {
	__typename?: "MeditationCategoryAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type MeditationCategoryAggregatorMax = {
	__typename?: "MeditationCategoryAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type MeditationCategoryAggregatorMin = {
	__typename?: "MeditationCategoryAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type MeditationCategoryAggregatorSum = {
	__typename?: "MeditationCategoryAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type MeditationCategoryConnection = {
	__typename?: "MeditationCategoryConnection";
	values?: Maybe<
		Array<Maybe<MeditationCategory>>
	>;
	groupBy?: Maybe<MeditationCategoryGroupBy>;
	aggregate?: Maybe<MeditationCategoryAggregator>;
};

export type MeditationCategoryConnectionCreatedAt =
	{
		__typename?: "MeditationCategoryConnectionCreatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<MeditationCategoryConnection>;
	};

export type MeditationCategoryConnectionId = {
	__typename?: "MeditationCategoryConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MeditationCategoryConnection>;
};

export type MeditationCategoryConnectionImage = {
	__typename?: "MeditationCategoryConnectionImage";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MeditationCategoryConnection>;
};

export type MeditationCategoryConnectionName = {
	__typename?: "MeditationCategoryConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MeditationCategoryConnection>;
};

export type MeditationCategoryConnectionOrder = {
	__typename?: "MeditationCategoryConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<MeditationCategoryConnection>;
};

export type MeditationCategoryConnectionPublished_At =
	{
		__typename?: "MeditationCategoryConnectionPublished_at";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<MeditationCategoryConnection>;
	};

export type MeditationCategoryConnectionUpdatedAt =
	{
		__typename?: "MeditationCategoryConnectionUpdatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<MeditationCategoryConnection>;
	};

export type MeditationCategoryConnection_Id = {
	__typename?: "MeditationCategoryConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MeditationCategoryConnection>;
};

export type MeditationCategoryGroupBy = {
	__typename?: "MeditationCategoryGroupBy";
	id?: Maybe<
		Array<Maybe<MeditationCategoryConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<MeditationCategoryConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<
			Maybe<MeditationCategoryConnectionCreatedAt>
		>
	>;
	updatedAt?: Maybe<
		Array<
			Maybe<MeditationCategoryConnectionUpdatedAt>
		>
	>;
	name?: Maybe<
		Array<Maybe<MeditationCategoryConnectionName>>
	>;
	image?: Maybe<
		Array<
			Maybe<MeditationCategoryConnectionImage>
		>
	>;
	order?: Maybe<
		Array<
			Maybe<MeditationCategoryConnectionOrder>
		>
	>;
	published_at?: Maybe<
		Array<
			Maybe<MeditationCategoryConnectionPublished_At>
		>
	>;
};

export type MeditationCategoryInput = {
	name: Scalars["String"];
	image?: Maybe<Scalars["ID"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type MeditationConnection = {
	__typename?: "MeditationConnection";
	values?: Maybe<Array<Maybe<Meditation>>>;
	groupBy?: Maybe<MeditationGroupBy>;
	aggregate?: Maybe<MeditationAggregator>;
};

export type MeditationConnectionCollaborator = {
	__typename?: "MeditationConnectionCollaborator";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionContent = {
	__typename?: "MeditationConnectionContent";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionCreatedAt = {
	__typename?: "MeditationConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionDuration = {
	__typename?: "MeditationConnectionDuration";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionId = {
	__typename?: "MeditationConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionMeditation_Category =
	{
		__typename?: "MeditationConnectionMeditation_category";
		key?: Maybe<Scalars["ID"]>;
		connection?: Maybe<MeditationConnection>;
	};

export type MeditationConnectionOrder = {
	__typename?: "MeditationConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionPublished_At = {
	__typename?: "MeditationConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionThumbnail = {
	__typename?: "MeditationConnectionThumbnail";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionTitle = {
	__typename?: "MeditationConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionUpdatedAt = {
	__typename?: "MeditationConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnectionVimeo_Url = {
	__typename?: "MeditationConnectionVimeo_url";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationConnection_Id = {
	__typename?: "MeditationConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MeditationConnection>;
};

export type MeditationGroupBy = {
	__typename?: "MeditationGroupBy";
	id?: Maybe<
		Array<Maybe<MeditationConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<MeditationConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<MeditationConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<MeditationConnectionUpdatedAt>>
	>;
	title?: Maybe<
		Array<Maybe<MeditationConnectionTitle>>
	>;
	thumbnail?: Maybe<
		Array<Maybe<MeditationConnectionThumbnail>>
	>;
	duration?: Maybe<
		Array<Maybe<MeditationConnectionDuration>>
	>;
	content?: Maybe<
		Array<Maybe<MeditationConnectionContent>>
	>;
	meditation_category?: Maybe<
		Array<
			Maybe<MeditationConnectionMeditation_Category>
		>
	>;
	collaborator?: Maybe<
		Array<Maybe<MeditationConnectionCollaborator>>
	>;
	vimeo_url?: Maybe<
		Array<Maybe<MeditationConnectionVimeo_Url>>
	>;
	order?: Maybe<
		Array<Maybe<MeditationConnectionOrder>>
	>;
	published_at?: Maybe<
		Array<Maybe<MeditationConnectionPublished_At>>
	>;
};

export type MeditationInput = {
	title: Scalars["String"];
	thumbnail?: Maybe<Scalars["ID"]>;
	duration: Scalars["String"];
	content: Scalars["String"];
	meditation_category?: Maybe<Scalars["ID"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Morph =
	| UsersPermissionsMe
	| UsersPermissionsMeRole
	| UsersPermissionsLoginPayload
	| UserPermissionsPasswordPayload
	| UsersPermissionsMe1
	| UsersPermissionsLoginPayload1
	| PlanDetails
	| CountryList
	| StateList
	| CityList
	| TimeZone
	| CityListWithTimezoneList
	| GroceryListCustom
	| CaloriesDataType
	| ListWeeklyPlannerEventsData
	| WeeklyPlannerEventWeekCaloriesData
	| BlogCategory
	| BlogCategoryConnection
	| BlogCategoryAggregator
	| BlogCategoryGroupBy
	| BlogCategoryConnectionId
	| BlogCategoryConnection_Id
	| BlogCategoryConnectionCreatedAt
	| BlogCategoryConnectionUpdatedAt
	| BlogCategoryConnectionTitle
	| BlogCategoryConnectionPublished_At
	| CreateBlogCategoryPayload
	| UpdateBlogCategoryPayload
	| DeleteBlogCategoryPayload
	| Blog
	| BlogConnection
	| BlogAggregator
	| BlogAggregatorSum
	| BlogAggregatorAvg
	| BlogAggregatorMin
	| BlogAggregatorMax
	| BlogGroupBy
	| BlogConnectionId
	| BlogConnection_Id
	| BlogConnectionCreatedAt
	| BlogConnectionUpdatedAt
	| BlogConnectionTitle
	| BlogConnectionBanner_Image
	| BlogConnectionContent
	| BlogConnectionCollaborator
	| BlogConnectionBlog_Category
	| BlogConnectionShort_Description
	| BlogConnectionOrder
	| BlogConnectionPublished_At
	| CreateBlogPayload
	| UpdateBlogPayload
	| DeleteBlogPayload
	| Collaborator
	| CollaboratorConnection
	| CollaboratorAggregator
	| CollaboratorAggregatorSum
	| CollaboratorAggregatorAvg
	| CollaboratorAggregatorMin
	| CollaboratorAggregatorMax
	| CollaboratorGroupBy
	| CollaboratorConnectionId
	| CollaboratorConnection_Id
	| CollaboratorConnectionCreatedAt
	| CollaboratorConnectionUpdatedAt
	| CollaboratorConnectionName
	| CollaboratorConnectionOccupation
	| CollaboratorConnectionLocation
	| CollaboratorConnectionExpertise
	| CollaboratorConnectionWhy
	| CollaboratorConnectionImage
	| CollaboratorConnectionExpertise_Rich
	| CollaboratorConnectionWhy_Rich
	| CollaboratorConnectionOrder
	| CreateCollaboratorPayload
	| UpdateCollaboratorPayload
	| DeleteCollaboratorPayload
	| ContactQuery
	| ContactQueryConnection
	| ContactQueryAggregator
	| ContactQueryGroupBy
	| ContactQueryConnectionId
	| ContactQueryConnection_Id
	| ContactQueryConnectionCreatedAt
	| ContactQueryConnectionUpdatedAt
	| ContactQueryConnectionName
	| ContactQueryConnectionEmail
	| ContactQueryConnectionPhone
	| ContactQueryConnectionType
	| ContactQueryConnectionMessage
	| ContactQueryConnectionExpertise
	| CreateContactQueryPayload
	| UpdateContactQueryPayload
	| DeleteContactQueryPayload
	| DailyQuote
	| DailyQuoteConnection
	| DailyQuoteAggregator
	| DailyQuoteGroupBy
	| DailyQuoteConnectionId
	| DailyQuoteConnection_Id
	| DailyQuoteConnectionCreatedAt
	| DailyQuoteConnectionUpdatedAt
	| DailyQuoteConnectionQuote
	| DailyQuoteConnectionAuthor
	| DailyQuoteConnectionPublished_At
	| CreateDailyQuotePayload
	| UpdateDailyQuotePayload
	| DeleteDailyQuotePayload
	| FoodIndex
	| FoodIndexConnection
	| FoodIndexAggregator
	| FoodIndexGroupBy
	| FoodIndexConnectionId
	| FoodIndexConnection_Id
	| FoodIndexConnectionCreatedAt
	| FoodIndexConnectionUpdatedAt
	| FoodIndexConnectionName
	| FoodIndexConnectionImage
	| FoodIndexConnectionInsights
	| FoodIndexConnectionQty
	| FoodIndexConnectionValueDisclaimer
	| FoodIndexConnectionPublished_At
	| CreateFoodIndexPayload
	| UpdateFoodIndexPayload
	| DeleteFoodIndexPayload
	| GroceryList
	| GroceryListConnection
	| GroceryListAggregator
	| GroceryListGroupBy
	| GroceryListConnectionId
	| GroceryListConnection_Id
	| GroceryListConnectionCreatedAt
	| GroceryListConnectionUpdatedAt
	| GroceryListConnectionDescription
	| GroceryListConnectionChecked
	| GroceryListConnectionCategory
	| GroceryListConnectionUser
	| CreateGroceryListPayload
	| UpdateGroceryListPayload
	| DeleteGroceryListPayload
	| JournalPrompts
	| JournalPromptsConnection
	| JournalPromptsAggregator
	| JournalPromptsAggregatorSum
	| JournalPromptsAggregatorAvg
	| JournalPromptsAggregatorMin
	| JournalPromptsAggregatorMax
	| JournalPromptsGroupBy
	| JournalPromptsConnectionId
	| JournalPromptsConnection_Id
	| JournalPromptsConnectionCreatedAt
	| JournalPromptsConnectionUpdatedAt
	| JournalPromptsConnectionDescription
	| JournalPromptsConnectionOrder
	| JournalPromptsConnectionPublished_At
	| CreateJournalPromptPayload
	| UpdateJournalPromptPayload
	| DeleteJournalPromptPayload
	| Mantra
	| MantraConnection
	| MantraAggregator
	| MantraAggregatorSum
	| MantraAggregatorAvg
	| MantraAggregatorMin
	| MantraAggregatorMax
	| MantraGroupBy
	| MantraConnectionId
	| MantraConnection_Id
	| MantraConnectionCreatedAt
	| MantraConnectionUpdatedAt
	| MantraConnectionTitle
	| MantraConnectionShort_Description
	| MantraConnectionCover_Image
	| MantraConnectionReading_Time
	| MantraConnectionCollaborator
	| MantraConnectionContent
	| MantraConnectionVimeo_Url
	| MantraConnectionOrder
	| MantraConnectionPublished_At
	| CreateMantraPayload
	| UpdateMantraPayload
	| DeleteMantraPayload
	| Masti
	| MastiConnection
	| MastiAggregator
	| MastiAggregatorSum
	| MastiAggregatorAvg
	| MastiAggregatorMin
	| MastiAggregatorMax
	| MastiGroupBy
	| MastiConnectionId
	| MastiConnection_Id
	| MastiConnectionCreatedAt
	| MastiConnectionUpdatedAt
	| MastiConnectionTitle
	| MastiConnectionShort_Description
	| MastiConnectionCover_Image
	| MastiConnectionReading_Time
	| MastiConnectionCollaborator
	| MastiConnectionContent
	| MastiConnectionVimeo_Url
	| MastiConnectionOrder
	| MastiConnectionPublished_At
	| CreateMastiPayload
	| UpdateMastiPayload
	| DeleteMastiPayload
	| MealCategory
	| MealCategoryConnection
	| MealCategoryAggregator
	| MealCategoryAggregatorSum
	| MealCategoryAggregatorAvg
	| MealCategoryAggregatorMin
	| MealCategoryAggregatorMax
	| MealCategoryGroupBy
	| MealCategoryConnectionId
	| MealCategoryConnection_Id
	| MealCategoryConnectionCreatedAt
	| MealCategoryConnectionUpdatedAt
	| MealCategoryConnectionName
	| MealCategoryConnectionOrder
	| MealCategoryConnectionPublished_At
	| CreateMealCategoryPayload
	| UpdateMealCategoryPayload
	| DeleteMealCategoryPayload
	| MeditationCategory
	| MeditationCategoryConnection
	| MeditationCategoryAggregator
	| MeditationCategoryAggregatorSum
	| MeditationCategoryAggregatorAvg
	| MeditationCategoryAggregatorMin
	| MeditationCategoryAggregatorMax
	| MeditationCategoryGroupBy
	| MeditationCategoryConnectionId
	| MeditationCategoryConnection_Id
	| MeditationCategoryConnectionCreatedAt
	| MeditationCategoryConnectionUpdatedAt
	| MeditationCategoryConnectionName
	| MeditationCategoryConnectionImage
	| MeditationCategoryConnectionOrder
	| MeditationCategoryConnectionPublished_At
	| CreateMeditationCategoryPayload
	| UpdateMeditationCategoryPayload
	| DeleteMeditationCategoryPayload
	| Meditation
	| MeditationConnection
	| MeditationAggregator
	| MeditationAggregatorSum
	| MeditationAggregatorAvg
	| MeditationAggregatorMin
	| MeditationAggregatorMax
	| MeditationGroupBy
	| MeditationConnectionId
	| MeditationConnection_Id
	| MeditationConnectionCreatedAt
	| MeditationConnectionUpdatedAt
	| MeditationConnectionTitle
	| MeditationConnectionThumbnail
	| MeditationConnectionDuration
	| MeditationConnectionContent
	| MeditationConnectionMeditation_Category
	| MeditationConnectionCollaborator
	| MeditationConnectionVimeo_Url
	| MeditationConnectionOrder
	| MeditationConnectionPublished_At
	| CreateMeditationPayload
	| UpdateMeditationPayload
	| DeleteMeditationPayload
	| MovementCategory
	| MovementCategoryConnection
	| MovementCategoryAggregator
	| MovementCategoryGroupBy
	| MovementCategoryConnectionId
	| MovementCategoryConnection_Id
	| MovementCategoryConnectionCreatedAt
	| MovementCategoryConnectionUpdatedAt
	| MovementCategoryConnectionName
	| MovementCategoryConnectionImage
	| MovementCategoryConnectionPublished_At
	| CreateMovementCategoryPayload
	| UpdateMovementCategoryPayload
	| DeleteMovementCategoryPayload
	| Movement
	| MovementConnection
	| MovementAggregator
	| MovementAggregatorSum
	| MovementAggregatorAvg
	| MovementAggregatorMin
	| MovementAggregatorMax
	| MovementGroupBy
	| MovementConnectionId
	| MovementConnection_Id
	| MovementConnectionCreatedAt
	| MovementConnectionUpdatedAt
	| MovementConnectionTitle
	| MovementConnectionThumbnail
	| MovementConnectionDuration
	| MovementConnectionCalories_Burn
	| MovementConnectionContent
	| MovementConnectionMovement_Category
	| MovementConnectionCollaborator
	| MovementConnectionVimeo_Url
	| MovementConnectionOrder
	| MovementConnectionPublished_At
	| CreateMovementPayload
	| UpdateMovementPayload
	| DeleteMovementPayload
	| Newsletter
	| NewsletterConnection
	| NewsletterAggregator
	| NewsletterGroupBy
	| NewsletterConnectionId
	| NewsletterConnection_Id
	| NewsletterConnectionCreatedAt
	| NewsletterConnectionUpdatedAt
	| CreateNewsletterPayload
	| UpdateNewsletterPayload
	| DeleteNewsletterPayload
	| PremiumBlogCategory
	| PremiumBlogCategoryConnection
	| PremiumBlogCategoryAggregator
	| PremiumBlogCategoryGroupBy
	| PremiumBlogCategoryConnectionId
	| PremiumBlogCategoryConnection_Id
	| PremiumBlogCategoryConnectionCreatedAt
	| PremiumBlogCategoryConnectionUpdatedAt
	| PremiumBlogCategoryConnectionTitle
	| CreatePremiumBlogCategoryPayload
	| UpdatePremiumBlogCategoryPayload
	| DeletePremiumBlogCategoryPayload
	| PremiumBlog
	| PremiumBlogConnection
	| PremiumBlogAggregator
	| PremiumBlogAggregatorSum
	| PremiumBlogAggregatorAvg
	| PremiumBlogAggregatorMin
	| PremiumBlogAggregatorMax
	| PremiumBlogGroupBy
	| PremiumBlogConnectionId
	| PremiumBlogConnection_Id
	| PremiumBlogConnectionCreatedAt
	| PremiumBlogConnectionUpdatedAt
	| PremiumBlogConnectionTitle
	| PremiumBlogConnectionBanner_Image
	| PremiumBlogConnectionContent
	| PremiumBlogConnectionCollaborator
	| PremiumBlogConnectionPremium_Blog_Category
	| PremiumBlogConnectionShort_Description
	| PremiumBlogConnectionOrder
	| PremiumBlogConnectionPublished_At
	| CreatePremiumBlogPayload
	| UpdatePremiumBlogPayload
	| DeletePremiumBlogPayload
	| Priority
	| PriorityConnection
	| PriorityAggregator
	| PriorityAggregatorSum
	| PriorityAggregatorAvg
	| PriorityAggregatorMin
	| PriorityAggregatorMax
	| PriorityGroupBy
	| PriorityConnectionId
	| PriorityConnection_Id
	| PriorityConnectionCreatedAt
	| PriorityConnectionUpdatedAt
	| PriorityConnectionTitle
	| PriorityConnectionDescription
	| PriorityConnectionCategory
	| PriorityConnectionOrder
	| CreatePriorityPayload
	| UpdatePriorityPayload
	| DeletePriorityPayload
	| Recipes
	| RecipesConnection
	| RecipesAggregator
	| RecipesAggregatorSum
	| RecipesAggregatorAvg
	| RecipesAggregatorMin
	| RecipesAggregatorMax
	| RecipesGroupBy
	| RecipesConnectionId
	| RecipesConnection_Id
	| RecipesConnectionCreatedAt
	| RecipesConnectionUpdatedAt
	| RecipesConnectionSlideShow
	| RecipesConnectionPrep_Duration
	| RecipesConnectionCook_Duration
	| RecipesConnectionNo_Of_Servings
	| RecipesConnectionIngredients
	| RecipesConnectionInsights
	| RecipesConnectionInstructions
	| RecipesConnectionCalories
	| RecipesConnectionTitle
	| RecipesConnectionCollaborator
	| RecipesConnectionThumbnail
	| RecipesConnectionKeywords
	| RecipesConnectionOrder
	| RecipesConnectionValueDisclaimer
	| RecipesConnectionInsightNotes
	| RecipesConnectionPublished_At
	| CreateRecipePayload
	| UpdateRecipePayload
	| DeleteRecipePayload
	| Stripevent
	| StripeventConnection
	| StripeventAggregator
	| StripeventGroupBy
	| StripeventConnectionId
	| StripeventConnection_Id
	| StripeventConnectionCreatedAt
	| StripeventConnectionUpdatedAt
	| CreateStripeventPayload
	| UpdateStripeventPayload
	| DeleteStripeventPayload
	| SubscriptionPlans
	| SubscriptionPlansConnection
	| SubscriptionPlansAggregator
	| SubscriptionPlansAggregatorSum
	| SubscriptionPlansAggregatorAvg
	| SubscriptionPlansAggregatorMin
	| SubscriptionPlansAggregatorMax
	| SubscriptionPlansGroupBy
	| SubscriptionPlansConnectionId
	| SubscriptionPlansConnection_Id
	| SubscriptionPlansConnectionCreatedAt
	| SubscriptionPlansConnectionUpdatedAt
	| SubscriptionPlansConnectionPlantitle
	| SubscriptionPlansConnectionStripepriceid
	| SubscriptionPlansConnectionPrice
	| SubscriptionPlansConnectionDuration
	| SubscriptionPlansConnectionPublished_At
	| CreateSubscriptionPlanPayload
	| UpdateSubscriptionPlanPayload
	| DeleteSubscriptionPlanPayload
	| TrialDuration
	| UpdateTrialDurationPayload
	| DeleteTrialDurationPayload
	| UserPriority
	| UserPriorityConnection
	| UserPriorityAggregator
	| UserPriorityGroupBy
	| UserPriorityConnectionId
	| UserPriorityConnection_Id
	| UserPriorityConnectionCreatedAt
	| UserPriorityConnectionUpdatedAt
	| UserPriorityConnectionUser
	| CreateUserPriorityPayload
	| UpdateUserPriorityPayload
	| DeleteUserPriorityPayload
	| WeeklyPlanner
	| WeeklyPlannerConnection
	| WeeklyPlannerAggregator
	| WeeklyPlannerGroupBy
	| WeeklyPlannerConnectionId
	| WeeklyPlannerConnection_Id
	| WeeklyPlannerConnectionCreatedAt
	| WeeklyPlannerConnectionUpdatedAt
	| WeeklyPlannerConnectionType
	| WeeklyPlannerConnectionDate
	| WeeklyPlannerConnectionSlot
	| WeeklyPlannerConnectionRecipe
	| WeeklyPlannerConnectionMovement
	| WeeklyPlannerConnectionMeditation
	| WeeklyPlannerConnectionMantra
	| WeeklyPlannerConnectionMasti
	| WeeklyPlannerConnectionUser
	| WeeklyPlannerConnectionPublished_At
	| CreateWeeklyPlannerPayload
	| UpdateWeeklyPlannerPayload
	| DeleteWeeklyPlannerPayload
	| I18NLocale
	| UploadFile
	| UploadFileConnection
	| UploadFileAggregator
	| UploadFileAggregatorSum
	| UploadFileAggregatorAvg
	| UploadFileAggregatorMin
	| UploadFileAggregatorMax
	| UploadFileGroupBy
	| UploadFileConnectionId
	| UploadFileConnection_Id
	| UploadFileConnectionCreatedAt
	| UploadFileConnectionUpdatedAt
	| UploadFileConnectionName
	| UploadFileConnectionAlternativeText
	| UploadFileConnectionCaption
	| UploadFileConnectionWidth
	| UploadFileConnectionHeight
	| UploadFileConnectionFormats
	| UploadFileConnectionHash
	| UploadFileConnectionExt
	| UploadFileConnectionMime
	| UploadFileConnectionSize
	| UploadFileConnectionUrl
	| UploadFileConnectionPreviewUrl
	| UploadFileConnectionProvider
	| UploadFileConnectionProvider_Metadata
	| DeleteFilePayload
	| UsersPermissionsPermission
	| UsersPermissionsRole
	| UsersPermissionsRoleConnection
	| UsersPermissionsRoleAggregator
	| UsersPermissionsRoleGroupBy
	| UsersPermissionsRoleConnectionId
	| UsersPermissionsRoleConnection_Id
	| UsersPermissionsRoleConnectionName
	| UsersPermissionsRoleConnectionDescription
	| UsersPermissionsRoleConnectionType
	| CreateRolePayload
	| UpdateRolePayload
	| DeleteRolePayload
	| UsersPermissionsUser
	| UsersPermissionsUserConnection
	| UsersPermissionsUserAggregator
	| UsersPermissionsUserAggregatorSum
	| UsersPermissionsUserAggregatorAvg
	| UsersPermissionsUserAggregatorMin
	| UsersPermissionsUserAggregatorMax
	| UsersPermissionsUserGroupBy
	| UsersPermissionsUserConnectionId
	| UsersPermissionsUserConnection_Id
	| UsersPermissionsUserConnectionCreatedAt
	| UsersPermissionsUserConnectionUpdatedAt
	| UsersPermissionsUserConnectionUsername
	| UsersPermissionsUserConnectionEmail
	| UsersPermissionsUserConnectionProvider
	| UsersPermissionsUserConnectionConfirmed
	| UsersPermissionsUserConnectionBlocked
	| UsersPermissionsUserConnectionRole
	| UsersPermissionsUserConnectionName
	| UsersPermissionsUserConnectionAvatar
	| UsersPermissionsUserConnectionDob
	| UsersPermissionsUserConnectionCountry
	| UsersPermissionsUserConnectionCity
	| UsersPermissionsUserConnectionTimezone
	| UsersPermissionsUserConnectionState
	| UsersPermissionsUserConnectionBio
	| UsersPermissionsUserConnectionStripeid
	| UsersPermissionsUserConnectionWhat
	| UsersPermissionsUserConnectionWhy
	| UsersPermissionsUserConnectionTrialDays
	| UsersPermissionsUserConnectionSubEnd
	| UsersPermissionsUserConnectionSubPlanId
	| UsersPermissionsUserConnectionSubStatus
	| UsersPermissionsUserConnectionUser_Priority
	| UsersPermissionsUserConnectionProfession
	| UsersPermissionsUserConnectionInterests
	| CreateUserPayload
	| UpdateUserPayload
	| DeleteUserPayload
	| ComponentLinksLinks
	| ComponentMealsAyurvedaInsight
	| ComponentMealsDuration
	| ComponentMealsIngredientsWithImages
	| ComponentMealsIngredientsWithTitle
	| ComponentMealsIngredients
	| ComponentMealsInsights
	| ComponentMealsInstructions
	| ComponentMealsNutritionFacts
	| ComponentMealsNutritionalInsight
	| ComponentMealsRecipes
	| ComponentMealsSteps
	| ComponentMealsSubNutritionFacts
	| ComponentMealsTags
	| ComponentPrioritiesPriorities;

export type Movement = {
	__typename?: "Movement";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
	thumbnail?: Maybe<UploadFile>;
	duration: Scalars["String"];
	calories_burn: Scalars["Int"];
	content: Scalars["String"];
	movement_category?: Maybe<MovementCategory>;
	collaborator?: Maybe<Collaborator>;
	vimeo_url: Scalars["String"];
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type MovementAggregator = {
	__typename?: "MovementAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<MovementAggregatorSum>;
	avg?: Maybe<MovementAggregatorAvg>;
	min?: Maybe<MovementAggregatorMin>;
	max?: Maybe<MovementAggregatorMax>;
};

export type MovementAggregatorAvg = {
	__typename?: "MovementAggregatorAvg";
	calories_burn?: Maybe<Scalars["Float"]>;
	order?: Maybe<Scalars["Float"]>;
};

export type MovementAggregatorMax = {
	__typename?: "MovementAggregatorMax";
	calories_burn?: Maybe<Scalars["Float"]>;
	order?: Maybe<Scalars["Float"]>;
};

export type MovementAggregatorMin = {
	__typename?: "MovementAggregatorMin";
	calories_burn?: Maybe<Scalars["Float"]>;
	order?: Maybe<Scalars["Float"]>;
};

export type MovementAggregatorSum = {
	__typename?: "MovementAggregatorSum";
	calories_burn?: Maybe<Scalars["Float"]>;
	order?: Maybe<Scalars["Float"]>;
};

export type MovementCategory = {
	__typename?: "MovementCategory";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	name: Scalars["String"];
	image?: Maybe<UploadFile>;
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type MovementCategoryAggregator = {
	__typename?: "MovementCategoryAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type MovementCategoryConnection = {
	__typename?: "MovementCategoryConnection";
	values?: Maybe<Array<Maybe<MovementCategory>>>;
	groupBy?: Maybe<MovementCategoryGroupBy>;
	aggregate?: Maybe<MovementCategoryAggregator>;
};

export type MovementCategoryConnectionCreatedAt =
	{
		__typename?: "MovementCategoryConnectionCreatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<MovementCategoryConnection>;
	};

export type MovementCategoryConnectionId = {
	__typename?: "MovementCategoryConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MovementCategoryConnection>;
};

export type MovementCategoryConnectionImage = {
	__typename?: "MovementCategoryConnectionImage";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MovementCategoryConnection>;
};

export type MovementCategoryConnectionName = {
	__typename?: "MovementCategoryConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MovementCategoryConnection>;
};

export type MovementCategoryConnectionPublished_At =
	{
		__typename?: "MovementCategoryConnectionPublished_at";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<MovementCategoryConnection>;
	};

export type MovementCategoryConnectionUpdatedAt =
	{
		__typename?: "MovementCategoryConnectionUpdatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<MovementCategoryConnection>;
	};

export type MovementCategoryConnection_Id = {
	__typename?: "MovementCategoryConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MovementCategoryConnection>;
};

export type MovementCategoryGroupBy = {
	__typename?: "MovementCategoryGroupBy";
	id?: Maybe<
		Array<Maybe<MovementCategoryConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<MovementCategoryConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<
			Maybe<MovementCategoryConnectionCreatedAt>
		>
	>;
	updatedAt?: Maybe<
		Array<
			Maybe<MovementCategoryConnectionUpdatedAt>
		>
	>;
	name?: Maybe<
		Array<Maybe<MovementCategoryConnectionName>>
	>;
	image?: Maybe<
		Array<Maybe<MovementCategoryConnectionImage>>
	>;
	published_at?: Maybe<
		Array<
			Maybe<MovementCategoryConnectionPublished_At>
		>
	>;
};

export type MovementCategoryInput = {
	name: Scalars["String"];
	image?: Maybe<Scalars["ID"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type MovementConnection = {
	__typename?: "MovementConnection";
	values?: Maybe<Array<Maybe<Movement>>>;
	groupBy?: Maybe<MovementGroupBy>;
	aggregate?: Maybe<MovementAggregator>;
};

export type MovementConnectionCalories_Burn = {
	__typename?: "MovementConnectionCalories_burn";
	key?: Maybe<Scalars["Int"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionCollaborator = {
	__typename?: "MovementConnectionCollaborator";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionContent = {
	__typename?: "MovementConnectionContent";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionCreatedAt = {
	__typename?: "MovementConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionDuration = {
	__typename?: "MovementConnectionDuration";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionId = {
	__typename?: "MovementConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionMovement_Category =
	{
		__typename?: "MovementConnectionMovement_category";
		key?: Maybe<Scalars["ID"]>;
		connection?: Maybe<MovementConnection>;
	};

export type MovementConnectionOrder = {
	__typename?: "MovementConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionPublished_At = {
	__typename?: "MovementConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionThumbnail = {
	__typename?: "MovementConnectionThumbnail";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionTitle = {
	__typename?: "MovementConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionUpdatedAt = {
	__typename?: "MovementConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnectionVimeo_Url = {
	__typename?: "MovementConnectionVimeo_url";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementConnection_Id = {
	__typename?: "MovementConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<MovementConnection>;
};

export type MovementGroupBy = {
	__typename?: "MovementGroupBy";
	id?: Maybe<Array<Maybe<MovementConnectionId>>>;
	_id?: Maybe<
		Array<Maybe<MovementConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<MovementConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<MovementConnectionUpdatedAt>>
	>;
	title?: Maybe<
		Array<Maybe<MovementConnectionTitle>>
	>;
	thumbnail?: Maybe<
		Array<Maybe<MovementConnectionThumbnail>>
	>;
	duration?: Maybe<
		Array<Maybe<MovementConnectionDuration>>
	>;
	calories_burn?: Maybe<
		Array<Maybe<MovementConnectionCalories_Burn>>
	>;
	content?: Maybe<
		Array<Maybe<MovementConnectionContent>>
	>;
	movement_category?: Maybe<
		Array<
			Maybe<MovementConnectionMovement_Category>
		>
	>;
	collaborator?: Maybe<
		Array<Maybe<MovementConnectionCollaborator>>
	>;
	vimeo_url?: Maybe<
		Array<Maybe<MovementConnectionVimeo_Url>>
	>;
	order?: Maybe<
		Array<Maybe<MovementConnectionOrder>>
	>;
	published_at?: Maybe<
		Array<Maybe<MovementConnectionPublished_At>>
	>;
};

export type MovementInput = {
	title: Scalars["String"];
	thumbnail?: Maybe<Scalars["ID"]>;
	duration: Scalars["String"];
	calories_burn: Scalars["Int"];
	content: Scalars["String"];
	movement_category?: Maybe<Scalars["ID"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	vimeo_url: Scalars["String"];
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Mutation = {
	__typename?: "Mutation";
	createBlogCategory?: Maybe<CreateBlogCategoryPayload>;
	updateBlogCategory?: Maybe<UpdateBlogCategoryPayload>;
	deleteBlogCategory?: Maybe<DeleteBlogCategoryPayload>;
	createBlog?: Maybe<CreateBlogPayload>;
	updateBlog?: Maybe<UpdateBlogPayload>;
	deleteBlog?: Maybe<DeleteBlogPayload>;
	createCollaborator?: Maybe<CreateCollaboratorPayload>;
	updateCollaborator?: Maybe<UpdateCollaboratorPayload>;
	deleteCollaborator?: Maybe<DeleteCollaboratorPayload>;
	createContactQuery?: Maybe<CreateContactQueryPayload>;
	updateContactQuery?: Maybe<UpdateContactQueryPayload>;
	deleteContactQuery?: Maybe<DeleteContactQueryPayload>;
	createDailyQuote?: Maybe<CreateDailyQuotePayload>;
	updateDailyQuote?: Maybe<UpdateDailyQuotePayload>;
	deleteDailyQuote?: Maybe<DeleteDailyQuotePayload>;
	createFoodIndex?: Maybe<CreateFoodIndexPayload>;
	updateFoodIndex?: Maybe<UpdateFoodIndexPayload>;
	deleteFoodIndex?: Maybe<DeleteFoodIndexPayload>;
	createGroceryList?: Maybe<CreateGroceryListPayload>;
	updateGroceryList?: Maybe<UpdateGroceryListPayload>;
	deleteGroceryList?: Maybe<DeleteGroceryListPayload>;
	createJournalPrompt?: Maybe<CreateJournalPromptPayload>;
	updateJournalPrompt?: Maybe<UpdateJournalPromptPayload>;
	deleteJournalPrompt?: Maybe<DeleteJournalPromptPayload>;
	createMantra?: Maybe<CreateMantraPayload>;
	updateMantra?: Maybe<UpdateMantraPayload>;
	deleteMantra?: Maybe<DeleteMantraPayload>;
	createMasti?: Maybe<CreateMastiPayload>;
	updateMasti?: Maybe<UpdateMastiPayload>;
	deleteMasti?: Maybe<DeleteMastiPayload>;
	createMealCategory?: Maybe<CreateMealCategoryPayload>;
	updateMealCategory?: Maybe<UpdateMealCategoryPayload>;
	deleteMealCategory?: Maybe<DeleteMealCategoryPayload>;
	createMeditationCategory?: Maybe<CreateMeditationCategoryPayload>;
	updateMeditationCategory?: Maybe<UpdateMeditationCategoryPayload>;
	deleteMeditationCategory?: Maybe<DeleteMeditationCategoryPayload>;
	createMeditation?: Maybe<CreateMeditationPayload>;
	updateMeditation?: Maybe<UpdateMeditationPayload>;
	deleteMeditation?: Maybe<DeleteMeditationPayload>;
	createMovementCategory?: Maybe<CreateMovementCategoryPayload>;
	updateMovementCategory?: Maybe<UpdateMovementCategoryPayload>;
	deleteMovementCategory?: Maybe<DeleteMovementCategoryPayload>;
	createMovement?: Maybe<CreateMovementPayload>;
	updateMovement?: Maybe<UpdateMovementPayload>;
	deleteMovement?: Maybe<DeleteMovementPayload>;
	createNewsletter?: Maybe<CreateNewsletterPayload>;
	updateNewsletter?: Maybe<UpdateNewsletterPayload>;
	deleteNewsletter?: Maybe<DeleteNewsletterPayload>;
	createPremiumBlogCategory?: Maybe<CreatePremiumBlogCategoryPayload>;
	updatePremiumBlogCategory?: Maybe<UpdatePremiumBlogCategoryPayload>;
	deletePremiumBlogCategory?: Maybe<DeletePremiumBlogCategoryPayload>;
	createPremiumBlog?: Maybe<CreatePremiumBlogPayload>;
	updatePremiumBlog?: Maybe<UpdatePremiumBlogPayload>;
	deletePremiumBlog?: Maybe<DeletePremiumBlogPayload>;
	createPriority?: Maybe<CreatePriorityPayload>;
	updatePriority?: Maybe<UpdatePriorityPayload>;
	deletePriority?: Maybe<DeletePriorityPayload>;
	createRecipe?: Maybe<CreateRecipePayload>;
	updateRecipe?: Maybe<UpdateRecipePayload>;
	deleteRecipe?: Maybe<DeleteRecipePayload>;
	createStripevent?: Maybe<CreateStripeventPayload>;
	updateStripevent?: Maybe<UpdateStripeventPayload>;
	deleteStripevent?: Maybe<DeleteStripeventPayload>;
	createSubscriptionPlan?: Maybe<CreateSubscriptionPlanPayload>;
	updateSubscriptionPlan?: Maybe<UpdateSubscriptionPlanPayload>;
	deleteSubscriptionPlan?: Maybe<DeleteSubscriptionPlanPayload>;
	updateTrialDuration?: Maybe<UpdateTrialDurationPayload>;
	deleteTrialDuration?: Maybe<DeleteTrialDurationPayload>;
	createUserPriority?: Maybe<CreateUserPriorityPayload>;
	updateUserPriority?: Maybe<UpdateUserPriorityPayload>;
	deleteUserPriority?: Maybe<DeleteUserPriorityPayload>;
	createWeeklyPlanner?: Maybe<CreateWeeklyPlannerPayload>;
	updateWeeklyPlanner?: Maybe<UpdateWeeklyPlannerPayload>;
	deleteWeeklyPlanner?: Maybe<DeleteWeeklyPlannerPayload>;
	/** Delete one file */
	deleteFile?: Maybe<DeleteFilePayload>;
	/** Create a new role */
	createRole?: Maybe<CreateRolePayload>;
	/** Update an existing role */
	updateRole?: Maybe<UpdateRolePayload>;
	/** Delete an existing role */
	deleteRole?: Maybe<DeleteRolePayload>;
	/** Create a new user */
	createUser?: Maybe<CreateUserPayload>;
	/** Update an existing user */
	updateUser?: Maybe<UpdateUserPayload>;
	/** Delete an existing user */
	deleteUser?: Maybe<DeleteUserPayload>;
	upload: UploadFile;
	multipleUpload: Array<Maybe<UploadFile>>;
	updateFileInfo: UploadFile;
	login: UsersPermissionsLoginPayload;
	register: UsersPermissionsLoginPayload;
	forgotPassword?: Maybe<UserPermissionsPasswordPayload>;
	resetPassword?: Maybe<UsersPermissionsLoginPayload>;
	emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
	newRegister: UsersPermissionsLoginPayload1;
	subscribePlan: PlanDetails;
	states?: Maybe<Array<StateList>>;
	cities: CityListWithTimezoneList;
	createMyListItem?: Maybe<
		Array<GroceryListCustom>
	>;
	updateMyListItem?: Maybe<
		Array<GroceryListCustom>
	>;
	deleteMyListItem?: Maybe<
		Array<GroceryListCustom>
	>;
	deleteMyListItemByCategory?: Maybe<
		Array<GroceryListCustom>
	>;
	checkedMyListItem?: Maybe<
		Array<GroceryListCustom>
	>;
	subscribe: Scalars["String"];
	updateMyPriorities: UserPriority;
	createWeeklyPlannerEvent?: Maybe<WeeklyPlanner>;
	deleteWeeklyPlannerEvent?: Maybe<
		Scalars["Boolean"]
	>;
};

export type MutationCreateBlogCategoryArgs = {
	input?: Maybe<CreateBlogCategoryInput>;
};

export type MutationUpdateBlogCategoryArgs = {
	input?: Maybe<UpdateBlogCategoryInput>;
};

export type MutationDeleteBlogCategoryArgs = {
	input?: Maybe<DeleteBlogCategoryInput>;
};

export type MutationCreateBlogArgs = {
	input?: Maybe<CreateBlogInput>;
};

export type MutationUpdateBlogArgs = {
	input?: Maybe<UpdateBlogInput>;
};

export type MutationDeleteBlogArgs = {
	input?: Maybe<DeleteBlogInput>;
};

export type MutationCreateCollaboratorArgs = {
	input?: Maybe<CreateCollaboratorInput>;
};

export type MutationUpdateCollaboratorArgs = {
	input?: Maybe<UpdateCollaboratorInput>;
};

export type MutationDeleteCollaboratorArgs = {
	input?: Maybe<DeleteCollaboratorInput>;
};

export type MutationCreateContactQueryArgs = {
	input?: Maybe<CreateContactQueryInput>;
};

export type MutationUpdateContactQueryArgs = {
	input?: Maybe<UpdateContactQueryInput>;
};

export type MutationDeleteContactQueryArgs = {
	input?: Maybe<DeleteContactQueryInput>;
};

export type MutationCreateDailyQuoteArgs = {
	input?: Maybe<CreateDailyQuoteInput>;
};

export type MutationUpdateDailyQuoteArgs = {
	input?: Maybe<UpdateDailyQuoteInput>;
};

export type MutationDeleteDailyQuoteArgs = {
	input?: Maybe<DeleteDailyQuoteInput>;
};

export type MutationCreateFoodIndexArgs = {
	input?: Maybe<CreateFoodIndexInput>;
};

export type MutationUpdateFoodIndexArgs = {
	input?: Maybe<UpdateFoodIndexInput>;
};

export type MutationDeleteFoodIndexArgs = {
	input?: Maybe<DeleteFoodIndexInput>;
};

export type MutationCreateGroceryListArgs = {
	input?: Maybe<CreateGroceryListInput>;
};

export type MutationUpdateGroceryListArgs = {
	input?: Maybe<UpdateGroceryListInput>;
};

export type MutationDeleteGroceryListArgs = {
	input?: Maybe<DeleteGroceryListInput>;
};

export type MutationCreateJournalPromptArgs = {
	input?: Maybe<CreateJournalPromptInput>;
};

export type MutationUpdateJournalPromptArgs = {
	input?: Maybe<UpdateJournalPromptInput>;
};

export type MutationDeleteJournalPromptArgs = {
	input?: Maybe<DeleteJournalPromptInput>;
};

export type MutationCreateMantraArgs = {
	input?: Maybe<CreateMantraInput>;
};

export type MutationUpdateMantraArgs = {
	input?: Maybe<UpdateMantraInput>;
};

export type MutationDeleteMantraArgs = {
	input?: Maybe<DeleteMantraInput>;
};

export type MutationCreateMastiArgs = {
	input?: Maybe<CreateMastiInput>;
};

export type MutationUpdateMastiArgs = {
	input?: Maybe<UpdateMastiInput>;
};

export type MutationDeleteMastiArgs = {
	input?: Maybe<DeleteMastiInput>;
};

export type MutationCreateMealCategoryArgs = {
	input?: Maybe<CreateMealCategoryInput>;
};

export type MutationUpdateMealCategoryArgs = {
	input?: Maybe<UpdateMealCategoryInput>;
};

export type MutationDeleteMealCategoryArgs = {
	input?: Maybe<DeleteMealCategoryInput>;
};

export type MutationCreateMeditationCategoryArgs =
	{
		input?: Maybe<CreateMeditationCategoryInput>;
	};

export type MutationUpdateMeditationCategoryArgs =
	{
		input?: Maybe<UpdateMeditationCategoryInput>;
	};

export type MutationDeleteMeditationCategoryArgs =
	{
		input?: Maybe<DeleteMeditationCategoryInput>;
	};

export type MutationCreateMeditationArgs = {
	input?: Maybe<CreateMeditationInput>;
};

export type MutationUpdateMeditationArgs = {
	input?: Maybe<UpdateMeditationInput>;
};

export type MutationDeleteMeditationArgs = {
	input?: Maybe<DeleteMeditationInput>;
};

export type MutationCreateMovementCategoryArgs = {
	input?: Maybe<CreateMovementCategoryInput>;
};

export type MutationUpdateMovementCategoryArgs = {
	input?: Maybe<UpdateMovementCategoryInput>;
};

export type MutationDeleteMovementCategoryArgs = {
	input?: Maybe<DeleteMovementCategoryInput>;
};

export type MutationCreateMovementArgs = {
	input?: Maybe<CreateMovementInput>;
};

export type MutationUpdateMovementArgs = {
	input?: Maybe<UpdateMovementInput>;
};

export type MutationDeleteMovementArgs = {
	input?: Maybe<DeleteMovementInput>;
};

export type MutationCreateNewsletterArgs = {
	input?: Maybe<CreateNewsletterInput>;
};

export type MutationUpdateNewsletterArgs = {
	input?: Maybe<UpdateNewsletterInput>;
};

export type MutationDeleteNewsletterArgs = {
	input?: Maybe<DeleteNewsletterInput>;
};

export type MutationCreatePremiumBlogCategoryArgs =
	{
		input?: Maybe<CreatePremiumBlogCategoryInput>;
	};

export type MutationUpdatePremiumBlogCategoryArgs =
	{
		input?: Maybe<UpdatePremiumBlogCategoryInput>;
	};

export type MutationDeletePremiumBlogCategoryArgs =
	{
		input?: Maybe<DeletePremiumBlogCategoryInput>;
	};

export type MutationCreatePremiumBlogArgs = {
	input?: Maybe<CreatePremiumBlogInput>;
};

export type MutationUpdatePremiumBlogArgs = {
	input?: Maybe<UpdatePremiumBlogInput>;
};

export type MutationDeletePremiumBlogArgs = {
	input?: Maybe<DeletePremiumBlogInput>;
};

export type MutationCreatePriorityArgs = {
	input?: Maybe<CreatePriorityInput>;
};

export type MutationUpdatePriorityArgs = {
	input?: Maybe<UpdatePriorityInput>;
};

export type MutationDeletePriorityArgs = {
	input?: Maybe<DeletePriorityInput>;
};

export type MutationCreateRecipeArgs = {
	input?: Maybe<CreateRecipeInput>;
};

export type MutationUpdateRecipeArgs = {
	input?: Maybe<UpdateRecipeInput>;
};

export type MutationDeleteRecipeArgs = {
	input?: Maybe<DeleteRecipeInput>;
};

export type MutationCreateStripeventArgs = {
	input?: Maybe<CreateStripeventInput>;
};

export type MutationUpdateStripeventArgs = {
	input?: Maybe<UpdateStripeventInput>;
};

export type MutationDeleteStripeventArgs = {
	input?: Maybe<DeleteStripeventInput>;
};

export type MutationCreateSubscriptionPlanArgs = {
	input?: Maybe<CreateSubscriptionPlanInput>;
};

export type MutationUpdateSubscriptionPlanArgs = {
	input?: Maybe<UpdateSubscriptionPlanInput>;
};

export type MutationDeleteSubscriptionPlanArgs = {
	input?: Maybe<DeleteSubscriptionPlanInput>;
};

export type MutationUpdateTrialDurationArgs = {
	input?: Maybe<UpdateTrialDurationInput>;
};

export type MutationCreateUserPriorityArgs = {
	input?: Maybe<CreateUserPriorityInput>;
};

export type MutationUpdateUserPriorityArgs = {
	input?: Maybe<UpdateUserPriorityInput>;
};

export type MutationDeleteUserPriorityArgs = {
	input?: Maybe<DeleteUserPriorityInput>;
};

export type MutationCreateWeeklyPlannerArgs = {
	input?: Maybe<CreateWeeklyPlannerInput>;
};

export type MutationUpdateWeeklyPlannerArgs = {
	input?: Maybe<UpdateWeeklyPlannerInput>;
};

export type MutationDeleteWeeklyPlannerArgs = {
	input?: Maybe<DeleteWeeklyPlannerInput>;
};

export type MutationDeleteFileArgs = {
	input?: Maybe<DeleteFileInput>;
};

export type MutationCreateRoleArgs = {
	input?: Maybe<CreateRoleInput>;
};

export type MutationUpdateRoleArgs = {
	input?: Maybe<UpdateRoleInput>;
};

export type MutationDeleteRoleArgs = {
	input?: Maybe<DeleteRoleInput>;
};

export type MutationCreateUserArgs = {
	input?: Maybe<CreateUserInput>;
};

export type MutationUpdateUserArgs = {
	input?: Maybe<UpdateUserInput>;
};

export type MutationDeleteUserArgs = {
	input?: Maybe<DeleteUserInput>;
};

export type MutationUploadArgs = {
	refId?: Maybe<Scalars["ID"]>;
	ref?: Maybe<Scalars["String"]>;
	field?: Maybe<Scalars["String"]>;
	source?: Maybe<Scalars["String"]>;
	info?: Maybe<FileInfoInput>;
	file: Scalars["Upload"];
};

export type MutationMultipleUploadArgs = {
	refId?: Maybe<Scalars["ID"]>;
	ref?: Maybe<Scalars["String"]>;
	field?: Maybe<Scalars["String"]>;
	source?: Maybe<Scalars["String"]>;
	files: Array<Maybe<Scalars["Upload"]>>;
};

export type MutationUpdateFileInfoArgs = {
	id: Scalars["ID"];
	info: FileInfoInput;
};

export type MutationLoginArgs = {
	input: UsersPermissionsLoginInput;
};

export type MutationRegisterArgs = {
	input: UsersPermissionsRegisterInput;
};

export type MutationForgotPasswordArgs = {
	email: Scalars["String"];
};

export type MutationResetPasswordArgs = {
	password: Scalars["String"];
	passwordConfirmation: Scalars["String"];
	code: Scalars["String"];
};

export type MutationEmailConfirmationArgs = {
	confirmation: Scalars["String"];
};

export type MutationNewRegisterArgs = {
	input: UsersPermissionsRegisterInput1;
};

export type MutationSubscribePlanArgs = {
	input: UserSelectedPlan;
};

export type MutationStatesArgs = {
	input: CountryName;
};

export type MutationCitiesArgs = {
	input: CountryNameAndStateName;
};

export type MutationCreateMyListItemArgs = {
	input: GroceryListCustomInput;
};

export type MutationUpdateMyListItemArgs = {
	input: GroceryListCustomUpdateInput;
};

export type MutationDeleteMyListItemArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteMyListItemByCategoryArgs =
	{
		category: Scalars["String"];
	};

export type MutationCheckedMyListItemArgs = {
	input: GroceryListCustomUpdateCheckedInput;
};

export type MutationSubscribeArgs = {
	input: EmailInput;
};

export type MutationUpdateMyPrioritiesArgs = {
	input: UserPriorityUpdateInput;
};

export type MutationCreateWeeklyPlannerEventArgs =
	{
		input: WeeklyPlannerEventInput;
	};

export type MutationDeleteWeeklyPlannerEventArgs =
	{
		id: Scalars["ID"];
	};

export type Newsletter = {
	__typename?: "Newsletter";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
};

export type NewsletterAggregator = {
	__typename?: "NewsletterAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type NewsletterConnection = {
	__typename?: "NewsletterConnection";
	values?: Maybe<Array<Maybe<Newsletter>>>;
	groupBy?: Maybe<NewsletterGroupBy>;
	aggregate?: Maybe<NewsletterAggregator>;
};

export type NewsletterConnectionCreatedAt = {
	__typename?: "NewsletterConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<NewsletterConnection>;
};

export type NewsletterConnectionId = {
	__typename?: "NewsletterConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<NewsletterConnection>;
};

export type NewsletterConnectionUpdatedAt = {
	__typename?: "NewsletterConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<NewsletterConnection>;
};

export type NewsletterConnection_Id = {
	__typename?: "NewsletterConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<NewsletterConnection>;
};

export type NewsletterGroupBy = {
	__typename?: "NewsletterGroupBy";
	id?: Maybe<
		Array<Maybe<NewsletterConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<NewsletterConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<NewsletterConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<NewsletterConnectionUpdatedAt>>
	>;
};

export type NewsletterInput = {
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type PlanDetails = {
	__typename?: "PlanDetails";
	url: Scalars["String"];
};

export type PremiumBlog = {
	__typename?: "PremiumBlog";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
	banner_image?: Maybe<UploadFile>;
	content: Scalars["String"];
	collaborator?: Maybe<Collaborator>;
	premium_blog_category?: Maybe<PremiumBlogCategory>;
	short_description: Scalars["String"];
	order: Scalars["Float"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type PremiumBlogAggregator = {
	__typename?: "PremiumBlogAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<PremiumBlogAggregatorSum>;
	avg?: Maybe<PremiumBlogAggregatorAvg>;
	min?: Maybe<PremiumBlogAggregatorMin>;
	max?: Maybe<PremiumBlogAggregatorMax>;
};

export type PremiumBlogAggregatorAvg = {
	__typename?: "PremiumBlogAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type PremiumBlogAggregatorMax = {
	__typename?: "PremiumBlogAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type PremiumBlogAggregatorMin = {
	__typename?: "PremiumBlogAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type PremiumBlogAggregatorSum = {
	__typename?: "PremiumBlogAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type PremiumBlogCategory = {
	__typename?: "PremiumBlogCategory";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
};

export type PremiumBlogCategoryAggregator = {
	__typename?: "PremiumBlogCategoryAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type PremiumBlogCategoryConnection = {
	__typename?: "PremiumBlogCategoryConnection";
	values?: Maybe<
		Array<Maybe<PremiumBlogCategory>>
	>;
	groupBy?: Maybe<PremiumBlogCategoryGroupBy>;
	aggregate?: Maybe<PremiumBlogCategoryAggregator>;
};

export type PremiumBlogCategoryConnectionCreatedAt =
	{
		__typename?: "PremiumBlogCategoryConnectionCreatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<PremiumBlogCategoryConnection>;
	};

export type PremiumBlogCategoryConnectionId = {
	__typename?: "PremiumBlogCategoryConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<PremiumBlogCategoryConnection>;
};

export type PremiumBlogCategoryConnectionTitle = {
	__typename?: "PremiumBlogCategoryConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<PremiumBlogCategoryConnection>;
};

export type PremiumBlogCategoryConnectionUpdatedAt =
	{
		__typename?: "PremiumBlogCategoryConnectionUpdatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<PremiumBlogCategoryConnection>;
	};

export type PremiumBlogCategoryConnection_Id = {
	__typename?: "PremiumBlogCategoryConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<PremiumBlogCategoryConnection>;
};

export type PremiumBlogCategoryGroupBy = {
	__typename?: "PremiumBlogCategoryGroupBy";
	id?: Maybe<
		Array<Maybe<PremiumBlogCategoryConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<PremiumBlogCategoryConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<
			Maybe<PremiumBlogCategoryConnectionCreatedAt>
		>
	>;
	updatedAt?: Maybe<
		Array<
			Maybe<PremiumBlogCategoryConnectionUpdatedAt>
		>
	>;
	title?: Maybe<
		Array<
			Maybe<PremiumBlogCategoryConnectionTitle>
		>
	>;
};

export type PremiumBlogCategoryInput = {
	title: Scalars["String"];
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type PremiumBlogConnection = {
	__typename?: "PremiumBlogConnection";
	values?: Maybe<Array<Maybe<PremiumBlog>>>;
	groupBy?: Maybe<PremiumBlogGroupBy>;
	aggregate?: Maybe<PremiumBlogAggregator>;
};

export type PremiumBlogConnectionBanner_Image = {
	__typename?: "PremiumBlogConnectionBanner_image";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnectionCollaborator = {
	__typename?: "PremiumBlogConnectionCollaborator";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnectionContent = {
	__typename?: "PremiumBlogConnectionContent";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnectionCreatedAt = {
	__typename?: "PremiumBlogConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnectionId = {
	__typename?: "PremiumBlogConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnectionOrder = {
	__typename?: "PremiumBlogConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnectionPremium_Blog_Category =
	{
		__typename?: "PremiumBlogConnectionPremium_blog_category";
		key?: Maybe<Scalars["ID"]>;
		connection?: Maybe<PremiumBlogConnection>;
	};

export type PremiumBlogConnectionPublished_At = {
	__typename?: "PremiumBlogConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnectionShort_Description =
	{
		__typename?: "PremiumBlogConnectionShort_description";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<PremiumBlogConnection>;
	};

export type PremiumBlogConnectionTitle = {
	__typename?: "PremiumBlogConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnectionUpdatedAt = {
	__typename?: "PremiumBlogConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogConnection_Id = {
	__typename?: "PremiumBlogConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<PremiumBlogConnection>;
};

export type PremiumBlogGroupBy = {
	__typename?: "PremiumBlogGroupBy";
	id?: Maybe<
		Array<Maybe<PremiumBlogConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<PremiumBlogConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<PremiumBlogConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<PremiumBlogConnectionUpdatedAt>>
	>;
	title?: Maybe<
		Array<Maybe<PremiumBlogConnectionTitle>>
	>;
	banner_image?: Maybe<
		Array<
			Maybe<PremiumBlogConnectionBanner_Image>
		>
	>;
	content?: Maybe<
		Array<Maybe<PremiumBlogConnectionContent>>
	>;
	collaborator?: Maybe<
		Array<
			Maybe<PremiumBlogConnectionCollaborator>
		>
	>;
	premium_blog_category?: Maybe<
		Array<
			Maybe<PremiumBlogConnectionPremium_Blog_Category>
		>
	>;
	short_description?: Maybe<
		Array<
			Maybe<PremiumBlogConnectionShort_Description>
		>
	>;
	order?: Maybe<
		Array<Maybe<PremiumBlogConnectionOrder>>
	>;
	published_at?: Maybe<
		Array<
			Maybe<PremiumBlogConnectionPublished_At>
		>
	>;
};

export type PremiumBlogInput = {
	title: Scalars["String"];
	banner_image?: Maybe<Scalars["ID"]>;
	content: Scalars["String"];
	collaborator?: Maybe<Scalars["ID"]>;
	premium_blog_category?: Maybe<Scalars["ID"]>;
	short_description: Scalars["String"];
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Priority = {
	__typename?: "Priority";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	title: Scalars["String"];
	description: Scalars["String"];
	category?: Maybe<Enum_Priority_Category>;
	order: Scalars["Float"];
};

export type PriorityAggregator = {
	__typename?: "PriorityAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<PriorityAggregatorSum>;
	avg?: Maybe<PriorityAggregatorAvg>;
	min?: Maybe<PriorityAggregatorMin>;
	max?: Maybe<PriorityAggregatorMax>;
};

export type PriorityAggregatorAvg = {
	__typename?: "PriorityAggregatorAvg";
	order?: Maybe<Scalars["Float"]>;
};

export type PriorityAggregatorMax = {
	__typename?: "PriorityAggregatorMax";
	order?: Maybe<Scalars["Float"]>;
};

export type PriorityAggregatorMin = {
	__typename?: "PriorityAggregatorMin";
	order?: Maybe<Scalars["Float"]>;
};

export type PriorityAggregatorSum = {
	__typename?: "PriorityAggregatorSum";
	order?: Maybe<Scalars["Float"]>;
};

export type PriorityConnection = {
	__typename?: "PriorityConnection";
	values?: Maybe<Array<Maybe<Priority>>>;
	groupBy?: Maybe<PriorityGroupBy>;
	aggregate?: Maybe<PriorityAggregator>;
};

export type PriorityConnectionCategory = {
	__typename?: "PriorityConnectionCategory";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<PriorityConnection>;
};

export type PriorityConnectionCreatedAt = {
	__typename?: "PriorityConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<PriorityConnection>;
};

export type PriorityConnectionDescription = {
	__typename?: "PriorityConnectionDescription";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<PriorityConnection>;
};

export type PriorityConnectionId = {
	__typename?: "PriorityConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<PriorityConnection>;
};

export type PriorityConnectionOrder = {
	__typename?: "PriorityConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<PriorityConnection>;
};

export type PriorityConnectionTitle = {
	__typename?: "PriorityConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<PriorityConnection>;
};

export type PriorityConnectionUpdatedAt = {
	__typename?: "PriorityConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<PriorityConnection>;
};

export type PriorityConnection_Id = {
	__typename?: "PriorityConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<PriorityConnection>;
};

export type PriorityGroupBy = {
	__typename?: "PriorityGroupBy";
	id?: Maybe<Array<Maybe<PriorityConnectionId>>>;
	_id?: Maybe<
		Array<Maybe<PriorityConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<PriorityConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<PriorityConnectionUpdatedAt>>
	>;
	title?: Maybe<
		Array<Maybe<PriorityConnectionTitle>>
	>;
	description?: Maybe<
		Array<Maybe<PriorityConnectionDescription>>
	>;
	category?: Maybe<
		Array<Maybe<PriorityConnectionCategory>>
	>;
	order?: Maybe<
		Array<Maybe<PriorityConnectionOrder>>
	>;
};

export type PriorityInput = {
	title: Scalars["String"];
	description: Scalars["String"];
	category?: Maybe<Enum_Priority_Category>;
	order?: Maybe<Scalars["Float"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type PriorityUpdateInput = {
	type: Scalars["String"];
	title: Scalars["String"];
	description: Scalars["String"];
	checkin?: Maybe<Scalars["Date"]>;
};

export enum PublicationState {
	Live = "LIVE",
	Preview = "PREVIEW",
}

export type Query = {
	__typename?: "Query";
	blogCategory?: Maybe<BlogCategory>;
	blogCategories?: Maybe<
		Array<Maybe<BlogCategory>>
	>;
	blogCategoriesConnection?: Maybe<BlogCategoryConnection>;
	blog?: Maybe<Blog>;
	blogs?: Maybe<Array<Maybe<Blog>>>;
	blogsConnection?: Maybe<BlogConnection>;
	collaborator?: Maybe<Collaborator>;
	collaborators?: Maybe<
		Array<Maybe<Collaborator>>
	>;
	collaboratorsConnection?: Maybe<CollaboratorConnection>;
	contactQuery?: Maybe<ContactQuery>;
	contactQueries?: Maybe<
		Array<Maybe<ContactQuery>>
	>;
	contactQueriesConnection?: Maybe<ContactQueryConnection>;
	dailyQuote?: Maybe<DailyQuote>;
	dailyQuotes?: Maybe<Array<Maybe<DailyQuote>>>;
	dailyQuotesConnection?: Maybe<DailyQuoteConnection>;
	foodIndex?: Maybe<FoodIndex>;
	foodIndices?: Maybe<Array<Maybe<FoodIndex>>>;
	foodIndicesConnection?: Maybe<FoodIndexConnection>;
	groceryList?: Maybe<GroceryList>;
	groceryLists?: Maybe<Array<Maybe<GroceryList>>>;
	groceryListsConnection?: Maybe<GroceryListConnection>;
	journalPrompt?: Maybe<JournalPrompts>;
	journalPrompts?: Maybe<
		Array<Maybe<JournalPrompts>>
	>;
	journalPromptsConnection?: Maybe<JournalPromptsConnection>;
	mantra?: Maybe<Mantra>;
	mantras?: Maybe<Array<Maybe<Mantra>>>;
	mantrasConnection?: Maybe<MantraConnection>;
	masti?: Maybe<Masti>;
	mastis?: Maybe<Array<Maybe<Masti>>>;
	mastisConnection?: Maybe<MastiConnection>;
	mealCategory?: Maybe<MealCategory>;
	mealCategories?: Maybe<
		Array<Maybe<MealCategory>>
	>;
	mealCategoriesConnection?: Maybe<MealCategoryConnection>;
	meditationCategory?: Maybe<MeditationCategory>;
	meditationCategories?: Maybe<
		Array<Maybe<MeditationCategory>>
	>;
	meditationCategoriesConnection?: Maybe<MeditationCategoryConnection>;
	meditation?: Maybe<Meditation>;
	meditations?: Maybe<Array<Maybe<Meditation>>>;
	meditationsConnection?: Maybe<MeditationConnection>;
	movementCategory?: Maybe<MovementCategory>;
	movementCategories?: Maybe<
		Array<Maybe<MovementCategory>>
	>;
	movementCategoriesConnection?: Maybe<MovementCategoryConnection>;
	movement?: Maybe<Movement>;
	movements?: Maybe<Array<Maybe<Movement>>>;
	movementsConnection?: Maybe<MovementConnection>;
	newsletter?: Maybe<Newsletter>;
	newsletters?: Maybe<Array<Maybe<Newsletter>>>;
	newslettersConnection?: Maybe<NewsletterConnection>;
	premiumBlogCategory?: Maybe<PremiumBlogCategory>;
	premiumBlogCategories?: Maybe<
		Array<Maybe<PremiumBlogCategory>>
	>;
	premiumBlogCategoriesConnection?: Maybe<PremiumBlogCategoryConnection>;
	premiumBlog?: Maybe<PremiumBlog>;
	premiumBlogs?: Maybe<Array<Maybe<PremiumBlog>>>;
	premiumBlogsConnection?: Maybe<PremiumBlogConnection>;
	priority?: Maybe<Priority>;
	priorities?: Maybe<Array<Maybe<Priority>>>;
	prioritiesConnection?: Maybe<PriorityConnection>;
	recipe?: Maybe<Recipes>;
	recipes?: Maybe<Array<Maybe<Recipes>>>;
	recipesConnection?: Maybe<RecipesConnection>;
	stripevent?: Maybe<Stripevent>;
	stripevents?: Maybe<Array<Maybe<Stripevent>>>;
	stripeventsConnection?: Maybe<StripeventConnection>;
	subscriptionPlan?: Maybe<SubscriptionPlans>;
	subscriptionPlans?: Maybe<
		Array<Maybe<SubscriptionPlans>>
	>;
	subscriptionPlansConnection?: Maybe<SubscriptionPlansConnection>;
	trialDuration?: Maybe<TrialDuration>;
	userPriority?: Maybe<UserPriority>;
	userPriorities?: Maybe<
		Array<Maybe<UserPriority>>
	>;
	userPrioritiesConnection?: Maybe<UserPriorityConnection>;
	weeklyPlanner?: Maybe<WeeklyPlanner>;
	weeklyPlanners?: Maybe<
		Array<Maybe<WeeklyPlanner>>
	>;
	weeklyPlannersConnection?: Maybe<WeeklyPlannerConnection>;
	files?: Maybe<Array<Maybe<UploadFile>>>;
	filesConnection?: Maybe<UploadFileConnection>;
	role?: Maybe<UsersPermissionsRole>;
	/** Retrieve all the existing roles. You can't apply filters on this query. */
	roles?: Maybe<
		Array<Maybe<UsersPermissionsRole>>
	>;
	rolesConnection?: Maybe<UsersPermissionsRoleConnection>;
	user?: Maybe<UsersPermissionsUser>;
	users?: Maybe<
		Array<Maybe<UsersPermissionsUser>>
	>;
	usersConnection?: Maybe<UsersPermissionsUserConnection>;
	me?: Maybe<UsersPermissionsMe>;
	newMe?: Maybe<UsersPermissionsMe1>;
	countries?: Maybe<Array<CountryList>>;
	getMyGroceryList?: Maybe<
		Array<GroceryListCustom>
	>;
	deleteAllMyList?: Maybe<
		Array<GroceryListCustom>
	>;
	getMyPriorities: UserPriority;
	listWeeklyPlannerEvents?: Maybe<ListWeeklyPlannerEventsData>;
	weeklyPlannerEventWeekCalories?: Maybe<WeeklyPlannerEventWeekCaloriesData>;
};

export type QueryBlogCategoryArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryBlogCategoriesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryBlogCategoriesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryBlogArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryBlogsArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryBlogsConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryCollaboratorArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryCollaboratorsArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryCollaboratorsConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryContactQueryArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryContactQueriesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryContactQueriesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryDailyQuoteArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryDailyQuotesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryDailyQuotesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryFoodIndexArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryFoodIndicesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryFoodIndicesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryGroceryListArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryGroceryListsArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryGroceryListsConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryJournalPromptArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryJournalPromptsArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryJournalPromptsConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryMantraArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryMantrasArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryMantrasConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryMastiArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryMastisArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryMastisConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryMealCategoryArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryMealCategoriesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryMealCategoriesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryMeditationCategoryArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryMeditationCategoriesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryMeditationCategoriesConnectionArgs =
	{
		sort?: Maybe<Scalars["String"]>;
		limit?: Maybe<Scalars["Int"]>;
		start?: Maybe<Scalars["Int"]>;
		where?: Maybe<Scalars["JSON"]>;
	};

export type QueryMeditationArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryMeditationsArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryMeditationsConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryMovementCategoryArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryMovementCategoriesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryMovementCategoriesConnectionArgs =
	{
		sort?: Maybe<Scalars["String"]>;
		limit?: Maybe<Scalars["Int"]>;
		start?: Maybe<Scalars["Int"]>;
		where?: Maybe<Scalars["JSON"]>;
	};

export type QueryMovementArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryMovementsArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryMovementsConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryNewsletterArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryNewslettersArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryNewslettersConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryPremiumBlogCategoryArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryPremiumBlogCategoriesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryPremiumBlogCategoriesConnectionArgs =
	{
		sort?: Maybe<Scalars["String"]>;
		limit?: Maybe<Scalars["Int"]>;
		start?: Maybe<Scalars["Int"]>;
		where?: Maybe<Scalars["JSON"]>;
	};

export type QueryPremiumBlogArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryPremiumBlogsArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryPremiumBlogsConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryPriorityArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryPrioritiesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryPrioritiesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryRecipeArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryRecipesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryRecipesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryStripeventArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryStripeventsArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryStripeventsConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QuerySubscriptionPlanArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QuerySubscriptionPlansArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QuerySubscriptionPlansConnectionArgs =
	{
		sort?: Maybe<Scalars["String"]>;
		limit?: Maybe<Scalars["Int"]>;
		start?: Maybe<Scalars["Int"]>;
		where?: Maybe<Scalars["JSON"]>;
	};

export type QueryTrialDurationArgs = {
	publicationState?: Maybe<PublicationState>;
};

export type QueryUserPriorityArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryUserPrioritiesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryUserPrioritiesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryWeeklyPlannerArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryWeeklyPlannersArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryWeeklyPlannersConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryFilesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryFilesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryRoleArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryRolesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryRolesConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryUserArgs = {
	id: Scalars["ID"];
	publicationState?: Maybe<PublicationState>;
};

export type QueryUsersArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
	publicationState?: Maybe<PublicationState>;
};

export type QueryUsersConnectionArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type QueryListWeeklyPlannerEventsArgs = {
	date: Scalars["Date"];
};

export type QueryWeeklyPlannerEventWeekCaloriesArgs =
	{
		input: WeeklyPlannerEventWeekCaloriesInput;
	};

export type RecipeInput = {
	SlideShow: ComponentMealsRecipeInput;
	Prep_Duration: ComponentMealsDurationInput;
	Cook_Duration: ComponentMealsDurationInput;
	No_Of_Servings?: Maybe<Scalars["Int"]>;
	Ingredients: ComponentMealsIngredientsWithImageInput;
	Insights?: Maybe<ComponentMealsInsightInput>;
	Instructions: ComponentMealsInstructionInput;
	Calories?: Maybe<Scalars["Int"]>;
	Nutrition_facts?: Maybe<
		Array<ComponentMealsNutritionFactInput>
	>;
	Title: Scalars["String"];
	meal_categories?: Maybe<
		Array<Maybe<Scalars["ID"]>>
	>;
	collaborator?: Maybe<Scalars["ID"]>;
	thumbnail?: Maybe<Scalars["ID"]>;
	keywords: Scalars["String"];
	order?: Maybe<Scalars["Float"]>;
	ValueDisclaimer?: Maybe<Scalars["String"]>;
	InsightNotes?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Recipes = {
	__typename?: "Recipes";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	SlideShow?: Maybe<ComponentMealsRecipes>;
	Prep_Duration?: Maybe<ComponentMealsDuration>;
	Cook_Duration?: Maybe<ComponentMealsDuration>;
	No_Of_Servings: Scalars["Int"];
	Ingredients?: Maybe<ComponentMealsIngredientsWithImages>;
	Insights?: Maybe<ComponentMealsInsights>;
	Instructions?: Maybe<ComponentMealsInstructions>;
	Calories?: Maybe<Scalars["Int"]>;
	Nutrition_facts?: Maybe<
		Array<Maybe<ComponentMealsNutritionFacts>>
	>;
	Title: Scalars["String"];
	collaborator?: Maybe<Collaborator>;
	thumbnail?: Maybe<UploadFile>;
	keywords: Scalars["String"];
	order: Scalars["Float"];
	ValueDisclaimer?: Maybe<Scalars["String"]>;
	InsightNotes?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	meal_categories?: Maybe<
		Array<Maybe<MealCategory>>
	>;
};

export type RecipesMeal_CategoriesArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type RecipesAggregator = {
	__typename?: "RecipesAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<RecipesAggregatorSum>;
	avg?: Maybe<RecipesAggregatorAvg>;
	min?: Maybe<RecipesAggregatorMin>;
	max?: Maybe<RecipesAggregatorMax>;
};

export type RecipesAggregatorAvg = {
	__typename?: "RecipesAggregatorAvg";
	No_Of_Servings?: Maybe<Scalars["Float"]>;
	Calories?: Maybe<Scalars["Float"]>;
	order?: Maybe<Scalars["Float"]>;
};

export type RecipesAggregatorMax = {
	__typename?: "RecipesAggregatorMax";
	No_Of_Servings?: Maybe<Scalars["Float"]>;
	Calories?: Maybe<Scalars["Float"]>;
	order?: Maybe<Scalars["Float"]>;
};

export type RecipesAggregatorMin = {
	__typename?: "RecipesAggregatorMin";
	No_Of_Servings?: Maybe<Scalars["Float"]>;
	Calories?: Maybe<Scalars["Float"]>;
	order?: Maybe<Scalars["Float"]>;
};

export type RecipesAggregatorSum = {
	__typename?: "RecipesAggregatorSum";
	No_Of_Servings?: Maybe<Scalars["Float"]>;
	Calories?: Maybe<Scalars["Float"]>;
	order?: Maybe<Scalars["Float"]>;
};

export type RecipesConnection = {
	__typename?: "RecipesConnection";
	values?: Maybe<Array<Maybe<Recipes>>>;
	groupBy?: Maybe<RecipesGroupBy>;
	aggregate?: Maybe<RecipesAggregator>;
};

export type RecipesConnectionCalories = {
	__typename?: "RecipesConnectionCalories";
	key?: Maybe<Scalars["Int"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionCollaborator = {
	__typename?: "RecipesConnectionCollaborator";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionCook_Duration = {
	__typename?: "RecipesConnectionCook_Duration";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionCreatedAt = {
	__typename?: "RecipesConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionId = {
	__typename?: "RecipesConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionIngredients = {
	__typename?: "RecipesConnectionIngredients";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionInsightNotes = {
	__typename?: "RecipesConnectionInsightNotes";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionInsights = {
	__typename?: "RecipesConnectionInsights";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionInstructions = {
	__typename?: "RecipesConnectionInstructions";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionKeywords = {
	__typename?: "RecipesConnectionKeywords";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionNo_Of_Servings = {
	__typename?: "RecipesConnectionNo_Of_Servings";
	key?: Maybe<Scalars["Int"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionOrder = {
	__typename?: "RecipesConnectionOrder";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionPrep_Duration = {
	__typename?: "RecipesConnectionPrep_Duration";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionPublished_At = {
	__typename?: "RecipesConnectionPublished_at";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionSlideShow = {
	__typename?: "RecipesConnectionSlideShow";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionThumbnail = {
	__typename?: "RecipesConnectionThumbnail";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionTitle = {
	__typename?: "RecipesConnectionTitle";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionUpdatedAt = {
	__typename?: "RecipesConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnectionValueDisclaimer = {
	__typename?: "RecipesConnectionValueDisclaimer";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesConnection_Id = {
	__typename?: "RecipesConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<RecipesConnection>;
};

export type RecipesGroupBy = {
	__typename?: "RecipesGroupBy";
	id?: Maybe<Array<Maybe<RecipesConnectionId>>>;
	_id?: Maybe<Array<Maybe<RecipesConnection_Id>>>;
	createdAt?: Maybe<
		Array<Maybe<RecipesConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<RecipesConnectionUpdatedAt>>
	>;
	SlideShow?: Maybe<
		Array<Maybe<RecipesConnectionSlideShow>>
	>;
	Prep_Duration?: Maybe<
		Array<Maybe<RecipesConnectionPrep_Duration>>
	>;
	Cook_Duration?: Maybe<
		Array<Maybe<RecipesConnectionCook_Duration>>
	>;
	No_Of_Servings?: Maybe<
		Array<Maybe<RecipesConnectionNo_Of_Servings>>
	>;
	Ingredients?: Maybe<
		Array<Maybe<RecipesConnectionIngredients>>
	>;
	Insights?: Maybe<
		Array<Maybe<RecipesConnectionInsights>>
	>;
	Instructions?: Maybe<
		Array<Maybe<RecipesConnectionInstructions>>
	>;
	Calories?: Maybe<
		Array<Maybe<RecipesConnectionCalories>>
	>;
	Title?: Maybe<
		Array<Maybe<RecipesConnectionTitle>>
	>;
	collaborator?: Maybe<
		Array<Maybe<RecipesConnectionCollaborator>>
	>;
	thumbnail?: Maybe<
		Array<Maybe<RecipesConnectionThumbnail>>
	>;
	keywords?: Maybe<
		Array<Maybe<RecipesConnectionKeywords>>
	>;
	order?: Maybe<
		Array<Maybe<RecipesConnectionOrder>>
	>;
	ValueDisclaimer?: Maybe<
		Array<Maybe<RecipesConnectionValueDisclaimer>>
	>;
	InsightNotes?: Maybe<
		Array<Maybe<RecipesConnectionInsightNotes>>
	>;
	published_at?: Maybe<
		Array<Maybe<RecipesConnectionPublished_At>>
	>;
};

export type RoleInput = {
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
	type?: Maybe<Scalars["String"]>;
	permissions?: Maybe<
		Array<Maybe<Scalars["ID"]>>
	>;
	users?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type Stripevent = {
	__typename?: "Stripevent";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
};

export type StripeventAggregator = {
	__typename?: "StripeventAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type StripeventConnection = {
	__typename?: "StripeventConnection";
	values?: Maybe<Array<Maybe<Stripevent>>>;
	groupBy?: Maybe<StripeventGroupBy>;
	aggregate?: Maybe<StripeventAggregator>;
};

export type StripeventConnectionCreatedAt = {
	__typename?: "StripeventConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<StripeventConnection>;
};

export type StripeventConnectionId = {
	__typename?: "StripeventConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<StripeventConnection>;
};

export type StripeventConnectionUpdatedAt = {
	__typename?: "StripeventConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<StripeventConnection>;
};

export type StripeventConnection_Id = {
	__typename?: "StripeventConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<StripeventConnection>;
};

export type StripeventGroupBy = {
	__typename?: "StripeventGroupBy";
	id?: Maybe<
		Array<Maybe<StripeventConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<StripeventConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<StripeventConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<StripeventConnectionUpdatedAt>>
	>;
};

export type StripeventInput = {
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type SubscriptionPlanInput = {
	plantitle: Scalars["String"];
	stripepriceid: Scalars["String"];
	price?: Maybe<Scalars["Float"]>;
	duration?: Maybe<Scalars["Int"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type SubscriptionPlans = {
	__typename?: "SubscriptionPlans";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	plantitle: Scalars["String"];
	stripepriceid: Scalars["String"];
	price: Scalars["Float"];
	duration: Scalars["Int"];
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type SubscriptionPlansAggregator = {
	__typename?: "SubscriptionPlansAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<SubscriptionPlansAggregatorSum>;
	avg?: Maybe<SubscriptionPlansAggregatorAvg>;
	min?: Maybe<SubscriptionPlansAggregatorMin>;
	max?: Maybe<SubscriptionPlansAggregatorMax>;
};

export type SubscriptionPlansAggregatorAvg = {
	__typename?: "SubscriptionPlansAggregatorAvg";
	price?: Maybe<Scalars["Float"]>;
	duration?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPlansAggregatorMax = {
	__typename?: "SubscriptionPlansAggregatorMax";
	price?: Maybe<Scalars["Float"]>;
	duration?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPlansAggregatorMin = {
	__typename?: "SubscriptionPlansAggregatorMin";
	price?: Maybe<Scalars["Float"]>;
	duration?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPlansAggregatorSum = {
	__typename?: "SubscriptionPlansAggregatorSum";
	price?: Maybe<Scalars["Float"]>;
	duration?: Maybe<Scalars["Float"]>;
};

export type SubscriptionPlansConnection = {
	__typename?: "SubscriptionPlansConnection";
	values?: Maybe<Array<Maybe<SubscriptionPlans>>>;
	groupBy?: Maybe<SubscriptionPlansGroupBy>;
	aggregate?: Maybe<SubscriptionPlansAggregator>;
};

export type SubscriptionPlansConnectionCreatedAt =
	{
		__typename?: "SubscriptionPlansConnectionCreatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<SubscriptionPlansConnection>;
	};

export type SubscriptionPlansConnectionDuration =
	{
		__typename?: "SubscriptionPlansConnectionDuration";
		key?: Maybe<Scalars["Int"]>;
		connection?: Maybe<SubscriptionPlansConnection>;
	};

export type SubscriptionPlansConnectionId = {
	__typename?: "SubscriptionPlansConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<SubscriptionPlansConnection>;
};

export type SubscriptionPlansConnectionPlantitle =
	{
		__typename?: "SubscriptionPlansConnectionPlantitle";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<SubscriptionPlansConnection>;
	};

export type SubscriptionPlansConnectionPrice = {
	__typename?: "SubscriptionPlansConnectionPrice";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<SubscriptionPlansConnection>;
};

export type SubscriptionPlansConnectionPublished_At =
	{
		__typename?: "SubscriptionPlansConnectionPublished_at";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<SubscriptionPlansConnection>;
	};

export type SubscriptionPlansConnectionStripepriceid =
	{
		__typename?: "SubscriptionPlansConnectionStripepriceid";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<SubscriptionPlansConnection>;
	};

export type SubscriptionPlansConnectionUpdatedAt =
	{
		__typename?: "SubscriptionPlansConnectionUpdatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<SubscriptionPlansConnection>;
	};

export type SubscriptionPlansConnection_Id = {
	__typename?: "SubscriptionPlansConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<SubscriptionPlansConnection>;
};

export type SubscriptionPlansGroupBy = {
	__typename?: "SubscriptionPlansGroupBy";
	id?: Maybe<
		Array<Maybe<SubscriptionPlansConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<SubscriptionPlansConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<
			Maybe<SubscriptionPlansConnectionCreatedAt>
		>
	>;
	updatedAt?: Maybe<
		Array<
			Maybe<SubscriptionPlansConnectionUpdatedAt>
		>
	>;
	plantitle?: Maybe<
		Array<
			Maybe<SubscriptionPlansConnectionPlantitle>
		>
	>;
	stripepriceid?: Maybe<
		Array<
			Maybe<SubscriptionPlansConnectionStripepriceid>
		>
	>;
	price?: Maybe<
		Array<Maybe<SubscriptionPlansConnectionPrice>>
	>;
	duration?: Maybe<
		Array<
			Maybe<SubscriptionPlansConnectionDuration>
		>
	>;
	published_at?: Maybe<
		Array<
			Maybe<SubscriptionPlansConnectionPublished_At>
		>
	>;
};

export type TrialDuration = {
	__typename?: "TrialDuration";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	Days?: Maybe<Scalars["Int"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type TrialDurationInput = {
	Days?: Maybe<Scalars["Int"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type UploadFile = {
	__typename?: "UploadFile";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	name: Scalars["String"];
	alternativeText?: Maybe<Scalars["String"]>;
	caption?: Maybe<Scalars["String"]>;
	width?: Maybe<Scalars["Int"]>;
	height?: Maybe<Scalars["Int"]>;
	formats?: Maybe<Scalars["JSON"]>;
	hash: Scalars["String"];
	ext?: Maybe<Scalars["String"]>;
	mime: Scalars["String"];
	size: Scalars["Float"];
	url: Scalars["String"];
	previewUrl?: Maybe<Scalars["String"]>;
	provider: Scalars["String"];
	provider_metadata?: Maybe<Scalars["JSON"]>;
	related?: Maybe<Array<Maybe<Morph>>>;
};

export type UploadFileRelatedArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type UploadFileAggregator = {
	__typename?: "UploadFileAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<UploadFileAggregatorSum>;
	avg?: Maybe<UploadFileAggregatorAvg>;
	min?: Maybe<UploadFileAggregatorMin>;
	max?: Maybe<UploadFileAggregatorMax>;
};

export type UploadFileAggregatorAvg = {
	__typename?: "UploadFileAggregatorAvg";
	width?: Maybe<Scalars["Float"]>;
	height?: Maybe<Scalars["Float"]>;
	size?: Maybe<Scalars["Float"]>;
};

export type UploadFileAggregatorMax = {
	__typename?: "UploadFileAggregatorMax";
	width?: Maybe<Scalars["Float"]>;
	height?: Maybe<Scalars["Float"]>;
	size?: Maybe<Scalars["Float"]>;
};

export type UploadFileAggregatorMin = {
	__typename?: "UploadFileAggregatorMin";
	width?: Maybe<Scalars["Float"]>;
	height?: Maybe<Scalars["Float"]>;
	size?: Maybe<Scalars["Float"]>;
};

export type UploadFileAggregatorSum = {
	__typename?: "UploadFileAggregatorSum";
	width?: Maybe<Scalars["Float"]>;
	height?: Maybe<Scalars["Float"]>;
	size?: Maybe<Scalars["Float"]>;
};

export type UploadFileConnection = {
	__typename?: "UploadFileConnection";
	values?: Maybe<Array<Maybe<UploadFile>>>;
	groupBy?: Maybe<UploadFileGroupBy>;
	aggregate?: Maybe<UploadFileAggregator>;
};

export type UploadFileConnectionAlternativeText =
	{
		__typename?: "UploadFileConnectionAlternativeText";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UploadFileConnection>;
	};

export type UploadFileConnectionCaption = {
	__typename?: "UploadFileConnectionCaption";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionCreatedAt = {
	__typename?: "UploadFileConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionExt = {
	__typename?: "UploadFileConnectionExt";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionFormats = {
	__typename?: "UploadFileConnectionFormats";
	key?: Maybe<Scalars["JSON"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionHash = {
	__typename?: "UploadFileConnectionHash";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionHeight = {
	__typename?: "UploadFileConnectionHeight";
	key?: Maybe<Scalars["Int"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionId = {
	__typename?: "UploadFileConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionMime = {
	__typename?: "UploadFileConnectionMime";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionName = {
	__typename?: "UploadFileConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionPreviewUrl = {
	__typename?: "UploadFileConnectionPreviewUrl";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionProvider = {
	__typename?: "UploadFileConnectionProvider";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionProvider_Metadata =
	{
		__typename?: "UploadFileConnectionProvider_metadata";
		key?: Maybe<Scalars["JSON"]>;
		connection?: Maybe<UploadFileConnection>;
	};

export type UploadFileConnectionSize = {
	__typename?: "UploadFileConnectionSize";
	key?: Maybe<Scalars["Float"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionUpdatedAt = {
	__typename?: "UploadFileConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionUrl = {
	__typename?: "UploadFileConnectionUrl";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnectionWidth = {
	__typename?: "UploadFileConnectionWidth";
	key?: Maybe<Scalars["Int"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileConnection_Id = {
	__typename?: "UploadFileConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UploadFileConnection>;
};

export type UploadFileGroupBy = {
	__typename?: "UploadFileGroupBy";
	id?: Maybe<
		Array<Maybe<UploadFileConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<UploadFileConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<UploadFileConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<UploadFileConnectionUpdatedAt>>
	>;
	name?: Maybe<
		Array<Maybe<UploadFileConnectionName>>
	>;
	alternativeText?: Maybe<
		Array<
			Maybe<UploadFileConnectionAlternativeText>
		>
	>;
	caption?: Maybe<
		Array<Maybe<UploadFileConnectionCaption>>
	>;
	width?: Maybe<
		Array<Maybe<UploadFileConnectionWidth>>
	>;
	height?: Maybe<
		Array<Maybe<UploadFileConnectionHeight>>
	>;
	formats?: Maybe<
		Array<Maybe<UploadFileConnectionFormats>>
	>;
	hash?: Maybe<
		Array<Maybe<UploadFileConnectionHash>>
	>;
	ext?: Maybe<
		Array<Maybe<UploadFileConnectionExt>>
	>;
	mime?: Maybe<
		Array<Maybe<UploadFileConnectionMime>>
	>;
	size?: Maybe<
		Array<Maybe<UploadFileConnectionSize>>
	>;
	url?: Maybe<
		Array<Maybe<UploadFileConnectionUrl>>
	>;
	previewUrl?: Maybe<
		Array<Maybe<UploadFileConnectionPreviewUrl>>
	>;
	provider?: Maybe<
		Array<Maybe<UploadFileConnectionProvider>>
	>;
	provider_metadata?: Maybe<
		Array<
			Maybe<UploadFileConnectionProvider_Metadata>
		>
	>;
};

export type UserInput = {
	username: Scalars["String"];
	email: Scalars["String"];
	provider?: Maybe<Scalars["String"]>;
	password?: Maybe<Scalars["String"]>;
	resetPasswordToken?: Maybe<Scalars["String"]>;
	confirmationToken?: Maybe<Scalars["String"]>;
	confirmed?: Maybe<Scalars["Boolean"]>;
	blocked?: Maybe<Scalars["Boolean"]>;
	role?: Maybe<Scalars["ID"]>;
	name: Scalars["String"];
	avatar?: Maybe<Scalars["ID"]>;
	dob?: Maybe<Scalars["Date"]>;
	country?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	timezone?: Maybe<Scalars["String"]>;
	state?: Maybe<Scalars["String"]>;
	bio?: Maybe<Scalars["String"]>;
	stripeid?: Maybe<Scalars["String"]>;
	what?: Maybe<Scalars["String"]>;
	why?: Maybe<Scalars["String"]>;
	trialDays?: Maybe<Scalars["Int"]>;
	subEnd?: Maybe<Scalars["Long"]>;
	subPlanId?: Maybe<Scalars["String"]>;
	subStatus?: Maybe<Scalars["String"]>;
	user_priority?: Maybe<Scalars["ID"]>;
	grocery_lists?: Maybe<
		Array<Maybe<Scalars["ID"]>>
	>;
	profession?: Maybe<Scalars["String"]>;
	interests?: Maybe<Scalars["String"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type UserPermissionsPasswordPayload = {
	__typename?: "UserPermissionsPasswordPayload";
	ok: Scalars["Boolean"];
};

export type UserPriority = {
	__typename?: "UserPriority";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	user?: Maybe<UsersPermissionsUser>;
	priorities?: Maybe<
		Array<Maybe<ComponentPrioritiesPriorities>>
	>;
};

export type UserPriorityAggregator = {
	__typename?: "UserPriorityAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type UserPriorityConnection = {
	__typename?: "UserPriorityConnection";
	values?: Maybe<Array<Maybe<UserPriority>>>;
	groupBy?: Maybe<UserPriorityGroupBy>;
	aggregate?: Maybe<UserPriorityAggregator>;
};

export type UserPriorityConnectionCreatedAt = {
	__typename?: "UserPriorityConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<UserPriorityConnection>;
};

export type UserPriorityConnectionId = {
	__typename?: "UserPriorityConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UserPriorityConnection>;
};

export type UserPriorityConnectionUpdatedAt = {
	__typename?: "UserPriorityConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<UserPriorityConnection>;
};

export type UserPriorityConnectionUser = {
	__typename?: "UserPriorityConnectionUser";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UserPriorityConnection>;
};

export type UserPriorityConnection_Id = {
	__typename?: "UserPriorityConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UserPriorityConnection>;
};

export type UserPriorityGroupBy = {
	__typename?: "UserPriorityGroupBy";
	id?: Maybe<
		Array<Maybe<UserPriorityConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<UserPriorityConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<UserPriorityConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<UserPriorityConnectionUpdatedAt>>
	>;
	user?: Maybe<
		Array<Maybe<UserPriorityConnectionUser>>
	>;
};

export type UserPriorityInput = {
	user?: Maybe<Scalars["ID"]>;
	priorities?: Maybe<
		Array<Maybe<ComponentPrioritiesPriorityInput>>
	>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type UserPriorityUpdateInput = {
	priorities?: Maybe<
		Array<Maybe<PriorityUpdateInput>>
	>;
};

export type UserSelectedPlan = {
	planId: Scalars["String"];
};

export type UsersPermissionsLoginInput = {
	identifier: Scalars["String"];
	password: Scalars["String"];
	provider?: Maybe<Scalars["String"]>;
};

export type UsersPermissionsLoginPayload = {
	__typename?: "UsersPermissionsLoginPayload";
	jwt?: Maybe<Scalars["String"]>;
	user: UsersPermissionsMe;
};

export type UsersPermissionsLoginPayload1 = {
	__typename?: "UsersPermissionsLoginPayload1";
	jwt?: Maybe<Scalars["String"]>;
	user: UsersPermissionsMe1;
};

export type UsersPermissionsMe = {
	__typename?: "UsersPermissionsMe";
	id: Scalars["ID"];
	username: Scalars["String"];
	email: Scalars["String"];
	confirmed?: Maybe<Scalars["Boolean"]>;
	blocked?: Maybe<Scalars["Boolean"]>;
	role?: Maybe<UsersPermissionsMeRole>;
};

export type UsersPermissionsMe1 = {
	__typename?: "UsersPermissionsMe1";
	id: Scalars["ID"];
	username: Scalars["String"];
	email: Scalars["String"];
	confirmed?: Maybe<Scalars["Boolean"]>;
	blocked?: Maybe<Scalars["Boolean"]>;
	role?: Maybe<UsersPermissionsMeRole>;
	name: Scalars["String"];
	createdAt: Scalars["Date"];
	avatar?: Maybe<UploadFile>;
	dob?: Maybe<Scalars["Date"]>;
	country?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	state?: Maybe<Scalars["String"]>;
	timezone?: Maybe<Scalars["String"]>;
	bio?: Maybe<Scalars["String"]>;
	what?: Maybe<Scalars["String"]>;
	why?: Maybe<Scalars["String"]>;
	trialDays?: Maybe<Scalars["Int"]>;
	subEnd?: Maybe<Scalars["Int"]>;
	subPlanId?: Maybe<Scalars["String"]>;
	subStatus?: Maybe<Scalars["String"]>;
	profession?: Maybe<Scalars["String"]>;
	interests?: Maybe<Scalars["String"]>;
};

export type UsersPermissionsMeRole = {
	__typename?: "UsersPermissionsMeRole";
	id: Scalars["ID"];
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
	type?: Maybe<Scalars["String"]>;
};

export type UsersPermissionsPermission = {
	__typename?: "UsersPermissionsPermission";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	type: Scalars["String"];
	controller: Scalars["String"];
	action: Scalars["String"];
	enabled: Scalars["Boolean"];
	policy?: Maybe<Scalars["String"]>;
	role?: Maybe<UsersPermissionsRole>;
};

export type UsersPermissionsRegisterInput = {
	username: Scalars["String"];
	email: Scalars["String"];
	password: Scalars["String"];
};

export type UsersPermissionsRegisterInput1 = {
	username: Scalars["String"];
	email: Scalars["String"];
	password: Scalars["String"];
	name: Scalars["String"];
};

export type UsersPermissionsRole = {
	__typename?: "UsersPermissionsRole";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	name: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
	type?: Maybe<Scalars["String"]>;
	permissions?: Maybe<
		Array<Maybe<UsersPermissionsPermission>>
	>;
	users?: Maybe<
		Array<Maybe<UsersPermissionsUser>>
	>;
};

export type UsersPermissionsRolePermissionsArgs =
	{
		sort?: Maybe<Scalars["String"]>;
		limit?: Maybe<Scalars["Int"]>;
		start?: Maybe<Scalars["Int"]>;
		where?: Maybe<Scalars["JSON"]>;
	};

export type UsersPermissionsRoleUsersArgs = {
	sort?: Maybe<Scalars["String"]>;
	limit?: Maybe<Scalars["Int"]>;
	start?: Maybe<Scalars["Int"]>;
	where?: Maybe<Scalars["JSON"]>;
};

export type UsersPermissionsRoleAggregator = {
	__typename?: "UsersPermissionsRoleAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type UsersPermissionsRoleConnection = {
	__typename?: "UsersPermissionsRoleConnection";
	values?: Maybe<
		Array<Maybe<UsersPermissionsRole>>
	>;
	groupBy?: Maybe<UsersPermissionsRoleGroupBy>;
	aggregate?: Maybe<UsersPermissionsRoleAggregator>;
};

export type UsersPermissionsRoleConnectionDescription =
	{
		__typename?: "UsersPermissionsRoleConnectionDescription";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsRoleConnection>;
	};

export type UsersPermissionsRoleConnectionId = {
	__typename?: "UsersPermissionsRoleConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UsersPermissionsRoleConnection>;
};

export type UsersPermissionsRoleConnectionName = {
	__typename?: "UsersPermissionsRoleConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UsersPermissionsRoleConnection>;
};

export type UsersPermissionsRoleConnectionType = {
	__typename?: "UsersPermissionsRoleConnectionType";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UsersPermissionsRoleConnection>;
};

export type UsersPermissionsRoleConnection_Id = {
	__typename?: "UsersPermissionsRoleConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UsersPermissionsRoleConnection>;
};

export type UsersPermissionsRoleGroupBy = {
	__typename?: "UsersPermissionsRoleGroupBy";
	id?: Maybe<
		Array<Maybe<UsersPermissionsRoleConnectionId>>
	>;
	_id?: Maybe<
		Array<
			Maybe<UsersPermissionsRoleConnection_Id>
		>
	>;
	name?: Maybe<
		Array<
			Maybe<UsersPermissionsRoleConnectionName>
		>
	>;
	description?: Maybe<
		Array<
			Maybe<UsersPermissionsRoleConnectionDescription>
		>
	>;
	type?: Maybe<
		Array<
			Maybe<UsersPermissionsRoleConnectionType>
		>
	>;
};

export type UsersPermissionsUser = {
	__typename?: "UsersPermissionsUser";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	username: Scalars["String"];
	email: Scalars["String"];
	provider?: Maybe<Scalars["String"]>;
	confirmed?: Maybe<Scalars["Boolean"]>;
	blocked?: Maybe<Scalars["Boolean"]>;
	role?: Maybe<UsersPermissionsRole>;
	name: Scalars["String"];
	avatar?: Maybe<UploadFile>;
	dob?: Maybe<Scalars["Date"]>;
	country?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	timezone?: Maybe<Scalars["String"]>;
	state?: Maybe<Scalars["String"]>;
	bio?: Maybe<Scalars["String"]>;
	stripeid?: Maybe<Scalars["String"]>;
	what?: Maybe<Scalars["String"]>;
	why?: Maybe<Scalars["String"]>;
	trialDays?: Maybe<Scalars["Int"]>;
	subEnd?: Maybe<Scalars["Long"]>;
	subPlanId?: Maybe<Scalars["String"]>;
	subStatus?: Maybe<Scalars["String"]>;
	user_priority?: Maybe<UserPriority>;
	profession?: Maybe<Scalars["String"]>;
	interests?: Maybe<Scalars["String"]>;
	grocery_lists?: Maybe<
		Array<Maybe<GroceryList>>
	>;
};

export type UsersPermissionsUserGrocery_ListsArgs =
	{
		sort?: Maybe<Scalars["String"]>;
		limit?: Maybe<Scalars["Int"]>;
		start?: Maybe<Scalars["Int"]>;
		where?: Maybe<Scalars["JSON"]>;
	};

export type UsersPermissionsUserAggregator = {
	__typename?: "UsersPermissionsUserAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
	sum?: Maybe<UsersPermissionsUserAggregatorSum>;
	avg?: Maybe<UsersPermissionsUserAggregatorAvg>;
	min?: Maybe<UsersPermissionsUserAggregatorMin>;
	max?: Maybe<UsersPermissionsUserAggregatorMax>;
};

export type UsersPermissionsUserAggregatorAvg = {
	__typename?: "UsersPermissionsUserAggregatorAvg";
	trialDays?: Maybe<Scalars["Float"]>;
};

export type UsersPermissionsUserAggregatorMax = {
	__typename?: "UsersPermissionsUserAggregatorMax";
	trialDays?: Maybe<Scalars["Float"]>;
};

export type UsersPermissionsUserAggregatorMin = {
	__typename?: "UsersPermissionsUserAggregatorMin";
	trialDays?: Maybe<Scalars["Float"]>;
};

export type UsersPermissionsUserAggregatorSum = {
	__typename?: "UsersPermissionsUserAggregatorSum";
	trialDays?: Maybe<Scalars["Float"]>;
};

export type UsersPermissionsUserConnection = {
	__typename?: "UsersPermissionsUserConnection";
	values?: Maybe<
		Array<Maybe<UsersPermissionsUser>>
	>;
	groupBy?: Maybe<UsersPermissionsUserGroupBy>;
	aggregate?: Maybe<UsersPermissionsUserAggregator>;
};

export type UsersPermissionsUserConnectionAvatar =
	{
		__typename?: "UsersPermissionsUserConnectionAvatar";
		key?: Maybe<Scalars["ID"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionBio = {
	__typename?: "UsersPermissionsUserConnectionBio";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionBlocked =
	{
		__typename?: "UsersPermissionsUserConnectionBlocked";
		key?: Maybe<Scalars["Boolean"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionCity = {
	__typename?: "UsersPermissionsUserConnectionCity";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionConfirmed =
	{
		__typename?: "UsersPermissionsUserConnectionConfirmed";
		key?: Maybe<Scalars["Boolean"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionCountry =
	{
		__typename?: "UsersPermissionsUserConnectionCountry";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionCreatedAt =
	{
		__typename?: "UsersPermissionsUserConnectionCreatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionDob = {
	__typename?: "UsersPermissionsUserConnectionDob";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionEmail =
	{
		__typename?: "UsersPermissionsUserConnectionEmail";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionId = {
	__typename?: "UsersPermissionsUserConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionInterests =
	{
		__typename?: "UsersPermissionsUserConnectionInterests";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionName = {
	__typename?: "UsersPermissionsUserConnectionName";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionProfession =
	{
		__typename?: "UsersPermissionsUserConnectionProfession";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionProvider =
	{
		__typename?: "UsersPermissionsUserConnectionProvider";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionRole = {
	__typename?: "UsersPermissionsUserConnectionRole";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionState =
	{
		__typename?: "UsersPermissionsUserConnectionState";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionStripeid =
	{
		__typename?: "UsersPermissionsUserConnectionStripeid";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionSubEnd =
	{
		__typename?: "UsersPermissionsUserConnectionSubEnd";
		key?: Maybe<Scalars["ID"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionSubPlanId =
	{
		__typename?: "UsersPermissionsUserConnectionSubPlanId";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionSubStatus =
	{
		__typename?: "UsersPermissionsUserConnectionSubStatus";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionTimezone =
	{
		__typename?: "UsersPermissionsUserConnectionTimezone";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionTrialDays =
	{
		__typename?: "UsersPermissionsUserConnectionTrialDays";
		key?: Maybe<Scalars["Int"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionUpdatedAt =
	{
		__typename?: "UsersPermissionsUserConnectionUpdatedAt";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionUser_Priority =
	{
		__typename?: "UsersPermissionsUserConnectionUser_priority";
		key?: Maybe<Scalars["ID"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionUsername =
	{
		__typename?: "UsersPermissionsUserConnectionUsername";
		key?: Maybe<Scalars["String"]>;
		connection?: Maybe<UsersPermissionsUserConnection>;
	};

export type UsersPermissionsUserConnectionWhat = {
	__typename?: "UsersPermissionsUserConnectionWhat";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnectionWhy = {
	__typename?: "UsersPermissionsUserConnectionWhy";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserConnection_Id = {
	__typename?: "UsersPermissionsUserConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<UsersPermissionsUserConnection>;
};

export type UsersPermissionsUserGroupBy = {
	__typename?: "UsersPermissionsUserGroupBy";
	id?: Maybe<
		Array<Maybe<UsersPermissionsUserConnectionId>>
	>;
	_id?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnection_Id>
		>
	>;
	createdAt?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionCreatedAt>
		>
	>;
	updatedAt?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionUpdatedAt>
		>
	>;
	username?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionUsername>
		>
	>;
	email?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionEmail>
		>
	>;
	provider?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionProvider>
		>
	>;
	confirmed?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionConfirmed>
		>
	>;
	blocked?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionBlocked>
		>
	>;
	role?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionRole>
		>
	>;
	name?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionName>
		>
	>;
	avatar?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionAvatar>
		>
	>;
	dob?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionDob>
		>
	>;
	country?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionCountry>
		>
	>;
	city?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionCity>
		>
	>;
	timezone?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionTimezone>
		>
	>;
	state?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionState>
		>
	>;
	bio?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionBio>
		>
	>;
	stripeid?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionStripeid>
		>
	>;
	what?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionWhat>
		>
	>;
	why?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionWhy>
		>
	>;
	trialDays?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionTrialDays>
		>
	>;
	subEnd?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionSubEnd>
		>
	>;
	subPlanId?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionSubPlanId>
		>
	>;
	subStatus?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionSubStatus>
		>
	>;
	user_priority?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionUser_Priority>
		>
	>;
	profession?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionProfession>
		>
	>;
	interests?: Maybe<
		Array<
			Maybe<UsersPermissionsUserConnectionInterests>
		>
	>;
};

export type WeeklyPlanner = {
	__typename?: "WeeklyPlanner";
	id: Scalars["ID"];
	_id: Scalars["ID"];
	createdAt: Scalars["DateTime"];
	updatedAt: Scalars["DateTime"];
	type?: Maybe<Scalars["String"]>;
	date?: Maybe<Scalars["Date"]>;
	slot?: Maybe<Scalars["String"]>;
	recipe?: Maybe<Recipes>;
	movement?: Maybe<Movement>;
	meditation?: Maybe<Meditation>;
	mantra?: Maybe<Mantra>;
	masti?: Maybe<Masti>;
	user?: Maybe<UsersPermissionsUser>;
	published_at?: Maybe<Scalars["DateTime"]>;
};

export type WeeklyPlannerAggregator = {
	__typename?: "WeeklyPlannerAggregator";
	count?: Maybe<Scalars["Int"]>;
	totalCount?: Maybe<Scalars["Int"]>;
};

export type WeeklyPlannerConnection = {
	__typename?: "WeeklyPlannerConnection";
	values?: Maybe<Array<Maybe<WeeklyPlanner>>>;
	groupBy?: Maybe<WeeklyPlannerGroupBy>;
	aggregate?: Maybe<WeeklyPlannerAggregator>;
};

export type WeeklyPlannerConnectionCreatedAt = {
	__typename?: "WeeklyPlannerConnectionCreatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionDate = {
	__typename?: "WeeklyPlannerConnectionDate";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionId = {
	__typename?: "WeeklyPlannerConnectionId";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionMantra = {
	__typename?: "WeeklyPlannerConnectionMantra";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionMasti = {
	__typename?: "WeeklyPlannerConnectionMasti";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionMeditation = {
	__typename?: "WeeklyPlannerConnectionMeditation";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionMovement = {
	__typename?: "WeeklyPlannerConnectionMovement";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionPublished_At =
	{
		__typename?: "WeeklyPlannerConnectionPublished_at";
		key?: Maybe<Scalars["DateTime"]>;
		connection?: Maybe<WeeklyPlannerConnection>;
	};

export type WeeklyPlannerConnectionRecipe = {
	__typename?: "WeeklyPlannerConnectionRecipe";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionSlot = {
	__typename?: "WeeklyPlannerConnectionSlot";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionType = {
	__typename?: "WeeklyPlannerConnectionType";
	key?: Maybe<Scalars["String"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionUpdatedAt = {
	__typename?: "WeeklyPlannerConnectionUpdatedAt";
	key?: Maybe<Scalars["DateTime"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnectionUser = {
	__typename?: "WeeklyPlannerConnectionUser";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerConnection_Id = {
	__typename?: "WeeklyPlannerConnection_id";
	key?: Maybe<Scalars["ID"]>;
	connection?: Maybe<WeeklyPlannerConnection>;
};

export type WeeklyPlannerGroupBy = {
	__typename?: "WeeklyPlannerGroupBy";
	id?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionId>>
	>;
	_id?: Maybe<
		Array<Maybe<WeeklyPlannerConnection_Id>>
	>;
	createdAt?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionCreatedAt>>
	>;
	updatedAt?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionUpdatedAt>>
	>;
	type?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionType>>
	>;
	date?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionDate>>
	>;
	slot?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionSlot>>
	>;
	recipe?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionRecipe>>
	>;
	movement?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionMovement>>
	>;
	meditation?: Maybe<
		Array<
			Maybe<WeeklyPlannerConnectionMeditation>
		>
	>;
	mantra?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionMantra>>
	>;
	masti?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionMasti>>
	>;
	user?: Maybe<
		Array<Maybe<WeeklyPlannerConnectionUser>>
	>;
	published_at?: Maybe<
		Array<
			Maybe<WeeklyPlannerConnectionPublished_At>
		>
	>;
};

export type WeeklyPlannerInput = {
	type?: Maybe<Scalars["String"]>;
	date?: Maybe<Scalars["Date"]>;
	slot?: Maybe<Scalars["String"]>;
	recipe?: Maybe<Scalars["ID"]>;
	movement?: Maybe<Scalars["ID"]>;
	meditation?: Maybe<Scalars["ID"]>;
	mantra?: Maybe<Scalars["ID"]>;
	masti?: Maybe<Scalars["ID"]>;
	user?: Maybe<Scalars["ID"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export enum WeeklyPlannerType {
	Meals = "MEALS",
	Movement = "MOVEMENT",
	Meditation = "MEDITATION",
	Mantra = "MANTRA",
	Masti = "MASTI",
}

export type CaloriesDataType = {
	__typename?: "caloriesDataType";
	totalFat?: Maybe<Scalars["Float"]>;
	satFat?: Maybe<Scalars["Float"]>;
	cholesterol?: Maybe<Scalars["Float"]>;
	sodium?: Maybe<Scalars["Float"]>;
	totalCarbs?: Maybe<Scalars["Float"]>;
	fiber?: Maybe<Scalars["Float"]>;
	sugar?: Maybe<Scalars["Float"]>;
	proteins?: Maybe<Scalars["Float"]>;
};

export type CityList = {
	__typename?: "cityList";
	id: Scalars["String"];
	name: Scalars["String"];
};

export type CityListWithTimezoneList = {
	__typename?: "cityListWithTimezoneList";
	cities?: Maybe<Array<CityList>>;
	timezone?: Maybe<Array<TimeZone>>;
};

export type CountryList = {
	__typename?: "countryList";
	id: Scalars["String"];
	name: Scalars["String"];
};

export type CountryName = {
	country: Scalars["String"];
};

export type CountryNameAndStateName = {
	country: Scalars["String"];
	state: Scalars["String"];
};

export type CreateBlogCategoryInput = {
	data?: Maybe<BlogCategoryInput>;
};

export type CreateBlogCategoryPayload = {
	__typename?: "createBlogCategoryPayload";
	blogCategory?: Maybe<BlogCategory>;
};

export type CreateBlogInput = {
	data?: Maybe<BlogInput>;
};

export type CreateBlogPayload = {
	__typename?: "createBlogPayload";
	blog?: Maybe<Blog>;
};

export type CreateCollaboratorInput = {
	data?: Maybe<CollaboratorInput>;
};

export type CreateCollaboratorPayload = {
	__typename?: "createCollaboratorPayload";
	collaborator?: Maybe<Collaborator>;
};

export type CreateContactQueryInput = {
	data?: Maybe<ContactQueryInput>;
};

export type CreateContactQueryPayload = {
	__typename?: "createContactQueryPayload";
	contactQuery?: Maybe<ContactQuery>;
};

export type CreateDailyQuoteInput = {
	data?: Maybe<DailyQuoteInput>;
};

export type CreateDailyQuotePayload = {
	__typename?: "createDailyQuotePayload";
	dailyQuote?: Maybe<DailyQuote>;
};

export type CreateFoodIndexInput = {
	data?: Maybe<FoodIndexInput>;
};

export type CreateFoodIndexPayload = {
	__typename?: "createFoodIndexPayload";
	foodIndex?: Maybe<FoodIndex>;
};

export type CreateGroceryListInput = {
	data?: Maybe<GroceryListInput>;
};

export type CreateGroceryListPayload = {
	__typename?: "createGroceryListPayload";
	groceryList?: Maybe<GroceryList>;
};

export type CreateJournalPromptInput = {
	data?: Maybe<JournalPromptInput>;
};

export type CreateJournalPromptPayload = {
	__typename?: "createJournalPromptPayload";
	journalPrompt?: Maybe<JournalPrompts>;
};

export type CreateMantraInput = {
	data?: Maybe<MantraInput>;
};

export type CreateMantraPayload = {
	__typename?: "createMantraPayload";
	mantra?: Maybe<Mantra>;
};

export type CreateMastiInput = {
	data?: Maybe<MastiInput>;
};

export type CreateMastiPayload = {
	__typename?: "createMastiPayload";
	masti?: Maybe<Masti>;
};

export type CreateMealCategoryInput = {
	data?: Maybe<MealCategoryInput>;
};

export type CreateMealCategoryPayload = {
	__typename?: "createMealCategoryPayload";
	mealCategory?: Maybe<MealCategory>;
};

export type CreateMeditationCategoryInput = {
	data?: Maybe<MeditationCategoryInput>;
};

export type CreateMeditationCategoryPayload = {
	__typename?: "createMeditationCategoryPayload";
	meditationCategory?: Maybe<MeditationCategory>;
};

export type CreateMeditationInput = {
	data?: Maybe<MeditationInput>;
};

export type CreateMeditationPayload = {
	__typename?: "createMeditationPayload";
	meditation?: Maybe<Meditation>;
};

export type CreateMovementCategoryInput = {
	data?: Maybe<MovementCategoryInput>;
};

export type CreateMovementCategoryPayload = {
	__typename?: "createMovementCategoryPayload";
	movementCategory?: Maybe<MovementCategory>;
};

export type CreateMovementInput = {
	data?: Maybe<MovementInput>;
};

export type CreateMovementPayload = {
	__typename?: "createMovementPayload";
	movement?: Maybe<Movement>;
};

export type CreateNewsletterInput = {
	data?: Maybe<NewsletterInput>;
};

export type CreateNewsletterPayload = {
	__typename?: "createNewsletterPayload";
	newsletter?: Maybe<Newsletter>;
};

export type CreatePremiumBlogCategoryInput = {
	data?: Maybe<PremiumBlogCategoryInput>;
};

export type CreatePremiumBlogCategoryPayload = {
	__typename?: "createPremiumBlogCategoryPayload";
	premiumBlogCategory?: Maybe<PremiumBlogCategory>;
};

export type CreatePremiumBlogInput = {
	data?: Maybe<PremiumBlogInput>;
};

export type CreatePremiumBlogPayload = {
	__typename?: "createPremiumBlogPayload";
	premiumBlog?: Maybe<PremiumBlog>;
};

export type CreatePriorityInput = {
	data?: Maybe<PriorityInput>;
};

export type CreatePriorityPayload = {
	__typename?: "createPriorityPayload";
	priority?: Maybe<Priority>;
};

export type CreateRecipeInput = {
	data?: Maybe<RecipeInput>;
};

export type CreateRecipePayload = {
	__typename?: "createRecipePayload";
	recipe?: Maybe<Recipes>;
};

export type CreateRoleInput = {
	data?: Maybe<RoleInput>;
};

export type CreateRolePayload = {
	__typename?: "createRolePayload";
	role?: Maybe<UsersPermissionsRole>;
};

export type CreateStripeventInput = {
	data?: Maybe<StripeventInput>;
};

export type CreateStripeventPayload = {
	__typename?: "createStripeventPayload";
	stripevent?: Maybe<Stripevent>;
};

export type CreateSubscriptionPlanInput = {
	data?: Maybe<SubscriptionPlanInput>;
};

export type CreateSubscriptionPlanPayload = {
	__typename?: "createSubscriptionPlanPayload";
	subscriptionPlan?: Maybe<SubscriptionPlans>;
};

export type CreateUserInput = {
	data?: Maybe<UserInput>;
};

export type CreateUserPayload = {
	__typename?: "createUserPayload";
	user?: Maybe<UsersPermissionsUser>;
};

export type CreateUserPriorityInput = {
	data?: Maybe<UserPriorityInput>;
};

export type CreateUserPriorityPayload = {
	__typename?: "createUserPriorityPayload";
	userPriority?: Maybe<UserPriority>;
};

export type CreateWeeklyPlannerInput = {
	data?: Maybe<WeeklyPlannerInput>;
};

export type CreateWeeklyPlannerPayload = {
	__typename?: "createWeeklyPlannerPayload";
	weeklyPlanner?: Maybe<WeeklyPlanner>;
};

export type DeleteBlogCategoryInput = {
	where?: Maybe<InputId>;
};

export type DeleteBlogCategoryPayload = {
	__typename?: "deleteBlogCategoryPayload";
	blogCategory?: Maybe<BlogCategory>;
};

export type DeleteBlogInput = {
	where?: Maybe<InputId>;
};

export type DeleteBlogPayload = {
	__typename?: "deleteBlogPayload";
	blog?: Maybe<Blog>;
};

export type DeleteCollaboratorInput = {
	where?: Maybe<InputId>;
};

export type DeleteCollaboratorPayload = {
	__typename?: "deleteCollaboratorPayload";
	collaborator?: Maybe<Collaborator>;
};

export type DeleteContactQueryInput = {
	where?: Maybe<InputId>;
};

export type DeleteContactQueryPayload = {
	__typename?: "deleteContactQueryPayload";
	contactQuery?: Maybe<ContactQuery>;
};

export type DeleteDailyQuoteInput = {
	where?: Maybe<InputId>;
};

export type DeleteDailyQuotePayload = {
	__typename?: "deleteDailyQuotePayload";
	dailyQuote?: Maybe<DailyQuote>;
};

export type DeleteFileInput = {
	where?: Maybe<InputId>;
};

export type DeleteFilePayload = {
	__typename?: "deleteFilePayload";
	file?: Maybe<UploadFile>;
};

export type DeleteFoodIndexInput = {
	where?: Maybe<InputId>;
};

export type DeleteFoodIndexPayload = {
	__typename?: "deleteFoodIndexPayload";
	foodIndex?: Maybe<FoodIndex>;
};

export type DeleteGroceryListInput = {
	where?: Maybe<InputId>;
};

export type DeleteGroceryListPayload = {
	__typename?: "deleteGroceryListPayload";
	groceryList?: Maybe<GroceryList>;
};

export type DeleteJournalPromptInput = {
	where?: Maybe<InputId>;
};

export type DeleteJournalPromptPayload = {
	__typename?: "deleteJournalPromptPayload";
	journalPrompt?: Maybe<JournalPrompts>;
};

export type DeleteMantraInput = {
	where?: Maybe<InputId>;
};

export type DeleteMantraPayload = {
	__typename?: "deleteMantraPayload";
	mantra?: Maybe<Mantra>;
};

export type DeleteMastiInput = {
	where?: Maybe<InputId>;
};

export type DeleteMastiPayload = {
	__typename?: "deleteMastiPayload";
	masti?: Maybe<Masti>;
};

export type DeleteMealCategoryInput = {
	where?: Maybe<InputId>;
};

export type DeleteMealCategoryPayload = {
	__typename?: "deleteMealCategoryPayload";
	mealCategory?: Maybe<MealCategory>;
};

export type DeleteMeditationCategoryInput = {
	where?: Maybe<InputId>;
};

export type DeleteMeditationCategoryPayload = {
	__typename?: "deleteMeditationCategoryPayload";
	meditationCategory?: Maybe<MeditationCategory>;
};

export type DeleteMeditationInput = {
	where?: Maybe<InputId>;
};

export type DeleteMeditationPayload = {
	__typename?: "deleteMeditationPayload";
	meditation?: Maybe<Meditation>;
};

export type DeleteMovementCategoryInput = {
	where?: Maybe<InputId>;
};

export type DeleteMovementCategoryPayload = {
	__typename?: "deleteMovementCategoryPayload";
	movementCategory?: Maybe<MovementCategory>;
};

export type DeleteMovementInput = {
	where?: Maybe<InputId>;
};

export type DeleteMovementPayload = {
	__typename?: "deleteMovementPayload";
	movement?: Maybe<Movement>;
};

export type DeleteNewsletterInput = {
	where?: Maybe<InputId>;
};

export type DeleteNewsletterPayload = {
	__typename?: "deleteNewsletterPayload";
	newsletter?: Maybe<Newsletter>;
};

export type DeletePremiumBlogCategoryInput = {
	where?: Maybe<InputId>;
};

export type DeletePremiumBlogCategoryPayload = {
	__typename?: "deletePremiumBlogCategoryPayload";
	premiumBlogCategory?: Maybe<PremiumBlogCategory>;
};

export type DeletePremiumBlogInput = {
	where?: Maybe<InputId>;
};

export type DeletePremiumBlogPayload = {
	__typename?: "deletePremiumBlogPayload";
	premiumBlog?: Maybe<PremiumBlog>;
};

export type DeletePriorityInput = {
	where?: Maybe<InputId>;
};

export type DeletePriorityPayload = {
	__typename?: "deletePriorityPayload";
	priority?: Maybe<Priority>;
};

export type DeleteRecipeInput = {
	where?: Maybe<InputId>;
};

export type DeleteRecipePayload = {
	__typename?: "deleteRecipePayload";
	recipe?: Maybe<Recipes>;
};

export type DeleteRoleInput = {
	where?: Maybe<InputId>;
};

export type DeleteRolePayload = {
	__typename?: "deleteRolePayload";
	role?: Maybe<UsersPermissionsRole>;
};

export type DeleteStripeventInput = {
	where?: Maybe<InputId>;
};

export type DeleteStripeventPayload = {
	__typename?: "deleteStripeventPayload";
	stripevent?: Maybe<Stripevent>;
};

export type DeleteSubscriptionPlanInput = {
	where?: Maybe<InputId>;
};

export type DeleteSubscriptionPlanPayload = {
	__typename?: "deleteSubscriptionPlanPayload";
	subscriptionPlan?: Maybe<SubscriptionPlans>;
};

export type DeleteTrialDurationPayload = {
	__typename?: "deleteTrialDurationPayload";
	trialDuration?: Maybe<TrialDuration>;
};

export type DeleteUserInput = {
	where?: Maybe<InputId>;
};

export type DeleteUserPayload = {
	__typename?: "deleteUserPayload";
	user?: Maybe<UsersPermissionsUser>;
};

export type DeleteUserPriorityInput = {
	where?: Maybe<InputId>;
};

export type DeleteUserPriorityPayload = {
	__typename?: "deleteUserPriorityPayload";
	userPriority?: Maybe<UserPriority>;
};

export type DeleteWeeklyPlannerInput = {
	where?: Maybe<InputId>;
};

export type DeleteWeeklyPlannerPayload = {
	__typename?: "deleteWeeklyPlannerPayload";
	weeklyPlanner?: Maybe<WeeklyPlanner>;
};

export type EditBlogCategoryInput = {
	title?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditBlogInput = {
	title?: Maybe<Scalars["String"]>;
	banner_image?: Maybe<Scalars["ID"]>;
	content?: Maybe<Scalars["String"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	blog_category?: Maybe<Scalars["ID"]>;
	short_description?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditCollaboratorInput = {
	name?: Maybe<Scalars["String"]>;
	occupation?: Maybe<Scalars["String"]>;
	location?: Maybe<Scalars["String"]>;
	expertise?: Maybe<Scalars["String"]>;
	why?: Maybe<Scalars["String"]>;
	links?: Maybe<
		Array<Maybe<EditComponentLinksLinkInput>>
	>;
	image?: Maybe<Scalars["ID"]>;
	recipes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	mantras?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	expertise_rich?: Maybe<Scalars["String"]>;
	why_rich?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditComponentLinksLinkInput = {
	id?: Maybe<Scalars["ID"]>;
	type?: Maybe<Enum_Componentlinkslinks_Type>;
	url?: Maybe<Scalars["String"]>;
};

export type EditComponentMealsAyurvedaInsightInput =
	{
		id?: Maybe<Scalars["ID"]>;
		Description?: Maybe<Scalars["String"]>;
	};

export type EditComponentMealsDurationInput = {
	id?: Maybe<Scalars["ID"]>;
	duration?: Maybe<Scalars["Int"]>;
	unit?: Maybe<Enum_Componentmealsduration_Unit>;
};

export type EditComponentMealsIngredientInput = {
	id?: Maybe<Scalars["ID"]>;
	Ingredients_Name?: Maybe<Scalars["String"]>;
	Unit?: Maybe<Scalars["String"]>;
};

export type EditComponentMealsIngredientsWithImageInput =
	{
		id?: Maybe<Scalars["ID"]>;
		Ingredients?: Maybe<
			Array<
				Maybe<EditComponentMealsIngredientInput>
			>
		>;
		Image?: Maybe<Scalars["ID"]>;
		IngredientsWithTitle?: Maybe<
			Array<
				Maybe<EditComponentMealsIngredientsWithTitleInput>
			>
		>;
	};

export type EditComponentMealsIngredientsWithTitleInput =
	{
		id?: Maybe<Scalars["ID"]>;
		Title?: Maybe<Scalars["String"]>;
		Ingredients?: Maybe<
			Array<
				Maybe<EditComponentMealsIngredientInput>
			>
		>;
	};

export type EditComponentMealsInsightInput = {
	id?: Maybe<Scalars["ID"]>;
	Nutritional_Insight?: Maybe<
		Array<
			Maybe<EditComponentMealsNutritionalInsightInput>
		>
	>;
	Ayurveda_Insight?: Maybe<
		Array<
			Maybe<EditComponentMealsAyurvedaInsightInput>
		>
	>;
	Nutritional_Insight_rich?: Maybe<
		Scalars["String"]
	>;
	Ayurveda_Insight_rich?: Maybe<
		Scalars["String"]
	>;
};

export type EditComponentMealsInstructionInput = {
	id?: Maybe<Scalars["ID"]>;
	Variations?: Maybe<Scalars["String"]>;
	Steps?: Maybe<
		Array<Maybe<EditComponentMealsStepInput>>
	>;
};

export type EditComponentMealsNutritionFactInput =
	{
		id?: Maybe<Scalars["ID"]>;
		Title?: Maybe<Scalars["String"]>;
		Unit?: Maybe<Scalars["String"]>;
		Sub_Nutrition_facts?: Maybe<
			Array<
				Maybe<EditComponentMealsSubNutritionFactInput>
			>
		>;
		value?: Maybe<Scalars["Float"]>;
	};

export type EditComponentMealsNutritionalInsightInput =
	{
		id?: Maybe<Scalars["ID"]>;
		Description?: Maybe<Scalars["String"]>;
	};

export type EditComponentMealsRecipeInput = {
	id?: Maybe<Scalars["ID"]>;
	images?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type EditComponentMealsStepInput = {
	id?: Maybe<Scalars["ID"]>;
	Step_Description?: Maybe<Scalars["String"]>;
	Step_Images?: Maybe<
		Array<Maybe<Scalars["ID"]>>
	>;
};

export type EditComponentMealsSubNutritionFactInput =
	{
		id?: Maybe<Scalars["ID"]>;
		Title?: Maybe<Scalars["String"]>;
		Unit?: Maybe<Scalars["String"]>;
		value?: Maybe<Scalars["Float"]>;
	};

export type EditComponentMealsTagInput = {
	id?: Maybe<Scalars["ID"]>;
	Tag?: Maybe<Scalars["String"]>;
};

export type EditComponentPrioritiesPriorityInput =
	{
		id?: Maybe<Scalars["ID"]>;
		type?: Maybe<Scalars["String"]>;
		title?: Maybe<Scalars["String"]>;
		description?: Maybe<Scalars["String"]>;
		checkin?: Maybe<Scalars["Date"]>;
	};

export type EditContactQueryInput = {
	name?: Maybe<Scalars["String"]>;
	email?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
	type?: Maybe<Scalars["String"]>;
	message?: Maybe<Scalars["String"]>;
	expertise?: Maybe<Scalars["String"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditDailyQuoteInput = {
	quote?: Maybe<Scalars["String"]>;
	author?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditFileInput = {
	name?: Maybe<Scalars["String"]>;
	alternativeText?: Maybe<Scalars["String"]>;
	caption?: Maybe<Scalars["String"]>;
	width?: Maybe<Scalars["Int"]>;
	height?: Maybe<Scalars["Int"]>;
	formats?: Maybe<Scalars["JSON"]>;
	hash?: Maybe<Scalars["String"]>;
	ext?: Maybe<Scalars["String"]>;
	mime?: Maybe<Scalars["String"]>;
	size?: Maybe<Scalars["Float"]>;
	url?: Maybe<Scalars["String"]>;
	previewUrl?: Maybe<Scalars["String"]>;
	provider?: Maybe<Scalars["String"]>;
	provider_metadata?: Maybe<Scalars["JSON"]>;
	related?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditFoodIndexInput = {
	name?: Maybe<Scalars["String"]>;
	image?: Maybe<Scalars["ID"]>;
	insights?: Maybe<EditComponentMealsInsightInput>;
	values?: Maybe<
		Array<
			Maybe<EditComponentMealsNutritionFactInput>
		>
	>;
	qty?: Maybe<Scalars["String"]>;
	ValueDisclaimer?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditGroceryListInput = {
	description?: Maybe<Scalars["String"]>;
	checked?: Maybe<Scalars["Boolean"]>;
	category?: Maybe<Scalars["String"]>;
	user?: Maybe<Scalars["ID"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditJournalPromptInput = {
	description?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditLocaleInput = {
	name?: Maybe<Scalars["String"]>;
	code?: Maybe<Scalars["String"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditMantraInput = {
	title?: Maybe<Scalars["String"]>;
	short_description?: Maybe<Scalars["String"]>;
	cover_image?: Maybe<Scalars["ID"]>;
	reading_time?: Maybe<Scalars["String"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	content?: Maybe<Scalars["String"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditMastiInput = {
	title?: Maybe<Scalars["String"]>;
	short_description?: Maybe<Scalars["String"]>;
	cover_image?: Maybe<Scalars["ID"]>;
	reading_time?: Maybe<Scalars["String"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	content?: Maybe<Scalars["String"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditMealCategoryInput = {
	Name?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	recipes?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditMeditationCategoryInput = {
	name?: Maybe<Scalars["String"]>;
	image?: Maybe<Scalars["ID"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditMeditationInput = {
	title?: Maybe<Scalars["String"]>;
	thumbnail?: Maybe<Scalars["ID"]>;
	duration?: Maybe<Scalars["String"]>;
	content?: Maybe<Scalars["String"]>;
	meditation_category?: Maybe<Scalars["ID"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditMovementCategoryInput = {
	name?: Maybe<Scalars["String"]>;
	image?: Maybe<Scalars["ID"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditMovementInput = {
	title?: Maybe<Scalars["String"]>;
	thumbnail?: Maybe<Scalars["ID"]>;
	duration?: Maybe<Scalars["String"]>;
	calories_burn?: Maybe<Scalars["Int"]>;
	content?: Maybe<Scalars["String"]>;
	movement_category?: Maybe<Scalars["ID"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	vimeo_url?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditNewsletterInput = {
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditPremiumBlogCategoryInput = {
	title?: Maybe<Scalars["String"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditPremiumBlogInput = {
	title?: Maybe<Scalars["String"]>;
	banner_image?: Maybe<Scalars["ID"]>;
	content?: Maybe<Scalars["String"]>;
	collaborator?: Maybe<Scalars["ID"]>;
	premium_blog_category?: Maybe<Scalars["ID"]>;
	short_description?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditPriorityInput = {
	title?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	category?: Maybe<Enum_Priority_Category>;
	order?: Maybe<Scalars["Float"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditRecipeInput = {
	SlideShow?: Maybe<EditComponentMealsRecipeInput>;
	Prep_Duration?: Maybe<EditComponentMealsDurationInput>;
	Cook_Duration?: Maybe<EditComponentMealsDurationInput>;
	No_Of_Servings?: Maybe<Scalars["Int"]>;
	Ingredients?: Maybe<EditComponentMealsIngredientsWithImageInput>;
	Insights?: Maybe<EditComponentMealsInsightInput>;
	Instructions?: Maybe<EditComponentMealsInstructionInput>;
	Calories?: Maybe<Scalars["Int"]>;
	Nutrition_facts?: Maybe<
		Array<
			Maybe<EditComponentMealsNutritionFactInput>
		>
	>;
	Title?: Maybe<Scalars["String"]>;
	meal_categories?: Maybe<
		Array<Maybe<Scalars["ID"]>>
	>;
	collaborator?: Maybe<Scalars["ID"]>;
	thumbnail?: Maybe<Scalars["ID"]>;
	keywords?: Maybe<Scalars["String"]>;
	order?: Maybe<Scalars["Float"]>;
	ValueDisclaimer?: Maybe<Scalars["String"]>;
	InsightNotes?: Maybe<Scalars["String"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditRoleInput = {
	name?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	type?: Maybe<Scalars["String"]>;
	permissions?: Maybe<
		Array<Maybe<Scalars["ID"]>>
	>;
	users?: Maybe<Array<Maybe<Scalars["ID"]>>>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditStripeventInput = {
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditSubscriptionPlanInput = {
	plantitle?: Maybe<Scalars["String"]>;
	stripepriceid?: Maybe<Scalars["String"]>;
	price?: Maybe<Scalars["Float"]>;
	duration?: Maybe<Scalars["Int"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditTrialDurationInput = {
	Days?: Maybe<Scalars["Int"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditUserInput = {
	username?: Maybe<Scalars["String"]>;
	email?: Maybe<Scalars["String"]>;
	provider?: Maybe<Scalars["String"]>;
	password?: Maybe<Scalars["String"]>;
	resetPasswordToken?: Maybe<Scalars["String"]>;
	confirmationToken?: Maybe<Scalars["String"]>;
	confirmed?: Maybe<Scalars["Boolean"]>;
	blocked?: Maybe<Scalars["Boolean"]>;
	role?: Maybe<Scalars["ID"]>;
	name?: Maybe<Scalars["String"]>;
	avatar?: Maybe<Scalars["ID"]>;
	dob?: Maybe<Scalars["Date"]>;
	country?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	timezone?: Maybe<Scalars["String"]>;
	state?: Maybe<Scalars["String"]>;
	bio?: Maybe<Scalars["String"]>;
	stripeid?: Maybe<Scalars["String"]>;
	what?: Maybe<Scalars["String"]>;
	why?: Maybe<Scalars["String"]>;
	trialDays?: Maybe<Scalars["Int"]>;
	subEnd?: Maybe<Scalars["Long"]>;
	subPlanId?: Maybe<Scalars["String"]>;
	subStatus?: Maybe<Scalars["String"]>;
	user_priority?: Maybe<Scalars["ID"]>;
	grocery_lists?: Maybe<
		Array<Maybe<Scalars["ID"]>>
	>;
	profession?: Maybe<Scalars["String"]>;
	interests?: Maybe<Scalars["String"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditUserPriorityInput = {
	user?: Maybe<Scalars["ID"]>;
	priorities?: Maybe<
		Array<
			Maybe<EditComponentPrioritiesPriorityInput>
		>
	>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EditWeeklyPlannerInput = {
	type?: Maybe<Scalars["String"]>;
	date?: Maybe<Scalars["Date"]>;
	slot?: Maybe<Scalars["String"]>;
	recipe?: Maybe<Scalars["ID"]>;
	movement?: Maybe<Scalars["ID"]>;
	meditation?: Maybe<Scalars["ID"]>;
	mantra?: Maybe<Scalars["ID"]>;
	masti?: Maybe<Scalars["ID"]>;
	user?: Maybe<Scalars["ID"]>;
	published_at?: Maybe<Scalars["DateTime"]>;
	created_by?: Maybe<Scalars["ID"]>;
	updated_by?: Maybe<Scalars["ID"]>;
};

export type EmailInput = {
	email: Scalars["String"];
};

export type GroceryListCustom = {
	__typename?: "groceryListCustom";
	id: Scalars["ID"];
	description: Scalars["String"];
	checked: Scalars["Boolean"];
	category: Scalars["String"];
	user: UsersPermissionsMe;
};

export type GroceryListCustomInput = {
	description: Scalars["String"];
	category: Scalars["String"];
};

export type GroceryListCustomUpdateCheckedInput =
	{
		id: Scalars["ID"];
		checked: Scalars["Boolean"];
	};

export type GroceryListCustomUpdateInput = {
	id: Scalars["ID"];
	description: Scalars["String"];
	category: Scalars["String"];
};

export type ListWeeklyPlannerEventsData = {
	__typename?: "listWeeklyPlannerEventsData";
	events?: Maybe<Array<WeeklyPlanner>>;
	dayCaloriesCount: Scalars["Int"];
	caloriesData: CaloriesDataType;
};

export type StateList = {
	__typename?: "stateList";
	id: Scalars["String"];
	name: Scalars["String"];
};

export type TimeZone = {
	__typename?: "timeZone";
	zoneName: Scalars["String"];
	gmtOffset: Scalars["Int"];
	gmtOffsetName: Scalars["String"];
	abbreviation: Scalars["String"];
	tzName: Scalars["String"];
};

export type UpdateBlogCategoryInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditBlogCategoryInput>;
};

export type UpdateBlogCategoryPayload = {
	__typename?: "updateBlogCategoryPayload";
	blogCategory?: Maybe<BlogCategory>;
};

export type UpdateBlogInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditBlogInput>;
};

export type UpdateBlogPayload = {
	__typename?: "updateBlogPayload";
	blog?: Maybe<Blog>;
};

export type UpdateCollaboratorInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditCollaboratorInput>;
};

export type UpdateCollaboratorPayload = {
	__typename?: "updateCollaboratorPayload";
	collaborator?: Maybe<Collaborator>;
};

export type UpdateContactQueryInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditContactQueryInput>;
};

export type UpdateContactQueryPayload = {
	__typename?: "updateContactQueryPayload";
	contactQuery?: Maybe<ContactQuery>;
};

export type UpdateDailyQuoteInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditDailyQuoteInput>;
};

export type UpdateDailyQuotePayload = {
	__typename?: "updateDailyQuotePayload";
	dailyQuote?: Maybe<DailyQuote>;
};

export type UpdateFoodIndexInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditFoodIndexInput>;
};

export type UpdateFoodIndexPayload = {
	__typename?: "updateFoodIndexPayload";
	foodIndex?: Maybe<FoodIndex>;
};

export type UpdateGroceryListInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditGroceryListInput>;
};

export type UpdateGroceryListPayload = {
	__typename?: "updateGroceryListPayload";
	groceryList?: Maybe<GroceryList>;
};

export type UpdateJournalPromptInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditJournalPromptInput>;
};

export type UpdateJournalPromptPayload = {
	__typename?: "updateJournalPromptPayload";
	journalPrompt?: Maybe<JournalPrompts>;
};

export type UpdateMantraInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditMantraInput>;
};

export type UpdateMantraPayload = {
	__typename?: "updateMantraPayload";
	mantra?: Maybe<Mantra>;
};

export type UpdateMastiInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditMastiInput>;
};

export type UpdateMastiPayload = {
	__typename?: "updateMastiPayload";
	masti?: Maybe<Masti>;
};

export type UpdateMealCategoryInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditMealCategoryInput>;
};

export type UpdateMealCategoryPayload = {
	__typename?: "updateMealCategoryPayload";
	mealCategory?: Maybe<MealCategory>;
};

export type UpdateMeditationCategoryInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditMeditationCategoryInput>;
};

export type UpdateMeditationCategoryPayload = {
	__typename?: "updateMeditationCategoryPayload";
	meditationCategory?: Maybe<MeditationCategory>;
};

export type UpdateMeditationInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditMeditationInput>;
};

export type UpdateMeditationPayload = {
	__typename?: "updateMeditationPayload";
	meditation?: Maybe<Meditation>;
};

export type UpdateMovementCategoryInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditMovementCategoryInput>;
};

export type UpdateMovementCategoryPayload = {
	__typename?: "updateMovementCategoryPayload";
	movementCategory?: Maybe<MovementCategory>;
};

export type UpdateMovementInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditMovementInput>;
};

export type UpdateMovementPayload = {
	__typename?: "updateMovementPayload";
	movement?: Maybe<Movement>;
};

export type UpdateNewsletterInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditNewsletterInput>;
};

export type UpdateNewsletterPayload = {
	__typename?: "updateNewsletterPayload";
	newsletter?: Maybe<Newsletter>;
};

export type UpdatePremiumBlogCategoryInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditPremiumBlogCategoryInput>;
};

export type UpdatePremiumBlogCategoryPayload = {
	__typename?: "updatePremiumBlogCategoryPayload";
	premiumBlogCategory?: Maybe<PremiumBlogCategory>;
};

export type UpdatePremiumBlogInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditPremiumBlogInput>;
};

export type UpdatePremiumBlogPayload = {
	__typename?: "updatePremiumBlogPayload";
	premiumBlog?: Maybe<PremiumBlog>;
};

export type UpdatePriorityInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditPriorityInput>;
};

export type UpdatePriorityPayload = {
	__typename?: "updatePriorityPayload";
	priority?: Maybe<Priority>;
};

export type UpdateRecipeInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditRecipeInput>;
};

export type UpdateRecipePayload = {
	__typename?: "updateRecipePayload";
	recipe?: Maybe<Recipes>;
};

export type UpdateRoleInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditRoleInput>;
};

export type UpdateRolePayload = {
	__typename?: "updateRolePayload";
	role?: Maybe<UsersPermissionsRole>;
};

export type UpdateStripeventInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditStripeventInput>;
};

export type UpdateStripeventPayload = {
	__typename?: "updateStripeventPayload";
	stripevent?: Maybe<Stripevent>;
};

export type UpdateSubscriptionPlanInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditSubscriptionPlanInput>;
};

export type UpdateSubscriptionPlanPayload = {
	__typename?: "updateSubscriptionPlanPayload";
	subscriptionPlan?: Maybe<SubscriptionPlans>;
};

export type UpdateTrialDurationInput = {
	data?: Maybe<EditTrialDurationInput>;
};

export type UpdateTrialDurationPayload = {
	__typename?: "updateTrialDurationPayload";
	trialDuration?: Maybe<TrialDuration>;
};

export type UpdateUserInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditUserInput>;
};

export type UpdateUserPayload = {
	__typename?: "updateUserPayload";
	user?: Maybe<UsersPermissionsUser>;
};

export type UpdateUserPriorityInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditUserPriorityInput>;
};

export type UpdateUserPriorityPayload = {
	__typename?: "updateUserPriorityPayload";
	userPriority?: Maybe<UserPriority>;
};

export type UpdateWeeklyPlannerInput = {
	where?: Maybe<InputId>;
	data?: Maybe<EditWeeklyPlannerInput>;
};

export type UpdateWeeklyPlannerPayload = {
	__typename?: "updateWeeklyPlannerPayload";
	weeklyPlanner?: Maybe<WeeklyPlanner>;
};

export type WeeklyPlannerEventInput = {
	type: WeeklyPlannerType;
	date: Scalars["Date"];
	slot?: Maybe<WeeklyPlannerEventSlot>;
	resourceId: Scalars["ID"];
};

export enum WeeklyPlannerEventSlot {
	Prebreakfast = "PREBREAKFAST",
	Breakfast = "BREAKFAST",
	Amsnack = "AMSNACK",
	Lunch = "LUNCH",
	Pmsnack = "PMSNACK",
	Dinner = "DINNER",
}

export type WeeklyPlannerEventWeekCaloriesData = {
	__typename?: "weeklyPlannerEventWeekCaloriesData";
	weekCaloriesCount: Scalars["Int"];
	mealsDays: Scalars["Int"];
	caloriesData: CaloriesDataType;
};

export type WeeklyPlannerEventWeekCaloriesInput =
	{
		startDate: Scalars["Date"];
		endDate: Scalars["Date"];
	};

export type EmailConfirmationMutationVariables =
	Exact<{
		code: Scalars["String"];
	}>;

export type EmailConfirmationMutation = {
	__typename?: "Mutation";
} & {
	emailConfirmation?: Maybe<
		{
			__typename?: "UsersPermissionsLoginPayload";
		} & Pick<
			UsersPermissionsLoginPayload,
			"jwt"
		> & {
				user: {
					__typename?: "UsersPermissionsMe";
				} & Pick<UsersPermissionsMe, "id">;
			}
	>;
};

export type ForgotPasswordMutationVariables =
	Exact<{
		email: Scalars["String"];
	}>;

export type ForgotPasswordMutation = {
	__typename?: "Mutation";
} & {
	forgotPassword?: Maybe<
		{
			__typename?: "UserPermissionsPasswordPayload";
		} & Pick<UserPermissionsPasswordPayload, "ok">
	>;
};

export type LoginMutationVariables = Exact<{
	email: Scalars["String"];
	password: Scalars["String"];
}>;

export type LoginMutation = {
	__typename?: "Mutation";
} & {
	login: {
		__typename?: "UsersPermissionsLoginPayload";
	} & Pick<
		UsersPermissionsLoginPayload,
		"jwt"
	> & {
			user: {
				__typename?: "UsersPermissionsMe";
			} & Pick<
				UsersPermissionsMe,
				"email" | "id"
			>;
		};
};

export type RegisterMutationVariables = Exact<{
	email: Scalars["String"];
	password: Scalars["String"];
	name: Scalars["String"];
}>;

export type RegisterMutation = {
	__typename?: "Mutation";
} & {
	newRegister: {
		__typename?: "UsersPermissionsLoginPayload1";
	} & Pick<
		UsersPermissionsLoginPayload1,
		"jwt"
	> & {
			user: {
				__typename?: "UsersPermissionsMe1";
			} & Pick<
				UsersPermissionsMe1,
				"username" | "email" | "name"
			>;
		};
};

export type ResetPasswordMutationVariables =
	Exact<{
		password: Scalars["String"];
		passwordConfirmation: Scalars["String"];
		code: Scalars["String"];
	}>;

export type ResetPasswordMutation = {
	__typename?: "Mutation";
} & {
	resetPassword?: Maybe<
		{
			__typename?: "UsersPermissionsLoginPayload";
		} & {
			user: {
				__typename?: "UsersPermissionsMe";
			} & Pick<UsersPermissionsMe, "email">;
		}
	>;
};

export type GetAllBlogsQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetAllBlogsQuery = {
	__typename?: "Query";
} & {
	blogs?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Blog" } & Pick<
					Blog,
					| "id"
					| "title"
					| "short_description"
					| "createdAt"
				> & {
						blog_category?: Maybe<
							{
								__typename?: "BlogCategory";
							} & Pick<
								BlogCategory,
								"id" | "title"
							>
						>;
						collaborator?: Maybe<
							{
								__typename?: "Collaborator";
							} & Pick<Collaborator, "name">
						>;
						banner_image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
					}
			>
		>
	>;
};

export type GetAllPremiumBlogsQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetAllPremiumBlogsQuery = {
	__typename?: "Query";
} & {
	premiumBlogs?: Maybe<
		Array<
			Maybe<
				{ __typename?: "PremiumBlog" } & Pick<
					PremiumBlog,
					| "id"
					| "title"
					| "short_description"
					| "createdAt"
				> & {
						premium_blog_category?: Maybe<
							{
								__typename?: "PremiumBlogCategory";
							} & Pick<
								PremiumBlogCategory,
								"id" | "title"
							>
						>;
						collaborator?: Maybe<
							{
								__typename?: "Collaborator";
							} & Pick<Collaborator, "name">
						>;
						banner_image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
					}
			>
		>
	>;
};

export type GetAllCollaboratorsQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetAllCollaboratorsQuery = {
	__typename?: "Query";
} & {
	collaborators?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Collaborator" } & Pick<
					Collaborator,
					| "id"
					| "name"
					| "occupation"
					| "location"
				> & {
						image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
					}
			>
		>
	>;
};

export type ContactMutationVariables = Exact<{
	type: Scalars["String"];
	name: Scalars["String"];
	email: Scalars["String"];
	phone?: Maybe<Scalars["String"]>;
	message: Scalars["String"];
	expertise?: Maybe<Scalars["String"]>;
}>;

export type ContactMutation = {
	__typename?: "Mutation";
} & {
	createContactQuery?: Maybe<
		{
			__typename?: "createContactQueryPayload";
		} & {
			contactQuery?: Maybe<
				{ __typename?: "ContactQuery" } & Pick<
					ContactQuery,
					"id"
				>
			>;
		}
	>;
};

export type GetBlogQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type GetBlogQuery = {
	__typename?: "Query";
} & {
	blog?: Maybe<
		{ __typename?: "Blog" } & Pick<
			Blog,
			"id" | "title" | "content" | "createdAt"
		> & {
				blog_category?: Maybe<
					{ __typename?: "BlogCategory" } & Pick<
						BlogCategory,
						"title"
					>
				>;
				collaborator?: Maybe<
					{ __typename?: "Collaborator" } & Pick<
						Collaborator,
						"name"
					>
				>;
				banner_image?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
			}
	>;
};

export type GetPremiumBlogQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type GetPremiumBlogQuery = {
	__typename?: "Query";
} & {
	premiumBlog?: Maybe<
		{ __typename?: "PremiumBlog" } & Pick<
			PremiumBlog,
			"id" | "title" | "content" | "createdAt"
		> & {
				premium_blog_category?: Maybe<
					{
						__typename?: "PremiumBlogCategory";
					} & Pick<PremiumBlogCategory, "title">
				>;
				collaborator?: Maybe<
					{ __typename?: "Collaborator" } & Pick<
						Collaborator,
						"name"
					>
				>;
				banner_image?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
			}
	>;
};

export type GetBlogCategoriesQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetBlogCategoriesQuery = {
	__typename?: "Query";
} & {
	blogCategories?: Maybe<
		Array<
			Maybe<
				{ __typename?: "BlogCategory" } & Pick<
					BlogCategory,
					"id" | "title"
				>
			>
		>
	>;
};

export type GetPremiumBlogCategoriesQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetPremiumBlogCategoriesQuery = {
	__typename?: "Query";
} & {
	premiumBlogCategories?: Maybe<
		Array<
			Maybe<
				{
					__typename?: "PremiumBlogCategory";
				} & Pick<
					PremiumBlogCategory,
					"id" | "title"
				>
			>
		>
	>;
};

export type GetCollaboratorQueryVariables =
	Exact<{
		id: Scalars["ID"];
	}>;

export type GetCollaboratorQuery = {
	__typename?: "Query";
} & {
	collaborator?: Maybe<
		{ __typename?: "Collaborator" } & Pick<
			Collaborator,
			| "id"
			| "name"
			| "occupation"
			| "location"
			| "expertise"
			| "expertise_rich"
			| "why"
			| "why_rich"
		> & {
				image?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
				links?: Maybe<
					Array<
						Maybe<
							{
								__typename?: "ComponentLinksLinks";
							} & Pick<
								ComponentLinksLinks,
								"type" | "url"
							>
						>
					>
				>;
			}
	>;
};

export type GetLimitedBlogsQueryVariables =
	Exact<{
		limit?: Maybe<Scalars["Int"]>;
	}>;

export type GetLimitedBlogsQuery = {
	__typename?: "Query";
} & {
	blogs?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Blog" } & Pick<
					Blog,
					"id" | "title" | "createdAt"
				> & {
						blog_category?: Maybe<
							{
								__typename?: "BlogCategory";
							} & Pick<
								BlogCategory,
								"id" | "title"
							>
						>;
						collaborator?: Maybe<
							{
								__typename?: "Collaborator";
							} & Pick<Collaborator, "name">
						>;
						banner_image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
					}
			>
		>
	>;
};

export type NewsletterMutationVariables = Exact<{
	email: Scalars["String"];
}>;

export type NewsletterMutation = {
	__typename?: "Mutation";
} & Pick<Mutation, "subscribe">;

export type QuotesQueryVariables = Exact<{
	[key: string]: never;
}>;

export type QuotesQuery = {
	__typename?: "Query";
} & {
	dailyQuotes?: Maybe<
		Array<
			Maybe<
				{ __typename?: "DailyQuote" } & Pick<
					DailyQuote,
					"quote" | "author" | "id"
				>
			>
		>
	>;
};

export type CreateWeeklyPlannerEventMutationVariables =
	Exact<{
		data: WeeklyPlannerEventInput;
	}>;

export type CreateWeeklyPlannerEventMutation = {
	__typename?: "Mutation";
} & {
	createWeeklyPlannerEvent?: Maybe<
		{ __typename?: "WeeklyPlanner" } & Pick<
			WeeklyPlanner,
			| "id"
			| "type"
			| "date"
			| "slot"
			| "createdAt"
		>
	>;
};

export type DeleteWeeklyPlannerEventMutationVariables =
	Exact<{
		id: Scalars["ID"];
	}>;

export type DeleteWeeklyPlannerEventMutation = {
	__typename?: "Mutation";
} & Pick<Mutation, "deleteWeeklyPlannerEvent">;

export type GetAllJournalPromptsQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetAllJournalPromptsQuery = {
	__typename?: "Query";
} & {
	journalPrompts?: Maybe<
		Array<
			Maybe<
				{ __typename?: "JournalPrompts" } & Pick<
					JournalPrompts,
					"id" | "description" | "createdAt"
				>
			>
		>
	>;
};

export type GetAllMantrasListingQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetAllMantrasListingQuery = {
	__typename?: "Query";
} & {
	mantras?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Mantra" } & Pick<
					Mantra,
					| "id"
					| "title"
					| "short_description"
					| "content"
					| "reading_time"
					| "vimeo_url"
				> & {
						cover_image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
						collaborator?: Maybe<
							{
								__typename?: "Collaborator";
							} & Pick<Collaborator, "name"> & {
									image?: Maybe<
										{
											__typename?: "UploadFile";
										} & Pick<UploadFile, "url">
									>;
								}
						>;
					}
			>
		>
	>;
};

export type GetAllMastisListingQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetAllMastisListingQuery = {
	__typename?: "Query";
} & {
	mastis?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Masti" } & Pick<
					Masti,
					| "id"
					| "title"
					| "short_description"
					| "content"
					| "reading_time"
					| "vimeo_url"
				> & {
						cover_image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
						collaborator?: Maybe<
							{
								__typename?: "Collaborator";
							} & Pick<Collaborator, "name"> & {
									image?: Maybe<
										{
											__typename?: "UploadFile";
										} & Pick<UploadFile, "url">
									>;
								}
						>;
					}
			>
		>
	>;
};

export type GetAllRecipesListingQueryVariables =
	Exact<{
		mode: PublicationState;
	}>;

export type GetAllRecipesListingQuery = {
	__typename?: "Query";
} & {
	recipes?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Recipes" } & Pick<
					Recipes,
					| "id"
					| "createdAt"
					| "No_Of_Servings"
					| "Calories"
					| "Title"
					| "keywords"
				> & {
						Prep_Duration?: Maybe<
							{
								__typename?: "ComponentMealsDuration";
							} & Pick<
								ComponentMealsDuration,
								"id" | "duration" | "unit"
							>
						>;
						Cook_Duration?: Maybe<
							{
								__typename?: "ComponentMealsDuration";
							} & Pick<
								ComponentMealsDuration,
								"id" | "duration" | "unit"
							>
						>;
						collaborator?: Maybe<
							{
								__typename?: "Collaborator";
							} & Pick<
								Collaborator,
								"id" | "name"
							> & {
									image?: Maybe<
										{
											__typename?: "UploadFile";
										} & Pick<
											UploadFile,
											"id" | "url"
										>
									>;
								}
						>;
						thumbnail?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url" | "id">
						>;
						Ingredients?: Maybe<
							{
								__typename?: "ComponentMealsIngredientsWithImages";
							} & {
								IngredientsWithTitle?: Maybe<
									Array<
										Maybe<
											{
												__typename?: "ComponentMealsIngredientsWithTitle";
											} & Pick<
												ComponentMealsIngredientsWithTitle,
												"Title"
											> & {
													Ingredients?: Maybe<
														Array<
															Maybe<
																{
																	__typename?: "ComponentMealsIngredients";
																} & Pick<
																	ComponentMealsIngredients,
																	"Ingredients_Name"
																>
															>
														>
													>;
												}
										>
									>
								>;
							}
						>;
						meal_categories?: Maybe<
							Array<
								Maybe<
									{
										__typename?: "MealCategory";
									} & Pick<
										MealCategory,
										"id" | "Name" | "order"
									>
								>
							>
						>;
					}
			>
		>
	>;
};

export type GetCitiesMutationVariables = Exact<{
	country: Scalars["String"];
	state: Scalars["String"];
}>;

export type GetCitiesMutation = {
	__typename?: "Mutation";
} & {
	cities: {
		__typename?: "cityListWithTimezoneList";
	} & {
		cities?: Maybe<
			Array<
				{ __typename?: "cityList" } & Pick<
					CityList,
					"id" | "name"
				>
			>
		>;
		timezone?: Maybe<
			Array<
				{ __typename?: "timeZone" } & Pick<
					TimeZone,
					| "gmtOffsetName"
					| "zoneName"
					| "gmtOffset"
				>
			>
		>;
	};
};

export type GetCountriesQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetCountriesQuery = {
	__typename?: "Query";
} & {
	countries?: Maybe<
		Array<
			{ __typename?: "countryList" } & Pick<
				CountryList,
				"id" | "name"
			>
		>
	>;
};

export type GetFoodIndexListingQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetFoodIndexListingQuery = {
	__typename?: "Query";
} & {
	foodIndices?: Maybe<
		Array<
			Maybe<
				{ __typename?: "FoodIndex" } & Pick<
					FoodIndex,
					"id" | "name"
				> & {
						image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
					}
			>
		>
	>;
};

export type GetFoodIndexDetailsQueryVariables =
	Exact<{
		id: Scalars["ID"];
	}>;

export type GetFoodIndexDetailsQuery = {
	__typename?: "Query";
} & {
	foodIndex?: Maybe<
		{ __typename?: "FoodIndex" } & Pick<
			FoodIndex,
			"id" | "name" | "ValueDisclaimer" | "qty"
		> & {
				image?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
				insights?: Maybe<
					{
						__typename?: "ComponentMealsInsights";
					} & Pick<
						ComponentMealsInsights,
						| "Nutritional_Insight_rich"
						| "Ayurveda_Insight_rich"
					> & {
							Nutritional_Insight?: Maybe<
								Array<
									Maybe<
										{
											__typename?: "ComponentMealsNutritionalInsight";
										} & Pick<
											ComponentMealsNutritionalInsight,
											"Description"
										>
									>
								>
							>;
							Ayurveda_Insight?: Maybe<
								Array<
									Maybe<
										{
											__typename?: "ComponentMealsAyurvedaInsight";
										} & Pick<
											ComponentMealsAyurvedaInsight,
											"Description"
										>
									>
								>
							>;
						}
				>;
				values?: Maybe<
					Array<
						Maybe<
							{
								__typename?: "ComponentMealsNutritionFacts";
							} & Pick<
								ComponentMealsNutritionFacts,
								"Title" | "Unit"
							> & {
									Sub_Nutrition_facts?: Maybe<
										Array<
											Maybe<
												{
													__typename?: "ComponentMealsSubNutritionFacts";
												} & Pick<
													ComponentMealsSubNutritionFacts,
													"Title" | "Unit"
												>
											>
										>
									>;
								}
						>
					>
				>;
			}
	>;
};

export type GetMantraDetailQueryVariables =
	Exact<{
		id: Scalars["ID"];
	}>;

export type GetMantraDetailQuery = {
	__typename?: "Query";
} & {
	mantra?: Maybe<
		{ __typename?: "Mantra" } & Pick<
			Mantra,
			| "id"
			| "title"
			| "reading_time"
			| "content"
			| "vimeo_url"
		> & {
				collaborator?: Maybe<
					{ __typename?: "Collaborator" } & Pick<
						Collaborator,
						"name"
					>
				>;
				cover_image?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
			}
	>;
};

export type GetMastiDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type GetMastiDetailQuery = {
	__typename?: "Query";
} & {
	masti?: Maybe<
		{ __typename?: "Masti" } & Pick<
			Masti,
			| "id"
			| "title"
			| "reading_time"
			| "content"
			| "vimeo_url"
		> & {
				collaborator?: Maybe<
					{ __typename?: "Collaborator" } & Pick<
						Collaborator,
						"name"
					>
				>;
				cover_image?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
			}
	>;
};

export type GetMeditationByCategoryQueryVariables =
	Exact<{
		categoryId: Scalars["ID"];
	}>;

export type GetMeditationByCategoryQuery = {
	__typename?: "Query";
} & {
	meditations?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Meditation" } & Pick<
					Meditation,
					"id" | "title" | "duration"
				> & {
						thumbnail?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
						collaborator?: Maybe<
							{
								__typename?: "Collaborator";
							} & Pick<Collaborator, "name">
						>;
						meditation_category?: Maybe<
							{
								__typename?: "MeditationCategory";
							} & Pick<
								MeditationCategory,
								"id" | "name"
							>
						>;
					}
			>
		>
	>;
};

export type GetMeditationCategoriesQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetMeditationCategoriesQuery = {
	__typename?: "Query";
} & {
	meditationCategories?: Maybe<
		Array<
			Maybe<
				{
					__typename?: "MeditationCategory";
				} & Pick<
					MeditationCategory,
					"id" | "name"
				> & {
						image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
					}
			>
		>
	>;
};

export type GetMeditationDetailsQueryVariables =
	Exact<{
		id: Scalars["ID"];
	}>;

export type GetMeditationDetailsQuery = {
	__typename?: "Query";
} & {
	meditation?: Maybe<
		{ __typename?: "Meditation" } & Pick<
			Meditation,
			| "id"
			| "title"
			| "vimeo_url"
			| "content"
			| "duration"
		> & {
				collaborator?: Maybe<
					{ __typename?: "Collaborator" } & Pick<
						Collaborator,
						"name"
					>
				>;
				thumbnail?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
			}
	>;
};

export type GetMovementCategoriesQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetMovementCategoriesQuery = {
	__typename?: "Query";
} & {
	movementCategories?: Maybe<
		Array<
			Maybe<
				{
					__typename?: "MovementCategory";
				} & Pick<
					MovementCategory,
					"id" | "name"
				> & {
						image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
					}
			>
		>
	>;
};

export type GetMovementDetailsQueryVariables =
	Exact<{
		id: Scalars["ID"];
	}>;

export type GetMovementDetailsQuery = {
	__typename?: "Query";
} & {
	movement?: Maybe<
		{ __typename?: "Movement" } & Pick<
			Movement,
			| "id"
			| "title"
			| "vimeo_url"
			| "content"
			| "duration"
			| "calories_burn"
		> & {
				collaborator?: Maybe<
					{ __typename?: "Collaborator" } & Pick<
						Collaborator,
						"name"
					>
				>;
				thumbnail?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
			}
	>;
};

export type GetMovementsByCategoryQueryVariables =
	Exact<{
		categoryId: Scalars["ID"];
	}>;

export type GetMovementsByCategoryQuery = {
	__typename?: "Query";
} & {
	movements?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Movement" } & Pick<
					Movement,
					| "id"
					| "title"
					| "duration"
					| "calories_burn"
				> & {
						thumbnail?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
						collaborator?: Maybe<
							{
								__typename?: "Collaborator";
							} & Pick<Collaborator, "name">
						>;
						movement_category?: Maybe<
							{
								__typename?: "MovementCategory";
							} & Pick<
								MovementCategory,
								"id" | "name"
							>
						>;
					}
			>
		>
	>;
};

export type GetMyPrioritiesQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetMyPrioritiesQuery = {
	__typename?: "Query";
} & {
	getMyPriorities: {
		__typename?: "UserPriority";
	} & Pick<UserPriority, "id" | "updatedAt"> & {
			priorities?: Maybe<
				Array<
					Maybe<
						{
							__typename?: "ComponentPrioritiesPriorities";
						} & Pick<
							ComponentPrioritiesPriorities,
							| "id"
							| "type"
							| "title"
							| "description"
							| "checkin"
						>
					>
				>
			>;
		};
};

export type GetPrioritiesQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetPrioritiesQuery = {
	__typename?: "Query";
} & {
	priorities?: Maybe<
		Array<
			Maybe<
				{ __typename?: "Priority" } & Pick<
					Priority,
					| "id"
					| "title"
					| "description"
					| "category"
				>
			>
		>
	>;
};

export type GetRecipesQueryVariables = Exact<{
	id: Scalars["ID"];
	mode: PublicationState;
}>;

export type GetRecipesQuery = {
	__typename?: "Query";
} & {
	recipe?: Maybe<
		{ __typename?: "Recipes" } & Pick<
			Recipes,
			| "id"
			| "updatedAt"
			| "ValueDisclaimer"
			| "InsightNotes"
			| "No_Of_Servings"
			| "Calories"
			| "Title"
			| "keywords"
		> & {
				SlideShow?: Maybe<
					{
						__typename?: "ComponentMealsRecipes";
					} & Pick<
						ComponentMealsRecipes,
						"id"
					> & {
							images?: Maybe<
								Array<
									Maybe<
										{
											__typename?: "UploadFile";
										} & Pick<UploadFile, "url">
									>
								>
							>;
						}
				>;
				Prep_Duration?: Maybe<
					{
						__typename?: "ComponentMealsDuration";
					} & Pick<
						ComponentMealsDuration,
						"duration" | "unit"
					>
				>;
				Cook_Duration?: Maybe<
					{
						__typename?: "ComponentMealsDuration";
					} & Pick<
						ComponentMealsDuration,
						"duration" | "unit"
					>
				>;
				Ingredients?: Maybe<
					{
						__typename?: "ComponentMealsIngredientsWithImages";
					} & {
						Image?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "url">
						>;
						IngredientsWithTitle?: Maybe<
							Array<
								Maybe<
									{
										__typename?: "ComponentMealsIngredientsWithTitle";
									} & Pick<
										ComponentMealsIngredientsWithTitle,
										"Title"
									> & {
											Ingredients?: Maybe<
												Array<
													Maybe<
														{
															__typename?: "ComponentMealsIngredients";
														} & Pick<
															ComponentMealsIngredients,
															| "id"
															| "Ingredients_Name"
															| "Unit"
														>
													>
												>
											>;
										}
								>
							>
						>;
					}
				>;
				Insights?: Maybe<
					{
						__typename?: "ComponentMealsInsights";
					} & Pick<
						ComponentMealsInsights,
						| "Nutritional_Insight_rich"
						| "Ayurveda_Insight_rich"
					> & {
							Nutritional_Insight?: Maybe<
								Array<
									Maybe<
										{
											__typename?: "ComponentMealsNutritionalInsight";
										} & Pick<
											ComponentMealsNutritionalInsight,
											"Description"
										>
									>
								>
							>;
							Ayurveda_Insight?: Maybe<
								Array<
									Maybe<
										{
											__typename?: "ComponentMealsAyurvedaInsight";
										} & Pick<
											ComponentMealsAyurvedaInsight,
											"Description"
										>
									>
								>
							>;
						}
				>;
				Instructions?: Maybe<
					{
						__typename?: "ComponentMealsInstructions";
					} & Pick<
						ComponentMealsInstructions,
						"Variations"
					> & {
							Steps?: Maybe<
								Array<
									Maybe<
										{
											__typename?: "ComponentMealsSteps";
										} & Pick<
											ComponentMealsSteps,
											"Step_Description"
										> & {
												Step_Images?: Maybe<
													Array<
														Maybe<
															{
																__typename?: "UploadFile";
															} & Pick<
																UploadFile,
																"url"
															>
														>
													>
												>;
											}
									>
								>
							>;
						}
				>;
				Nutrition_facts?: Maybe<
					Array<
						Maybe<
							{
								__typename?: "ComponentMealsNutritionFacts";
							} & Pick<
								ComponentMealsNutritionFacts,
								"Title" | "Unit"
							> & {
									Sub_Nutrition_facts?: Maybe<
										Array<
											Maybe<
												{
													__typename?: "ComponentMealsSubNutritionFacts";
												} & Pick<
													ComponentMealsSubNutritionFacts,
													"Title" | "Unit"
												>
											>
										>
									>;
								}
						>
					>
				>;
				collaborator?: Maybe<
					{ __typename?: "Collaborator" } & Pick<
						Collaborator,
						"id" | "name"
					> & {
							image?: Maybe<
								{
									__typename?: "UploadFile";
								} & Pick<UploadFile, "url">
							>;
						}
				>;
				thumbnail?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"url"
					>
				>;
				meal_categories?: Maybe<
					Array<
						Maybe<
							{
								__typename?: "MealCategory";
							} & Pick<MealCategory, "Name">
						>
					>
				>;
			}
	>;
};

export type GetStatesMutationVariables = Exact<{
	country: Scalars["String"];
}>;

export type GetStatesMutation = {
	__typename?: "Mutation";
} & {
	states?: Maybe<
		Array<
			{ __typename?: "stateList" } & Pick<
				StateList,
				"id" | "name"
			>
		>
	>;
};

export type EventsFieldsFragment = {
	__typename?: "listWeeklyPlannerEventsData";
} & {
	events?: Maybe<
		Array<
			{ __typename?: "WeeklyPlanner" } & Pick<
				WeeklyPlanner,
				| "id"
				| "type"
				| "date"
				| "slot"
				| "createdAt"
			> & {
					recipe?: Maybe<
						{ __typename?: "Recipes" } & Pick<
							Recipes,
							"id" | "Title" | "Calories"
						> & {
								collaborator?: Maybe<
									{
										__typename?: "Collaborator";
									} & Pick<Collaborator, "name">
								>;
								thumbnail?: Maybe<
									{
										__typename?: "UploadFile";
									} & Pick<UploadFile, "url">
								>;
								Prep_Duration?: Maybe<
									{
										__typename?: "ComponentMealsDuration";
									} & Pick<
										ComponentMealsDuration,
										"duration"
									>
								>;
							}
					>;
					movement?: Maybe<
						{ __typename?: "Movement" } & Pick<
							Movement,
							| "id"
							| "title"
							| "duration"
							| "calories_burn"
						> & {
								collaborator?: Maybe<
									{
										__typename?: "Collaborator";
									} & Pick<Collaborator, "name">
								>;
								thumbnail?: Maybe<
									{
										__typename?: "UploadFile";
									} & Pick<UploadFile, "url">
								>;
								movement_category?: Maybe<
									{
										__typename?: "MovementCategory";
									} & Pick<MovementCategory, "id">
								>;
							}
					>;
					meditation?: Maybe<
						{ __typename?: "Meditation" } & Pick<
							Meditation,
							"id" | "title" | "duration"
						> & {
								collaborator?: Maybe<
									{
										__typename?: "Collaborator";
									} & Pick<Collaborator, "name">
								>;
								thumbnail?: Maybe<
									{
										__typename?: "UploadFile";
									} & Pick<UploadFile, "url">
								>;
								meditation_category?: Maybe<
									{
										__typename?: "MeditationCategory";
									} & Pick<
										MeditationCategory,
										"id"
									>
								>;
							}
					>;
					mantra?: Maybe<
						{ __typename?: "Mantra" } & Pick<
							Mantra,
							"id" | "title"
						> & {
								cover_image?: Maybe<
									{
										__typename?: "UploadFile";
									} & Pick<UploadFile, "url">
								>;
								collaborator?: Maybe<
									{
										__typename?: "Collaborator";
									} & Pick<Collaborator, "name">
								>;
							}
					>;
					masti?: Maybe<
						{ __typename?: "Masti" } & Pick<
							Masti,
							"id" | "title" | "reading_time"
						> & {
								cover_image?: Maybe<
									{
										__typename?: "UploadFile";
									} & Pick<UploadFile, "url">
								>;
								collaborator?: Maybe<
									{
										__typename?: "Collaborator";
									} & Pick<Collaborator, "name">
								>;
							}
					>;
				}
		>
	>;
};

export type ListWeeklyPlannerEventsPrintFriendlyQueryVariables =
	Exact<{
		day1: Scalars["Date"];
		day2: Scalars["Date"];
		day3: Scalars["Date"];
		day4: Scalars["Date"];
		day5: Scalars["Date"];
		day6: Scalars["Date"];
		day7: Scalars["Date"];
	}>;

export type ListWeeklyPlannerEventsPrintFriendlyQuery =
	{ __typename?: "Query" } & {
		day1?: Maybe<
			{
				__typename?: "listWeeklyPlannerEventsData";
			} & EventsFieldsFragment
		>;
		day2?: Maybe<
			{
				__typename?: "listWeeklyPlannerEventsData";
			} & EventsFieldsFragment
		>;
		day3?: Maybe<
			{
				__typename?: "listWeeklyPlannerEventsData";
			} & EventsFieldsFragment
		>;
		day4?: Maybe<
			{
				__typename?: "listWeeklyPlannerEventsData";
			} & EventsFieldsFragment
		>;
		day5?: Maybe<
			{
				__typename?: "listWeeklyPlannerEventsData";
			} & EventsFieldsFragment
		>;
		day6?: Maybe<
			{
				__typename?: "listWeeklyPlannerEventsData";
			} & EventsFieldsFragment
		>;
		day7?: Maybe<
			{
				__typename?: "listWeeklyPlannerEventsData";
			} & EventsFieldsFragment
		>;
	};

export type GetweeklyPlannerEventWeekCaloriesQueryVariables =
	Exact<{
		startDate: Scalars["Date"];
		endDate: Scalars["Date"];
	}>;

export type GetweeklyPlannerEventWeekCaloriesQuery =
	{ __typename?: "Query" } & {
		weeklyPlannerEventWeekCalories?: Maybe<
			{
				__typename?: "weeklyPlannerEventWeekCaloriesData";
			} & Pick<
				WeeklyPlannerEventWeekCaloriesData,
				"weekCaloriesCount" | "mealsDays"
			> & {
					caloriesData: {
						__typename?: "caloriesDataType";
					} & Pick<
						CaloriesDataType,
						| "totalFat"
						| "satFat"
						| "proteins"
						| "totalCarbs"
						| "cholesterol"
						| "sodium"
						| "fiber"
						| "sugar"
					>;
				}
		>;
	};

export type GetMyGroceryListQueryVariables =
	Exact<{ [key: string]: never }>;

export type GetMyGroceryListQuery = {
	__typename?: "Query";
} & {
	getMyGroceryList?: Maybe<
		Array<
			{ __typename?: "groceryListCustom" } & Pick<
				GroceryListCustom,
				| "id"
				| "description"
				| "checked"
				| "category"
			> & {
					user: {
						__typename?: "UsersPermissionsMe";
					} & Pick<
						UsersPermissionsMe,
						"id" | "username"
					>;
				}
		>
	>;
};

export type CreateMyListItemMutationVariables =
	Exact<{
		decription: Scalars["String"];
		category: Scalars["String"];
	}>;

export type CreateMyListItemMutation = {
	__typename?: "Mutation";
} & {
	createMyListItem?: Maybe<
		Array<
			{ __typename?: "groceryListCustom" } & Pick<
				GroceryListCustom,
				| "id"
				| "description"
				| "checked"
				| "category"
			> & {
					user: {
						__typename?: "UsersPermissionsMe";
					} & Pick<
						UsersPermissionsMe,
						"id" | "username"
					>;
				}
		>
	>;
};

export type UpdateMyListItemMutationVariables =
	Exact<{
		id: Scalars["ID"];
		decription: Scalars["String"];
		category: Scalars["String"];
	}>;

export type UpdateMyListItemMutation = {
	__typename?: "Mutation";
} & {
	updateMyListItem?: Maybe<
		Array<
			{ __typename?: "groceryListCustom" } & Pick<
				GroceryListCustom,
				| "id"
				| "description"
				| "checked"
				| "category"
			> & {
					user: {
						__typename?: "UsersPermissionsMe";
					} & Pick<
						UsersPermissionsMe,
						"id" | "username"
					>;
				}
		>
	>;
};

export type DeleteMyListItemMutationVariables =
	Exact<{
		id: Scalars["ID"];
	}>;

export type DeleteMyListItemMutation = {
	__typename?: "Mutation";
} & {
	deleteMyListItem?: Maybe<
		Array<
			{ __typename?: "groceryListCustom" } & Pick<
				GroceryListCustom,
				| "id"
				| "description"
				| "checked"
				| "category"
			> & {
					user: {
						__typename?: "UsersPermissionsMe";
					} & Pick<
						UsersPermissionsMe,
						"id" | "username"
					>;
				}
		>
	>;
};

export type DeleteAllMyListQueryVariables =
	Exact<{ [key: string]: never }>;

export type DeleteAllMyListQuery = {
	__typename?: "Query";
} & {
	deleteAllMyList?: Maybe<
		Array<
			{ __typename?: "groceryListCustom" } & Pick<
				GroceryListCustom,
				| "id"
				| "description"
				| "checked"
				| "category"
			> & {
					user: {
						__typename?: "UsersPermissionsMe";
					} & Pick<
						UsersPermissionsMe,
						"id" | "username"
					>;
				}
		>
	>;
};

export type CheckMyListItemMutationVariables =
	Exact<{
		id: Scalars["ID"];
		checked: Scalars["Boolean"];
	}>;

export type CheckMyListItemMutation = {
	__typename?: "Mutation";
} & {
	checkedMyListItem?: Maybe<
		Array<
			{ __typename?: "groceryListCustom" } & Pick<
				GroceryListCustom,
				| "id"
				| "description"
				| "checked"
				| "category"
			> & {
					user: {
						__typename?: "UsersPermissionsMe";
					} & Pick<
						UsersPermissionsMe,
						"id" | "username"
					>;
				}
		>
	>;
};

export type DeleteMyListItemByCategoryMutationVariables =
	Exact<{
		category: Scalars["String"];
	}>;

export type DeleteMyListItemByCategoryMutation = {
	__typename?: "Mutation";
} & {
	deleteMyListItemByCategory?: Maybe<
		Array<
			{ __typename?: "groceryListCustom" } & Pick<
				GroceryListCustom,
				| "id"
				| "description"
				| "checked"
				| "category"
			> & {
					user: {
						__typename?: "UsersPermissionsMe";
					} & Pick<
						UsersPermissionsMe,
						"id" | "username"
					>;
				}
		>
	>;
};

export type ListWeeklyPlannerEventsQueryVariables =
	Exact<{
		date: Scalars["Date"];
	}>;

export type ListWeeklyPlannerEventsQuery = {
	__typename?: "Query";
} & {
	listWeeklyPlannerEvents?: Maybe<
		{
			__typename?: "listWeeklyPlannerEventsData";
		} & Pick<
			ListWeeklyPlannerEventsData,
			"dayCaloriesCount"
		> & {
				caloriesData: {
					__typename?: "caloriesDataType";
				} & Pick<
					CaloriesDataType,
					| "totalFat"
					| "satFat"
					| "proteins"
					| "totalCarbs"
					| "cholesterol"
					| "sodium"
					| "fiber"
					| "sugar"
				>;
				events?: Maybe<
					Array<
						{
							__typename?: "WeeklyPlanner";
						} & Pick<
							WeeklyPlanner,
							| "id"
							| "type"
							| "date"
							| "slot"
							| "createdAt"
						> & {
								recipe?: Maybe<
									{
										__typename?: "Recipes";
									} & Pick<
										Recipes,
										"id" | "Title" | "Calories"
									> & {
											collaborator?: Maybe<
												{
													__typename?: "Collaborator";
												} & Pick<
													Collaborator,
													"name"
												>
											>;
											thumbnail?: Maybe<
												{
													__typename?: "UploadFile";
												} & Pick<
													UploadFile,
													"url"
												>
											>;
											Prep_Duration?: Maybe<
												{
													__typename?: "ComponentMealsDuration";
												} & Pick<
													ComponentMealsDuration,
													"duration"
												>
											>;
										}
								>;
								movement?: Maybe<
									{
										__typename?: "Movement";
									} & Pick<
										Movement,
										| "id"
										| "title"
										| "duration"
										| "calories_burn"
									> & {
											collaborator?: Maybe<
												{
													__typename?: "Collaborator";
												} & Pick<
													Collaborator,
													"name"
												>
											>;
											thumbnail?: Maybe<
												{
													__typename?: "UploadFile";
												} & Pick<
													UploadFile,
													"url"
												>
											>;
											movement_category?: Maybe<
												{
													__typename?: "MovementCategory";
												} & Pick<
													MovementCategory,
													"id"
												>
											>;
										}
								>;
								meditation?: Maybe<
									{
										__typename?: "Meditation";
									} & Pick<
										Meditation,
										"id" | "title" | "duration"
									> & {
											collaborator?: Maybe<
												{
													__typename?: "Collaborator";
												} & Pick<
													Collaborator,
													"name"
												>
											>;
											thumbnail?: Maybe<
												{
													__typename?: "UploadFile";
												} & Pick<
													UploadFile,
													"url"
												>
											>;
											meditation_category?: Maybe<
												{
													__typename?: "MeditationCategory";
												} & Pick<
													MeditationCategory,
													"id"
												>
											>;
										}
								>;
								mantra?: Maybe<
									{
										__typename?: "Mantra";
									} & Pick<
										Mantra,
										"id" | "title"
									> & {
											cover_image?: Maybe<
												{
													__typename?: "UploadFile";
												} & Pick<
													UploadFile,
													"url"
												>
											>;
											collaborator?: Maybe<
												{
													__typename?: "Collaborator";
												} & Pick<
													Collaborator,
													"name"
												>
											>;
										}
								>;
								masti?: Maybe<
									{ __typename?: "Masti" } & Pick<
										Masti,
										| "id"
										| "title"
										| "reading_time"
									> & {
											cover_image?: Maybe<
												{
													__typename?: "UploadFile";
												} & Pick<
													UploadFile,
													"url"
												>
											>;
											collaborator?: Maybe<
												{
													__typename?: "Collaborator";
												} & Pick<
													Collaborator,
													"name"
												>
											>;
										}
								>;
							}
					>
				>;
			}
	>;
};

export type MeQueryVariables = Exact<{
	[key: string]: never;
}>;

export type MeQuery = { __typename?: "Query" } & {
	newMe?: Maybe<
		{ __typename?: "UsersPermissionsMe1" } & Pick<
			UsersPermissionsMe1,
			| "id"
			| "email"
			| "name"
			| "dob"
			| "country"
			| "city"
			| "state"
			| "timezone"
			| "bio"
			| "createdAt"
			| "what"
			| "why"
			| "trialDays"
			| "subEnd"
			| "subPlanId"
			| "subStatus"
			| "profession"
			| "interests"
		> & {
				avatar?: Maybe<
					{ __typename?: "UploadFile" } & Pick<
						UploadFile,
						"id" | "previewUrl" | "url"
					>
				>;
			}
	>;
};

export type PlansQueryVariables = Exact<{
	[key: string]: never;
}>;

export type PlansQuery = {
	__typename?: "Query";
} & {
	subscriptionPlans?: Maybe<
		Array<
			Maybe<
				{
					__typename?: "SubscriptionPlans";
				} & Pick<
					SubscriptionPlans,
					| "id"
					| "plantitle"
					| "stripepriceid"
					| "price"
					| "duration"
				>
			>
		>
	>;
};

export type SubscribePlanMutationVariables =
	Exact<{
		planId: Scalars["String"];
	}>;

export type SubscribePlanMutation = {
	__typename?: "Mutation";
} & {
	subscribePlan: {
		__typename?: "PlanDetails";
	} & Pick<PlanDetails, "url">;
};

export type UpdateMyPrioritiesMutationVariables =
	Exact<{
		priorities?: Maybe<
			| Array<Maybe<PriorityUpdateInput>>
			| Maybe<PriorityUpdateInput>
		>;
	}>;

export type UpdateMyPrioritiesMutation = {
	__typename?: "Mutation";
} & {
	updateMyPriorities: {
		__typename?: "UserPriority";
	} & Pick<UserPriority, "id" | "updatedAt"> & {
			priorities?: Maybe<
				Array<
					Maybe<
						{
							__typename?: "ComponentPrioritiesPriorities";
						} & Pick<
							ComponentPrioritiesPriorities,
							| "id"
							| "type"
							| "title"
							| "description"
							| "checkin"
						>
					>
				>
			>;
		};
};

export type UpdateProfileMutationVariables =
	Exact<{
		id: Scalars["ID"];
		name: Scalars["String"];
		dob: Scalars["Date"];
		country: Scalars["String"];
		state: Scalars["String"];
		city: Scalars["String"];
		timezone: Scalars["String"];
		bio: Scalars["String"];
		file: Scalars["Upload"];
		interests?: Maybe<Scalars["String"]>;
		profession?: Maybe<Scalars["String"]>;
	}>;

export type UpdateProfileMutation = {
	__typename?: "Mutation";
} & {
	upload: { __typename?: "UploadFile" } & Pick<
		UploadFile,
		"id" | "url"
	>;
	updateUser?: Maybe<
		{ __typename?: "updateUserPayload" } & {
			user?: Maybe<
				{
					__typename?: "UsersPermissionsUser";
				} & Pick<
					UsersPermissionsUser,
					| "name"
					| "dob"
					| "country"
					| "state"
					| "city"
					| "timezone"
					| "bio"
					| "interests"
					| "profession"
				> & {
						avatar?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "id" | "url">
						>;
					}
			>;
		}
	>;
};

export type UpdateProfileWoImageMutationVariables =
	Exact<{
		id: Scalars["ID"];
		name: Scalars["String"];
		dob: Scalars["Date"];
		country: Scalars["String"];
		state: Scalars["String"];
		city: Scalars["String"];
		timezone: Scalars["String"];
		bio: Scalars["String"];
		interests?: Maybe<Scalars["String"]>;
		profession?: Maybe<Scalars["String"]>;
	}>;

export type UpdateProfileWoImageMutation = {
	__typename?: "Mutation";
} & {
	updateUser?: Maybe<
		{ __typename?: "updateUserPayload" } & {
			user?: Maybe<
				{
					__typename?: "UsersPermissionsUser";
				} & Pick<
					UsersPermissionsUser,
					| "name"
					| "dob"
					| "country"
					| "state"
					| "city"
					| "timezone"
					| "bio"
					| "interests"
					| "profession"
				> & {
						avatar?: Maybe<
							{
								__typename?: "UploadFile";
							} & Pick<UploadFile, "id" | "url">
						>;
					}
			>;
		}
	>;
};

export type UpdateWhatWhyMutationVariables =
	Exact<{
		id: Scalars["ID"];
		what: Scalars["String"];
		why: Scalars["String"];
	}>;

export type UpdateWhatWhyMutation = {
	__typename?: "Mutation";
} & {
	updateUser?: Maybe<
		{ __typename?: "updateUserPayload" } & {
			user?: Maybe<
				{
					__typename?: "UsersPermissionsUser";
				} & Pick<
					UsersPermissionsUser,
					"what" | "why"
				>
			>;
		}
	>;
};

export const EventsFieldsFragmentDoc = gql`
	fragment eventsFields on listWeeklyPlannerEventsData {
		events {
			id
			type
			date
			slot
			createdAt
			recipe {
				id
				Title
				collaborator {
					name
				}
				thumbnail {
					url
				}
				Prep_Duration {
					duration
				}
				Calories
			}
			movement {
				id
				title
				collaborator {
					name
				}
				thumbnail {
					url
				}
				duration
				calories_burn
				movement_category {
					id
				}
			}
			meditation {
				id
				title
				collaborator {
					name
				}
				thumbnail {
					url
				}
				duration
				meditation_category {
					id
				}
			}
			mantra {
				id
				title
				cover_image {
					url
				}
				collaborator {
					name
				}
			}
			masti {
				id
				title
				cover_image {
					url
				}
				collaborator {
					name
				}
				reading_time
			}
		}
	}
`;
export const EmailConfirmationDocument = gql`
	mutation emailConfirmation($code: String!) {
		emailConfirmation(confirmation: $code) {
			jwt
			user {
				id
			}
		}
	}
`;
export type EmailConfirmationMutationFn =
	Apollo.MutationFunction<
		EmailConfirmationMutation,
		EmailConfirmationMutationVariables
	>;

/**
 * __useEmailConfirmationMutation__
 *
 * To run a mutation, you first call `useEmailConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailConfirmationMutation, { data, loading, error }] = useEmailConfirmationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useEmailConfirmationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EmailConfirmationMutation,
		EmailConfirmationMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		EmailConfirmationMutation,
		EmailConfirmationMutationVariables
	>(EmailConfirmationDocument, options);
}
export type EmailConfirmationMutationHookResult =
	ReturnType<typeof useEmailConfirmationMutation>;
export type EmailConfirmationMutationResult =
	Apollo.MutationResult<EmailConfirmationMutation>;
export type EmailConfirmationMutationOptions =
	Apollo.BaseMutationOptions<
		EmailConfirmationMutation,
		EmailConfirmationMutationVariables
	>;
export const ForgotPasswordDocument = gql`
	mutation forgotPassword($email: String!) {
		forgotPassword(email: $email) {
			ok
		}
	}
`;
export type ForgotPasswordMutationFn =
	Apollo.MutationFunction<
		ForgotPasswordMutation,
		ForgotPasswordMutationVariables
	>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ForgotPasswordMutation,
		ForgotPasswordMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		ForgotPasswordMutation,
		ForgotPasswordMutationVariables
	>(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult =
	ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult =
	Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions =
	Apollo.BaseMutationOptions<
		ForgotPasswordMutation,
		ForgotPasswordMutationVariables
	>;
export const LoginDocument = gql`
	mutation login(
		$email: String!
		$password: String!
	) {
		login(
			input: {
				identifier: $email
				password: $password
			}
		) {
			jwt
			user {
				email
				id
			}
		}
	}
`;
export type LoginMutationFn =
	Apollo.MutationFunction<
		LoginMutation,
		LoginMutationVariables
	>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
	baseOptions?: Apollo.MutationHookOptions<
		LoginMutation,
		LoginMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		LoginMutation,
		LoginMutationVariables
	>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<
	typeof useLoginMutation
>;
export type LoginMutationResult =
	Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions =
	Apollo.BaseMutationOptions<
		LoginMutation,
		LoginMutationVariables
	>;
export const RegisterDocument = gql`
	mutation register(
		$email: String!
		$password: String!
		$name: String!
	) {
		newRegister(
			input: {
				username: $email
				email: $email
				password: $password
				name: $name
			}
		) {
			jwt
			user {
				username
				email
				name
			}
		}
	}
`;
export type RegisterMutationFn =
	Apollo.MutationFunction<
		RegisterMutation,
		RegisterMutationVariables
	>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRegisterMutation(
	baseOptions?: Apollo.MutationHookOptions<
		RegisterMutation,
		RegisterMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		RegisterMutation,
		RegisterMutationVariables
	>(RegisterDocument, options);
}
export type RegisterMutationHookResult =
	ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult =
	Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions =
	Apollo.BaseMutationOptions<
		RegisterMutation,
		RegisterMutationVariables
	>;
export const ResetPasswordDocument = gql`
	mutation resetPassword(
		$password: String!
		$passwordConfirmation: String!
		$code: String!
	) {
		resetPassword(
			password: $password
			passwordConfirmation: $passwordConfirmation
			code: $code
		) {
			user {
				email
			}
		}
	}
`;
export type ResetPasswordMutationFn =
	Apollo.MutationFunction<
		ResetPasswordMutation,
		ResetPasswordMutationVariables
	>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetPasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ResetPasswordMutation,
		ResetPasswordMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		ResetPasswordMutation,
		ResetPasswordMutationVariables
	>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult =
	ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult =
	Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions =
	Apollo.BaseMutationOptions<
		ResetPasswordMutation,
		ResetPasswordMutationVariables
	>;
export const GetAllBlogsDocument = gql`
	query getAllBlogs {
		blogs {
			id
			title
			short_description
			createdAt
			blog_category {
				id
				title
			}
			collaborator {
				name
			}
			banner_image {
				url
			}
		}
	}
`;

/**
 * __useGetAllBlogsQuery__
 *
 * To run a query within a React component, call `useGetAllBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBlogsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAllBlogsQuery,
		GetAllBlogsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetAllBlogsQuery,
		GetAllBlogsQueryVariables
	>(GetAllBlogsDocument, options);
}
export function useGetAllBlogsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAllBlogsQuery,
		GetAllBlogsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetAllBlogsQuery,
		GetAllBlogsQueryVariables
	>(GetAllBlogsDocument, options);
}
export type GetAllBlogsQueryHookResult =
	ReturnType<typeof useGetAllBlogsQuery>;
export type GetAllBlogsLazyQueryHookResult =
	ReturnType<typeof useGetAllBlogsLazyQuery>;
export type GetAllBlogsQueryResult =
	Apollo.QueryResult<
		GetAllBlogsQuery,
		GetAllBlogsQueryVariables
	>;
export const GetAllPremiumBlogsDocument = gql`
	query getAllPremiumBlogs {
		premiumBlogs {
			id
			title
			short_description
			createdAt
			premium_blog_category {
				id
				title
			}
			collaborator {
				name
			}
			banner_image {
				url
			}
		}
	}
`;

/**
 * __useGetAllPremiumBlogsQuery__
 *
 * To run a query within a React component, call `useGetAllPremiumBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPremiumBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPremiumBlogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPremiumBlogsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAllPremiumBlogsQuery,
		GetAllPremiumBlogsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetAllPremiumBlogsQuery,
		GetAllPremiumBlogsQueryVariables
	>(GetAllPremiumBlogsDocument, options);
}
export function useGetAllPremiumBlogsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAllPremiumBlogsQuery,
		GetAllPremiumBlogsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetAllPremiumBlogsQuery,
		GetAllPremiumBlogsQueryVariables
	>(GetAllPremiumBlogsDocument, options);
}
export type GetAllPremiumBlogsQueryHookResult =
	ReturnType<typeof useGetAllPremiumBlogsQuery>;
export type GetAllPremiumBlogsLazyQueryHookResult =
	ReturnType<
		typeof useGetAllPremiumBlogsLazyQuery
	>;
export type GetAllPremiumBlogsQueryResult =
	Apollo.QueryResult<
		GetAllPremiumBlogsQuery,
		GetAllPremiumBlogsQueryVariables
	>;
export const GetAllCollaboratorsDocument = gql`
	query getAllCollaborators {
		collaborators {
			id
			image {
				url
			}
			name
			occupation
			location
		}
	}
`;

/**
 * __useGetAllCollaboratorsQuery__
 *
 * To run a query within a React component, call `useGetAllCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollaboratorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCollaboratorsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAllCollaboratorsQuery,
		GetAllCollaboratorsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetAllCollaboratorsQuery,
		GetAllCollaboratorsQueryVariables
	>(GetAllCollaboratorsDocument, options);
}
export function useGetAllCollaboratorsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAllCollaboratorsQuery,
		GetAllCollaboratorsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetAllCollaboratorsQuery,
		GetAllCollaboratorsQueryVariables
	>(GetAllCollaboratorsDocument, options);
}
export type GetAllCollaboratorsQueryHookResult =
	ReturnType<typeof useGetAllCollaboratorsQuery>;
export type GetAllCollaboratorsLazyQueryHookResult =
	ReturnType<
		typeof useGetAllCollaboratorsLazyQuery
	>;
export type GetAllCollaboratorsQueryResult =
	Apollo.QueryResult<
		GetAllCollaboratorsQuery,
		GetAllCollaboratorsQueryVariables
	>;
export const ContactDocument = gql`
	mutation contact(
		$type: String!
		$name: String!
		$email: String!
		$phone: String
		$message: String!
		$expertise: String
	) {
		createContactQuery(
			input: {
				data: {
					type: $type
					name: $name
					email: $email
					phone: $phone
					message: $message
					expertise: $expertise
				}
			}
		) {
			contactQuery {
				id
			}
		}
	}
`;
export type ContactMutationFn =
	Apollo.MutationFunction<
		ContactMutation,
		ContactMutationVariables
	>;

/**
 * __useContactMutation__
 *
 * To run a mutation, you first call `useContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactMutation, { data, loading, error }] = useContactMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      message: // value for 'message'
 *      expertise: // value for 'expertise'
 *   },
 * });
 */
export function useContactMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ContactMutation,
		ContactMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		ContactMutation,
		ContactMutationVariables
	>(ContactDocument, options);
}
export type ContactMutationHookResult =
	ReturnType<typeof useContactMutation>;
export type ContactMutationResult =
	Apollo.MutationResult<ContactMutation>;
export type ContactMutationOptions =
	Apollo.BaseMutationOptions<
		ContactMutation,
		ContactMutationVariables
	>;
export const GetBlogDocument = gql`
	query getBlog($id: ID!) {
		blog(id: $id) {
			id
			title
			content
			createdAt
			blog_category {
				title
			}
			collaborator {
				name
			}
			banner_image {
				url
			}
		}
	}
`;

/**
 * __useGetBlogQuery__
 *
 * To run a query within a React component, call `useGetBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBlogQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetBlogQuery,
		GetBlogQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetBlogQuery,
		GetBlogQueryVariables
	>(GetBlogDocument, options);
}
export function useGetBlogLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetBlogQuery,
		GetBlogQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetBlogQuery,
		GetBlogQueryVariables
	>(GetBlogDocument, options);
}
export type GetBlogQueryHookResult = ReturnType<
	typeof useGetBlogQuery
>;
export type GetBlogLazyQueryHookResult =
	ReturnType<typeof useGetBlogLazyQuery>;
export type GetBlogQueryResult =
	Apollo.QueryResult<
		GetBlogQuery,
		GetBlogQueryVariables
	>;
export const GetPremiumBlogDocument = gql`
	query getPremiumBlog($id: ID!) {
		premiumBlog(id: $id) {
			id
			title
			content
			createdAt
			premium_blog_category {
				title
			}
			collaborator {
				name
			}
			banner_image {
				url
			}
		}
	}
`;

/**
 * __useGetPremiumBlogQuery__
 *
 * To run a query within a React component, call `useGetPremiumBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPremiumBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPremiumBlogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPremiumBlogQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetPremiumBlogQuery,
		GetPremiumBlogQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetPremiumBlogQuery,
		GetPremiumBlogQueryVariables
	>(GetPremiumBlogDocument, options);
}
export function useGetPremiumBlogLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetPremiumBlogQuery,
		GetPremiumBlogQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetPremiumBlogQuery,
		GetPremiumBlogQueryVariables
	>(GetPremiumBlogDocument, options);
}
export type GetPremiumBlogQueryHookResult =
	ReturnType<typeof useGetPremiumBlogQuery>;
export type GetPremiumBlogLazyQueryHookResult =
	ReturnType<typeof useGetPremiumBlogLazyQuery>;
export type GetPremiumBlogQueryResult =
	Apollo.QueryResult<
		GetPremiumBlogQuery,
		GetPremiumBlogQueryVariables
	>;
export const GetBlogCategoriesDocument = gql`
	query getBlogCategories {
		blogCategories {
			id
			title
		}
	}
`;

/**
 * __useGetBlogCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlogCategoriesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetBlogCategoriesQuery,
		GetBlogCategoriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetBlogCategoriesQuery,
		GetBlogCategoriesQueryVariables
	>(GetBlogCategoriesDocument, options);
}
export function useGetBlogCategoriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetBlogCategoriesQuery,
		GetBlogCategoriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetBlogCategoriesQuery,
		GetBlogCategoriesQueryVariables
	>(GetBlogCategoriesDocument, options);
}
export type GetBlogCategoriesQueryHookResult =
	ReturnType<typeof useGetBlogCategoriesQuery>;
export type GetBlogCategoriesLazyQueryHookResult =
	ReturnType<
		typeof useGetBlogCategoriesLazyQuery
	>;
export type GetBlogCategoriesQueryResult =
	Apollo.QueryResult<
		GetBlogCategoriesQuery,
		GetBlogCategoriesQueryVariables
	>;
export const GetPremiumBlogCategoriesDocument = gql`
	query getPremiumBlogCategories {
		premiumBlogCategories {
			id
			title
		}
	}
`;

/**
 * __useGetPremiumBlogCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPremiumBlogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPremiumBlogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPremiumBlogCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPremiumBlogCategoriesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetPremiumBlogCategoriesQuery,
		GetPremiumBlogCategoriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetPremiumBlogCategoriesQuery,
		GetPremiumBlogCategoriesQueryVariables
	>(GetPremiumBlogCategoriesDocument, options);
}
export function useGetPremiumBlogCategoriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetPremiumBlogCategoriesQuery,
		GetPremiumBlogCategoriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetPremiumBlogCategoriesQuery,
		GetPremiumBlogCategoriesQueryVariables
	>(GetPremiumBlogCategoriesDocument, options);
}
export type GetPremiumBlogCategoriesQueryHookResult =
	ReturnType<
		typeof useGetPremiumBlogCategoriesQuery
	>;
export type GetPremiumBlogCategoriesLazyQueryHookResult =
	ReturnType<
		typeof useGetPremiumBlogCategoriesLazyQuery
	>;
export type GetPremiumBlogCategoriesQueryResult =
	Apollo.QueryResult<
		GetPremiumBlogCategoriesQuery,
		GetPremiumBlogCategoriesQueryVariables
	>;
export const GetCollaboratorDocument = gql`
	query getCollaborator($id: ID!) {
		collaborator(id: $id) {
			id
			name
			occupation
			location
			expertise
			expertise_rich
			why
			why_rich
			image {
				url
			}
			links {
				type
				url
			}
		}
	}
`;

/**
 * __useGetCollaboratorQuery__
 *
 * To run a query within a React component, call `useGetCollaboratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaboratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaboratorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollaboratorQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCollaboratorQuery,
		GetCollaboratorQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetCollaboratorQuery,
		GetCollaboratorQueryVariables
	>(GetCollaboratorDocument, options);
}
export function useGetCollaboratorLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCollaboratorQuery,
		GetCollaboratorQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetCollaboratorQuery,
		GetCollaboratorQueryVariables
	>(GetCollaboratorDocument, options);
}
export type GetCollaboratorQueryHookResult =
	ReturnType<typeof useGetCollaboratorQuery>;
export type GetCollaboratorLazyQueryHookResult =
	ReturnType<typeof useGetCollaboratorLazyQuery>;
export type GetCollaboratorQueryResult =
	Apollo.QueryResult<
		GetCollaboratorQuery,
		GetCollaboratorQueryVariables
	>;
export const GetLimitedBlogsDocument = gql`
	query getLimitedBlogs($limit: Int) {
		blogs(limit: $limit, sort: "createdAt:desc") {
			id
			title
			createdAt
			blog_category {
				id
				title
			}
			collaborator {
				name
			}
			banner_image {
				url
			}
		}
	}
`;

/**
 * __useGetLimitedBlogsQuery__
 *
 * To run a query within a React component, call `useGetLimitedBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLimitedBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLimitedBlogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLimitedBlogsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetLimitedBlogsQuery,
		GetLimitedBlogsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetLimitedBlogsQuery,
		GetLimitedBlogsQueryVariables
	>(GetLimitedBlogsDocument, options);
}
export function useGetLimitedBlogsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetLimitedBlogsQuery,
		GetLimitedBlogsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetLimitedBlogsQuery,
		GetLimitedBlogsQueryVariables
	>(GetLimitedBlogsDocument, options);
}
export type GetLimitedBlogsQueryHookResult =
	ReturnType<typeof useGetLimitedBlogsQuery>;
export type GetLimitedBlogsLazyQueryHookResult =
	ReturnType<typeof useGetLimitedBlogsLazyQuery>;
export type GetLimitedBlogsQueryResult =
	Apollo.QueryResult<
		GetLimitedBlogsQuery,
		GetLimitedBlogsQueryVariables
	>;
export const NewsletterDocument = gql`
	mutation newsletter($email: String!) {
		subscribe(input: { email: $email })
	}
`;
export type NewsletterMutationFn =
	Apollo.MutationFunction<
		NewsletterMutation,
		NewsletterMutationVariables
	>;

/**
 * __useNewsletterMutation__
 *
 * To run a mutation, you first call `useNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsletterMutation, { data, loading, error }] = useNewsletterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useNewsletterMutation(
	baseOptions?: Apollo.MutationHookOptions<
		NewsletterMutation,
		NewsletterMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		NewsletterMutation,
		NewsletterMutationVariables
	>(NewsletterDocument, options);
}
export type NewsletterMutationHookResult =
	ReturnType<typeof useNewsletterMutation>;
export type NewsletterMutationResult =
	Apollo.MutationResult<NewsletterMutation>;
export type NewsletterMutationOptions =
	Apollo.BaseMutationOptions<
		NewsletterMutation,
		NewsletterMutationVariables
	>;
export const QuotesDocument = gql`
	query Quotes {
		dailyQuotes {
			quote
			author
			id
		}
	}
`;

/**
 * __useQuotesQuery__
 *
 * To run a query within a React component, call `useQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuotesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		QuotesQuery,
		QuotesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		QuotesQuery,
		QuotesQueryVariables
	>(QuotesDocument, options);
}
export function useQuotesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		QuotesQuery,
		QuotesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		QuotesQuery,
		QuotesQueryVariables
	>(QuotesDocument, options);
}
export type QuotesQueryHookResult = ReturnType<
	typeof useQuotesQuery
>;
export type QuotesLazyQueryHookResult =
	ReturnType<typeof useQuotesLazyQuery>;
export type QuotesQueryResult =
	Apollo.QueryResult<
		QuotesQuery,
		QuotesQueryVariables
	>;
export const CreateWeeklyPlannerEventDocument = gql`
	mutation createWeeklyPlannerEvent(
		$data: weeklyPlannerEventInput!
	) {
		createWeeklyPlannerEvent(input: $data) {
			id
			type
			date
			slot
			createdAt
		}
	}
`;
export type CreateWeeklyPlannerEventMutationFn =
	Apollo.MutationFunction<
		CreateWeeklyPlannerEventMutation,
		CreateWeeklyPlannerEventMutationVariables
	>;

/**
 * __useCreateWeeklyPlannerEventMutation__
 *
 * To run a mutation, you first call `useCreateWeeklyPlannerEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWeeklyPlannerEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWeeklyPlannerEventMutation, { data, loading, error }] = useCreateWeeklyPlannerEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWeeklyPlannerEventMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateWeeklyPlannerEventMutation,
		CreateWeeklyPlannerEventMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		CreateWeeklyPlannerEventMutation,
		CreateWeeklyPlannerEventMutationVariables
	>(CreateWeeklyPlannerEventDocument, options);
}
export type CreateWeeklyPlannerEventMutationHookResult =
	ReturnType<
		typeof useCreateWeeklyPlannerEventMutation
	>;
export type CreateWeeklyPlannerEventMutationResult =
	Apollo.MutationResult<CreateWeeklyPlannerEventMutation>;
export type CreateWeeklyPlannerEventMutationOptions =
	Apollo.BaseMutationOptions<
		CreateWeeklyPlannerEventMutation,
		CreateWeeklyPlannerEventMutationVariables
	>;
export const DeleteWeeklyPlannerEventDocument = gql`
	mutation deleteWeeklyPlannerEvent($id: ID!) {
		deleteWeeklyPlannerEvent(id: $id)
	}
`;
export type DeleteWeeklyPlannerEventMutationFn =
	Apollo.MutationFunction<
		DeleteWeeklyPlannerEventMutation,
		DeleteWeeklyPlannerEventMutationVariables
	>;

/**
 * __useDeleteWeeklyPlannerEventMutation__
 *
 * To run a mutation, you first call `useDeleteWeeklyPlannerEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWeeklyPlannerEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWeeklyPlannerEventMutation, { data, loading, error }] = useDeleteWeeklyPlannerEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWeeklyPlannerEventMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteWeeklyPlannerEventMutation,
		DeleteWeeklyPlannerEventMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		DeleteWeeklyPlannerEventMutation,
		DeleteWeeklyPlannerEventMutationVariables
	>(DeleteWeeklyPlannerEventDocument, options);
}
export type DeleteWeeklyPlannerEventMutationHookResult =
	ReturnType<
		typeof useDeleteWeeklyPlannerEventMutation
	>;
export type DeleteWeeklyPlannerEventMutationResult =
	Apollo.MutationResult<DeleteWeeklyPlannerEventMutation>;
export type DeleteWeeklyPlannerEventMutationOptions =
	Apollo.BaseMutationOptions<
		DeleteWeeklyPlannerEventMutation,
		DeleteWeeklyPlannerEventMutationVariables
	>;
export const GetAllJournalPromptsDocument = gql`
	query getAllJournalPrompts {
		journalPrompts(sort: "order:desc") {
			id
			description
			createdAt
		}
	}
`;

/**
 * __useGetAllJournalPromptsQuery__
 *
 * To run a query within a React component, call `useGetAllJournalPromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllJournalPromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllJournalPromptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllJournalPromptsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAllJournalPromptsQuery,
		GetAllJournalPromptsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetAllJournalPromptsQuery,
		GetAllJournalPromptsQueryVariables
	>(GetAllJournalPromptsDocument, options);
}
export function useGetAllJournalPromptsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAllJournalPromptsQuery,
		GetAllJournalPromptsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetAllJournalPromptsQuery,
		GetAllJournalPromptsQueryVariables
	>(GetAllJournalPromptsDocument, options);
}
export type GetAllJournalPromptsQueryHookResult =
	ReturnType<typeof useGetAllJournalPromptsQuery>;
export type GetAllJournalPromptsLazyQueryHookResult =
	ReturnType<
		typeof useGetAllJournalPromptsLazyQuery
	>;
export type GetAllJournalPromptsQueryResult =
	Apollo.QueryResult<
		GetAllJournalPromptsQuery,
		GetAllJournalPromptsQueryVariables
	>;
export const GetAllMantrasListingDocument = gql`
	query getAllMantrasListing {
		mantras(sort: "order:desc") {
			id
			title
			short_description
			content
			reading_time
			cover_image {
				url
			}
			vimeo_url
			collaborator {
				name
				image {
					url
				}
			}
		}
	}
`;

/**
 * __useGetAllMantrasListingQuery__
 *
 * To run a query within a React component, call `useGetAllMantrasListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMantrasListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMantrasListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMantrasListingQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAllMantrasListingQuery,
		GetAllMantrasListingQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetAllMantrasListingQuery,
		GetAllMantrasListingQueryVariables
	>(GetAllMantrasListingDocument, options);
}
export function useGetAllMantrasListingLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAllMantrasListingQuery,
		GetAllMantrasListingQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetAllMantrasListingQuery,
		GetAllMantrasListingQueryVariables
	>(GetAllMantrasListingDocument, options);
}
export type GetAllMantrasListingQueryHookResult =
	ReturnType<typeof useGetAllMantrasListingQuery>;
export type GetAllMantrasListingLazyQueryHookResult =
	ReturnType<
		typeof useGetAllMantrasListingLazyQuery
	>;
export type GetAllMantrasListingQueryResult =
	Apollo.QueryResult<
		GetAllMantrasListingQuery,
		GetAllMantrasListingQueryVariables
	>;
export const GetAllMastisListingDocument = gql`
	query getAllMastisListing {
		mastis(sort: "order:desc") {
			id
			title
			short_description
			content
			reading_time
			cover_image {
				url
			}
			vimeo_url
			collaborator {
				name
				image {
					url
				}
			}
		}
	}
`;

/**
 * __useGetAllMastisListingQuery__
 *
 * To run a query within a React component, call `useGetAllMastisListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMastisListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMastisListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMastisListingQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetAllMastisListingQuery,
		GetAllMastisListingQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetAllMastisListingQuery,
		GetAllMastisListingQueryVariables
	>(GetAllMastisListingDocument, options);
}
export function useGetAllMastisListingLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAllMastisListingQuery,
		GetAllMastisListingQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetAllMastisListingQuery,
		GetAllMastisListingQueryVariables
	>(GetAllMastisListingDocument, options);
}
export type GetAllMastisListingQueryHookResult =
	ReturnType<typeof useGetAllMastisListingQuery>;
export type GetAllMastisListingLazyQueryHookResult =
	ReturnType<
		typeof useGetAllMastisListingLazyQuery
	>;
export type GetAllMastisListingQueryResult =
	Apollo.QueryResult<
		GetAllMastisListingQuery,
		GetAllMastisListingQueryVariables
	>;
export const GetAllRecipesListingDocument = gql`
	query getAllRecipesListing(
		$mode: PublicationState!
	) {
		recipes(
			sort: "order:desc"
			publicationState: $mode
		) {
			id
			Prep_Duration {
				id
				duration
				unit
			}
			Cook_Duration {
				id
				duration
				unit
			}
			createdAt
			No_Of_Servings
			Calories
			Title
			collaborator {
				id
				name
				image {
					id
					url
				}
			}
			thumbnail {
				url
				id
			}
			keywords
			Ingredients {
				IngredientsWithTitle {
					Title
					Ingredients {
						Ingredients_Name
					}
				}
			}
			meal_categories {
				id
				Name
				order
			}
		}
	}
`;

/**
 * __useGetAllRecipesListingQuery__
 *
 * To run a query within a React component, call `useGetAllRecipesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecipesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecipesListingQuery({
 *   variables: {
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useGetAllRecipesListingQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetAllRecipesListingQuery,
		GetAllRecipesListingQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetAllRecipesListingQuery,
		GetAllRecipesListingQueryVariables
	>(GetAllRecipesListingDocument, options);
}
export function useGetAllRecipesListingLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAllRecipesListingQuery,
		GetAllRecipesListingQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetAllRecipesListingQuery,
		GetAllRecipesListingQueryVariables
	>(GetAllRecipesListingDocument, options);
}
export type GetAllRecipesListingQueryHookResult =
	ReturnType<typeof useGetAllRecipesListingQuery>;
export type GetAllRecipesListingLazyQueryHookResult =
	ReturnType<
		typeof useGetAllRecipesListingLazyQuery
	>;
export type GetAllRecipesListingQueryResult =
	Apollo.QueryResult<
		GetAllRecipesListingQuery,
		GetAllRecipesListingQueryVariables
	>;
export const GetCitiesDocument = gql`
	mutation getCities(
		$country: String!
		$state: String!
	) {
		cities(
			input: { country: $country, state: $state }
		) {
			cities {
				id
				name
			}
			timezone {
				gmtOffsetName
				zoneName
				gmtOffset
			}
		}
	}
`;
export type GetCitiesMutationFn =
	Apollo.MutationFunction<
		GetCitiesMutation,
		GetCitiesMutationVariables
	>;

/**
 * __useGetCitiesMutation__
 *
 * To run a mutation, you first call `useGetCitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCitiesMutation, { data, loading, error }] = useGetCitiesMutation({
 *   variables: {
 *      country: // value for 'country'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetCitiesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		GetCitiesMutation,
		GetCitiesMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		GetCitiesMutation,
		GetCitiesMutationVariables
	>(GetCitiesDocument, options);
}
export type GetCitiesMutationHookResult =
	ReturnType<typeof useGetCitiesMutation>;
export type GetCitiesMutationResult =
	Apollo.MutationResult<GetCitiesMutation>;
export type GetCitiesMutationOptions =
	Apollo.BaseMutationOptions<
		GetCitiesMutation,
		GetCitiesMutationVariables
	>;
export const GetCountriesDocument = gql`
	query getCountries {
		countries {
			id
			name
		}
	}
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetCountriesQuery,
		GetCountriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetCountriesQuery,
		GetCountriesQueryVariables
	>(GetCountriesDocument, options);
}
export function useGetCountriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCountriesQuery,
		GetCountriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetCountriesQuery,
		GetCountriesQueryVariables
	>(GetCountriesDocument, options);
}
export type GetCountriesQueryHookResult =
	ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult =
	ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult =
	Apollo.QueryResult<
		GetCountriesQuery,
		GetCountriesQueryVariables
	>;
export const GetFoodIndexListingDocument = gql`
	query getFoodIndexListing {
		foodIndices {
			id
			name
			image {
				url
			}
		}
	}
`;

/**
 * __useGetFoodIndexListingQuery__
 *
 * To run a query within a React component, call `useGetFoodIndexListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoodIndexListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoodIndexListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFoodIndexListingQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetFoodIndexListingQuery,
		GetFoodIndexListingQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetFoodIndexListingQuery,
		GetFoodIndexListingQueryVariables
	>(GetFoodIndexListingDocument, options);
}
export function useGetFoodIndexListingLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFoodIndexListingQuery,
		GetFoodIndexListingQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetFoodIndexListingQuery,
		GetFoodIndexListingQueryVariables
	>(GetFoodIndexListingDocument, options);
}
export type GetFoodIndexListingQueryHookResult =
	ReturnType<typeof useGetFoodIndexListingQuery>;
export type GetFoodIndexListingLazyQueryHookResult =
	ReturnType<
		typeof useGetFoodIndexListingLazyQuery
	>;
export type GetFoodIndexListingQueryResult =
	Apollo.QueryResult<
		GetFoodIndexListingQuery,
		GetFoodIndexListingQueryVariables
	>;
export const GetFoodIndexDetailsDocument = gql`
	query getFoodIndexDetails($id: ID!) {
		foodIndex(id: $id) {
			id
			name
			image {
				url
			}
			ValueDisclaimer
			insights {
				Nutritional_Insight {
					Description
				}
				Ayurveda_Insight {
					Description
				}
				Nutritional_Insight_rich
				Ayurveda_Insight_rich
			}
			qty
			values {
				Title
				Unit
				Sub_Nutrition_facts {
					Title
					Unit
				}
			}
		}
	}
`;

/**
 * __useGetFoodIndexDetailsQuery__
 *
 * To run a query within a React component, call `useGetFoodIndexDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoodIndexDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoodIndexDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFoodIndexDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetFoodIndexDetailsQuery,
		GetFoodIndexDetailsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetFoodIndexDetailsQuery,
		GetFoodIndexDetailsQueryVariables
	>(GetFoodIndexDetailsDocument, options);
}
export function useGetFoodIndexDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetFoodIndexDetailsQuery,
		GetFoodIndexDetailsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetFoodIndexDetailsQuery,
		GetFoodIndexDetailsQueryVariables
	>(GetFoodIndexDetailsDocument, options);
}
export type GetFoodIndexDetailsQueryHookResult =
	ReturnType<typeof useGetFoodIndexDetailsQuery>;
export type GetFoodIndexDetailsLazyQueryHookResult =
	ReturnType<
		typeof useGetFoodIndexDetailsLazyQuery
	>;
export type GetFoodIndexDetailsQueryResult =
	Apollo.QueryResult<
		GetFoodIndexDetailsQuery,
		GetFoodIndexDetailsQueryVariables
	>;
export const GetMantraDetailDocument = gql`
	query getMantraDetail($id: ID!) {
		mantra(id: $id) {
			id
			title
			reading_time
			collaborator {
				name
			}
			cover_image {
				url
			}
			content
			vimeo_url
		}
	}
`;

/**
 * __useGetMantraDetailQuery__
 *
 * To run a query within a React component, call `useGetMantraDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMantraDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMantraDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMantraDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetMantraDetailQuery,
		GetMantraDetailQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMantraDetailQuery,
		GetMantraDetailQueryVariables
	>(GetMantraDetailDocument, options);
}
export function useGetMantraDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMantraDetailQuery,
		GetMantraDetailQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMantraDetailQuery,
		GetMantraDetailQueryVariables
	>(GetMantraDetailDocument, options);
}
export type GetMantraDetailQueryHookResult =
	ReturnType<typeof useGetMantraDetailQuery>;
export type GetMantraDetailLazyQueryHookResult =
	ReturnType<typeof useGetMantraDetailLazyQuery>;
export type GetMantraDetailQueryResult =
	Apollo.QueryResult<
		GetMantraDetailQuery,
		GetMantraDetailQueryVariables
	>;
export const GetMastiDetailDocument = gql`
	query getMastiDetail($id: ID!) {
		masti(id: $id) {
			id
			title
			reading_time
			collaborator {
				name
			}
			cover_image {
				url
			}
			content
			vimeo_url
		}
	}
`;

/**
 * __useGetMastiDetailQuery__
 *
 * To run a query within a React component, call `useGetMastiDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMastiDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMastiDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMastiDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetMastiDetailQuery,
		GetMastiDetailQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMastiDetailQuery,
		GetMastiDetailQueryVariables
	>(GetMastiDetailDocument, options);
}
export function useGetMastiDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMastiDetailQuery,
		GetMastiDetailQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMastiDetailQuery,
		GetMastiDetailQueryVariables
	>(GetMastiDetailDocument, options);
}
export type GetMastiDetailQueryHookResult =
	ReturnType<typeof useGetMastiDetailQuery>;
export type GetMastiDetailLazyQueryHookResult =
	ReturnType<typeof useGetMastiDetailLazyQuery>;
export type GetMastiDetailQueryResult =
	Apollo.QueryResult<
		GetMastiDetailQuery,
		GetMastiDetailQueryVariables
	>;
export const GetMeditationByCategoryDocument = gql`
	query getMeditationByCategory(
		$categoryId: ID!
	) {
		meditations(
			where: { meditation_category: $categoryId }
		) {
			id
			title
			thumbnail {
				url
			}
			collaborator {
				name
			}
			duration
			meditation_category {
				id
				name
			}
		}
	}
`;

/**
 * __useGetMeditationByCategoryQuery__
 *
 * To run a query within a React component, call `useGetMeditationByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeditationByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeditationByCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetMeditationByCategoryQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetMeditationByCategoryQuery,
		GetMeditationByCategoryQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMeditationByCategoryQuery,
		GetMeditationByCategoryQueryVariables
	>(GetMeditationByCategoryDocument, options);
}
export function useGetMeditationByCategoryLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMeditationByCategoryQuery,
		GetMeditationByCategoryQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMeditationByCategoryQuery,
		GetMeditationByCategoryQueryVariables
	>(GetMeditationByCategoryDocument, options);
}
export type GetMeditationByCategoryQueryHookResult =
	ReturnType<
		typeof useGetMeditationByCategoryQuery
	>;
export type GetMeditationByCategoryLazyQueryHookResult =
	ReturnType<
		typeof useGetMeditationByCategoryLazyQuery
	>;
export type GetMeditationByCategoryQueryResult =
	Apollo.QueryResult<
		GetMeditationByCategoryQuery,
		GetMeditationByCategoryQueryVariables
	>;
export const GetMeditationCategoriesDocument = gql`
	query getMeditationCategories {
		meditationCategories {
			id
			image {
				url
			}
			name
		}
	}
`;

/**
 * __useGetMeditationCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMeditationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeditationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeditationCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeditationCategoriesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetMeditationCategoriesQuery,
		GetMeditationCategoriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMeditationCategoriesQuery,
		GetMeditationCategoriesQueryVariables
	>(GetMeditationCategoriesDocument, options);
}
export function useGetMeditationCategoriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMeditationCategoriesQuery,
		GetMeditationCategoriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMeditationCategoriesQuery,
		GetMeditationCategoriesQueryVariables
	>(GetMeditationCategoriesDocument, options);
}
export type GetMeditationCategoriesQueryHookResult =
	ReturnType<
		typeof useGetMeditationCategoriesQuery
	>;
export type GetMeditationCategoriesLazyQueryHookResult =
	ReturnType<
		typeof useGetMeditationCategoriesLazyQuery
	>;
export type GetMeditationCategoriesQueryResult =
	Apollo.QueryResult<
		GetMeditationCategoriesQuery,
		GetMeditationCategoriesQueryVariables
	>;
export const GetMeditationDetailsDocument = gql`
	query getMeditationDetails($id: ID!) {
		meditation(id: $id) {
			id
			title
			vimeo_url
			content
			collaborator {
				name
			}
			duration
			thumbnail {
				url
			}
		}
	}
`;

/**
 * __useGetMeditationDetailsQuery__
 *
 * To run a query within a React component, call `useGetMeditationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeditationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeditationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMeditationDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetMeditationDetailsQuery,
		GetMeditationDetailsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMeditationDetailsQuery,
		GetMeditationDetailsQueryVariables
	>(GetMeditationDetailsDocument, options);
}
export function useGetMeditationDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMeditationDetailsQuery,
		GetMeditationDetailsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMeditationDetailsQuery,
		GetMeditationDetailsQueryVariables
	>(GetMeditationDetailsDocument, options);
}
export type GetMeditationDetailsQueryHookResult =
	ReturnType<typeof useGetMeditationDetailsQuery>;
export type GetMeditationDetailsLazyQueryHookResult =
	ReturnType<
		typeof useGetMeditationDetailsLazyQuery
	>;
export type GetMeditationDetailsQueryResult =
	Apollo.QueryResult<
		GetMeditationDetailsQuery,
		GetMeditationDetailsQueryVariables
	>;
export const GetMovementCategoriesDocument = gql`
	query getMovementCategories {
		movementCategories {
			id
			image {
				url
			}
			name
		}
	}
`;

/**
 * __useGetMovementCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMovementCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMovementCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMovementCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMovementCategoriesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetMovementCategoriesQuery,
		GetMovementCategoriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMovementCategoriesQuery,
		GetMovementCategoriesQueryVariables
	>(GetMovementCategoriesDocument, options);
}
export function useGetMovementCategoriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMovementCategoriesQuery,
		GetMovementCategoriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMovementCategoriesQuery,
		GetMovementCategoriesQueryVariables
	>(GetMovementCategoriesDocument, options);
}
export type GetMovementCategoriesQueryHookResult =
	ReturnType<
		typeof useGetMovementCategoriesQuery
	>;
export type GetMovementCategoriesLazyQueryHookResult =
	ReturnType<
		typeof useGetMovementCategoriesLazyQuery
	>;
export type GetMovementCategoriesQueryResult =
	Apollo.QueryResult<
		GetMovementCategoriesQuery,
		GetMovementCategoriesQueryVariables
	>;
export const GetMovementDetailsDocument = gql`
	query getMovementDetails($id: ID!) {
		movement(id: $id) {
			id
			title
			vimeo_url
			content
			collaborator {
				name
			}
			duration
			calories_burn
			thumbnail {
				url
			}
		}
	}
`;

/**
 * __useGetMovementDetailsQuery__
 *
 * To run a query within a React component, call `useGetMovementDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMovementDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMovementDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMovementDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetMovementDetailsQuery,
		GetMovementDetailsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMovementDetailsQuery,
		GetMovementDetailsQueryVariables
	>(GetMovementDetailsDocument, options);
}
export function useGetMovementDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMovementDetailsQuery,
		GetMovementDetailsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMovementDetailsQuery,
		GetMovementDetailsQueryVariables
	>(GetMovementDetailsDocument, options);
}
export type GetMovementDetailsQueryHookResult =
	ReturnType<typeof useGetMovementDetailsQuery>;
export type GetMovementDetailsLazyQueryHookResult =
	ReturnType<
		typeof useGetMovementDetailsLazyQuery
	>;
export type GetMovementDetailsQueryResult =
	Apollo.QueryResult<
		GetMovementDetailsQuery,
		GetMovementDetailsQueryVariables
	>;
export const GetMovementsByCategoryDocument = gql`
	query getMovementsByCategory($categoryId: ID!) {
		movements(
			where: { movement_category: $categoryId }
		) {
			id
			title
			thumbnail {
				url
			}
			collaborator {
				name
			}
			duration
			calories_burn
			movement_category {
				id
				name
			}
		}
	}
`;

/**
 * __useGetMovementsByCategoryQuery__
 *
 * To run a query within a React component, call `useGetMovementsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMovementsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMovementsByCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetMovementsByCategoryQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetMovementsByCategoryQuery,
		GetMovementsByCategoryQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMovementsByCategoryQuery,
		GetMovementsByCategoryQueryVariables
	>(GetMovementsByCategoryDocument, options);
}
export function useGetMovementsByCategoryLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMovementsByCategoryQuery,
		GetMovementsByCategoryQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMovementsByCategoryQuery,
		GetMovementsByCategoryQueryVariables
	>(GetMovementsByCategoryDocument, options);
}
export type GetMovementsByCategoryQueryHookResult =
	ReturnType<
		typeof useGetMovementsByCategoryQuery
	>;
export type GetMovementsByCategoryLazyQueryHookResult =
	ReturnType<
		typeof useGetMovementsByCategoryLazyQuery
	>;
export type GetMovementsByCategoryQueryResult =
	Apollo.QueryResult<
		GetMovementsByCategoryQuery,
		GetMovementsByCategoryQueryVariables
	>;
export const GetMyPrioritiesDocument = gql`
	query getMyPriorities {
		getMyPriorities {
			id
			priorities {
				id
				type
				title
				description
				checkin
			}
			updatedAt
		}
	}
`;

/**
 * __useGetMyPrioritiesQuery__
 *
 * To run a query within a React component, call `useGetMyPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPrioritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPrioritiesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetMyPrioritiesQuery,
		GetMyPrioritiesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMyPrioritiesQuery,
		GetMyPrioritiesQueryVariables
	>(GetMyPrioritiesDocument, options);
}
export function useGetMyPrioritiesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMyPrioritiesQuery,
		GetMyPrioritiesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMyPrioritiesQuery,
		GetMyPrioritiesQueryVariables
	>(GetMyPrioritiesDocument, options);
}
export type GetMyPrioritiesQueryHookResult =
	ReturnType<typeof useGetMyPrioritiesQuery>;
export type GetMyPrioritiesLazyQueryHookResult =
	ReturnType<typeof useGetMyPrioritiesLazyQuery>;
export type GetMyPrioritiesQueryResult =
	Apollo.QueryResult<
		GetMyPrioritiesQuery,
		GetMyPrioritiesQueryVariables
	>;
export const GetPrioritiesDocument = gql`
	query getPriorities {
		priorities(sort: "order:desc") {
			id
			title
			description
			category
		}
	}
`;

/**
 * __useGetPrioritiesQuery__
 *
 * To run a query within a React component, call `useGetPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrioritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrioritiesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetPrioritiesQuery,
		GetPrioritiesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetPrioritiesQuery,
		GetPrioritiesQueryVariables
	>(GetPrioritiesDocument, options);
}
export function useGetPrioritiesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetPrioritiesQuery,
		GetPrioritiesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetPrioritiesQuery,
		GetPrioritiesQueryVariables
	>(GetPrioritiesDocument, options);
}
export type GetPrioritiesQueryHookResult =
	ReturnType<typeof useGetPrioritiesQuery>;
export type GetPrioritiesLazyQueryHookResult =
	ReturnType<typeof useGetPrioritiesLazyQuery>;
export type GetPrioritiesQueryResult =
	Apollo.QueryResult<
		GetPrioritiesQuery,
		GetPrioritiesQueryVariables
	>;
export const GetRecipesDocument = gql`
	query getRecipes(
		$id: ID!
		$mode: PublicationState!
	) {
		recipe(id: $id, publicationState: $mode) {
			id
			updatedAt
			ValueDisclaimer
			InsightNotes
			SlideShow {
				id
				images {
					url
				}
			}
			Prep_Duration {
				duration
				unit
			}
			Cook_Duration {
				duration
				unit
			}
			No_Of_Servings
			Ingredients {
				Image {
					url
				}
				IngredientsWithTitle {
					Title
					Ingredients {
						id
						Ingredients_Name
						Unit
					}
				}
			}
			Insights {
				Nutritional_Insight {
					Description
				}
				Ayurveda_Insight {
					Description
				}
				Nutritional_Insight_rich
				Ayurveda_Insight_rich
			}
			Instructions {
				Variations
				Steps {
					Step_Description
					Step_Images {
						url
					}
				}
			}
			Calories
			Nutrition_facts {
				Title
				Unit
				Sub_Nutrition_facts {
					Title
					Unit
				}
			}
			Title
			collaborator {
				id
				name
				image {
					url
				}
			}
			thumbnail {
				url
			}
			keywords
			meal_categories {
				Name
			}
		}
	}
`;

/**
 * __useGetRecipesQuery__
 *
 * To run a query within a React component, call `useGetRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useGetRecipesQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetRecipesQuery,
		GetRecipesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetRecipesQuery,
		GetRecipesQueryVariables
	>(GetRecipesDocument, options);
}
export function useGetRecipesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetRecipesQuery,
		GetRecipesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetRecipesQuery,
		GetRecipesQueryVariables
	>(GetRecipesDocument, options);
}
export type GetRecipesQueryHookResult =
	ReturnType<typeof useGetRecipesQuery>;
export type GetRecipesLazyQueryHookResult =
	ReturnType<typeof useGetRecipesLazyQuery>;
export type GetRecipesQueryResult =
	Apollo.QueryResult<
		GetRecipesQuery,
		GetRecipesQueryVariables
	>;
export const GetStatesDocument = gql`
	mutation getStates($country: String!) {
		states(input: { country: $country }) {
			id
			name
		}
	}
`;
export type GetStatesMutationFn =
	Apollo.MutationFunction<
		GetStatesMutation,
		GetStatesMutationVariables
	>;

/**
 * __useGetStatesMutation__
 *
 * To run a mutation, you first call `useGetStatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetStatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getStatesMutation, { data, loading, error }] = useGetStatesMutation({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetStatesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		GetStatesMutation,
		GetStatesMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		GetStatesMutation,
		GetStatesMutationVariables
	>(GetStatesDocument, options);
}
export type GetStatesMutationHookResult =
	ReturnType<typeof useGetStatesMutation>;
export type GetStatesMutationResult =
	Apollo.MutationResult<GetStatesMutation>;
export type GetStatesMutationOptions =
	Apollo.BaseMutationOptions<
		GetStatesMutation,
		GetStatesMutationVariables
	>;
export const ListWeeklyPlannerEventsPrintFriendlyDocument = gql`
	query listWeeklyPlannerEventsPrintFriendly(
		$day1: Date!
		$day2: Date!
		$day3: Date!
		$day4: Date!
		$day5: Date!
		$day6: Date!
		$day7: Date!
	) {
		day1: listWeeklyPlannerEvents(date: $day1) {
			...eventsFields
		}
		day2: listWeeklyPlannerEvents(date: $day2) {
			...eventsFields
		}
		day3: listWeeklyPlannerEvents(date: $day3) {
			...eventsFields
		}
		day4: listWeeklyPlannerEvents(date: $day4) {
			...eventsFields
		}
		day5: listWeeklyPlannerEvents(date: $day5) {
			...eventsFields
		}
		day6: listWeeklyPlannerEvents(date: $day6) {
			...eventsFields
		}
		day7: listWeeklyPlannerEvents(date: $day7) {
			...eventsFields
		}
	}
	${EventsFieldsFragmentDoc}
`;

/**
 * __useListWeeklyPlannerEventsPrintFriendlyQuery__
 *
 * To run a query within a React component, call `useListWeeklyPlannerEventsPrintFriendlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWeeklyPlannerEventsPrintFriendlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWeeklyPlannerEventsPrintFriendlyQuery({
 *   variables: {
 *      day1: // value for 'day1'
 *      day2: // value for 'day2'
 *      day3: // value for 'day3'
 *      day4: // value for 'day4'
 *      day5: // value for 'day5'
 *      day6: // value for 'day6'
 *      day7: // value for 'day7'
 *   },
 * });
 */
export function useListWeeklyPlannerEventsPrintFriendlyQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListWeeklyPlannerEventsPrintFriendlyQuery,
		ListWeeklyPlannerEventsPrintFriendlyQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		ListWeeklyPlannerEventsPrintFriendlyQuery,
		ListWeeklyPlannerEventsPrintFriendlyQueryVariables
	>(
		ListWeeklyPlannerEventsPrintFriendlyDocument,
		options,
	);
}
export function useListWeeklyPlannerEventsPrintFriendlyLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListWeeklyPlannerEventsPrintFriendlyQuery,
		ListWeeklyPlannerEventsPrintFriendlyQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		ListWeeklyPlannerEventsPrintFriendlyQuery,
		ListWeeklyPlannerEventsPrintFriendlyQueryVariables
	>(
		ListWeeklyPlannerEventsPrintFriendlyDocument,
		options,
	);
}
export type ListWeeklyPlannerEventsPrintFriendlyQueryHookResult =
	ReturnType<
		typeof useListWeeklyPlannerEventsPrintFriendlyQuery
	>;
export type ListWeeklyPlannerEventsPrintFriendlyLazyQueryHookResult =
	ReturnType<
		typeof useListWeeklyPlannerEventsPrintFriendlyLazyQuery
	>;
export type ListWeeklyPlannerEventsPrintFriendlyQueryResult =
	Apollo.QueryResult<
		ListWeeklyPlannerEventsPrintFriendlyQuery,
		ListWeeklyPlannerEventsPrintFriendlyQueryVariables
	>;
export const GetweeklyPlannerEventWeekCaloriesDocument = gql`
	query getweeklyPlannerEventWeekCalories(
		$startDate: Date!
		$endDate: Date!
	) {
		weeklyPlannerEventWeekCalories(
			input: {
				startDate: $startDate
				endDate: $endDate
			}
		) {
			weekCaloriesCount
			mealsDays
			caloriesData {
				totalFat
				satFat
				proteins
				totalCarbs
				cholesterol
				sodium
				fiber
				sugar
			}
		}
	}
`;

/**
 * __useGetweeklyPlannerEventWeekCaloriesQuery__
 *
 * To run a query within a React component, call `useGetweeklyPlannerEventWeekCaloriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetweeklyPlannerEventWeekCaloriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetweeklyPlannerEventWeekCaloriesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetweeklyPlannerEventWeekCaloriesQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetweeklyPlannerEventWeekCaloriesQuery,
		GetweeklyPlannerEventWeekCaloriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetweeklyPlannerEventWeekCaloriesQuery,
		GetweeklyPlannerEventWeekCaloriesQueryVariables
	>(
		GetweeklyPlannerEventWeekCaloriesDocument,
		options,
	);
}
export function useGetweeklyPlannerEventWeekCaloriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetweeklyPlannerEventWeekCaloriesQuery,
		GetweeklyPlannerEventWeekCaloriesQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetweeklyPlannerEventWeekCaloriesQuery,
		GetweeklyPlannerEventWeekCaloriesQueryVariables
	>(
		GetweeklyPlannerEventWeekCaloriesDocument,
		options,
	);
}
export type GetweeklyPlannerEventWeekCaloriesQueryHookResult =
	ReturnType<
		typeof useGetweeklyPlannerEventWeekCaloriesQuery
	>;
export type GetweeklyPlannerEventWeekCaloriesLazyQueryHookResult =
	ReturnType<
		typeof useGetweeklyPlannerEventWeekCaloriesLazyQuery
	>;
export type GetweeklyPlannerEventWeekCaloriesQueryResult =
	Apollo.QueryResult<
		GetweeklyPlannerEventWeekCaloriesQuery,
		GetweeklyPlannerEventWeekCaloriesQueryVariables
	>;
export const GetMyGroceryListDocument = gql`
	query getMyGroceryList {
		getMyGroceryList {
			id
			description
			checked
			category
			user {
				id
				username
			}
		}
	}
`;

/**
 * __useGetMyGroceryListQuery__
 *
 * To run a query within a React component, call `useGetMyGroceryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyGroceryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyGroceryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyGroceryListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetMyGroceryListQuery,
		GetMyGroceryListQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		GetMyGroceryListQuery,
		GetMyGroceryListQueryVariables
	>(GetMyGroceryListDocument, options);
}
export function useGetMyGroceryListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMyGroceryListQuery,
		GetMyGroceryListQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		GetMyGroceryListQuery,
		GetMyGroceryListQueryVariables
	>(GetMyGroceryListDocument, options);
}
export type GetMyGroceryListQueryHookResult =
	ReturnType<typeof useGetMyGroceryListQuery>;
export type GetMyGroceryListLazyQueryHookResult =
	ReturnType<typeof useGetMyGroceryListLazyQuery>;
export type GetMyGroceryListQueryResult =
	Apollo.QueryResult<
		GetMyGroceryListQuery,
		GetMyGroceryListQueryVariables
	>;
export const CreateMyListItemDocument = gql`
	mutation createMyListItem(
		$decription: String!
		$category: String!
	) {
		createMyListItem(
			input: {
				description: $decription
				category: $category
			}
		) {
			id
			description
			checked
			category
			user {
				id
				username
			}
		}
	}
`;
export type CreateMyListItemMutationFn =
	Apollo.MutationFunction<
		CreateMyListItemMutation,
		CreateMyListItemMutationVariables
	>;

/**
 * __useCreateMyListItemMutation__
 *
 * To run a mutation, you first call `useCreateMyListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyListItemMutation, { data, loading, error }] = useCreateMyListItemMutation({
 *   variables: {
 *      decription: // value for 'decription'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateMyListItemMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateMyListItemMutation,
		CreateMyListItemMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		CreateMyListItemMutation,
		CreateMyListItemMutationVariables
	>(CreateMyListItemDocument, options);
}
export type CreateMyListItemMutationHookResult =
	ReturnType<typeof useCreateMyListItemMutation>;
export type CreateMyListItemMutationResult =
	Apollo.MutationResult<CreateMyListItemMutation>;
export type CreateMyListItemMutationOptions =
	Apollo.BaseMutationOptions<
		CreateMyListItemMutation,
		CreateMyListItemMutationVariables
	>;
export const UpdateMyListItemDocument = gql`
	mutation updateMyListItem(
		$id: ID!
		$decription: String!
		$category: String!
	) {
		updateMyListItem(
			input: {
				id: $id
				description: $decription
				category: $category
			}
		) {
			id
			description
			checked
			category
			user {
				id
				username
			}
		}
	}
`;
export type UpdateMyListItemMutationFn =
	Apollo.MutationFunction<
		UpdateMyListItemMutation,
		UpdateMyListItemMutationVariables
	>;

/**
 * __useUpdateMyListItemMutation__
 *
 * To run a mutation, you first call `useUpdateMyListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyListItemMutation, { data, loading, error }] = useUpdateMyListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      decription: // value for 'decription'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateMyListItemMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateMyListItemMutation,
		UpdateMyListItemMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		UpdateMyListItemMutation,
		UpdateMyListItemMutationVariables
	>(UpdateMyListItemDocument, options);
}
export type UpdateMyListItemMutationHookResult =
	ReturnType<typeof useUpdateMyListItemMutation>;
export type UpdateMyListItemMutationResult =
	Apollo.MutationResult<UpdateMyListItemMutation>;
export type UpdateMyListItemMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateMyListItemMutation,
		UpdateMyListItemMutationVariables
	>;
export const DeleteMyListItemDocument = gql`
	mutation deleteMyListItem($id: ID!) {
		deleteMyListItem(id: $id) {
			id
			description
			checked
			category
			user {
				id
				username
			}
		}
	}
`;
export type DeleteMyListItemMutationFn =
	Apollo.MutationFunction<
		DeleteMyListItemMutation,
		DeleteMyListItemMutationVariables
	>;

/**
 * __useDeleteMyListItemMutation__
 *
 * To run a mutation, you first call `useDeleteMyListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyListItemMutation, { data, loading, error }] = useDeleteMyListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMyListItemMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteMyListItemMutation,
		DeleteMyListItemMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		DeleteMyListItemMutation,
		DeleteMyListItemMutationVariables
	>(DeleteMyListItemDocument, options);
}
export type DeleteMyListItemMutationHookResult =
	ReturnType<typeof useDeleteMyListItemMutation>;
export type DeleteMyListItemMutationResult =
	Apollo.MutationResult<DeleteMyListItemMutation>;
export type DeleteMyListItemMutationOptions =
	Apollo.BaseMutationOptions<
		DeleteMyListItemMutation,
		DeleteMyListItemMutationVariables
	>;
export const DeleteAllMyListDocument = gql`
	query deleteAllMyList {
		deleteAllMyList {
			id
			description
			checked
			category
			user {
				id
				username
			}
		}
	}
`;

/**
 * __useDeleteAllMyListQuery__
 *
 * To run a query within a React component, call `useDeleteAllMyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllMyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteAllMyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllMyListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		DeleteAllMyListQuery,
		DeleteAllMyListQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		DeleteAllMyListQuery,
		DeleteAllMyListQueryVariables
	>(DeleteAllMyListDocument, options);
}
export function useDeleteAllMyListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DeleteAllMyListQuery,
		DeleteAllMyListQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		DeleteAllMyListQuery,
		DeleteAllMyListQueryVariables
	>(DeleteAllMyListDocument, options);
}
export type DeleteAllMyListQueryHookResult =
	ReturnType<typeof useDeleteAllMyListQuery>;
export type DeleteAllMyListLazyQueryHookResult =
	ReturnType<typeof useDeleteAllMyListLazyQuery>;
export type DeleteAllMyListQueryResult =
	Apollo.QueryResult<
		DeleteAllMyListQuery,
		DeleteAllMyListQueryVariables
	>;
export const CheckMyListItemDocument = gql`
	mutation checkMyListItem(
		$id: ID!
		$checked: Boolean!
	) {
		checkedMyListItem(
			input: { id: $id, checked: $checked }
		) {
			id
			description
			checked
			category
			user {
				id
				username
			}
		}
	}
`;
export type CheckMyListItemMutationFn =
	Apollo.MutationFunction<
		CheckMyListItemMutation,
		CheckMyListItemMutationVariables
	>;

/**
 * __useCheckMyListItemMutation__
 *
 * To run a mutation, you first call `useCheckMyListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckMyListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkMyListItemMutation, { data, loading, error }] = useCheckMyListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      checked: // value for 'checked'
 *   },
 * });
 */
export function useCheckMyListItemMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CheckMyListItemMutation,
		CheckMyListItemMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		CheckMyListItemMutation,
		CheckMyListItemMutationVariables
	>(CheckMyListItemDocument, options);
}
export type CheckMyListItemMutationHookResult =
	ReturnType<typeof useCheckMyListItemMutation>;
export type CheckMyListItemMutationResult =
	Apollo.MutationResult<CheckMyListItemMutation>;
export type CheckMyListItemMutationOptions =
	Apollo.BaseMutationOptions<
		CheckMyListItemMutation,
		CheckMyListItemMutationVariables
	>;
export const DeleteMyListItemByCategoryDocument = gql`
	mutation deleteMyListItemByCategory(
		$category: String!
	) {
		deleteMyListItemByCategory(
			category: $category
		) {
			id
			description
			checked
			category
			user {
				id
				username
			}
		}
	}
`;
export type DeleteMyListItemByCategoryMutationFn =
	Apollo.MutationFunction<
		DeleteMyListItemByCategoryMutation,
		DeleteMyListItemByCategoryMutationVariables
	>;

/**
 * __useDeleteMyListItemByCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteMyListItemByCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyListItemByCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyListItemByCategoryMutation, { data, loading, error }] = useDeleteMyListItemByCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useDeleteMyListItemByCategoryMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteMyListItemByCategoryMutation,
		DeleteMyListItemByCategoryMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		DeleteMyListItemByCategoryMutation,
		DeleteMyListItemByCategoryMutationVariables
	>(DeleteMyListItemByCategoryDocument, options);
}
export type DeleteMyListItemByCategoryMutationHookResult =
	ReturnType<
		typeof useDeleteMyListItemByCategoryMutation
	>;
export type DeleteMyListItemByCategoryMutationResult =
	Apollo.MutationResult<DeleteMyListItemByCategoryMutation>;
export type DeleteMyListItemByCategoryMutationOptions =
	Apollo.BaseMutationOptions<
		DeleteMyListItemByCategoryMutation,
		DeleteMyListItemByCategoryMutationVariables
	>;
export const ListWeeklyPlannerEventsDocument = gql`
	query listWeeklyPlannerEvents($date: Date!) {
		listWeeklyPlannerEvents(date: $date) {
			dayCaloriesCount
			caloriesData {
				totalFat
				satFat
				proteins
				totalCarbs
				cholesterol
				sodium
				fiber
				sugar
			}
			events {
				id
				type
				date
				slot
				createdAt
				recipe {
					id
					Title
					collaborator {
						name
					}
					thumbnail {
						url
					}
					Prep_Duration {
						duration
					}
					Calories
				}
				movement {
					id
					title
					collaborator {
						name
					}
					thumbnail {
						url
					}
					duration
					calories_burn
					movement_category {
						id
					}
				}
				meditation {
					id
					title
					collaborator {
						name
					}
					thumbnail {
						url
					}
					duration
					meditation_category {
						id
					}
				}
				mantra {
					id
					title
					cover_image {
						url
					}
					collaborator {
						name
					}
				}
				masti {
					id
					title
					cover_image {
						url
					}
					collaborator {
						name
					}
					reading_time
				}
			}
		}
	}
`;

/**
 * __useListWeeklyPlannerEventsQuery__
 *
 * To run a query within a React component, call `useListWeeklyPlannerEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWeeklyPlannerEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWeeklyPlannerEventsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useListWeeklyPlannerEventsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListWeeklyPlannerEventsQuery,
		ListWeeklyPlannerEventsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		ListWeeklyPlannerEventsQuery,
		ListWeeklyPlannerEventsQueryVariables
	>(ListWeeklyPlannerEventsDocument, options);
}
export function useListWeeklyPlannerEventsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListWeeklyPlannerEventsQuery,
		ListWeeklyPlannerEventsQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		ListWeeklyPlannerEventsQuery,
		ListWeeklyPlannerEventsQueryVariables
	>(ListWeeklyPlannerEventsDocument, options);
}
export type ListWeeklyPlannerEventsQueryHookResult =
	ReturnType<
		typeof useListWeeklyPlannerEventsQuery
	>;
export type ListWeeklyPlannerEventsLazyQueryHookResult =
	ReturnType<
		typeof useListWeeklyPlannerEventsLazyQuery
	>;
export type ListWeeklyPlannerEventsQueryResult =
	Apollo.QueryResult<
		ListWeeklyPlannerEventsQuery,
		ListWeeklyPlannerEventsQueryVariables
	>;
export const MeDocument = gql`
	query me {
		newMe {
			id
			email
			name
			dob
			country
			city
			state
			timezone
			bio
			createdAt
			avatar {
				id
				previewUrl
				url
			}
			what
			why
			trialDays
			subEnd
			subPlanId
			subStatus
			profession
			interests
		}
	}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
	baseOptions?: Apollo.QueryHookOptions<
		MeQuery,
		MeQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		MeQuery,
		MeQueryVariables
	>(MeDocument, options);
}
export function useMeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		MeQuery,
		MeQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		MeQuery,
		MeQueryVariables
	>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<
	typeof useMeQuery
>;
export type MeLazyQueryHookResult = ReturnType<
	typeof useMeLazyQuery
>;
export type MeQueryResult = Apollo.QueryResult<
	MeQuery,
	MeQueryVariables
>;
export const PlansDocument = gql`
	query plans {
		subscriptionPlans {
			id
			plantitle
			stripepriceid
			price
			duration
		}
	}
`;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(
	baseOptions?: Apollo.QueryHookOptions<
		PlansQuery,
		PlansQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useQuery<
		PlansQuery,
		PlansQueryVariables
	>(PlansDocument, options);
}
export function usePlansLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		PlansQuery,
		PlansQueryVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useLazyQuery<
		PlansQuery,
		PlansQueryVariables
	>(PlansDocument, options);
}
export type PlansQueryHookResult = ReturnType<
	typeof usePlansQuery
>;
export type PlansLazyQueryHookResult = ReturnType<
	typeof usePlansLazyQuery
>;
export type PlansQueryResult = Apollo.QueryResult<
	PlansQuery,
	PlansQueryVariables
>;
export const SubscribePlanDocument = gql`
	mutation subscribePlan($planId: String!) {
		subscribePlan(input: { planId: $planId }) {
			url
		}
	}
`;
export type SubscribePlanMutationFn =
	Apollo.MutationFunction<
		SubscribePlanMutation,
		SubscribePlanMutationVariables
	>;

/**
 * __useSubscribePlanMutation__
 *
 * To run a mutation, you first call `useSubscribePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribePlanMutation, { data, loading, error }] = useSubscribePlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useSubscribePlanMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SubscribePlanMutation,
		SubscribePlanMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		SubscribePlanMutation,
		SubscribePlanMutationVariables
	>(SubscribePlanDocument, options);
}
export type SubscribePlanMutationHookResult =
	ReturnType<typeof useSubscribePlanMutation>;
export type SubscribePlanMutationResult =
	Apollo.MutationResult<SubscribePlanMutation>;
export type SubscribePlanMutationOptions =
	Apollo.BaseMutationOptions<
		SubscribePlanMutation,
		SubscribePlanMutationVariables
	>;
export const UpdateMyPrioritiesDocument = gql`
	mutation updateMyPriorities(
		$priorities: [PriorityUpdateInput]
	) {
		updateMyPriorities(
			input: { priorities: $priorities }
		) {
			id
			priorities {
				id
				type
				title
				description
				checkin
			}
			updatedAt
		}
	}
`;
export type UpdateMyPrioritiesMutationFn =
	Apollo.MutationFunction<
		UpdateMyPrioritiesMutation,
		UpdateMyPrioritiesMutationVariables
	>;

/**
 * __useUpdateMyPrioritiesMutation__
 *
 * To run a mutation, you first call `useUpdateMyPrioritiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyPrioritiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyPrioritiesMutation, { data, loading, error }] = useUpdateMyPrioritiesMutation({
 *   variables: {
 *      priorities: // value for 'priorities'
 *   },
 * });
 */
export function useUpdateMyPrioritiesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateMyPrioritiesMutation,
		UpdateMyPrioritiesMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		UpdateMyPrioritiesMutation,
		UpdateMyPrioritiesMutationVariables
	>(UpdateMyPrioritiesDocument, options);
}
export type UpdateMyPrioritiesMutationHookResult =
	ReturnType<
		typeof useUpdateMyPrioritiesMutation
	>;
export type UpdateMyPrioritiesMutationResult =
	Apollo.MutationResult<UpdateMyPrioritiesMutation>;
export type UpdateMyPrioritiesMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateMyPrioritiesMutation,
		UpdateMyPrioritiesMutationVariables
	>;
export const UpdateProfileDocument = gql`
	mutation updateProfile(
		$id: ID!
		$name: String!
		$dob: Date!
		$country: String!
		$state: String!
		$city: String!
		$timezone: String!
		$bio: String!
		$file: Upload!
		$interests: String
		$profession: String
	) {
		upload(
			ref: "user"
			source: "users-permissions"
			refId: $id
			field: "avatar"
			file: $file
		) {
			id
			url
		}
		updateUser(
			input: {
				where: { id: $id }
				data: {
					name: $name
					dob: $dob
					bio: $bio
					state: $state
					city: $city
					country: $country
					timezone: $timezone
					interests: $interests
					profession: $profession
				}
			}
		) {
			user {
				name
				dob
				country
				state
				city
				timezone
				bio
				avatar {
					id
					url
				}
				interests
				profession
			}
		}
	}
`;
export type UpdateProfileMutationFn =
	Apollo.MutationFunction<
		UpdateProfileMutation,
		UpdateProfileMutationVariables
	>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      dob: // value for 'dob'
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      timezone: // value for 'timezone'
 *      bio: // value for 'bio'
 *      file: // value for 'file'
 *      interests: // value for 'interests'
 *      profession: // value for 'profession'
 *   },
 * });
 */
export function useUpdateProfileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateProfileMutation,
		UpdateProfileMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		UpdateProfileMutation,
		UpdateProfileMutationVariables
	>(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult =
	ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult =
	Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateProfileMutation,
		UpdateProfileMutationVariables
	>;
export const UpdateProfileWoImageDocument = gql`
	mutation updateProfileWOImage(
		$id: ID!
		$name: String!
		$dob: Date!
		$country: String!
		$state: String!
		$city: String!
		$timezone: String!
		$bio: String!
		$interests: String
		$profession: String
	) {
		updateUser(
			input: {
				where: { id: $id }
				data: {
					name: $name
					dob: $dob
					bio: $bio
					state: $state
					city: $city
					country: $country
					timezone: $timezone
					interests: $interests
					profession: $profession
				}
			}
		) {
			user {
				name
				dob
				country
				state
				city
				timezone
				bio
				avatar {
					id
					url
				}
				interests
				profession
			}
		}
	}
`;
export type UpdateProfileWoImageMutationFn =
	Apollo.MutationFunction<
		UpdateProfileWoImageMutation,
		UpdateProfileWoImageMutationVariables
	>;

/**
 * __useUpdateProfileWoImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileWoImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileWoImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileWoImageMutation, { data, loading, error }] = useUpdateProfileWoImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      dob: // value for 'dob'
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      timezone: // value for 'timezone'
 *      bio: // value for 'bio'
 *      interests: // value for 'interests'
 *      profession: // value for 'profession'
 *   },
 * });
 */
export function useUpdateProfileWoImageMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateProfileWoImageMutation,
		UpdateProfileWoImageMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		UpdateProfileWoImageMutation,
		UpdateProfileWoImageMutationVariables
	>(UpdateProfileWoImageDocument, options);
}
export type UpdateProfileWoImageMutationHookResult =
	ReturnType<
		typeof useUpdateProfileWoImageMutation
	>;
export type UpdateProfileWoImageMutationResult =
	Apollo.MutationResult<UpdateProfileWoImageMutation>;
export type UpdateProfileWoImageMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateProfileWoImageMutation,
		UpdateProfileWoImageMutationVariables
	>;
export const UpdateWhatWhyDocument = gql`
	mutation updateWhatWhy(
		$id: ID!
		$what: String!
		$why: String!
	) {
		updateUser(
			input: {
				where: { id: $id }
				data: { what: $what, why: $why }
			}
		) {
			user {
				what
				why
			}
		}
	}
`;
export type UpdateWhatWhyMutationFn =
	Apollo.MutationFunction<
		UpdateWhatWhyMutation,
		UpdateWhatWhyMutationVariables
	>;

/**
 * __useUpdateWhatWhyMutation__
 *
 * To run a mutation, you first call `useUpdateWhatWhyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWhatWhyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWhatWhyMutation, { data, loading, error }] = useUpdateWhatWhyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      what: // value for 'what'
 *      why: // value for 'why'
 *   },
 * });
 */
export function useUpdateWhatWhyMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateWhatWhyMutation,
		UpdateWhatWhyMutationVariables
	>,
) {
	const options = {
		...defaultOptions,
		...baseOptions,
	};
	return Apollo.useMutation<
		UpdateWhatWhyMutation,
		UpdateWhatWhyMutationVariables
	>(UpdateWhatWhyDocument, options);
}
export type UpdateWhatWhyMutationHookResult =
	ReturnType<typeof useUpdateWhatWhyMutation>;
export type UpdateWhatWhyMutationResult =
	Apollo.MutationResult<UpdateWhatWhyMutation>;
export type UpdateWhatWhyMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateWhatWhyMutation,
		UpdateWhatWhyMutationVariables
	>;
