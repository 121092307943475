import styled from "styled-components";
import {
	H2,
	Body1,
	Subtitle1,
	Body2,
	Wrapper,
	FlexCenter,
	Button,
	Spacing,
} from "@workshore/nyaari-ds";
import {
	useParams,
	useHistory,
} from "react-router-dom";

import { useGetCollaboratorQuery } from "../../hooks/graphql/graphql";
import ShowIf from "../../components/ShowIf/ShowIf";
import CollabPageModal from "../../components/CollabPageModal/CollabPageModal";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import Seperator from "../../components/Seperator/Seperator";
import Footer from "../../components/Footer/Footer";
import IconLink from "../../components/Icons/IconLink";
import IconRoundYoutube from "../../components/Icons/IconRoundYoutube";
import IconRoundFB from "../../components/Icons/IconRoundFB";
import IconRoundInsta from "../../components/Icons/IconRoundInsta";
import Loader from "../../components/Loader/Loader";
import IconRoundTwitter from "../../components/Icons/IconRoundTwitter";
import HTMLParser from "../../components/HTMLParser";

const ModalDiv = styled.div`
	img {
		height: 245px;
		width: 350px;
		margin-right: 24px;
		object-fit: cover;
		border-radius: 5px;
	}
`;

const ModalHeader = styled.div`
	display: flex;
	flex-direction: row;
`;

const Links = styled.div`
	display: flex;
	flex-direction: row;

	> * {
		height: 27px;
		width: 27px;
	}

	> :not(:last-child) {
		margin-right: 9px;
	}
`;

const ModalDiv1 = styled.div`
	> img {
		height: 250px;
		width: 100%;
		object-fit: cover;
	}
`;

const ModalHeader1 = styled.div`
	margin: auto;
	margin-top: -32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 24px 14px;
	border-radius: 5px;
	width: calc(100% - 100px);
	background: white;
	box-shadow: 0 3px 6px #0000001a;
`;

function CollaboratorDetails() {
	const isTablet = useIsTablet();

	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const { data, loading, error, refetch } =
		useGetCollaboratorQuery({
			variables: {
				id,
			},
		});

	const collaborator = data?.collaborator;
	return (
		<>
			<ShowIf value={loading}>
				<CollabPageModal>
					<div
						style={{
							height: "50vh",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Loader />
					</div>
				</CollabPageModal>
			</ShowIf>
			<ShowIf value={error}>
				<CollabPageModal>
					<div
						style={{
							height: "50vh",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{error &&
						error!.message.includes(
							"Cast to ObjectId failed",
						) ? (
							<div>
								<Body1>
									Oops! Page does not exist.
								</Body1>
								<Spacing type="s24" />
								<FlexCenter>
									<Button
										width="200px"
										handleButtonClick={() =>
											history.push(
												"/collaborators",
											)
										}
									>
										<Body2
											as="span"
											fontColor="white"
										>
											Go to Our Team
										</Body2>
									</Button>
								</FlexCenter>
							</div>
						) : (
							<>
								<FlexCenter>
									<Body1>
										Failed to collaborator details
									</Body1>
								</FlexCenter>
								<Spacing type="s24" />
								<FlexCenter>
									<Button width="100px">
										<Body2
											as="span"
											fontColor="white"
											onClick={() => {
												refetch();
											}}
										>
											Retry
										</Body2>
									</Button>
								</FlexCenter>
							</>
						)}
					</div>
				</CollabPageModal>
			</ShowIf>
			{!loading && !error && collaborator && (
				<CollabPageModal>
					{!isTablet && (
						<ModalDiv>
							<ModalHeader>
								<img
									src={
										(collaborator as any).image
											.url
									}
									alt=""
								/>
								<div>
									<Subtitle1>
										{collaborator!.name}
									</Subtitle1>
									<Body2
										marginBottom="8px"
										fontColor="boulder"
									>
										{collaborator.occupation}
									</Body2>
									<Body1 marginBottom="9px">
										{collaborator.location}
									</Body1>
									<Links>
										<ShowIf
											value={
												collaborator.links?.length
											}
										>
											{collaborator.links?.map(
												(link: any) => (
													<>
														{link.type ===
															"Other" && (
															<a href={link.url}>
																<IconLink />
															</a>
														)}
														{link.type ===
															"Facebook" && (
															<a href={link.url}>
																<IconRoundFB />
															</a>
														)}
														{link.type ===
															"Instagram" && (
															<a href={link.url}>
																<IconRoundInsta />
															</a>
														)}
														{link.type ===
															"Youtube" && (
															<a href={link.url}>
																<IconRoundYoutube />
															</a>
														)}
														{link.type ===
															"Twitter" && (
															<a href={link.url}>
																<IconRoundTwitter />
															</a>
														)}
													</>
												),
											)}
										</ShowIf>
									</Links>
								</div>
							</ModalHeader>
							<Subtitle1
								marginTop="34px"
								marginBottom="8px"
							>
								Area of Expertise
							</Subtitle1>
							<HTMLParser
								data={collaborator.expertise_rich}
							/>
							{/* <Body2
								style={{ lineHeight: "30px" }}
								fontWeight={400}
								marginBottom="30px"
							>
								{collaborator.expertise}
							</Body2> */}
							<Seperator color="mercury" />
							<Subtitle1
								marginTop="30px"
								marginBottom="11px"
							>
								Why I joined Nyaari
							</Subtitle1>
							{/* <Body2
								style={{ lineHeight: "30px" }}
								fontWeight={400}
							>
								{collaborator.why}
							</Body2> */}
							<HTMLParser
								data={collaborator.why_rich!}
							/>
						</ModalDiv>
					)}
					{isTablet && (
						<ModalDiv1>
							<img
								src={
									(collaborator as any).image.url
								}
								alt=""
							/>
							<div
								style={{ position: "relative" }}
							>
								<ModalHeader1>
									<div>
										<H2
											as="span"
											paddingBottom="8px"
											style={{
												textAlign: "center",
											}}
										>
											{collaborator.name} |{" "}
											<H2
												as="span"
												fontColor="boulder"
											>
												{collaborator.occupation}
											</H2>
										</H2>
									</div>
									<Body1 marginBottom="14px">
										{collaborator.location}
									</Body1>
									<Links>
										<ShowIf
											value={
												collaborator.links?.length
											}
										>
											{collaborator.links?.map(
												(link: any) => (
													<>
														{link.type ===
															"Other" && (
															<a href={link.url}>
																<IconLink />
															</a>
														)}
														{link.type ===
															"Facebook" && (
															<a href={link.url}>
																<IconRoundFB />
															</a>
														)}
														{link.type ===
															"Instagram" && (
															<a href={link.url}>
																<IconRoundInsta />
															</a>
														)}
														{link.type ===
															"Youtube" && (
															<a href={link.url}>
																<IconRoundYoutube />
															</a>
														)}
													</>
												),
											)}
										</ShowIf>
									</Links>
								</ModalHeader1>
								<Wrapper>
									<Subtitle1
										paddingTop="20px"
										marginBottom="6px"
									>
										Area of Expertise
									</Subtitle1>
									<HTMLParser
										data={
											collaborator.expertise_rich
										}
									/>
									{/* <Body2
										style={{ lineHeight: "30px" }}
										fontWeight={400}
										marginBottom="20px"
									>
										{collaborator.expertise}
									</Body2> */}
									<Seperator color="mercury" />
									<Subtitle1
										marginTop="16px"
										marginBottom="8px"
									>
										Why I joined Nyaari
									</Subtitle1>
									{/* <Body2
										style={{ lineHeight: "30px" }}
										fontWeight={400}
										marginBottom="43px"
									>
										{collaborator.why}
									</Body2> */}
									<HTMLParser
										data={collaborator.why_rich!}
									/>
								</Wrapper>
							</div>
							<Footer />
						</ModalDiv1>
					)}
				</CollabPageModal>
			)}
		</>
	);
}

export default CollaboratorDetails;
