import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";

function useRoute() {
  const history = useHistory();

  const goTo = useCallback(
    (
      path: string | number = "/",
      type: "push" | "go" | "replace" = "push"
    ) => {
      switch (type) {
        case "go":
          history.go(path as number);
          break;
        case "replace":
          history.replace(path as string);
          break;
        default:
          history.push(path as string);
      }
    },
    [history]
  );
  useEffect(() => {
    const unregister = history.listen(() => {});

    return () => unregister();
  }, [history]);

  return {
    path: history.location.pathname,
    location: history.location,
    goTo,
    goBack: history.goBack,
    goForward: history.goForward,
  };
}

export default useRoute;
