import useSharedState, {
	useSharedStateChange,
	useSharedStateValue,
} from "@tinyd/usesharedstate";
import dayjs from "dayjs";
import {
	Body1,
	Body2,
	Box,
	BulletText,
	Caption,
	CardContainer,
	DS,
	FlexBetween,
	H3,
	ListItem,
	Spacing,
	Subtitle1,
	Wrapper,
	Modal,
} from "@workshore/nyaari-ds";
import React, {
	FC,
	useCallback,
	useEffect,
	useState,
} from "react";
import Lottie from "react-lottie-player";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import {
	GetMyPrioritiesQuery,
	useGetMyPrioritiesQuery,
	useUpdateMyPrioritiesMutation,
} from "../../hooks/graphql/graphql";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import storage from "../../services/storage";
import {
	minimalPriorityType,
	priorityFactory,
} from "../../utils/priorityHelper";
import BulletList from "../BulletList/BulletList";
import IconChecked from "../Icons/IconChecked";
import IconNonChecked from "../Icons/IconNonChecked";
import IconRightArrow from "../Icons/IconRightArrow";
import LayoutContainer from "../LayoutContainer/LayoutContainer";
import Loader from "../Loader/Loader";
import ShowIf from "../ShowIf/ShowIf";
import TypedIcon from "../TypedIcon/TypedIcon";
import RewardAnim from "../../assets/anim/celebration.json";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import IconClose from "../Icons/IconClose";
import SuperImg from "../../assets/images/super_img.png";

const RewardContainer = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
`;

// modal
const ModalDiv = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 64px 98px;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 35px 55px;
  `};
`;

const IconContainer = styled.div`
	float: right;
	cursor: pointer;
`;

const PrioritiesList: FC<{
	data?: GetMyPrioritiesQuery;
}> = ({ data }) => {
	const { addToast } = useToasts();
	const changeTotalTick =
		useSharedStateChange("priorities");
	const changeShowAnim = useSharedStateChange(
		"priorities_anim",
	);

	const [savingIndex, setSavingIndex] =
		useState(-1);
	const updateMyPriorities =
		useUpdateMyPrioritiesMutation();
	const isTablet = useIsTablet();
	const finalData = priorityFactory(
		(data?.getMyPriorities?.priorities ||
			[]) as any as minimalPriorityType[],
	);
	const [updatedData, setUpdatedData] =
		useState(finalData);
	const updateValue = useCallback(
		(index: number) => {
			const newData = updatedData;
			if (
				updatedData[index].checkin &&
				updatedData[index].checkin !==
					dayjs().format("YYYY-MM-DD")
			) {
				updatedData[index].checkin = null;
			}
			newData[index].checkin = updatedData[index]
				.checkin
				? null
				: dayjs().format("YYYY-MM-DD");

			setUpdatedData([...newData]);
			setSavingIndex(index);
			updateMyPriorities[0]({
				variables: {
					priorities: updatedData.map((d) => ({
						title: d.title,
						description: d.description,
						type: d.type,
						checkin: d.checkin,
					})),
				},
			})
				.then((res) => {
					console.log(res);
					setSavingIndex(-1);
				})
				.catch((e) => {
					console.log(e);
				});
		},
		[updatedData, updateMyPriorities],
	);
	useEffect(() => {
		storage.set("myPriorities", updatedData);
		changeTotalTick(
			updatedData.filter(
				(d) =>
					d.checkin ===
					dayjs().format("YYYY-MM-DD"),
			).length,
		);
		if (savingIndex !== -1)
			changeShowAnim(
				updatedData.filter(
					(d) =>
						d.checkin ===
						dayjs().format("YYYY-MM-DD"),
				).length === 3,
			);
	}, [
		updatedData,
		changeTotalTick,
		changeShowAnim,
		savingIndex,
	]);
	return (
		<div>
			{updatedData.map((data, index) => {
				return (
					<Box key={`${index}${data.checkin}`}>
						<CardContainer>
							<FlexBetween
								style={{
									alignItems: "center",
								}}
							>
								<Link
									to={`/dashboard/priorities/select/${index}`}
									style={{
										flex: 1,
									}}
								>
									<ListItem
										leftIcon={
											data.type ? (
												<TypedIcon
													type={data.type || ""}
													active={
														dayjs().format(
															"YYYY-MM-DD",
														) === data.checkin
													}
												/>
											) : (
												<H3 fontColor="redRibbon">
													<i>{index + 1}</i>
												</H3>
											)
										}
										title={
											<Body1>
												{data.title ||
													(isTablet
														? "Tap to set a priority"
														: "Select your priority")}
											</Body1>
										}
										subTitle={
											data.title ? (
												<Caption>
													{data.description}
												</Caption>
											) : undefined
										}
										rightIcon={
											!data.title ? (
												<IconRightArrow color="redRibbon" />
											) : undefined
										}
									/>
								</Link>
								{data.title ? (
									<Box
										style={{ cursor: "pointer" }}
										marginRight={
											DS.spacing.s8 * 1.5
										}
										onClick={() => {
											if (
												updateMyPriorities[1]
													.loading
											) {
												addToast(
													"Please wait...",
													{
														appearance: "info",
														autoDismiss: true,
													},
												);
											} else {
												updateValue(index);
											}
										}}
									>
										{savingIndex !== index ? (
											dayjs().format(
												"YYYY-MM-DD",
											) === data.checkin ? (
												<IconChecked />
											) : (
												<IconNonChecked />
											)
										) : (
											<Body2>Saving...</Body2>
										)}
									</Box>
								) : null}
							</FlexBetween>
						</CardContainer>
						<Spacing type="s24" />
					</Box>
				);
			})}
		</div>
	);
};

const ViewPriorities = () => {
	const myPriorities = useGetMyPrioritiesQuery();
	const totalTicks = useSharedStateValue(
		"priorities",
		0,
	);
	const [showAnimation, setShowAnimation] =
		useSharedState("priorities_anim", false);

	const isMobile = useIsMobile();

	return (
		<>
			<LayoutContainer backgroundColor="wildSand">
				<Box
					paddingTop={isMobile ? 25 : 50}
					paddingBottom={50}
				>
					<Wrapper type="fluid">
						<FlexBetween>
							<H3>Daily Priorities</H3>

							<H3>
								You scored :{" "}
								<H3
									fontColor="redRibbon"
									as="span"
								>
									{totalTicks}
								</H3>
								/3
							</H3>
						</FlexBetween>
						<Spacing type="s24" />
						<Body1
							fontWeight={600}
							fontColor="boulder"
						>
							Check-Off Your Top 3 Priorities
							Every Day
						</Body1>
						<Spacing type="s8" />
						<Body2 fontColor="boulder">
							Strive for progress, not perfection.
							This is not a test.
						</Body2>
						<Spacing type="s24" />
						<ShowIf value={myPriorities.loading}>
							<Loader>
								<Body2>
									Loading your daily priorities ✅
								</Body2>
							</Loader>
						</ShowIf>
						<ShowIf value={!myPriorities.loading}>
							<PrioritiesList
								data={myPriorities.data}
							/>
						</ShowIf>
					</Wrapper>
				</Box>
				<Spacing type="s64" multiplier={2} />
			</LayoutContainer>
			{/* <ShowIf value={showAnimation}>
				<RewardContainer>
					<Lottie
						animationData={RewardAnim}
						play
						loop={false}
						speed={1.2}
						style={{
							width: "100%",
						}}
						onComplete={() => {
							setShowAnimation(false);
						}}
					/>
				</RewardContainer>
			</ShowIf> */}
			<ShowIf value={showAnimation}>
				<RewardContainer>
					<Modal
						show={showAnimation}
						handleClose={() =>
							setShowAnimation(false)
						}
						borderRadius="5"
						width={isMobile ? "80vw" : "565px"}
						placement="center"
						dismissible
					>
						<IconContainer
							onClick={() =>
								setShowAnimation(false)
							}
						>
							<IconClose />
						</IconContainer>
						<ModalDiv isMobile={isMobile}>
							<div>
								<img
									src={SuperImg}
									alt="Reward"
									style={{
										maxWidth: 300,
									}}
								/>
							</div>
							<H3
								marginTop={
									isMobile ? "26px" : "30px"
								}
							>
								You scored : 3/3
							</H3>
							<Body2
								style={{ textAlign: "center" }}
								marginTop={
									isMobile ? "9px" : "15px"
								}
								marginBottom={
									isMobile ? "24px" : "41px"
								}
							>
								Today’s success is the beginning
								of tomorrow’s achievement.
							</Body2>
						</ModalDiv>
					</Modal>
				</RewardContainer>
			</ShowIf>
		</>
	);
};

export default ViewPriorities;
