import React, {
	useRef,
	useState,
	useEffect,
	useMemo,
} from "react";
import styled from "styled-components";
import {
	H3,
	Body1,
	Body2,
	Box,
	DS,
	Wrapper,
	Spacing,
} from "@workshore/nyaari-ds";

import LayoutContainer from "../LayoutContainer/LayoutContainer";
import ShowIf from "../ShowIf/ShowIf";
import { Reminder } from "../Reminder";

import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import { useGetAllJournalPromptsQuery } from "../../hooks/graphql/graphql";

import IconTick from "../Icons/IconTick";
import Loader from "../Loader/Loader";
import { JournalListingType } from "./type";

const StyledList = styled.div`
	counter-reset: journal-number;
`;

const StyledItem = styled(Body1)`
	background-color: ${DS.colors.white};
	border-radius: ${DS.borderRadius.body1};
	padding: ${DS.spacing.s16}px;
	counter-increment: journal-number;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	&:before {
		content: counter(journal-number);
		color: ${DS.colors.mantra};
		font-size: ${DS.fontSize.body6}px;
		font-weight: ${DS.fontWeight.semiBold};
		font-style: italic;
		padding-right: 26px;
	}
`;

const JournalListing = () => {
	const ref = useRef<HTMLDivElement>(null);
	const { data, loading } =
		useGetAllJournalPromptsQuery();
	const isTablet = useIsTablet();
	const elementWidth = useElementWidth(ref);
	const isSmall = elementWidth <= 750;
	const allJournalsData = useMemo(
		() => data?.journalPrompts || [],
		[data],
	);
	const [journals, setJournals] =
		useState<JournalListingType>([]);

	useEffect(() => {
		if (allJournalsData.length) {
			setJournals(allJournalsData);
		}
	}, [allJournalsData]);

	return (
		<LayoutContainer
			ref={ref}
			backgroundColor="wildSand"
		>
			<Box
				paddingTop={isTablet ? 15 : 50}
				paddingBottom={90}
			>
				<Wrapper type="fluid">
					{/* Heading */}
					<H3>Journal Prompts</H3>
					<Spacing type="s24" />

					{/* Reminders */}
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "1fr",
							gridGap: "10px",
						}}
					>
						{/* <Reminder>
							<div>
								<IconTick color="mantra" />
							</div>
							<Body2>
								Choose and revisit your priorities
								once a week.
							</Body2>
						</Reminder> */}
						<Reminder>
							<div>
								<IconTick color="mantra" />
							</div>
							<Body2>
								Journaling thoughts, emotions,
								fears &amp; aspirations helps us
								connect, navigate, and grow. This
								is your space for self-expression
								sans judgment. You learn to
								release, to observe, to pause
								&amp; respond, not just react. And
								that’s how you take your power
								back!
							</Body2>
						</Reminder>
					</div>
					<Spacing type="s24" />

					{/* Loading */}
					<ShowIf value={loading}>
						<Loader>
							<Body1>Loading Journal..</Body1>
						</Loader>
					</ShowIf>

					{/* Listing */}
					<ShowIf value={journals?.length}>
						<StyledList>
							{journals?.map((journal) => (
								<StyledItem>
									{journal?.description}
								</StyledItem>
							))}
						</StyledList>
					</ShowIf>
				</Wrapper>
			</Box>
		</LayoutContainer>
	);
};

export default JournalListing;
