import styled from "styled-components";

const Layout = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
`;

export default Layout;
