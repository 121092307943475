import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";
import styled from "styled-components";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";

const BulletListContainer = styled.div`
	flex-wrap: wrap;
	justify-content: space-between;
`;

const BulletList: FC<{
	num?: number;
	items: JSX.Element[];
}> = ({ num = 3, items }) => {
	const isTablet = useIsTablet();
	return (
		<BulletListContainer
			style={{
				display: isTablet ? "block" : "flex",
			}}
		>
			{items.map((child, index) => {
				return (
					<div
						key={index}
						style={{
							width: isTablet
								? "100%"
								: `calc(${100 / num}% - ${
										DS.spacing.s16
								  }px)`,
							marginBottom: isTablet
								? DS.spacing.s8
								: "0px",
						}}
					>
						{child}
					</div>
				);
			})}
		</BulletListContainer>
	);
};

export default BulletList;
