const IconRoundInsta = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="27"
			height="27"
			viewBox="0 0 27 27"
		>
			<g
				id="Group_9900"
				data-name="Group 9900"
				transform="translate(-0.111 0)"
			>
				<g
					id="Group_9897"
					data-name="Group 9897"
					transform="translate(0 0)"
				>
					<g
						id="Ellipse_18"
						data-name="Ellipse 18"
						transform="translate(0.111 0)"
						fill="#fcfcfc"
						stroke="#f57933"
						strokeWidth="1.5"
					>
						<circle
							cx="13.5"
							cy="13.5"
							r="13.5"
							stroke="none"
						/>
						<circle
							cx="13.5"
							cy="13.5"
							r="12.75"
							fill="none"
						/>
					</g>
				</g>
				<g
					id="instagram"
					transform="translate(7.111 7.111)"
				>
					<rect
						id="Rectangle_1004"
						data-name="Rectangle 1004"
						width="13"
						height="13"
						rx="5"
						transform="translate(0 -0.111)"
						fill="none"
						stroke="#f57933"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
					<path
						id="Path_1485"
						data-name="Path 1485"
						d="M13.124,10.136a2.554,2.554,0,1,1-2.152-2.152A2.554,2.554,0,0,1,13.124,10.136Z"
						transform="translate(-4.198 -4.317)"
						fill="none"
						stroke="#f57933"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
					<line
						id="Line_27"
						data-name="Line 27"
						x2="0.006"
						transform="translate(9.885 2.71)"
						fill="none"
						stroke="#f57933"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconRoundInsta;
