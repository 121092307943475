import React from "react";
import {
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import FoodIndexDetails from "../../components/FoodIndexDetails/FoodIndexDetails";
import FoodIndexListing from "../../components/FoodIndexListing/FoodIndexListing";
import GroceryList from "../../components/GroceryList/GroceryList";

import ProtectedSwitch from "../../components/ProtectedSwitch/ProtectedSwitch";
import RecipesDetails from "../../components/RecipesDetails/RecipesDetails";
import RecipesListing from "../../components/RecipesListing/RecipesListing";

const RecipesRoutes = () => {
	return (
		<>
			<RecipesListing />
			<Switch>
				<Route
					path="/dashboard/meals/recipes/:id"
					exact
				>
					<RecipesDetails />
				</Route>
			</Switch>
		</>
	);
};
const FoodIndexRoutes = () => {
	return (
		<>
			<FoodIndexListing />
			<Switch>
				<Route
					path="/dashboard/meals/food-index/:id"
					exact
				>
					<FoodIndexDetails />
				</Route>
			</Switch>
		</>
	);
};

const MealsPage = () => {
	return (
		<ProtectedSwitch>
			<Route path="/dashboard/meals/recipes">
				<RecipesRoutes />
			</Route>
			<Route path="/dashboard/meals/grocery-list">
				<GroceryList />
			</Route>
			<Route path="/dashboard/meals/food-index">
				<FoodIndexRoutes />
			</Route>
			<Route>
				<Redirect to="/dashboard/meals/recipes" />
			</Route>
		</ProtectedSwitch>
	);
};

export default MealsPage;
