const IconRoundTwitter = () => {
	return (
		<svg
			width="27"
			height="27"
			viewBox="0 0 27 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
				fill="#FCFCFC"
			/>
			<path
				d="M13.5 26.25C20.5416 26.25 26.25 20.5416 26.25 13.5C26.25 6.45837 20.5416 0.75 13.5 0.75C6.45837 0.75 0.75 6.45837 0.75 13.5C0.75 20.5416 6.45837 26.25 13.5 26.25Z"
				stroke="#2CAAE1"
				strokeWidth="1.5"
			/>
			<path
				d="M20.66 8.0017C20.109 8.39914 19.499 8.70311 18.8534 8.90191C18.5063 8.49583 18.0458 8.20831 17.534 8.07807C17.0222 7.94784 16.4837 7.98114 15.991 8.17349C15.4983 8.36585 15.075 8.70801 14.7782 9.1539C14.4815 9.59979 14.3255 10.128 14.3312 10.6673V11.254C13.3201 11.2808 12.3183 11.0514 11.4148 10.5863C10.5114 10.1212 9.73446 9.43486 9.1532 8.58834C9.1532 8.58834 6.8516 13.884 12.0296 16.2379C10.8442 17.0614 9.43186 17.4744 8 17.4161C13.178 20.3579 19.5068 17.4161 19.5068 10.6495C19.5064 10.4848 19.4909 10.3204 19.4606 10.1586C20.0484 9.56748 20.4638 8.82062 20.66 8.0017Z"
				fill="#2CAAE1"
			/>
		</svg>
	);
};

export default IconRoundTwitter;
