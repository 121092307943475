import React from "react";

const IconBannerEdit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="47.001"
      viewBox="0 0 47 47.001"
    >
      <g
        id="resume"
        transform="translate(-0.007)"
      >
        <g
          id="Group_10041"
          data-name="Group 10041"
          transform="translate(0.007)"
        >
          <g
            id="Group_10040"
            data-name="Group 10040"
            transform="translate(0)"
          >
            <path
              id="Path_1762"
              data-name="Path 1762"
              d="M38.012,13.655a.918.918,0,0,0,.918-.918V2.754A2.757,2.757,0,0,0,36.176,0H2.761A2.757,2.757,0,0,0,.007,2.754V44.247A2.757,2.757,0,0,0,2.761,47H36.176a2.757,2.757,0,0,0,2.754-2.754V37.484a.918.918,0,1,0-1.836,0v6.763a.919.919,0,0,1-.918.918H2.761a.919.919,0,0,1-.918-.918V2.754a.919.919,0,0,1,.918-.918H36.176a.919.919,0,0,1,.918.918v9.983A.918.918,0,0,0,38.012,13.655Z"
              transform="translate(-0.007)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10043"
          data-name="Group 10043"
          transform="translate(11.023 4.957)"
        >
          <g
            id="Group_10042"
            data-name="Group 10042"
          >
            <path
              id="Path_1763"
              data-name="Path 1763"
              d="M128.453,54a8.441,8.441,0,0,0-5.794,14.584.91.91,0,0,0,.1.092,8.43,8.43,0,0,0,11.391,0,.924.924,0,0,0,.1-.092A8.441,8.441,0,0,0,128.453,54Zm0,15.055a6.574,6.574,0,0,1-3.992-1.347,4.59,4.59,0,0,1,7.985,0A6.575,6.575,0,0,1,128.453,69.055Zm-1.469-6.977v-.6a1.469,1.469,0,0,1,2.938,0v.6a1.469,1.469,0,0,1-2.938,0Zm6.788,4.285a6.368,6.368,0,0,0-2.7-2.267,3.287,3.287,0,0,0,.691-2.018v-.6a3.3,3.3,0,1,0-6.61,0v.6a3.287,3.287,0,0,0,.691,2.018,6.369,6.369,0,0,0-2.7,2.267,6.61,6.61,0,1,1,10.638,0Z"
              transform="translate(-120.007 -54)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10045"
          data-name="Group 10045"
          transform="translate(18.55 40.208)"
        >
          <g
            id="Group_10044"
            data-name="Group 10044"
          >
            <path
              id="Path_1764"
              data-name="Path 1764"
              d="M207.882,438h-4.957a.918.918,0,1,0,0,1.836h4.957a.918.918,0,1,0,0-1.836Z"
              transform="translate(-202.007 -438)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10047"
          data-name="Group 10047"
          transform="translate(10.472 35.067)"
        >
          <g
            id="Group_10046"
            data-name="Group 10046"
          >
            <path
              id="Path_1765"
              data-name="Path 1765"
              d="M127.96,382H114.925a.918.918,0,0,0,0,1.836H127.96a.918.918,0,0,0,0-1.836Z"
              transform="translate(-114.007 -382)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10049"
          data-name="Group 10049"
          transform="translate(10.472 29.927)"
        >
          <g
            id="Group_10048"
            data-name="Group 10048"
          >
            <path
              id="Path_1766"
              data-name="Path 1766"
              d="M127.96,326H114.925a.918.918,0,0,0,0,1.836H127.96a.918.918,0,0,0,0-1.836Z"
              transform="translate(-114.007 -326)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10051"
          data-name="Group 10051"
          transform="translate(6.55 24.786)"
        >
          <g
            id="Group_10050"
            data-name="Group 10050"
          >
            <path
              id="Path_1767"
              data-name="Path 1767"
              d="M72.855,270.269a.917.917,0,1,0,.269.649A.925.925,0,0,0,72.855,270.269Z"
              transform="translate(-71.287 -270)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10053"
          data-name="Group 10053"
          transform="translate(6.55 29.927)"
        >
          <g
            id="Group_10052"
            data-name="Group 10052"
          >
            <path
              id="Path_1768"
              data-name="Path 1768"
              d="M72.855,326.269a.917.917,0,1,0,.269.649A.927.927,0,0,0,72.855,326.269Z"
              transform="translate(-71.287 -326)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10055"
          data-name="Group 10055"
          transform="translate(6.55 35.067)"
        >
          <g
            id="Group_10054"
            data-name="Group 10054"
          >
            <path
              id="Path_1769"
              data-name="Path 1769"
              d="M72.855,382.269a.917.917,0,1,0,.269.649A.926.926,0,0,0,72.855,382.269Z"
              transform="translate(-71.287 -382)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10057"
          data-name="Group 10057"
          transform="translate(10.472 24.786)"
        >
          <g
            id="Group_10056"
            data-name="Group 10056"
          >
            <path
              id="Path_1770"
              data-name="Path 1770"
              d="M127.96,270H114.925a.918.918,0,0,0,0,1.836H127.96a.918.918,0,0,0,0-1.836Z"
              transform="translate(-114.007 -270)"
              fill="#333"
            />
          </g>
        </g>
        <g
          id="Group_10059"
          data-name="Group 10059"
          transform="translate(27.325 11.445)"
        >
          <g
            id="Group_10058"
            data-name="Group 10058"
          >
            <path
              id="Path_1771"
              data-name="Path 1771"
              d="M315.344,125.193a3.856,3.856,0,0,0-5.267,1.411l-11.552,20.009a.919.919,0,0,0-.117.357l-.813,7.284a.918.918,0,0,0,1.457.841l5.9-4.346a.918.918,0,0,0,.251-.28l11.552-20.009A3.86,3.86,0,0,0,315.344,125.193Zm-15.692,27.179.425-3.8,2.657,1.534Zm4.42-3.617-3.5-2.02,9.27-16.057,3.5,2.02Zm11.093-19.214-.9,1.567-3.5-2.02.9-1.567a2.02,2.02,0,0,1,3.5,2.02Z"
              transform="translate(-297.589 -124.675)"
              fill="#333"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconBannerEdit;
