import dayjs from "dayjs";

const getIsExpired = (
	date: string,
	trialDays: number = 0,
	status: string = "inactive",
) => {
	const day = dayjs(date).add(trialDays, "days");
	const currentDateDiff = day.diff(
		dayjs().format("YYYY-MM-DD"),
		"day",
	);

	return (
		currentDateDiff < 0 && status !== "active"
	);
};

export default getIsExpired;
