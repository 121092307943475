import { FC } from "react";
import styled from "styled-components";
import { DS } from "@workshore/nyaari-ds";
import IconMinus from "../Icons/IconMinus";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";

const StyledDiv = styled.div<{
	isMobile: boolean;
}>`
	width: 30px;
	height: 30px;
	background: ${DS.colors.wildSand};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	> svg {
		width: 11px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    width: 22px;
	  height: 22px;
    > svg {
      width: 8px;
    }
  `}
`;

interface Props {
	stroke?: keyof typeof DS.colors;
	onClick?:
		| React.MouseEventHandler<HTMLDivElement>
		| undefined;
}

const MinusButton: FC<Props> = ({
	stroke,
	onClick,
}) => {
	const isMobile = useIsMobile();
	return (
		<StyledDiv
			onClick={onClick}
			isMobile={isMobile}
		>
			<IconMinus stroke={stroke} />{" "}
		</StyledDiv>
	);
};

export default MinusButton;
