import React from "react";

const IconServe = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21.033"
			height="21.033"
			viewBox="0 0 21.033 21.033"
		>
			<g
				id="Group_10252"
				data-name="Group 10252"
				transform="translate(0.25 0.25)"
			>
				<path
					id="Path_1892"
					data-name="Path 1892"
					d="M10.267,20.533A10.267,10.267,0,1,1,20.533,10.267,10.267,10.267,0,0,1,10.267,20.533Zm0-19.25a8.983,8.983,0,1,0,8.983,8.983A8.983,8.983,0,0,0,10.267,1.283Z"
					fill="#83c59d"
					stroke="#83c59d"
					strokeWidth="0.5"
				/>
				<path
					id="Path_1893"
					data-name="Path 1893"
					d="M102.463,108.925a6.463,6.463,0,1,1,6.463-6.463A6.463,6.463,0,0,1,102.463,108.925Zm0-11.633a5.17,5.17,0,1,0,5.17,5.17A5.17,5.17,0,0,0,102.463,97.293Z"
					transform="translate(-92.196 -92.196)"
					fill="#83c59d"
					stroke="#83c59d"
					strokeWidth="0.5"
				/>
			</g>
		</svg>
	);
};

export default IconServe;
