import { useScreenWidth } from "@tinyd/usescreensize";
import {
	Body1,
	Body2,
	Box,
	Button,
	DS,
	FlexBetween,
	FlexCenter,
	H3,
	Spacing,
	Subtitle1,
	Table,
	Tabs,
	Tag,
	Wrapper,
	useOutsideClick,
} from "@workshore/nyaari-ds";
import dayjs from "dayjs";
import React, {
	useEffect,
	useRef,
	useState,
} from "react";
import {
	Redirect,
	useLocation,
	useParams,
} from "react-router-dom";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import queryString from "query-string";

import {
	PublicationState,
	useCreateWeeklyPlannerEventMutation,
	useGetRecipesQuery,
	WeeklyPlannerEventSlot,
	WeeklyPlannerType,
} from "../../hooks/graphql/graphql";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import storage from "../../services/storage";
import { AddButtonFixed } from "../AddButton/AddButton";
import AddWeeklyPlanner from "../AddWeeklyPlanner/AddWeeklyPlanner";
import CustomCalendar from "../CustomCalendar/CustomCalendar";
import HTMLParser from "../HTMLParser";
import IconClock from "../Icons/IconClock";
import IconFire from "../Icons/IconFire";
import IconServe from "../Icons/IconServe";
import IconUser from "../Icons/IconUser";
import ImageSlider from "../ImageSlider/ImageSlider";
import Loader from "../Loader/Loader";
import PageModal from "../PageModal/PageModal";
import ShowIf from "../ShowIf/ShowIf";

const ScaleDown = styled.span<{ scale?: number }>`
	display: flex;
	justify-content: center;
	align-items: center;
	& > * {
		transform: scale(${({ scale = 1 }) => scale});
	}
`;

const IconContainer = styled.div`
	position: absolute;
	z-index: 10;
	right: 0;
	bottom: 0;
	margin-right: 30px;
	margin-bottom: -16px;
`;
const CalendarContainer = styled.div`
	position: absolute;
	background-color: white;
	margin-top: 5px;
	right: 0;
`;
const CalendarContainer1 = styled.div<{
	isMobile: boolean;
}>`
	position: absolute;
	margin-right: 50px;
	bottom: -80px;
	right: 0;

	${({ isMobile }) =>
		isMobile &&
		`
		position: fixed;
		height: 100vh;
		width: 100%;
		background: ${DS.colors.mineShaft}90;
		margin-right: 0px;
		display: flex;
		justify-content:center;
		align-items:center;

		> * {
			margin: 20px;
		}
	`}
`;
const Tag1 = styled(Body2)<{ active?: boolean }>`
	padding: 4px 8px;
	border-radius: 5px;
	background: ${DS.colors.wildSand};
	cursor: pointer;
	font-size: 10px;
	font-family: ${DS.fontFamilies.primary};
	color: ${DS.colors.boulder};

	${({ active }) =>
		active &&
		`
    background: ${DS.colors.meals};
		color: ${DS.colors.mineShaft};
  `};
`;

const isActive = (
	index: number,
	currentIndex: number,
) => {
	return index === currentIndex;
};

const RecipesDetails = () => {
	const [isSubmitting, setIsSubmitting] =
		useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [date, setDate] = useState(new Date());
	const [showCalendar, setShowCalendar] =
		useState(false);
	const [showCalendar1, setShowCalendar1] =
		useState(false);
	const [slot, setSlot] =
		useState<WeeklyPlannerEventSlot>(
			WeeklyPlannerEventSlot.Prebreakfast,
		);
	const isTablet = useIsTablet();
	const ref = useRef<HTMLDivElement>(null);
	const width = useElementWidth(ref);
	const isMobile = useIsMobile();
	const screenWidth = useScreenWidth();
	const isPhone = screenWidth <= 578;
	const calendarRef = useOutsideClick(() => {
		setShowCalendar(false);
	});
	const calendarRef1 = useOutsideClick(() => {
		setShowCalendar1(false);
	});
	const containerRef = useOutsideClick(() => {
		if (!isPhone) return;
		setShowCalendar1(false);
	});

	const isSmall = width < 730 || isTablet;
	const { id } = useParams<{ id: string }>();
	const { data, loading, error, refetch } =
		useGetRecipesQuery({
			variables: {
				id,
				mode:
					storage.get<string>("@mode").value ===
					"yes"
						? PublicationState.Preview
						: PublicationState.Live,
			},
		});

	const recipe = data?.recipe;
	const _activeTab = isSmall
		? activeTab
		: Math.min(activeTab, 3);
	const { addToast } = useToasts();
	const createWeeklyPlannerEvent =
		useCreateWeeklyPlannerEventMutation();
	const { search } = useLocation();

	useEffect(() => {
		const parsed = queryString.parse(search);
		if (
			parsed.slot &&
			typeof parsed.slot === "string"
		) {
			if (parsed.slot === "PREBREAKFAST") {
				setSlot(
					WeeklyPlannerEventSlot.Prebreakfast,
				);
			}
			if (parsed.slot === "BREAKFAST") {
				setSlot(WeeklyPlannerEventSlot.Breakfast);
			}
			if (parsed.slot === "AMSNACK") {
				setSlot(WeeklyPlannerEventSlot.Amsnack);
			}
			if (parsed.slot === "LUNCH") {
				setSlot(WeeklyPlannerEventSlot.Lunch);
			}
			if (parsed.slot === "PMSNACK") {
				setSlot(WeeklyPlannerEventSlot.Pmsnack);
			}
			if (parsed.slot === "DINNER") {
				setSlot(WeeklyPlannerEventSlot.Dinner);
			}
		}
	}, [search]);

	useEffect(() => {
		setDate(new Date());
		// setSlot(WeeklyPlannerEventSlot.Prebreakfast);
	}, [showCalendar, showCalendar1]);

	const addToWeeklyPlanner = () => {
		setIsSubmitting(true);
		createWeeklyPlannerEvent[0]({
			variables: {
				data: {
					date: dayjs(date).format("YYYY-MM-DD"),
					type: WeeklyPlannerType.Meals,
					slot: slot,
					resourceId: id,
				},
			},
		})
			.then((res) => {
				setIsSubmitting(false);
				setShowCalendar(false);
				setShowCalendar1(false);
				addToast("Item added to weekly planner", {
					appearance: "success",
					autoDismiss: true,
				});
			})
			.catch((err) =>
				addToast(
					"Error adding to the weekly planner",
					{
						appearance: "error",
						autoDismiss: true,
					},
				),
			);
	};

	if (id)
		return (
			<PageModal>
				<ShowIf value={loading}>
					<Loader>
						<Body2>Loading Recipe...</Body2>
					</Loader>
				</ShowIf>
				<ShowIf value={recipe && !loading}>
					<ShowIf value={isSmall}>
						<Box
							style={{
								width: "100%",
								position: "relative",
								backgroundColor: "#000",
							}}
						>
							<ImageSlider
								isSmall={isSmall}
								width="100%"
								height="50vw"
								images={data?.recipe?.SlideShow?.images
									?.map((d) => d?.url || "")
									.filter((d) => d.length)}
							/>
							<IconContainer ref={calendarRef1}>
								<AddButtonFixed
									onClick={() => {
										setShowCalendar1(
											!showCalendar1,
										);
									}}
								/>
								{showCalendar1 ? (
									<CalendarContainer1
										isMobile={isPhone}
									>
										<CustomCalendar
											ref={containerRef}
											style={{
												background: "white",
											}}
											date={date}
											setDate={setDate}
											accentColor="meals"
											isSubmitting={isSubmitting}
											handleButtonClick={
												addToWeeklyPlanner
											}
										>
											<div
												style={{
													display: "flex",
													flexWrap: "wrap",
													gap: 6,
												}}
											>
												<Tag1
													active={
														slot ===
														WeeklyPlannerEventSlot.Prebreakfast
													}
													onClick={() =>
														setSlot(
															WeeklyPlannerEventSlot.Prebreakfast,
														)
													}
												>
													PRE - BREAKFAST
												</Tag1>
												<Tag1
													active={
														slot ===
														WeeklyPlannerEventSlot.Breakfast
													}
													onClick={() =>
														setSlot(
															WeeklyPlannerEventSlot.Breakfast,
														)
													}
												>
													BREAKFAST
												</Tag1>
												<Tag1
													active={
														slot ===
														WeeklyPlannerEventSlot.Amsnack
													}
													onClick={() =>
														setSlot(
															WeeklyPlannerEventSlot.Amsnack,
														)
													}
												>
													AM SNACK
												</Tag1>
												<Tag1
													active={
														slot ===
														WeeklyPlannerEventSlot.Lunch
													}
													onClick={() =>
														setSlot(
															WeeklyPlannerEventSlot.Lunch,
														)
													}
												>
													LUNCH
												</Tag1>
												<Tag1
													active={
														slot ===
														WeeklyPlannerEventSlot.Pmsnack
													}
													onClick={() =>
														setSlot(
															WeeklyPlannerEventSlot.Pmsnack,
														)
													}
												>
													PM SNACK
												</Tag1>
												<Tag1
													active={
														slot ===
														WeeklyPlannerEventSlot.Dinner
													}
													onClick={() =>
														setSlot(
															WeeklyPlannerEventSlot.Dinner,
														)
													}
												>
													DINNER
												</Tag1>
											</div>
										</CustomCalendar>
									</CalendarContainer1>
								) : null}
							</IconContainer>
						</Box>
						<div
							style={{
								height: 5,
								width: "100%",
								borderRadius: "5px 5px 0px 0px",
								backgroundColor: "#fff",
								marginTop: -5,
								zIndex: 1,
								position: "relative",
							}}
						/>
					</ShowIf>
					<Wrapper type="fluid" ref={ref}>
						<Box
							padding={
								isSmall
									? "14px 0px 30px 0px"
									: "30px 0px"
							}
						>
							<H3>{recipe?.Title}</H3>

							<FlexBetween
								style={{ alignItems: "center" }}
							>
								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
									}}
								>
									<div>
										<Spacing
											type="s16"
											multiplier={
												isSmall ? 0.75 : 1
											}
										/>

										<Tag
											background={
												DS.colors.wildSand
											}
											paddingVertical={
												isSmall ? "4" : "8"
											}
										>
											<FlexCenter>
												<ScaleDown
													scale={
														isSmall ? 0.9 : 1
													}
												>
													<IconClock color="meals" />
												</ScaleDown>
												<Spacing
													multiplier={
														isSmall ? 0.75 : 1
													}
												/>
												<Body2>
													Prep :{" "}
													{
														data?.recipe
															?.Prep_Duration
															?.duration
													}{" "}
													{
														data?.recipe
															?.Prep_Duration
															?.unit
													}
												</Body2>
											</FlexCenter>
										</Tag>
									</div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>
									<div>
										<Spacing
											type="s16"
											multiplier={
												isSmall ? 0.75 : 1
											}
										/>
										<Tag
											background={
												DS.colors.wildSand
											}
											paddingVertical={
												isSmall ? "4" : "8"
											}
										>
											<FlexCenter>
												<ScaleDown
													scale={
														isSmall ? 0.9 : 1
													}
												>
													<IconClock color="meals" />
												</ScaleDown>
												<Spacing
													multiplier={
														isSmall ? 0.75 : 1
													}
												/>
												<Body2>
													Cook :{" "}
													{
														data?.recipe
															?.Cook_Duration
															?.duration
													}{" "}
													{
														data?.recipe
															?.Cook_Duration
															?.unit
													}
												</Body2>
											</FlexCenter>
										</Tag>
									</div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>
									<div>
										<Spacing
											type="s16"
											multiplier={
												isSmall ? 0.75 : 1
											}
										/>
										<Tag
											background={
												DS.colors.wildSand
											}
											paddingVertical={
												isSmall ? "4" : "8"
											}
										>
											<FlexCenter>
												<ScaleDown
													scale={
														isSmall ? 0.9 : 1
													}
												>
													<IconFire color="meals" />
												</ScaleDown>
												<Spacing
													multiplier={
														isSmall ? 0.75 : 1
													}
												/>
												<Body2>
													{data?.recipe?.Calories}
												</Body2>
											</FlexCenter>
										</Tag>
									</div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>
									<div>
										<Spacing
											type="s16"
											multiplier={
												isSmall ? 0.75 : 1
											}
										/>
										<Tag
											background={
												DS.colors.wildSand
											}
											paddingVertical={
												isSmall ? "4" : "8"
											}
										>
											<FlexCenter>
												<ScaleDown
													scale={
														isSmall ? 0.8 : 1
													}
												>
													<IconServe />
												</ScaleDown>
												<Spacing
													multiplier={
														isSmall ? 0.75 : 1
													}
												/>
												<Body2>
													Serve
													{(data?.recipe
														?.No_Of_Servings ||
														0) > 1
														? "s"
														: ""}{" "}
													{
														data?.recipe
															?.No_Of_Servings
													}
												</Body2>
											</FlexCenter>
										</Tag>
									</div>
									<Spacing
										type="s16"
										multiplier={
											isSmall ? 0.75 : 1
										}
									/>
									<div>
										<Spacing
											type="s16"
											multiplier={
												isSmall ? 0.75 : 1
											}
										/>
										<Tag
											background={
												DS.colors.wildSand
											}
											paddingVertical={
												isSmall ? "4" : "8"
											}
										>
											<FlexCenter>
												<ScaleDown
													scale={
														isSmall ? 0.9 : 1
													}
												>
													<IconUser />
												</ScaleDown>
												<Spacing
													multiplier={
														isSmall ? 0.75 : 1
													}
												/>
												<Body2>
													By{" "}
													{
														data?.recipe
															?.collaborator?.name
													}
												</Body2>
											</FlexCenter>
										</Tag>
									</div>
								</div>
								{!isSmall ? (
									<div
										ref={calendarRef}
										style={{
											position: "relative",
										}}
									>
										<AddWeeklyPlanner
											onClick={() =>
												setShowCalendar(
													!showCalendar,
												)
											}
										/>
										{showCalendar ? (
											<CalendarContainer>
												<CustomCalendar
													date={date}
													setDate={setDate}
													accentColor="meals"
													isSubmitting={
														isSubmitting
													}
													handleButtonClick={
														addToWeeklyPlanner
													}
												>
													<div
														style={{
															display: "flex",
															flexWrap: "wrap",
															gap: 6,
														}}
													>
														<Tag1
															active={
																slot ===
																WeeklyPlannerEventSlot.Prebreakfast
															}
															onClick={() =>
																setSlot(
																	WeeklyPlannerEventSlot.Prebreakfast,
																)
															}
														>
															PRE - BREAKFAST
														</Tag1>
														<Tag1
															active={
																slot ===
																WeeklyPlannerEventSlot.Breakfast
															}
															onClick={() =>
																setSlot(
																	WeeklyPlannerEventSlot.Breakfast,
																)
															}
														>
															BREAKFAST
														</Tag1>
														<Tag1
															active={
																slot ===
																WeeklyPlannerEventSlot.Amsnack
															}
															onClick={() =>
																setSlot(
																	WeeklyPlannerEventSlot.Amsnack,
																)
															}
														>
															AM SNACK
														</Tag1>
														<Tag1
															active={
																slot ===
																WeeklyPlannerEventSlot.Lunch
															}
															onClick={() =>
																setSlot(
																	WeeklyPlannerEventSlot.Lunch,
																)
															}
														>
															LUNCH
														</Tag1>
														<Tag1
															active={
																slot ===
																WeeklyPlannerEventSlot.Pmsnack
															}
															onClick={() =>
																setSlot(
																	WeeklyPlannerEventSlot.Pmsnack,
																)
															}
														>
															PM SNACK
														</Tag1>
														<Tag1
															active={
																slot ===
																WeeklyPlannerEventSlot.Dinner
															}
															onClick={() =>
																setSlot(
																	WeeklyPlannerEventSlot.Dinner,
																)
															}
														>
															DINNER
														</Tag1>
													</div>
												</CustomCalendar>
											</CalendarContainer>
										) : null}
									</div>
								) : null}
							</FlexBetween>
							<Spacing type="s24" />
							<div
								style={{
									display: "flex",
								}}
							>
								<ShowIf value={!isSmall}>
									<Box
										marginRight={50}
										style={{
											width: "40%",
											maxWidth: 404,
										}}
									>
										<ImageSlider
											isSmall={isSmall}
											width="100%"
											images={data?.recipe?.SlideShow?.images
												?.map((d) => d?.url || "")
												.filter((d) => d.length)}
										/>
										<Spacing type="s32" />
										<Body1>Values</Body1>
										<Spacing type="s16" />
										<Table
											isSmall={isSmall}
											tabItems={(
												data?.recipe
													?.Nutrition_facts || []
											)?.map((d) => {
												return {
													title: (
														<Body2
															fontWeight={500}
														>
															{d?.Title}
														</Body2>
													),
													value: (
														<Body2>
															{d?.Unit}
														</Body2>
													),
													subItems: (
														d?.Sub_Nutrition_facts ||
														[]
													).map((d) => {
														return {
															title: (
																<Body2>
																	{d?.Title}
																</Body2>
															),
															value: (
																<Body2>
																	{d?.Unit}
																</Body2>
															),
														};
													}),
												};
											})}
										/>
										<Spacing type="s16" />
										<Body2>
											{
												data?.recipe
													?.ValueDisclaimer
											}
										</Body2>
									</Box>
								</ShowIf>
								<Box
									style={{
										width: isSmall
											? "100%"
											: "calc(60% - 50px)",
									}}
								>
									<Tabs
										width="unset"
										offsetPadding={
											isSmall
												? DS.spacing.s8
												: DS.spacing.s24
										}
										activeTabIndex={_activeTab}
										onTabClick={(tabIndex) => {
											setActiveTab(tabIndex);
										}}
										tabs={[
											...(isSmall
												? [
														{
															title: (
																<div>
																	<Body1
																		fontColor={
																			isActive(
																				_activeTab,
																				0,
																			)
																				? "meals"
																				: "mineShaft"
																		}
																	>
																		Values
																	</Body1>
																	<Spacing type="s4" />
																</div>
															),
															view: (
																<div>
																	<Box
																		padding={
																			isSmall
																				? "20px 14px"
																				: "30px 24px"
																		}
																		style={{
																			backgroundColor:
																				DS.colors
																					.wildSand,
																			borderRadius:
																				DS
																					.borderRadius
																					.body1,
																		}}
																	>
																		<Table
																			isSmall={
																				isSmall
																			}
																			tabItems={(
																				data
																					?.recipe
																					?.Nutrition_facts ||
																				[]
																			)?.map(
																				(d) => {
																					return {
																						title:
																							(
																								<Body2
																									fontWeight={
																										500
																									}
																								>
																									{
																										d?.Title
																									}
																								</Body2>
																							),
																						value:
																							(
																								<Body2>
																									{
																										d?.Unit
																									}
																								</Body2>
																							),
																						subItems:
																							(
																								d?.Sub_Nutrition_facts ||
																								[]
																							).map(
																								(
																									d,
																								) => {
																									return {
																										title:
																											(
																												<Body2>
																													{
																														d?.Title
																													}
																												</Body2>
																											),
																										value:
																											(
																												<Body2>
																													{
																														d?.Unit
																													}
																												</Body2>
																											),
																									};
																								},
																							),
																					};
																				},
																			)}
																		/>
																	</Box>
																	<Spacing type="s16" />
																	<Body2>
																		{
																			data?.recipe
																				?.ValueDisclaimer
																		}
																	</Body2>
																</div>
															),
														},
												  ]
												: []),
											{
												title: (
													<div>
														<Body1
															fontColor={
																isActive(
																	_activeTab,
																	isSmall ? 1 : 0,
																)
																	? "meals"
																	: "mineShaft"
															}
														>
															Insights
														</Body1>
														<Spacing type="s4" />
													</div>
												),
												view: (
													<div>
														<ShowIf
															value={
																(
																	data?.recipe
																		?.Insights
																		?.Nutritional_Insight ||
																	[]
																).length > 0
															}
														>
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		DS.colors
																			.wildSand,
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1
																	fontWeight={500}
																>
																	Nutritional
																	Insight
																</Body1>
																<Spacing
																	type="s2"
																	multiplier={6}
																/>
																<HTMLParser
																	data={
																		data?.recipe
																			?.Insights
																			?.Nutritional_Insight_rich!
																	}
																/>

																{/* {(
																	data?.recipe
																		?.Insights
																		?.Nutritional_Insight ||
																	[]
																)?.map(
																	(d, index) => {
																		return (
																			<div
																				key={
																					index
																				}
																			>
																				<div
																					key={
																						index
																					}
																					style={{
																						display:
																							"flex",
																						alignItems:
																							"center",
																					}}
																				>
																					<Body2
																						fontWeight={
																							400
																						}
																					>
																						{
																							d?.Description
																						}
																					</Body2>
																				</div>
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.recipe
																								?.Insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																				{data
																					?.recipe
																					?.Insights
																					?.Nutritional_Insight
																					?.length! -
																					1 !==
																					index && (
																					<Seperator />
																				)}
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.recipe
																								?.Insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																			</div>
																		);
																	},
																)} */}
															</Box>

															<Spacing type="s16" />
														</ShowIf>
														<ShowIf
															value={
																(
																	data?.recipe
																		?.Insights
																		?.Ayurveda_Insight ||
																	[]
																).length > 0
															}
														>
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		DS.colors
																			.wildSand,
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1
																	fontWeight={500}
																>
																	Ayurveda Insight
																</Body1>
																<Spacing
																	type="s2"
																	multiplier={6}
																/>
																<HTMLParser
																	data={
																		data?.recipe
																			?.Insights
																			?.Ayurveda_Insight_rich!
																	}
																/>
																{/* {(
																	data?.recipe
																		?.Insights
																		?.Ayurveda_Insight ||
																	[]
																)?.map(
																	(d, index) => {
																		return (
																			<div
																				key={
																					index
																				}
																			>
																				<div
																					key={
																						index
																					}
																					style={{
																						display:
																							"flex",
																						alignItems:
																							"center",
																					}}
																				>
																					<Body2
																						fontWeight={
																							400
																						}
																					>
																						{
																							d?.Description
																						}
																					</Body2>
																				</div>
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.recipe
																								?.Insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																				{data
																					?.recipe
																					?.Insights
																					?.Ayurveda_Insight
																					?.length! -
																					1 !==
																					index && (
																					<Seperator />
																				)}
																				<Spacing
																					type="s8"
																					multiplier={
																						index ===
																						(
																							data
																								?.recipe
																								?.Insights
																								?.Ayurveda_Insight ||
																							[]
																						)
																							.length -
																							1
																							? 0
																							: isSmall
																							? 0.5
																							: 1
																					}
																				/>
																			</div>
																		);
																	},
																)} */}
															</Box>
														</ShowIf>
														<Spacing />
														<Body2>
															{
																data?.recipe
																	?.InsightNotes
															}
														</Body2>
													</div>
												),
											},
											{
												title: (
													<div>
														<Body1
															fontColor={
																isActive(
																	_activeTab,
																	isSmall ? 2 : 1,
																)
																	? "meals"
																	: "mineShaft"
															}
														>
															Ingredients
														</Body1>
														<Spacing type="s4" />
													</div>
												),
												view: (
													<div>
														<Box
															padding={
																isSmall
																	? "20px 14px"
																	: "30px 24px"
															}
															style={{
																backgroundColor:
																	DS.colors
																		.wildSand,
																borderRadius:
																	DS.borderRadius
																		.body1,
															}}
														>
															{data?.recipe?.Ingredients?.IngredientsWithTitle?.map(
																(
																	item,
																	index,
																	arr,
																) => {
																	return (
																		<div
																			key={index}
																		>
																			<Body1>
																				{
																					item?.Title
																				}
																			</Body1>
																			<Spacing />
																			<div
																				style={{
																					height: 1,
																					backgroundColor:
																						DS
																							.colors
																							.lightGray,
																				}}
																			></div>
																			<Spacing type="s2" />
																			<Table
																				isSmall={
																					isSmall
																				}
																				padding={
																					22
																				}
																				tabItems={(
																					item?.Ingredients ||
																					[]
																				)?.map(
																					(d) => {
																						return {
																							title:
																								(
																									<Body2
																										fontWeight={
																											500
																										}
																									>
																										{
																											d?.Ingredients_Name
																										}
																									</Body2>
																								),
																							value:
																								(
																									<Body2>
																										{
																											d?.Unit
																										}
																									</Body2>
																								),
																							subItems:
																								[],
																						};
																					},
																				)}
																			/>
																			{arr.length >
																			index +
																				1 ? (
																				<Spacing
																					type="s24"
																					multiplier={
																						1.8
																					}
																				/>
																			) : null}
																		</div>
																	);
																},
															)}

															<Spacing type="s32" />
															<ShowIf
																value={
																	(
																		data?.recipe
																			?.Ingredients
																			?.Image
																			?.url || ""
																	).length
																}
															>
																<img
																	src={
																		data?.recipe
																			?.Ingredients
																			?.Image?.url
																	}
																	alt="Ingredients"
																	style={{
																		width: "100%",
																		maxHeight: 400,
																		objectFit:
																			"contain",
																		backgroundColor:
																			DS.colors
																				.black,
																		borderRadius:
																			DS
																				.borderRadius
																				.body1,
																	}}
																/>
															</ShowIf>
														</Box>
													</div>
												),
											},
											{
												title: (
													<div>
														<Body1
															fontColor={
																isActive(
																	_activeTab,
																	isSmall ? 3 : 2,
																)
																	? "meals"
																	: "mineShaft"
															}
														>
															Instructions
														</Body1>
														<Spacing type="s4" />
													</div>
												),
												view: (
													<div>
														<ShowIf
															value={
																(
																	data?.recipe
																		?.Instructions
																		?.Variations ||
																	""
																)?.length
															}
														>
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		"#ECF6F0",
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1>
																	Variations
																</Body1>
																<Spacing type="s16" />
																<Body2>
																	{
																		data?.recipe
																			?.Instructions
																			?.Variations
																	}
																</Body2>
															</Box>
															<Spacing type="s16" />
															<Box
																padding={
																	isSmall
																		? "20px 14px"
																		: "30px 24px"
																}
																style={{
																	backgroundColor:
																		DS.colors
																			.wildSand,
																	borderRadius:
																		DS
																			.borderRadius
																			.body1,
																}}
															>
																<Body1>
																	Cooking
																	Instructions
																</Body1>
																<Spacing type="s16" />
																{(
																	data?.recipe
																		?.Instructions
																		?.Steps || []
																).map(
																	(
																		step,
																		index,
																	) => {
																		return (
																			<div
																				key={
																					index
																				}
																			>
																				<Subtitle1 fontColor="meals">
																					Step{" "}
																					{index +
																						1}
																				</Subtitle1>
																				<Spacing type="s8" />
																				<Body2
																					fontWeight={
																						400
																					}
																				>
																					{
																						step?.Step_Description
																					}
																				</Body2>
																				<ShowIf
																					value={
																						(
																							step?.Step_Images ||
																							[]
																						)
																							?.length >
																						0
																					}
																				>
																					<Spacing type="s16" />
																					<ImageSlider
																						showActiveIndex
																						width="100%"
																						height={
																							isMobile
																								? 215
																								: 400
																						}
																						images={(
																							step?.Step_Images ||
																							[]
																						)
																							.map(
																								(
																									d,
																								) =>
																									d?.url ||
																									"",
																							)
																							.filter(
																								(
																									d,
																								) =>
																									d.length,
																							)}
																					/>
																				</ShowIf>
																				<Spacing type="s24" />
																			</div>
																		);
																	},
																)}
															</Box>
														</ShowIf>
													</div>
												),
											},
										]}
									/>
								</Box>
							</div>
						</Box>
					</Wrapper>
				</ShowIf>
				<ShowIf value={error}>
					<FlexCenter>
						<Body1>Failed to load Recipe</Body1>
					</FlexCenter>
					<Spacing type="s24" />
					<FlexCenter>
						<Button width="100px">
							<Body2
								as="span"
								fontColor="white"
								onClick={() => {
									refetch();
								}}
							>
								Retry
							</Body2>
						</Button>
					</FlexCenter>
				</ShowIf>
			</PageModal>
		);

	return (
		<Redirect to="/dashboard/meals/recipes" />
	);
};

export default RecipesDetails;
