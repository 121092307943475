const IconRoundFB = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="27"
			height="27"
			viewBox="0 0 27 27"
		>
			<g
				id="Group_9901"
				data-name="Group 9901"
				transform="translate(-0.444 0)"
			>
				<g
					id="Group_9896"
					data-name="Group 9896"
					transform="translate(0 0)"
				>
					<g
						id="Ellipse_18"
						data-name="Ellipse 18"
						transform="translate(0.444 0)"
						fill="#fcfcfc"
						stroke="#00abe2"
						strokeWidth="1.5"
					>
						<circle
							cx="13.5"
							cy="13.5"
							r="13.5"
							stroke="none"
						/>
						<circle
							cx="13.5"
							cy="13.5"
							r="12.75"
							fill="none"
						/>
					</g>
				</g>
				<path
					id="facebook"
					d="M14.189,2H12.228A3.268,3.268,0,0,0,8.961,5.268V7.228H7V9.842H8.961V15.07h2.614V9.842h1.961l.654-2.614H11.575V5.268a.654.654,0,0,1,.654-.654h1.961Z"
					transform="translate(3.365 4.798)"
					fill="none"
					stroke="#00abe2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.5"
				/>
			</g>
		</svg>
	);
};

export default IconRoundFB;
