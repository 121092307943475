import styled from "styled-components";
import {
	H2,
	Subtitle1,
	Body1,
	Body2,
	Wrapper,
	DS,
	FlexCenter,
	Spacing,
	Button,
} from "@workshore/nyaari-ds";
import { useHistory } from "react-router-dom";

import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
// import collab_img from "../../assets/images/Collaborators_image_1.png";
import Footer from "../../components/Footer/Footer";
import ShowIf from "../../components/ShowIf/ShowIf";
import { useGetAllCollaboratorsQuery } from "../../hooks/graphql/graphql";
import Loader from "../../components/Loader/Loader";

const Container = styled.div<{
	isTablet: boolean;
}>`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 66px;
	grid-row-gap: 65px;

	${({ isTablet }) =>
		isTablet &&
		`
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
  `};

	@media screen and (max-width: 768px) {
		grid-column-gap: 24px;
		grid-row-gap: 20px;
	}

	@media screen and (max-width: 480px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

const Card = styled.div<{
	isTablet: boolean;
}>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-radius: 5px;
	background: white;
	box-shadow: 0px 3px 6px #0000001a;
	cursor: pointer;
	overflow: hidden;
	img {
		height: 211px;
		width: 100%;
		object-fit: cover;
	}
	@media screen and (max-width: ${DS.breakPoints
			.sm}px) {
		img {
			height: 155px;
		}
	}
`;

const CardContent = styled.div<{
	isMobile: boolean;
}>`
	margin: 8px 18px 14px 18px;

	${({ isMobile }) =>
		isMobile &&
		`
    margin: 4px 8px 8px 8px;
  `};
`;

const CollaboratorPage = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const history = useHistory();
	const { data, loading, error, refetch } =
		useGetAllCollaboratorsQuery();
	// console.log(data);

	return (
		<div
			style={
				isMobile
					? { background: DS.colors.wildSand }
					: {}
			}
		>
			<ShowIf value={loading}>
				<div
					style={{
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<FlexCenter>
						<Body1>
							Failed to load collaborators
						</Body1>
					</FlexCenter>
					<Spacing type="s24" />
					<FlexCenter>
						<Button width="100px">
							<Body2
								as="span"
								fontColor="white"
								onClick={() => {
									refetch();
								}}
							>
								Retry
							</Body2>
						</Button>
					</FlexCenter>
				</div>
			</ShowIf>
			<ShowIf value={!loading && !error}>
				<ShowIf
					value={data?.collaborators?.length}
				>
					<Wrapper
						style={
							isMobile
								? {
										padding: "25px 0 50px 0",
								  }
								: { padding: "70px 0 92px 0" }
						}
					>
						<H2
							marginBottom={
								isMobile ? "12px" : "50px"
							}
						>
							Get To Know Us
						</H2>
						<Container isTablet={isTablet}>
							{data?.collaborators!.map(
								(collaborator: any) => (
									<Card
										key={collaborator.id}
										isTablet={isTablet}
										onClick={() => {
											history.push(
												`/collaborators/${collaborator.id}`,
											);
										}}
									>
										<img
											src={collaborator.image.url}
											alt=""
										/>
										<CardContent
											isMobile={isMobile}
										>
											<Subtitle1>
												{collaborator.name}
											</Subtitle1>
											<Body2
												marginBottom="8px"
												fontColor="boulder"
											>
												{collaborator.occupation}
											</Body2>
											<Body1>
												{collaborator.location}
											</Body1>
										</CardContent>
									</Card>
								),
							)}
						</Container>
					</Wrapper>
				</ShowIf>
			</ShowIf>
			<Footer />
		</div>
	);
};

export default CollaboratorPage;
