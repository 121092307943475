import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconClock: FC<{
	color?: keyof typeof DS.colors;
}> = ({ color = "boulder" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<g
				id="clock"
				transform="translate(-1.317 -0.65)"
			>
				<circle
					id="Ellipse_10"
					data-name="Ellipse 10"
					cx="7"
					cy="7"
					r="7"
					transform="translate(2.317 1.65)"
					fill="none"
					stroke={DS.colors[color]}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
				<path
					id="Path_1391"
					data-name="Path 1391"
					d="M12,6v4.3l2.867,1.434"
					transform="translate(-3.649 -1.436)"
					fill="none"
					stroke={DS.colors[color]}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				/>
			</g>
		</svg>
	);
};

export default IconClock;
