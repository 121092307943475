import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconBook: FC<{
	color: keyof typeof DS.colors;
}> = ({ color = "boulder" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23.553"
			height="21.398"
			viewBox="0 0 23.553 21.398"
		>
			<g
				id="book-open"
				transform="translate(1 1)"
			>
				<path
					id="Path_1408"
					data-name="Path 1408"
					d="M2,3H8.466a4.311,4.311,0,0,1,4.311,4.311V22.4a3.233,3.233,0,0,0-3.233-3.233H2Z"
					transform="translate(-2 -3)"
					fill="none"
					stroke={DS.colors[color]}
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				/>
				<path
					id="Path_1409"
					data-name="Path 1409"
					d="M22.777,3H16.311A4.311,4.311,0,0,0,12,7.311V22.4a3.233,3.233,0,0,1,3.233-3.233h7.544Z"
					transform="translate(-1.223 -3)"
					fill="none"
					stroke={DS.colors[color]}
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				/>
			</g>
		</svg>
	);
};

export default IconBook;
