import { Spacing } from "@workshore/nyaari-ds";
import React, { FC } from "react";
import Lottie from "react-lottie-player";
import loader from "../../assets/anim/loader.json";
const Loader: FC = ({ children }) => {
	return (
		<div
			style={{
				height: "500px",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<Lottie
				loop
				animationData={loader}
				play
				speed={1.2}
				style={{ width: 250, height: 250 }}
			/>
			<Spacing type="s24" />
			{children}
		</div>
	);
};

export default Loader;
