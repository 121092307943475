import { Route, Switch } from "react-router-dom";
import ProtectedSwitch from "../../components/ProtectedSwitch/ProtectedSwitch";
import MovementListing from "../../components/MovementListing";
import MovementCategory from "../../components/MovementCategory";
import MovementDetails from "../../components/MovementDetails/MovementDetails";

const MovementPage = () => {
	return (
		<ProtectedSwitch>
			<Route exact path="/dashboard/movement">
				<MovementCategory />
			</Route>
			<Route path="/dashboard/movement/:category_id">
				<MovementListing />
				<Switch>
					<Route
						path="/dashboard/movement/:category_id/:movement_id"
						exact
					>
						<MovementDetails />
					</Route>
				</Switch>
			</Route>
		</ProtectedSwitch>
	);
};

export default MovementPage;
