import dayjs from "dayjs";
import {
	H1,
	Subtitle1,
} from "@workshore/nyaari-ds";
import React, {
	useEffect,
	useState,
} from "react";
import { useQuotesQuery } from "../../hooks/graphql/graphql";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import storage from "../../services/storage";
import generateRandomNumber from "../../utils/getRandomNumber";
import ShowIf from "../ShowIf/ShowIf";

const DailyQuote = () => {
	const isTablet = useIsTablet();
	const { data, loading } = useQuotesQuery();
	const [author, setAuthor] = useState("");
	const [qoute, setQoute] = useState("");
	const [show, setShow] = useState(false);
	useEffect(() => {
		if (!loading && data && data.dailyQuotes) {
			if (
				storage.get("dailyQuote", "").value !==
					dayjs().format("YYYY-MM-DD") &&
				data.dailyQuotes.length
			) {
				const getAnyQuote = generateRandomNumber(
					0,
					data.dailyQuotes.length - 1,
				);
				console.log(
					getAnyQuote,
					data.dailyQuotes.length - 1,
				);
				storage.set(
					"dailyQuote",
					dayjs().format("YYYY-MM-DD"),
				);
				setAuthor(
					data.dailyQuotes![getAnyQuote]!
						.author || "",
				);
				setQoute(
					data.dailyQuotes![getAnyQuote]!.quote!,
				);
				setShow(true);
			}
		}
	}, [data, loading]);
	if (loading || !show) {
		return null;
	}
	return (
		<div
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				zIndex: 999,
				width: "100vw",
				height: "100vh",
				background: "rgba(51,51,51,0.74)",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				overflowX: "hidden",
				overflowY: "auto",
			}}
			onClick={() => setShow(false)}
		>
			<div
				style={{
					margin: "50px 20px",
					maxWidth: isTablet
						? "calc(500px - 40px)"
						: "calc(800px - 40px)",
					// maxHeight: "500px",
					minHeight: 500,
					background: "#ffffff",
					borderRadius: 5,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div
					style={{
						position: "relative",
						overflow: "hidden",
						padding: "40px",
					}}
				>
					<div
						style={{
							position: "absolute",
							zIndex: 0,
							right: -60,
							top: 60,
							opacity: 0.27,
						}}
					>
						<svg
							id="Group_9841"
							data-name="Group 9841"
							xmlns="http://www.w3.org/2000/svg"
							width="138.433"
							height="126.817"
							viewBox="0 0 138.433 126.817"
						>
							<g
								id="Group_9836"
								data-name="Group 9836"
								transform="translate(66.571)"
							>
								<path
									id="Path_1519"
									data-name="Path 1519"
									d="M333.924,272.966a11.482,11.482,0,0,1-.077-3.076,14.909,14.909,0,0,1,2.594-6.958c6.982-9.8,21.309-1.176,13.579,8.932a16.051,16.051,0,0,1-5.881,4.622,13.452,13.452,0,0,1-3.365,1.076,6.246,6.246,0,0,1-4.64-.671A5.587,5.587,0,0,1,333.924,272.966Z"
									transform="translate(-305.989 -258.623)"
									fill="#ffd100"
									fillRule="evenodd"
								/>
								<path
									id="Path_1520"
									data-name="Path 1520"
									d="M323.577,283.874c.946.6,3.828,1.093,6.247,1.507,1.229.211,2.345.4,3.056.58a13.158,13.158,0,0,1,1.749.569,12.173,12.173,0,0,1,6.319,5.742,11.147,11.147,0,0,1,.866,8.041,10.8,10.8,0,0,1-.55,1.521,14.787,14.787,0,0,1-3.674,4.672,6.587,6.587,0,0,0-.61.6.649.649,0,0,1-.873.152.577.577,0,0,1-.16-.829l.029-.039a7.948,7.948,0,0,0,1.532-6.85,9.046,9.046,0,0,0-1.678-3.3,12.53,12.53,0,0,0-2.942-2.677,13.339,13.339,0,0,0-10.092-2.086h0l-.214.05c-3.034.71-4.29,1-5.715-4.024-.693-2.447-3.077-4.331-5.707-5.052a8.208,8.208,0,0,0-3.3-.246,5.684,5.684,0,0,0-2.843,1.212c-1.678,1.382-2.682,3.98-2.171,8.172.478,3.918,2.176,7,3.841,10.02,1.893,3.435,3.748,6.8,3.858,11.286a25.906,25.906,0,0,1-1.268,6.731,5.264,5.264,0,0,1-.2.581c-.013.108-.025.2-.037.282a.944.944,0,0,1-.05.2.6.6,0,0,1-.435.614.656.656,0,0,1-.356.007h-.009a.611.611,0,0,1-.423-.391,1.2,1.2,0,0,1,.03-.568.135.135,0,0,1,0-.016c.03-.2.066-.5.093-.85.141-1.85.074-5.552-2.173-8.419l-1.262-1.6c-4.09-5.188-7.615-9.662-8.961-14.334a21.262,21.262,0,0,1-.148-12.4,12.7,12.7,0,0,1,6.866-7.97,14.591,14.591,0,0,1,10.031-.646,14.805,14.805,0,0,1,8.11,5.99l.218.324.022.036a11.471,11.471,0,0,0,2.969,3.4Z"
									transform="translate(-294.488 -263.514)"
									fill="#ffd100"
									fillRule="evenodd"
								/>
							</g>
							<g
								id="Group_9837"
								data-name="Group 9837"
								transform="translate(72.557 45.015)"
							>
								<path
									id="Path_1521"
									data-name="Path 1521"
									d="M369.3,359.177a13.149,13.149,0,0,1,3.057-1.02,17.058,17.058,0,0,1,7.772.191c11.981,3.274,7.764,18.874-4.752,15.019a16.342,16.342,0,0,1-6.448-3.881,12.386,12.386,0,0,1-2.118-2.706,5.423,5.423,0,0,1-.76-4.4A5.784,5.784,0,0,1,369.3,359.177Z"
									transform="translate(-321.362 -336.227)"
									fill="#00abe2"
									fillRule="evenodd"
								/>
								<path
									id="Path_1522"
									data-name="Path 1522"
									d="M345.214,351.519c-.308,1.039.088,3.794.42,6.106.169,1.175.322,2.241.364,2.938a11.417,11.417,0,0,1-3.83,9.234,12.622,12.622,0,0,1-7.789,3.266,12.317,12.317,0,0,1-1.693-.026,16.037,16.037,0,0,1-5.817-1.873,6.864,6.864,0,0,0-.79-.366.592.592,0,0,1-.422-.742.634.634,0,0,1,.782-.4l.047.015a9.129,9.129,0,0,0,7.337-.734,9.528,9.528,0,0,0,2.786-2.534,11.907,11.907,0,0,0,1.773-3.483,11.6,11.6,0,0,0-1.029-9.755h0l-.117-.178c-1.649-2.52-2.333-3.563,2.265-6.4a7.6,7.6,0,0,0,3.3-6.713,7.109,7.109,0,0,0-.774-3.058,5.375,5.375,0,0,0-2.094-2.192c-1.9-1.088-4.817-1.191-8.859.566-3.778,1.642-6.339,4.126-8.852,6.564-2.857,2.771-5.655,5.485-10.116,6.971a30.02,30.02,0,0,1-7.136.935,6.165,6.165,0,0,1-.646,0c-.112.022-.209.04-.294.054a1.1,1.1,0,0,1-.211.016.648.648,0,0,1-.749-.2.57.57,0,0,1-.117-.319l0-.009h0a.585.585,0,0,1,.261-.5,1.39,1.39,0,0,1,.579-.148l.016,0c.214-.036.519-.094.881-.179,1.9-.444,5.585-1.649,7.764-4.564l1.216-1.635c3.935-5.295,7.328-9.86,11.592-12.519a24.4,24.4,0,0,1,12.381-3.967,13.572,13.572,0,0,1,10.107,3.736,12.851,12.851,0,0,1,3.747,8.856,13.7,13.7,0,0,1-3.5,9.173l-.258.3-.029.031a11.233,11.233,0,0,0-2.49,3.732Z"
									transform="translate(-302.952 -325.669)"
									fill="#00abe2"
									fillRule="evenodd"
								/>
							</g>
							<g
								id="Group_9838"
								data-name="Group 9838"
								transform="translate(44.426 64.861)"
							>
								<path
									id="Path_1523"
									data-name="Path 1523"
									d="M301.013,418.52a12.171,12.171,0,0,1,1.967,2.445,14.834,14.834,0,0,1,2.21,7.077c.423,11.827-16.511,12.841-16.516.35a14.771,14.771,0,0,1,1.9-7.021,12.351,12.351,0,0,1,2.056-2.749,5.408,5.408,0,0,1,8.388-.1Z"
									transform="translate(-270.641 -375.387)"
									fill="#f57933"
									fillRule="evenodd"
								/>
								<path
									id="Path_1524"
									data-name="Path 1524"
									d="M288.892,394.137c-1.137.043-3.774,1.251-5.988,2.266-1.125.516-2.145.984-2.831,1.236a13.268,13.268,0,0,1-1.767.516,13.023,13.023,0,0,1-8.668-1.119,11.917,11.917,0,0,1-5.68-6.023,10.63,10.63,0,0,1-.5-1.537,13.953,13.953,0,0,1,.079-5.83,6.134,6.134,0,0,0,.123-.826.629.629,0,0,1,1.255-.028v.047a8.11,8.11,0,0,0,3,6.4,9.9,9.9,0,0,0,3.4,1.732,13.45,13.45,0,0,0,4.038.524,13.118,13.118,0,0,0,9.456-3.943h0l.142-.16c2.014-2.268,2.848-3.207,7.115.066a8.52,8.52,0,0,0,7.745.9,7.877,7.877,0,0,0,2.825-1.644,5.2,5.2,0,0,0,1.549-2.568c.5-2.054-.295-4.717-3.3-7.823-2.813-2.9-6.093-4.447-9.312-5.963-3.659-1.723-7.243-3.41-10.109-6.979a26.554,26.554,0,0,1-3.142-6.153,5.156,5.156,0,0,1-.2-.584c-.057-.094-.1-.177-.144-.248a.981.981,0,0,1-.081-.186.573.573,0,0,1-.028-.739.62.62,0,0,1,.284-.2l.008,0h0a.652.652,0,0,1,.585.081,1.268,1.268,0,0,1,.328.477.063.063,0,0,1,.009.013c.1.182.255.44.451.739a13.44,13.44,0,0,0,6.972,5.6l2.014.592c6.521,1.916,12.143,3.569,16.125,6.6a22.462,22.462,0,0,1,7.8,9.952,11.947,11.947,0,0,1-.62,10.279,13.756,13.756,0,0,1-7.715,6.12,15.634,15.634,0,0,1-10.27-.321l-.377-.142-.04-.017a12.446,12.446,0,0,0-4.508-1.1Z"
									transform="translate(-263.179 -355.228)"
									fill="#f57933"
									fillRule="evenodd"
								/>
							</g>
							<g
								id="Group_9839"
								data-name="Group 9839"
								transform="translate(0 47.293)"
							>
								<path
									id="Path_1525"
									data-name="Path 1525"
									d="M220.94,372.156a12.04,12.04,0,0,1-1.842,2.53,16.27,16.27,0,0,1-6.407,4.182c-11.719,4.036-17.968-10.938-5.455-14.8a17.025,17.025,0,0,1,7.62-.458,13.493,13.493,0,0,1,3.389,1.006,5.827,5.827,0,0,1,3.338,3.133A5.405,5.405,0,0,1,220.94,372.156Z"
									transform="translate(-200.368 -340.338)"
									fill="#83c59d"
									fillRule="evenodd"
								/>
								<path
									id="Path_1526"
									data-name="Path 1526"
									d="M229.655,355.369c-.395-1.013-2.42-3.02-4.121-4.7-.864-.857-1.648-1.634-2.113-2.175a12.034,12.034,0,0,1-1.062-1.435,11.3,11.3,0,0,1-1.557-8.172,11.584,11.584,0,0,1,5.665-7.912,16.086,16.086,0,0,1,5.865-1.73,7.031,7.031,0,0,0,.866-.145.637.637,0,0,1,.8.365.589.589,0,0,1-.384.76l-.047.015a8.569,8.569,0,0,0-5.481,4.687,8.816,8.816,0,0,0-.685,3.6,11.592,11.592,0,0,0,.723,3.807A12.385,12.385,0,0,0,235,349.653h0l.2.079c2.894,1.118,4.092,1.58,2.132,6.443a7.434,7.434,0,0,0,1.49,7.271,7.7,7.7,0,0,0,2.519,2.042,5.791,5.791,0,0,0,3.051.606c2.214-.182,4.635-1.724,6.817-5.4,2.039-3.436,2.573-6.875,3.1-10.25.6-3.835,1.178-7.592,3.868-11.284a27.989,27.989,0,0,1,5.194-4.738,5.691,5.691,0,0,1,.524-.357c.077-.08.145-.149.2-.208a.98.98,0,0,1,.161-.13.644.644,0,0,1,.732-.254.624.624,0,0,1,.292.193l.007.007h0a.568.568,0,0,1,.1.552,1.274,1.274,0,0,1-.376.443.076.076,0,0,1-.01.012c-.151.149-.361.366-.6.636-1.26,1.418-3.5,4.451-3.457,8.025l.03,2c.1,6.479.177,12.065-1.626,16.6a22.429,22.429,0,0,1-7.56,10.117,13.714,13.714,0,0,1-10.491,2.618,14.011,14.011,0,0,1-8.515-5.075,13.625,13.625,0,0,1-2.852-9.371l.025-.384,0-.042a10.765,10.765,0,0,0-.3-4.409Z"
									transform="translate(-206.291 -329.062)"
									fill="#83c59d"
									fillRule="evenodd"
								/>
							</g>
							<g
								id="Group_9840"
								data-name="Group 9840"
								transform="translate(21.15 2.191)"
							>
								<path
									id="Path_1527"
									data-name="Path 1527"
									d="M241.873,280.926a13.229,13.229,0,0,1-3.1-.88,16.151,16.151,0,0,1-6.17-4.492c-7.666-9.333,5.407-19.6,13.145-9.5a14.945,14.945,0,0,1,2.814,6.738,11.689,11.689,0,0,1,.038,3.37,5.583,5.583,0,0,1-2.107,3.981A6.217,6.217,0,0,1,241.873,280.926Z"
									transform="translate(-230.271 -261.886)"
									fill="#e4003f"
									fillRule="evenodd"
								/>
								<path
									id="Path_1528"
									data-name="Path 1528"
									d="M249.144,285c.893-.669,2.278-3.118,3.441-5.174.59-1.045,1.127-1.993,1.525-2.579a12.1,12.1,0,0,1,1.11-1.4,12.64,12.64,0,0,1,7.706-3.931,12.843,12.843,0,0,1,8.323,1.7,11.684,11.684,0,0,1,1.354.966,14.7,14.7,0,0,1,3.546,4.76,6.2,6.2,0,0,0,.412.736.577.577,0,0,1-.118.836.652.652,0,0,1-.881-.112l-.029-.038a8.842,8.842,0,0,0-6.39-3.5,10.2,10.2,0,0,0-3.822.5,13.046,13.046,0,0,0-3.592,1.829,12.042,12.042,0,0,0-5.208,8.466h0l-.016.209c-.226,2.958-.319,4.183-5.8,3.916a8.266,8.266,0,0,0-6.825,3.591,7.219,7.219,0,0,0-1.267,2.906,5.014,5.014,0,0,0,.337,2.941c.866,1.942,3.159,3.651,7.517,4.485,4.073.78,7.683.2,11.226-.371,4.027-.648,7.971-1.282,12.5.005a29.1,29.1,0,0,1,6.352,3.225,5.591,5.591,0,0,1,.52.363c.1.045.194.085.271.12a1.062,1.062,0,0,1,.181.105.6.6,0,0,1,.48.583.573.573,0,0,1-.1.324l0,.007h0a.636.636,0,0,1-.523.261,1.393,1.393,0,0,1-.56-.2l-.016-.005c-.2-.09-.478-.214-.823-.346-1.81-.7-5.54-1.783-9.108-.641l-2,.645c-6.462,2.088-12.033,3.888-17.13,3.66a24.428,24.428,0,0,1-12.472-3.7,12.471,12.471,0,0,1-5.863-8.662,12.689,12.689,0,0,1,2.453-9.255,14.928,14.928,0,0,1,8.507-5.471l.393-.1.042-.009a12.286,12.286,0,0,0,4.326-1.629Z"
									transform="translate(-231.157 -265.146)"
									fill="#e4003f"
									fillRule="evenodd"
								/>
							</g>
						</svg>
					</div>

					<div
						style={{
							position: "absolute",
							zIndex: 0,
							left: -60,
							bottom: 30,
							opacity: 0.27,
						}}
					>
						<svg
							id="Group_9841"
							data-name="Group 9841"
							xmlns="http://www.w3.org/2000/svg"
							width="138.433"
							height="126.817"
							viewBox="0 0 138.433 126.817"
						>
							<g
								id="Group_9836"
								data-name="Group 9836"
								transform="translate(66.571)"
							>
								<path
									id="Path_1519"
									data-name="Path 1519"
									d="M333.924,272.966a11.482,11.482,0,0,1-.077-3.076,14.909,14.909,0,0,1,2.594-6.958c6.982-9.8,21.309-1.176,13.579,8.932a16.051,16.051,0,0,1-5.881,4.622,13.452,13.452,0,0,1-3.365,1.076,6.246,6.246,0,0,1-4.64-.671A5.587,5.587,0,0,1,333.924,272.966Z"
									transform="translate(-305.989 -258.623)"
									fill="#ffd100"
									fillRule="evenodd"
								/>
								<path
									id="Path_1520"
									data-name="Path 1520"
									d="M323.577,283.874c.946.6,3.828,1.093,6.247,1.507,1.229.211,2.345.4,3.056.58a13.158,13.158,0,0,1,1.749.569,12.173,12.173,0,0,1,6.319,5.742,11.147,11.147,0,0,1,.866,8.041,10.8,10.8,0,0,1-.55,1.521,14.787,14.787,0,0,1-3.674,4.672,6.587,6.587,0,0,0-.61.6.649.649,0,0,1-.873.152.577.577,0,0,1-.16-.829l.029-.039a7.948,7.948,0,0,0,1.532-6.85,9.046,9.046,0,0,0-1.678-3.3,12.53,12.53,0,0,0-2.942-2.677,13.339,13.339,0,0,0-10.092-2.086h0l-.214.05c-3.034.71-4.29,1-5.715-4.024-.693-2.447-3.077-4.331-5.707-5.052a8.208,8.208,0,0,0-3.3-.246,5.684,5.684,0,0,0-2.843,1.212c-1.678,1.382-2.682,3.98-2.171,8.172.478,3.918,2.176,7,3.841,10.02,1.893,3.435,3.748,6.8,3.858,11.286a25.906,25.906,0,0,1-1.268,6.731,5.264,5.264,0,0,1-.2.581c-.013.108-.025.2-.037.282a.944.944,0,0,1-.05.2.6.6,0,0,1-.435.614.656.656,0,0,1-.356.007h-.009a.611.611,0,0,1-.423-.391,1.2,1.2,0,0,1,.03-.568.135.135,0,0,1,0-.016c.03-.2.066-.5.093-.85.141-1.85.074-5.552-2.173-8.419l-1.262-1.6c-4.09-5.188-7.615-9.662-8.961-14.334a21.262,21.262,0,0,1-.148-12.4,12.7,12.7,0,0,1,6.866-7.97,14.591,14.591,0,0,1,10.031-.646,14.805,14.805,0,0,1,8.11,5.99l.218.324.022.036a11.471,11.471,0,0,0,2.969,3.4Z"
									transform="translate(-294.488 -263.514)"
									fill="#ffd100"
									fillRule="evenodd"
								/>
							</g>
							<g
								id="Group_9837"
								data-name="Group 9837"
								transform="translate(72.557 45.015)"
							>
								<path
									id="Path_1521"
									data-name="Path 1521"
									d="M369.3,359.177a13.149,13.149,0,0,1,3.057-1.02,17.058,17.058,0,0,1,7.772.191c11.981,3.274,7.764,18.874-4.752,15.019a16.342,16.342,0,0,1-6.448-3.881,12.386,12.386,0,0,1-2.118-2.706,5.423,5.423,0,0,1-.76-4.4A5.784,5.784,0,0,1,369.3,359.177Z"
									transform="translate(-321.362 -336.227)"
									fill="#00abe2"
									fillRule="evenodd"
								/>
								<path
									id="Path_1522"
									data-name="Path 1522"
									d="M345.214,351.519c-.308,1.039.088,3.794.42,6.106.169,1.175.322,2.241.364,2.938a11.417,11.417,0,0,1-3.83,9.234,12.622,12.622,0,0,1-7.789,3.266,12.317,12.317,0,0,1-1.693-.026,16.037,16.037,0,0,1-5.817-1.873,6.864,6.864,0,0,0-.79-.366.592.592,0,0,1-.422-.742.634.634,0,0,1,.782-.4l.047.015a9.129,9.129,0,0,0,7.337-.734,9.528,9.528,0,0,0,2.786-2.534,11.907,11.907,0,0,0,1.773-3.483,11.6,11.6,0,0,0-1.029-9.755h0l-.117-.178c-1.649-2.52-2.333-3.563,2.265-6.4a7.6,7.6,0,0,0,3.3-6.713,7.109,7.109,0,0,0-.774-3.058,5.375,5.375,0,0,0-2.094-2.192c-1.9-1.088-4.817-1.191-8.859.566-3.778,1.642-6.339,4.126-8.852,6.564-2.857,2.771-5.655,5.485-10.116,6.971a30.02,30.02,0,0,1-7.136.935,6.165,6.165,0,0,1-.646,0c-.112.022-.209.04-.294.054a1.1,1.1,0,0,1-.211.016.648.648,0,0,1-.749-.2.57.57,0,0,1-.117-.319l0-.009h0a.585.585,0,0,1,.261-.5,1.39,1.39,0,0,1,.579-.148l.016,0c.214-.036.519-.094.881-.179,1.9-.444,5.585-1.649,7.764-4.564l1.216-1.635c3.935-5.295,7.328-9.86,11.592-12.519a24.4,24.4,0,0,1,12.381-3.967,13.572,13.572,0,0,1,10.107,3.736,12.851,12.851,0,0,1,3.747,8.856,13.7,13.7,0,0,1-3.5,9.173l-.258.3-.029.031a11.233,11.233,0,0,0-2.49,3.732Z"
									transform="translate(-302.952 -325.669)"
									fill="#00abe2"
									fillRule="evenodd"
								/>
							</g>
							<g
								id="Group_9838"
								data-name="Group 9838"
								transform="translate(44.426 64.861)"
							>
								<path
									id="Path_1523"
									data-name="Path 1523"
									d="M301.013,418.52a12.171,12.171,0,0,1,1.967,2.445,14.834,14.834,0,0,1,2.21,7.077c.423,11.827-16.511,12.841-16.516.35a14.771,14.771,0,0,1,1.9-7.021,12.351,12.351,0,0,1,2.056-2.749,5.408,5.408,0,0,1,8.388-.1Z"
									transform="translate(-270.641 -375.387)"
									fill="#f57933"
									fillRule="evenodd"
								/>
								<path
									id="Path_1524"
									data-name="Path 1524"
									d="M288.892,394.137c-1.137.043-3.774,1.251-5.988,2.266-1.125.516-2.145.984-2.831,1.236a13.268,13.268,0,0,1-1.767.516,13.023,13.023,0,0,1-8.668-1.119,11.917,11.917,0,0,1-5.68-6.023,10.63,10.63,0,0,1-.5-1.537,13.953,13.953,0,0,1,.079-5.83,6.134,6.134,0,0,0,.123-.826.629.629,0,0,1,1.255-.028v.047a8.11,8.11,0,0,0,3,6.4,9.9,9.9,0,0,0,3.4,1.732,13.45,13.45,0,0,0,4.038.524,13.118,13.118,0,0,0,9.456-3.943h0l.142-.16c2.014-2.268,2.848-3.207,7.115.066a8.52,8.52,0,0,0,7.745.9,7.877,7.877,0,0,0,2.825-1.644,5.2,5.2,0,0,0,1.549-2.568c.5-2.054-.295-4.717-3.3-7.823-2.813-2.9-6.093-4.447-9.312-5.963-3.659-1.723-7.243-3.41-10.109-6.979a26.554,26.554,0,0,1-3.142-6.153,5.156,5.156,0,0,1-.2-.584c-.057-.094-.1-.177-.144-.248a.981.981,0,0,1-.081-.186.573.573,0,0,1-.028-.739.62.62,0,0,1,.284-.2l.008,0h0a.652.652,0,0,1,.585.081,1.268,1.268,0,0,1,.328.477.063.063,0,0,1,.009.013c.1.182.255.44.451.739a13.44,13.44,0,0,0,6.972,5.6l2.014.592c6.521,1.916,12.143,3.569,16.125,6.6a22.462,22.462,0,0,1,7.8,9.952,11.947,11.947,0,0,1-.62,10.279,13.756,13.756,0,0,1-7.715,6.12,15.634,15.634,0,0,1-10.27-.321l-.377-.142-.04-.017a12.446,12.446,0,0,0-4.508-1.1Z"
									transform="translate(-263.179 -355.228)"
									fill="#f57933"
									fillRule="evenodd"
								/>
							</g>
							<g
								id="Group_9839"
								data-name="Group 9839"
								transform="translate(0 47.293)"
							>
								<path
									id="Path_1525"
									data-name="Path 1525"
									d="M220.94,372.156a12.04,12.04,0,0,1-1.842,2.53,16.27,16.27,0,0,1-6.407,4.182c-11.719,4.036-17.968-10.938-5.455-14.8a17.025,17.025,0,0,1,7.62-.458,13.493,13.493,0,0,1,3.389,1.006,5.827,5.827,0,0,1,3.338,3.133A5.405,5.405,0,0,1,220.94,372.156Z"
									transform="translate(-200.368 -340.338)"
									fill="#83c59d"
									fillRule="evenodd"
								/>
								<path
									id="Path_1526"
									data-name="Path 1526"
									d="M229.655,355.369c-.395-1.013-2.42-3.02-4.121-4.7-.864-.857-1.648-1.634-2.113-2.175a12.034,12.034,0,0,1-1.062-1.435,11.3,11.3,0,0,1-1.557-8.172,11.584,11.584,0,0,1,5.665-7.912,16.086,16.086,0,0,1,5.865-1.73,7.031,7.031,0,0,0,.866-.145.637.637,0,0,1,.8.365.589.589,0,0,1-.384.76l-.047.015a8.569,8.569,0,0,0-5.481,4.687,8.816,8.816,0,0,0-.685,3.6,11.592,11.592,0,0,0,.723,3.807A12.385,12.385,0,0,0,235,349.653h0l.2.079c2.894,1.118,4.092,1.58,2.132,6.443a7.434,7.434,0,0,0,1.49,7.271,7.7,7.7,0,0,0,2.519,2.042,5.791,5.791,0,0,0,3.051.606c2.214-.182,4.635-1.724,6.817-5.4,2.039-3.436,2.573-6.875,3.1-10.25.6-3.835,1.178-7.592,3.868-11.284a27.989,27.989,0,0,1,5.194-4.738,5.691,5.691,0,0,1,.524-.357c.077-.08.145-.149.2-.208a.98.98,0,0,1,.161-.13.644.644,0,0,1,.732-.254.624.624,0,0,1,.292.193l.007.007h0a.568.568,0,0,1,.1.552,1.274,1.274,0,0,1-.376.443.076.076,0,0,1-.01.012c-.151.149-.361.366-.6.636-1.26,1.418-3.5,4.451-3.457,8.025l.03,2c.1,6.479.177,12.065-1.626,16.6a22.429,22.429,0,0,1-7.56,10.117,13.714,13.714,0,0,1-10.491,2.618,14.011,14.011,0,0,1-8.515-5.075,13.625,13.625,0,0,1-2.852-9.371l.025-.384,0-.042a10.765,10.765,0,0,0-.3-4.409Z"
									transform="translate(-206.291 -329.062)"
									fill="#83c59d"
									fillRule="evenodd"
								/>
							</g>
							<g
								id="Group_9840"
								data-name="Group 9840"
								transform="translate(21.15 2.191)"
							>
								<path
									id="Path_1527"
									data-name="Path 1527"
									d="M241.873,280.926a13.229,13.229,0,0,1-3.1-.88,16.151,16.151,0,0,1-6.17-4.492c-7.666-9.333,5.407-19.6,13.145-9.5a14.945,14.945,0,0,1,2.814,6.738,11.689,11.689,0,0,1,.038,3.37,5.583,5.583,0,0,1-2.107,3.981A6.217,6.217,0,0,1,241.873,280.926Z"
									transform="translate(-230.271 -261.886)"
									fill="#e4003f"
									fillRule="evenodd"
								/>
								<path
									id="Path_1528"
									data-name="Path 1528"
									d="M249.144,285c.893-.669,2.278-3.118,3.441-5.174.59-1.045,1.127-1.993,1.525-2.579a12.1,12.1,0,0,1,1.11-1.4,12.64,12.64,0,0,1,7.706-3.931,12.843,12.843,0,0,1,8.323,1.7,11.684,11.684,0,0,1,1.354.966,14.7,14.7,0,0,1,3.546,4.76,6.2,6.2,0,0,0,.412.736.577.577,0,0,1-.118.836.652.652,0,0,1-.881-.112l-.029-.038a8.842,8.842,0,0,0-6.39-3.5,10.2,10.2,0,0,0-3.822.5,13.046,13.046,0,0,0-3.592,1.829,12.042,12.042,0,0,0-5.208,8.466h0l-.016.209c-.226,2.958-.319,4.183-5.8,3.916a8.266,8.266,0,0,0-6.825,3.591,7.219,7.219,0,0,0-1.267,2.906,5.014,5.014,0,0,0,.337,2.941c.866,1.942,3.159,3.651,7.517,4.485,4.073.78,7.683.2,11.226-.371,4.027-.648,7.971-1.282,12.5.005a29.1,29.1,0,0,1,6.352,3.225,5.591,5.591,0,0,1,.52.363c.1.045.194.085.271.12a1.062,1.062,0,0,1,.181.105.6.6,0,0,1,.48.583.573.573,0,0,1-.1.324l0,.007h0a.636.636,0,0,1-.523.261,1.393,1.393,0,0,1-.56-.2l-.016-.005c-.2-.09-.478-.214-.823-.346-1.81-.7-5.54-1.783-9.108-.641l-2,.645c-6.462,2.088-12.033,3.888-17.13,3.66a24.428,24.428,0,0,1-12.472-3.7,12.471,12.471,0,0,1-5.863-8.662,12.689,12.689,0,0,1,2.453-9.255,14.928,14.928,0,0,1,8.507-5.471l.393-.1.042-.009a12.286,12.286,0,0,0,4.326-1.629Z"
									transform="translate(-231.157 -265.146)"
									fill="#e4003f"
									fillRule="evenodd"
								/>
							</g>
						</svg>
					</div>
					<div
						style={{
							position: "absolute",
							right: 15,
							top: 10,
							cursor: "pointer",
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="14"
							height="14.095"
							viewBox="0 0 14 14.095"
						>
							<g
								id="Group_11118"
								data-name="Group 11118"
								transform="translate(-1502.558 -43.726)"
							>
								<path
									id="Icon_ionic-ios-close"
									data-name="Icon ionic-ios-close"
									d="M19.325,17.787l4.643-4.643a1.088,1.088,0,0,0-1.539-1.539l-4.643,4.643-4.643-4.643a1.088,1.088,0,1,0-1.539,1.539l4.643,4.643L11.605,22.43a1.088,1.088,0,1,0,1.539,1.539l4.643-4.643,4.643,4.643a1.088,1.088,0,1,0,1.539-1.539Z"
									transform="translate(1491.773 32.937)"
									fill="#333"
									stroke="#333"
									strokeWidth="1"
								/>
							</g>
						</svg>
					</div>
					<div
						style={{
							margin: "80px 0px",
							zIndex: 1,
							position: "relative",
						}}
					>
						<H1>{qoute}</H1>
						<ShowIf value={author.length}>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									width: "100%",
									marginTop: 14,
								}}
							>
								<Subtitle1 fontColor="redRibbon">
									- {author}
								</Subtitle1>
							</div>
						</ShowIf>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DailyQuote;
