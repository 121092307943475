import styled from "styled-components";
import { DS } from "@workshore/nyaari-ds";

const { breakPoints, fontWeight } = DS;

export const ImageContainer = styled.div`
	display: flex;
	width: 100%;
	overflow: hidden;
`;

export const Image = styled.img`
	position: relative;
	flex: 1;
	align-self: stretch;
`;

export const InputDiv = styled.div`
	margin-bottom: 25px;

	@media screen and (max-width: ${breakPoints.m}px) {
		margin-bottom: 5px;
	}
`;

export const SideHeading = styled.h3<{
	screenClass?: string | any;
}>`
	font-weight: ${fontWeight.semiBold};
	margin: 0;
	padding-top: 23px;
	${({ screenClass }) => `
    font-size: ${
			["lg", "xl", "xxl"].includes(screenClass)
				? `40px`
				: `20px`
		}; 
`}
`;

export const ContentWrapper = styled.div<{
	screenClass?: string | any;
}>`
	& > h3 {
		font-weight: ${fontWeight.semiBold};

		margin-top: 23px;
	}

	${({ screenClass }) => `
  min-height: ${
		["lg", "xl", "xxl"].includes(screenClass)
			? `calc(100vh - 410px)`
			: `calc(100vh - 298px)`
	};
  & > h3{
    font-size: ${
			["lg", "xl", "xxl"].includes(screenClass)
				? `40px`
				: `20px`
		}; 
  }
    
`}
`;
