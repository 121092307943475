import {
	useEffect,
	useRef,
	useState,
} from "react";
import {
	DS,
	Box,
	Wrapper,
	H3,
	FlexBetween,
	Caption,
	Body1,
	Subtitle1,
	Body2,
	FlexCenter,
	Table,
	useOutsideClick,
	Spacing,
	Button,
} from "@workshore/nyaari-ds";
import styled from "styled-components";
import {
	Link,
	useHistory,
	useLocation,
} from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { Doughnut } from "react-chartjs-2";
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
} from "chart.js";
import { useToasts } from "react-toast-notifications";
import { useScreenWidth } from "@tinyd/usescreensize";
import queryString from "query-string";

import LayoutContainer from "../../components/LayoutContainer/LayoutContainer";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import IconPrint from "../../components/Icons/IconPrint";
import IconCalorie from "../../components/Icons/IconCalorie";
import IconLeft from "../../components/Icons/IconLeft";
import IconRight from "../../components/Icons/IconRight";
import WeeklyMWrapper from "../../components/WeeklyMWrapper/WeeklyMWrapper";
import WeeklyMItem from "../../components/WeeklyMItem/WeeklyMItem";
import IconClose from "../../components/Icons/IconClose";
import Loader from "../../components/Loader/Loader";
import {
	useDeleteWeeklyPlannerEventMutation,
	useGetweeklyPlannerEventWeekCaloriesQuery,
	useListWeeklyPlannerEventsQuery,
} from "../../hooks/graphql/graphql";
import ShowIf from "../../components/ShowIf/ShowIf";
import { setPrevUrl } from "../../utils/getPrevUrl";
dayjs.extend(weekday);
ChartJS.register(ArcElement, Tooltip, Legend);

const IconContainer = styled.div`
	display: flex;
`;
const WeekWrapper = styled.div<{
	isMobile: boolean;
}>`
	display: grid;
	grid-template-columns: 1fr minmax(200px, 1fr) 1fr;
	justify-items: center;
	margin-bottom: 30px;

	> :first-child {
		grid-column-start: 2;
	}
	> :last-child {
		margin-left: auto;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    margin-bottom: 20px;
  `}
`;
const WeekDiv = styled.div<{ isMobile: boolean }>`
	display: flex;
	align-items: center;
	gap: 30px;

	${({ isMobile }) =>
		isMobile &&
		`
      gap: 16px;
  `}
`;
const IconWrapper = styled.div`
	display: flex;
	cursor: pointer;
`;
const DayWrapper = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	justify-content: space-between;
	gap: 12px;
	margin-bottom: 30px;

	${({ isMobile }) =>
		isMobile &&
		`
    margin-bottom: 20px;
  `}
`;
const DayContainer = styled.div<{
	active?: boolean;
	isMobile: boolean;
}>`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	height: 60px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid ${DS.colors.wildSand};
	> * {
		color: ${DS.colors.boulder};
	}

	${({ active }) =>
		active &&
		`
    border: 1px solid ${DS.colors.mineShaft};
		> * {
			color: ${DS.colors.mineShaft};
		}
  `}
	${({ isMobile }) =>
		isMobile &&
		`
			flex-direction: column;
      height: 52px;
  `};
`;
const MWrapper = styled.div`
	> :not(:last-child) {
		margin-bottom: 20px;
	}
`;
const ChartContainer = styled.div<{
	isMobile: boolean;
}>`
	position: fixed;
	height: 100vh;
	width: 100%;
	background: ${DS.colors.mineShaft}90;
	left: 0;
	top: 0;
	z-index: 20;
	overflow-y: scroll;

	${({ isMobile }) =>
		isMobile &&
		`
		position: fixed;
		height: 100vh;
		width: 100%;
		background: ${DS.colors.mineShaft}90;
		left: 0;
		top: 0;
		z-index: 20;

		> * {
			margin: 0 10px 0 12px;
		}
	`}
`;
const ChartWrapper = styled.div<{
	isMobile: boolean;
}>`
	position: absolute;
	z-index: 1;
	padding: 20px 30px;
	background-color: white;
	// top: 210px;
	// right: 160px;
	border-radius: 5px;
	box-shadow: 0 3px 6px #00000029;
	width: 347px;

	${({ isMobile }) =>
		isMobile &&
		`
		width: calc(100% - 85px);
	`}
`;
const CloseWrapper = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
`;
const CalorieWrapper = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;
const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`;
const TypeWrapper = styled.div`
	display: flex;
	align-items: center;
`;
const Dot = styled.div<{
	stroke: keyof typeof DS.colors;
}>`
	height: 12px;
	width: 12px;
	border-radius: 6px;
	margin-right: 8px;
	background-color: ${({ stroke }) =>
		DS.colors[stroke]};
`;

const WeeklyPlanner = () => {
	const [date, setDate] = useState(dayjs());
	const [selectedDate, setSelectedDate] =
		useState<Dayjs>();
	const [activeTab, setActiveTab] = useState(
		dayjs().weekday(),
	);
	const [showChart, setShowChart] =
		useState(false);
	const isMobile = useIsMobile();
	const history = useHistory();
	const screenWidth = useScreenWidth();
	const { pathname, search } = useLocation();
	const weeklyCountRef =
		useRef<HTMLDivElement>(null);
	const chartRef = useOutsideClick(() => {
		setShowChart(false);
	});
	const { addToast } = useToasts();
	const { data, loading, error, refetch } =
		useListWeeklyPlannerEventsQuery({
			fetchPolicy: "no-cache",
			variables: {
				date: dayjs(selectedDate).format(
					"YYYY-MM-DD",
				),
			},
		});
	// console.log(data?.listWeeklyPlannerEvents);
	const {
		data: data1,
		loading: loading1,
		error: error1,
		refetch: refetch1,
	} = useGetweeklyPlannerEventWeekCaloriesQuery({
		fetchPolicy: "no-cache",
		variables: {
			startDate: date
				.weekday(1)
				.format("YYYY-MM-DD"),
			endDate: date
				.weekday(7)
				.format("YYYY-MM-DD"),
		},
	});

	const deleteWeeklyPlannerEventMutation =
		useDeleteWeeklyPlannerEventMutation();

	const mealsUnordered =
		data?.listWeeklyPlannerEvents?.events?.filter(
			(event) => event.type === "MEALS",
		);
	let meals = [];
	let preBreakfast = mealsUnordered?.filter(
		(event) => event.slot === "PREBREAKFAST",
	);
	let breakfast = mealsUnordered?.filter(
		(event) => event.slot === "BREAKFAST",
	);
	let amSnack = mealsUnordered?.filter(
		(event) => event.slot === "AMSNACK",
	);
	let lunch = mealsUnordered?.filter(
		(event) => event.slot === "LUNCH",
	);
	let pmSnack = mealsUnordered?.filter(
		(event) => event.slot === "PMSNACK",
	);
	let dinner = mealsUnordered?.filter(
		(event) => event.slot === "DINNER",
	);
	if (preBreakfast && preBreakfast?.length > 0) {
		meals.push(...preBreakfast);
	}
	if (breakfast && breakfast?.length > 0) {
		meals.push(...breakfast);
	}
	if (amSnack && amSnack?.length > 0) {
		meals.push(...amSnack);
	}
	if (lunch && lunch?.length > 0) {
		meals.push(...lunch);
	}
	if (pmSnack && pmSnack?.length > 0) {
		meals.push(...pmSnack);
	}
	if (dinner && dinner?.length > 0) {
		meals.push(...dinner);
	}

	const movement =
		data?.listWeeklyPlannerEvents?.events?.filter(
			(event) => event.type === "MOVEMENT",
		);
	const meditation =
		data?.listWeeklyPlannerEvents?.events?.filter(
			(event) => event.type === "MEDITATION",
		);
	const mantra =
		data?.listWeeklyPlannerEvents?.events?.filter(
			(event) => event.type === "MANTRA",
		);
	const masti =
		data?.listWeeklyPlannerEvents?.events?.filter(
			(event) => event.type === "MASTI",
		);

	useEffect(() => {
		setSelectedDate(date.weekday(activeTab));
	}, [activeTab, date, search]);

	useEffect(() => {
		const parsed = queryString.parse(search);
		if (
			parsed.date &&
			typeof parsed.date === "string"
		) {
			setDate(dayjs(parsed.date).weekday(1));
			setSelectedDate(parsed.date as any);
			setActiveTab(dayjs(parsed.date).weekday());
		}
	}, [search]);

	useEffect(() => {
		setPrevUrl(pathname);
	}, [pathname]);

	return (
		<LayoutContainer backgroundColor="wildSand">
			<ShowIf value={loading}>
				<div
					style={{
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{error &&
					error!.message.includes(
						"Cast to ObjectId failed",
					) ? (
						<div>
							<Body1>
								Oops! Page does not exist.
							</Body1>
							<Spacing type="s24" />
							<FlexCenter>
								<Button
									width="200px"
									handleButtonClick={() =>
										history.push("/dashboard")
									}
								>
									<Body2
										as="span"
										fontColor="white"
									>
										Go to Dashboard
									</Body2>
								</Button>
							</FlexCenter>
						</div>
					) : (
						<>
							<FlexCenter>
								<Body1>
									Failed to load Weekly Planner
								</Body1>
							</FlexCenter>
							<Spacing type="s24" />
							<FlexCenter>
								<Button width="100px">
									<Body2
										as="span"
										fontColor="white"
										onClick={() => {
											refetch();
										}}
									>
										Retry
									</Body2>
								</Button>
							</FlexCenter>
						</>
					)}
				</div>
			</ShowIf>
			<ShowIf value={!loading && !error}>
				<Box
					paddingTop={isMobile ? 25 : 50}
					paddingBottom={100}
				>
					<Wrapper type="fluid">
						<div style={{ marginBottom: 25 }}>
							<H3>Weekly Planner</H3>
						</div>
						<Box
							style={
								isMobile
									? {
											background: DS.colors.white,
											borderRadius: 10,
											padding: 20,
									  }
									: {
											background: DS.colors.white,
											borderRadius: 10,
											padding: 35,
									  }
							}
						>
							<WeekWrapper isMobile={isMobile}>
								<WeekDiv isMobile={isMobile}>
									<IconWrapper
										onClick={() =>
											setDate(
												date.subtract(7, "day"),
											)
										}
									>
										<IconLeft />
									</IconWrapper>
									<Subtitle1>
										{date
											.weekday(1)
											.format("MMM D")}{" "}
										-{" "}
										{date
											.weekday(7)
											.format("MMM D")}
									</Subtitle1>
									<IconWrapper
										onClick={() =>
											setDate(date.add(7, "day"))
										}
									>
										<IconRight />
									</IconWrapper>
								</WeekDiv>
								<FlexCenter
									style={
										isMobile
											? { gap: 14 }
											: { gap: 30 }
									}
								>
									<IconContainer
										ref={weeklyCountRef}
										style={{
											position: "relative",
											cursor: "pointer",
										}}
										onClick={() =>
											setShowChart(!showChart)
										}
									>
										<IconCalorie />
										{!isMobile ? (
											<Body2
												style={{
													marginLeft: 10,
												}}
											>
												Weekly Calorie Count
											</Body2>
										) : null}
										{showChart ? (
											<ChartContainer
												isMobile={
													screenWidth < 768
												}
											>
												<ChartWrapper
													style={
														screenWidth < 768
															? { top: 80 }
															: {
																	top: weeklyCountRef!
																		.current
																		? weeklyCountRef!
																				.current
																				.offsetTop +
																		  30
																		: 30,
																	right:
																		screenWidth <
																		DS.breakPoints
																			.m
																			? 60
																			: 160,
															  }
													}
													isMobile={
														screenWidth < 768
													}
													ref={chartRef}
												>
													<CloseWrapper
														onClick={() =>
															setShowChart(false)
														}
													>
														<IconClose />
													</CloseWrapper>
													<ShowIf
														value={loading1}
													>
														<Loader />
													</ShowIf>
													<ShowIf value={error1}>
														<FlexCenter>
															<Body1>
																Failed to load
																Weekly Calorie
																Count
															</Body1>
														</FlexCenter>
														<Spacing type="s24" />
														<FlexCenter>
															<Button width="100px">
																<Body2
																	as="span"
																	fontColor="white"
																	onClick={() => {
																		refetch1();
																	}}
																>
																	Retry
																</Body2>
															</Button>
														</FlexCenter>
													</ShowIf>
													<ShowIf
														value={
															!loading1 && !error1
														}
													>
														<Body1
															fontWeight={600}
															style={{
																display: "flex",
																justifyContent:
																	"center",
																marginBottom: 14,
															}}
														>
															Weekly Calorie Count
														</Body1>
														<div
															style={{
																position:
																	"relative",
															}}
														>
															<Doughnut
																height={198}
																width={198}
																data={{
																	labels: [
																		"Fat",
																		"Protein",
																		"Carbs",
																	],
																	datasets: [
																		{
																			data: [
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.totalFat ===
																				0
																					? 0
																					: ((data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.totalFat! +
																							data1
																								?.weeklyPlannerEventWeekCalories
																								?.caloriesData
																								?.proteins! +
																							data1
																								?.weeklyPlannerEventWeekCalories
																								?.caloriesData
																								?.totalCarbs!) *
																							100) /
																					  data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.totalFat!,
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.proteins ===
																				0
																					? 0
																					: ((data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.totalFat! +
																							data1
																								?.weeklyPlannerEventWeekCalories
																								?.caloriesData
																								?.proteins! +
																							data1
																								?.weeklyPlannerEventWeekCalories
																								?.caloriesData
																								?.totalCarbs!) *
																							100) /
																					  data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.proteins!,
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.totalCarbs ===
																				0
																					? 0
																					: ((data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.totalFat! +
																							data1
																								?.weeklyPlannerEventWeekCalories
																								?.caloriesData
																								?.proteins! +
																							data1
																								?.weeklyPlannerEventWeekCalories
																								?.caloriesData
																								?.totalCarbs!) *
																							100) /
																					  data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.totalCarbs!,
																			],
																			backgroundColor:
																				[
																					DS
																						.colors
																						.masti,
																					DS
																						.colors
																						.meditation,
																					DS
																						.colors
																						.meals,
																				],
																			borderColor:
																				[
																					DS
																						.colors
																						.masti,
																					DS
																						.colors
																						.meditation,
																					DS
																						.colors
																						.meals,
																				],
																			borderWidth: 1,
																		},
																	],
																}}
																options={{
																	responsive:
																		true,
																	plugins: {
																		legend: {
																			display:
																				false,
																		},
																	},
																	maintainAspectRatio:
																		false,
																	cutout: 70,
																}}
															/>
															<CalorieWrapper>
																<H3>
																	{
																		data1
																			?.weeklyPlannerEventWeekCalories
																			?.weekCaloriesCount
																	}
																</H3>
																<Body1>
																	CALORIES
																</Body1>
															</CalorieWrapper>
														</div>
														<Body2
															marginTop={18}
															style={{
																display: "flex",
																justifyContent:
																	"center",
															}}
														>
															Daily Average
															calorie count:{" "}
															{parseInt(
																(data1
																	?.weeklyPlannerEventWeekCalories
																	?.weekCaloriesCount! /
																	data1
																		?.weeklyPlannerEventWeekCalories
																		?.mealsDays!) as any,
															)}{" "}
															cal
														</Body2>
														<FlexBetween
															style={{
																margin:
																	"20px 0px",
															}}
														>
															<FlexColumn>
																<TypeWrapper>
																	<Dot stroke="masti" />
																	<Body2>
																		Fat
																	</Body2>
																</TypeWrapper>
																<Body2>
																	{data1
																		?.weeklyPlannerEventWeekCalories
																		?.caloriesData
																		?.totalFat ===
																	0
																		? 0
																		: ((data1
																				?.weeklyPlannerEventWeekCalories
																				?.caloriesData
																				?.totalFat! +
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.proteins! +
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.totalCarbs!) *
																				100) /
																		  data1
																				?.weeklyPlannerEventWeekCalories
																				?.caloriesData
																				?.totalFat!}
																	% /{" "}
																	{
																		data1
																			?.weeklyPlannerEventWeekCalories
																			?.caloriesData
																			?.totalFat!
																	}
																	g
																</Body2>
															</FlexColumn>
															<FlexColumn>
																<TypeWrapper>
																	<Dot stroke="meditation" />
																	<Body2>
																		Protien
																	</Body2>
																</TypeWrapper>
																<Body2>
																	{data1
																		?.weeklyPlannerEventWeekCalories
																		?.caloriesData
																		?.proteins ===
																	0
																		? 0
																		: ((data1
																				?.weeklyPlannerEventWeekCalories
																				?.caloriesData
																				?.totalFat! +
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.proteins! +
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.totalCarbs!) *
																				100) /
																		  data1
																				?.weeklyPlannerEventWeekCalories
																				?.caloriesData
																				?.proteins!}
																	% /{" "}
																	{
																		data1
																			?.weeklyPlannerEventWeekCalories
																			?.caloriesData
																			?.proteins!
																	}
																	g
																</Body2>
															</FlexColumn>
															<FlexColumn>
																<TypeWrapper>
																	<Dot stroke="meals" />
																	<Body2>
																		Carbs
																	</Body2>
																</TypeWrapper>
																<Body2>
																	{data1
																		?.weeklyPlannerEventWeekCalories
																		?.caloriesData
																		?.totalCarbs ===
																	0
																		? 0
																		: ((data1
																				?.weeklyPlannerEventWeekCalories
																				?.caloriesData
																				?.totalFat! +
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.proteins! +
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.totalCarbs!) *
																				100) /
																		  data1
																				?.weeklyPlannerEventWeekCalories
																				?.caloriesData
																				?.totalCarbs!}
																	% /{" "}
																	{
																		data1
																			?.weeklyPlannerEventWeekCalories
																			?.caloriesData
																			?.totalCarbs!
																	}
																	g
																</Body2>
															</FlexColumn>
														</FlexBetween>
														<Table
															tabItems={[
																{
																	title: (
																		<Caption
																			style={{
																				fontWeight:
																					"bold",
																			}}
																		>
																			Total Fat
																		</Caption>
																	),
																	value: (
																		<Caption>
																			{
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.totalFat
																			}
																		</Caption>
																	),
																	subItems: [
																		{
																			title: (
																				<Caption>
																					Sat Fat
																				</Caption>
																			),
																			value: (
																				<Caption>
																					{
																						data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.satFat
																					}
																				</Caption>
																			),
																		},
																	],
																},
																{
																	title: (
																		<Caption
																			style={{
																				fontWeight:
																					"bold",
																			}}
																		>
																			Cholesterol
																		</Caption>
																	),
																	value: (
																		<Caption>
																			{
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.cholesterol
																			}
																		</Caption>
																	),
																},
																{
																	title: (
																		<Caption
																			style={{
																				fontWeight:
																					"bold",
																			}}
																		>
																			Sodium
																		</Caption>
																	),
																	value: (
																		<Caption>
																			{
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.sodium
																			}
																		</Caption>
																	),
																},
																{
																	title: (
																		<Caption
																			style={{
																				fontWeight:
																					"bold",
																			}}
																		>
																			Total Carbs
																		</Caption>
																	),
																	value: (
																		<Caption>
																			{
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.totalCarbs
																			}
																		</Caption>
																	),
																	subItems: [
																		{
																			title: (
																				<Caption>
																					Fiber
																				</Caption>
																			),
																			value: (
																				<Caption>
																					{
																						data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.fiber
																					}
																				</Caption>
																			),
																		},
																		{
																			title: (
																				<Caption>
																					Sugar
																				</Caption>
																			),
																			value: (
																				<Caption>
																					{
																						data1
																							?.weeklyPlannerEventWeekCalories
																							?.caloriesData
																							?.sugar
																					}
																				</Caption>
																			),
																		},
																	],
																},
																{
																	title: (
																		<Caption
																			style={{
																				fontWeight:
																					"bold",
																			}}
																		>
																			Protiens
																		</Caption>
																	),
																	value: (
																		<Caption>
																			{
																				data1
																					?.weeklyPlannerEventWeekCalories
																					?.caloriesData
																					?.proteins
																			}
																		</Caption>
																	),
																},
															]}
														/>
													</ShowIf>
												</ChartWrapper>
											</ChartContainer>
										) : null}
									</IconContainer>
									<IconContainer
										style={{ cursor: "pointer" }}
										onClick={() =>
											history.push(
												`weekly-planner/print/${date
													.weekday(1)
													.format("YYYY-MM-DD")}`,
											)
										}
									>
										<IconPrint />
										{!isMobile ? (
											<Body2
												style={{ marginLeft: 10 }}
											>
												Print
											</Body2>
										) : null}
									</IconContainer>
								</FlexCenter>
							</WeekWrapper>
							<DayWrapper isMobile={isMobile}>
								<DayContainer
									isMobile={isMobile}
									active={activeTab === 1}
									onClick={() => setActiveTab(1)}
								>
									{isMobile ? (
										<>
											<Body2>
												{date
													.weekday(1)
													.format("dd")
													.substring(0, 1)}
											</Body2>
											<Body2>
												{date
													.weekday(1)
													.format("D")}
											</Body2>
										</>
									) : (
										<Body2>
											{date
												.weekday(1)
												.format("ddd, MMM D")}
										</Body2>
									)}
								</DayContainer>
								<DayContainer
									isMobile={isMobile}
									active={activeTab === 2}
									onClick={() => setActiveTab(2)}
								>
									{isMobile ? (
										<>
											<Body2>
												{date
													.weekday(2)
													.format("dd")
													.substring(0, 1)}
											</Body2>
											<Body2>
												{date
													.weekday(2)
													.format("D")}
											</Body2>
										</>
									) : (
										<Body2>
											{date
												.weekday(2)
												.format("ddd, MMM D")}
										</Body2>
									)}
								</DayContainer>
								<DayContainer
									isMobile={isMobile}
									active={activeTab === 3}
									onClick={() => setActiveTab(3)}
								>
									{isMobile ? (
										<>
											<Body2>
												{date
													.weekday(3)
													.format("dd")
													.substring(0, 1)}
											</Body2>
											<Body2>
												{date
													.weekday(3)
													.format("D")}
											</Body2>
										</>
									) : (
										<Body2>
											{date
												.weekday(3)
												.format("ddd, MMM D")}
										</Body2>
									)}
								</DayContainer>
								<DayContainer
									isMobile={isMobile}
									active={activeTab === 4}
									onClick={() => setActiveTab(4)}
								>
									{isMobile ? (
										<>
											<Body2>
												{date
													.weekday(4)
													.format("dd")
													.substring(0, 1)}
											</Body2>
											<Body2>
												{date
													.weekday(4)
													.format("D")}
											</Body2>
										</>
									) : (
										<Body2>
											{date
												.weekday(4)
												.format("ddd, MMM D")}
										</Body2>
									)}
								</DayContainer>
								<DayContainer
									isMobile={isMobile}
									active={activeTab === 5}
									onClick={() => setActiveTab(5)}
								>
									{isMobile ? (
										<>
											<Body2>
												{date
													.weekday(5)
													.format("dd")
													.substring(0, 1)}
											</Body2>
											<Body2>
												{date
													.weekday(5)
													.format("D")}
											</Body2>
										</>
									) : (
										<Body2>
											{date
												.weekday(5)
												.format("ddd, MMM D")}
										</Body2>
									)}
								</DayContainer>
								<DayContainer
									isMobile={isMobile}
									active={activeTab === 6}
									onClick={() => setActiveTab(6)}
								>
									{isMobile ? (
										<>
											<Body2>
												{date
													.weekday(6)
													.format("dd")
													.substring(0, 1)}
											</Body2>
											<Body2>
												{date
													.weekday(6)
													.format("D")}
											</Body2>
										</>
									) : (
										<Body2>
											{date
												.weekday(6)
												.format("ddd, MMM D")}
										</Body2>
									)}
								</DayContainer>
								<DayContainer
									isMobile={isMobile}
									active={activeTab === 7}
									onClick={() => setActiveTab(7)}
								>
									{isMobile ? (
										<>
											<Body2>
												{date
													.weekday(7)
													.format("dd")
													.substring(0, 1)}
											</Body2>
											<Body2>
												{date
													.weekday(7)
													.format("D")}
											</Body2>
										</>
									) : (
										<Body2>
											{date
												.weekday(7)
												.format("ddd, MMM D")}
										</Body2>
									)}
								</DayContainer>
							</DayWrapper>
							<MWrapper>
								<WeeklyMWrapper
									type="meals"
									stroke="meals"
									showAdd={
										meals && meals?.length < 6
									}
									dayCaloriesCount={
										data?.listWeeklyPlannerEvents
											?.dayCaloriesCount
									}
									caloriesData={
										data?.listWeeklyPlannerEvents
											?.caloriesData
									}
									onAddClick={() =>
										history.push(
											`/dashboard/meals/recipes?date=${selectedDate?.format(
												"YYYY-MM-DD",
											)}`,
										)
									}
								>
									{meals?.length
										? meals.map(
												(meal: any, i) => (
													<Link
														key={meal.id}
														to={`/dashboard/meals/recipes/${meal.recipe?.id}?slot=${meal.slot}&date=${selectedDate}`}
													>
														<ShowIf
															value={i !== 0}
														>
															<Spacing
																type="s2"
																multiplier={
																	isMobile
																		? 7
																		: 13
																}
															/>
														</ShowIf>
														<WeeklyMItem
															imgSrc={
																meal.recipe
																	?.thumbnail?.url
															}
															mealTime={
																meal.slot!
															}
															accentColor="meals"
															title={
																meal.recipe
																	?.Title!
															}
															person={
																meal.recipe
																	?.collaborator
																	?.name!
															}
															time={
																meal.recipe
																	?.Prep_Duration
																	?.duration as any
															}
															calorie={
																meal.recipe
																	?.Calories as any
															}
															onMinusClick={(
																e,
															) => {
																e.preventDefault();
																deleteWeeklyPlannerEventMutation[0](
																	{
																		variables: {
																			id: meal.id,
																		},
																	},
																)
																	.then((res) => {
																		addToast(
																			"Item removed weekly planner",
																			{
																				appearance:
																					"success",
																				autoDismiss:
																					true,
																			},
																		);
																		refetch();
																	})
																	.catch((err) =>
																		addToast(
																			"Error removing the weekly planner",
																			{
																				appearance:
																					"error",
																				autoDismiss:
																					true,
																			},
																		),
																	);
															}}
														/>
													</Link>
												),
										  )
										: null}
								</WeeklyMWrapper>
								<WeeklyMWrapper
									type="movement"
									stroke="movement"
									showAdd={
										movement &&
										movement?.length < 1
									}
									onAddClick={() =>
										history.push(
											`/dashboard/movement?date=${selectedDate?.format(
												"YYYY-MM-DD",
											)}`,
										)
									}
								>
									{movement?.length ? (
										<Link
											to={`/dashboard/movement/${movement[0].movement?.movement_category?.id}/${movement[0]?.movement?.id}?date=${selectedDate}`}
										>
											<WeeklyMItem
												imgSrc={
													movement[0].movement
														?.thumbnail?.url
												}
												accentColor="movement"
												title={
													movement[0].movement
														?.title!
												}
												person={
													movement[0].movement
														?.collaborator?.name!
												}
												time={
													movement[0].movement
														?.duration
												}
												calorie={
													movement[0].movement
														?.calories_burn as any
												}
												onMinusClick={(e) => {
													e.preventDefault();
													deleteWeeklyPlannerEventMutation[0](
														{
															variables: {
																id: movement[0]
																	.id,
															},
														},
													)
														.then((res) => {
															addToast(
																"Item removed weekly planner",
																{
																	appearance:
																		"success",
																	autoDismiss:
																		true,
																},
															);
															refetch();
														})
														.catch((err) =>
															addToast(
																"Error removing the weekly planner",
																{
																	appearance:
																		"error",
																	autoDismiss:
																		true,
																},
															),
														);
												}}
											/>
										</Link>
									) : null}
								</WeeklyMWrapper>
								<WeeklyMWrapper
									type="meditation"
									stroke="meditation"
									showAdd={
										meditation &&
										meditation?.length < 1
									}
									onAddClick={() =>
										history.push(
											`/dashboard/meditation?date=${selectedDate?.format(
												"YYYY-MM-DD",
											)}`,
										)
									}
								>
									{meditation?.length ? (
										<Link
											to={`/dashboard/meditation/${meditation[0].meditation?.meditation_category?.id}/${meditation[0].meditation?.id}?date=${selectedDate}`}
										>
											<WeeklyMItem
												imgSrc={
													meditation[0].meditation
														?.thumbnail?.url
												}
												accentColor="meditation"
												title={
													meditation[0].meditation
														?.title!
												}
												person={
													meditation[0].meditation
														?.collaborator?.name!
												}
												time={
													meditation[0].meditation
														?.duration
												}
												onMinusClick={(e) => {
													e.preventDefault();
													deleteWeeklyPlannerEventMutation[0](
														{
															variables: {
																id: meditation[0]
																	.id,
															},
														},
													)
														.then((res) => {
															addToast(
																"Item removed weekly planner",
																{
																	appearance:
																		"success",
																	autoDismiss:
																		true,
																},
															);
															refetch();
														})
														.catch((err) =>
															addToast(
																"Error removing the weekly planner",
																{
																	appearance:
																		"error",
																	autoDismiss:
																		true,
																},
															),
														);
												}}
											/>
										</Link>
									) : null}
								</WeeklyMWrapper>
								<WeeklyMWrapper
									type="mantra"
									stroke="mantra"
									showAdd={
										mantra && mantra?.length < 1
									}
									onAddClick={() =>
										history.push(
											`/dashboard/mantra?date=${selectedDate?.format(
												"YYYY-MM-DD",
											)}`,
										)
									}
								>
									{mantra?.length ? (
										<Link
											to={`/dashboard/mantra/${mantra[0].mantra?.id}?date=${selectedDate}`}
										>
											<WeeklyMItem
												imgSrc={
													mantra[0].mantra
														?.cover_image?.url
												}
												accentColor="mantra"
												title={
													mantra[0].mantra?.title!
												}
												person={
													mantra[0].mantra
														?.collaborator?.name!
												}
												onMinusClick={(e) => {
													e.preventDefault();
													deleteWeeklyPlannerEventMutation[0](
														{
															variables: {
																id: mantra[0].id,
															},
														},
													)
														.then((res) => {
															addToast(
																"Item removed weekly planner",
																{
																	appearance:
																		"success",
																	autoDismiss:
																		true,
																},
															);
															refetch();
														})
														.catch((err) =>
															addToast(
																"Error removing the weekly planner",
																{
																	appearance:
																		"error",
																	autoDismiss:
																		true,
																},
															),
														);
												}}
											/>
										</Link>
									) : null}
								</WeeklyMWrapper>
								<WeeklyMWrapper
									type="masti"
									stroke="masti"
									showAdd={
										masti && masti?.length < 1
									}
									onAddClick={() =>
										history.push(
											`/dashboard/masti?date=${selectedDate?.format(
												"YYYY-MM-DD",
											)}`,
										)
									}
								>
									{masti?.length ? (
										<Link
											to={`/dashboard/masti/${masti[0].masti?.id}?date=${selectedDate}`}
										>
											<WeeklyMItem
												imgSrc={
													masti[0].masti
														?.cover_image?.url
												}
												accentColor="masti"
												title={
													masti[0].masti?.title!
												}
												person={
													masti[0].masti
														?.collaborator?.name!
												}
												time={
													masti[0].masti
														?.reading_time!
												}
												onMinusClick={(e) => {
													e.preventDefault();
													deleteWeeklyPlannerEventMutation[0](
														{
															variables: {
																id: masti[0].id,
															},
														},
													)
														.then((res) => {
															addToast(
																"Item removed weekly planner",
																{
																	appearance:
																		"success",
																	autoDismiss:
																		true,
																},
															);
															refetch();
														})
														.catch((err) =>
															addToast(
																"Error removing the weekly planner",
																{
																	appearance:
																		"error",
																	autoDismiss:
																		true,
																},
															),
														);
												}}
											/>
										</Link>
									) : null}
								</WeeklyMWrapper>
							</MWrapper>
						</Box>
					</Wrapper>
				</Box>
			</ShowIf>
		</LayoutContainer>
	);
};

export default WeeklyPlanner;
