export const LOCAL_URL = "http://localhost:1337";
export const DEV_URL =
	"https://devadmin.nyaari.com";
export const PROD_URL =
	"https://admin.nyaari.com";

export const IS_PROD =
	window.location.host.includes("localhost")
		? false
		: window.location.host.includes("dev.nyaari")
		? false
		: true;
export let ENDPOINT_URL =
	window.location.host.includes("localhost")
		? LOCAL_URL
		: window.location.host.includes("dev.nyaari")
		? DEV_URL
		: PROD_URL;

export const GQL_URL = ENDPOINT_URL + "/graphql";

//TODO fix this url
export const REFRESH_TOKEN_URL =
	ENDPOINT_URL + "/refreshToken";

const doFallbackToProdUrl = () => {
	console.warn(
		"Admin is not running in dev env falling back to production url",
	);
	ENDPOINT_URL = DEV_URL;
};

if (window.location.host.includes("localhost"))
	fetch("http://localhost:1337")
		.then((response) => {
			if (response.status !== 200) {
				doFallbackToProdUrl();
			}
		})
		.catch((error) => {
			console.log(error);
			doFallbackToProdUrl();
		});
