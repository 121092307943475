import React from "react";

import { Switch, Route } from "react-router";

import HomePage from "./pages/HomePage/HomePage";
import SignUp from "./pages/SignUpPage/SignUpPage";
import SignInPage from "./pages/SignInPage/SignInPage";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";

import ContactUs from "./pages/ContactUs/ContactUs";

import AppVersion from "./components/AppVersion/AppVersion";
import { H3 } from "@workshore/nyaari-ds";
import HeaderRoutes from "./components/HeaderRoutes/HeaderRoutes";
import AuthRoute, {
	NotAllowedToAuthRoute,
} from "./components/AuthRoute/AuthRoute";
import LogoutPage from "./pages/LogoutPage/LogoutPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import PublicMenu from "./components/PublicMenu/PublicMenu";
import { ReactPWAToHomeScreen } from "react-pwa-to-homescreen";
import "react-pwa-to-homescreen/dist/index.css";
import Blog from "./pages/Blog/Blog";
import BlogDetail from "./pages/BlogDetail/BlogDetail";
import CollaboratorPage from "./pages/CollaboratorPage/CollaboratorPage";
import CollaboratorDetails from "./pages/CollaboratorDetails/CollaboratorDetails";
import ResetPassword from "./pages/ResetPassword";
import EmailConfirmationPage from "./pages/EmailConfirmationPage";
import LoginRedirect from "./components/LoginRedirect/LoginRedirect";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions/TermsConditions";

function App() {
	return (
		<>
			<HeaderRoutes />
			<Switch>
				<Route path="/auth/:provider" exact>
					<LoginRedirect />
				</Route>
				<Route path="/" exact>
					<NotAllowedToAuthRoute>
						<HomePage />
					</NotAllowedToAuthRoute>
				</Route>
				<Route path="/privacy-policy" exact>
					<PrivacyPolicy />
				</Route>
				<Route path="/terms-conditions" exact>
					<TermsConditions />
				</Route>
				<Route path="/contactus" exact>
					<ContactUs />
				</Route>
				<Route path="/freemium-gyan" exact>
					<Blog />
				</Route>
				<Route path="/freemium-gyan/:id" exact>
					<BlogDetail />
				</Route>
				<Route path="/collaborators">
					<CollaboratorPage />
					<Switch>
						<Route
							path="/collaborators/:id"
							exact
						>
							<CollaboratorDetails />
						</Route>
					</Switch>
				</Route>

				<Route path="/signin" exact>
					<NotAllowedToAuthRoute>
						<SignInPage />
					</NotAllowedToAuthRoute>
				</Route>
				<Route path="/signup" exact>
					<NotAllowedToAuthRoute>
						<SignUp />
					</NotAllowedToAuthRoute>
				</Route>
				<Route path="/forgotpassword" exact>
					<NotAllowedToAuthRoute>
						<ForgotPassword />
					</NotAllowedToAuthRoute>
				</Route>
				<Route path="/resetpassword/:code" exact>
					<NotAllowedToAuthRoute>
						<ResetPassword />
					</NotAllowedToAuthRoute>
				</Route>
				<Route
					path="/emailconfirmation/:code"
					exact
				>
					<NotAllowedToAuthRoute>
						<EmailConfirmationPage />
					</NotAllowedToAuthRoute>
				</Route>
				<Route path="/dashboard">
					<AuthRoute>
						<DashboardPage />
					</AuthRoute>
				</Route>
				<Route path="/logout" exact>
					<AuthRoute>
						<LogoutPage />
					</AuthRoute>
				</Route>
				<Route>
					<div
						style={{
							height: "100vh",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<H3>Page Not Found</H3>
					</div>
				</Route>
			</Switch>
			<PublicMenu />
			<AppVersion />
			<ReactPWAToHomeScreen />
		</>
	);
}

export default App;
