import {
	forwardRef,
	ForwardRefRenderFunction,
} from "react";
import styled from "styled-components";
import { DS } from "@workshore/nyaari-ds";

import IconAdd from "../Icons/IconAdd";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";

interface Props {
	stroke?: keyof typeof DS.colors;
	onClick?:
		| React.MouseEventHandler<HTMLDivElement>
		| undefined;
}

const IconPlusContiner = styled.div<{
	isMobile: boolean;
}>`
	cursor: pointer;
	height: 38px;
	width: 38px;
	background-color: white;
	border-radius: 19px;
	box-shadow: 0 3px 6px #00000016;
	display: flex;
	justify-content: center;
	align-items: center;
	> * {
		width: 18px;
		height: 33px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
			height: 22px;
			width: 22px;

			> * {
				width: 10px;
				height: 19px;
			}
	`}
`;
const IconPlusContiner1 = styled.div`
	cursor: pointer;
	height: 38px;
	width: 38px;
	background-color: white;
	border-radius: 19px;
	box-shadow: 0 3px 6px #00000016;
	display: flex;
	justify-content: center;
	align-items: center;
	> * {
		width: 18px;
		height: 33px;
	}
`;

const AddButton: ForwardRefRenderFunction<
	HTMLDivElement,
	Props
> = ({ stroke, onClick }, ref) => {
	const isMobile = useIsMobile();
	return (
		<IconPlusContiner
			ref={ref}
			isMobile={isMobile}
			onClick={onClick}
		>
			<IconAdd stroke={stroke} />
		</IconPlusContiner>
	);
};

const AddButton1: ForwardRefRenderFunction<
	HTMLDivElement,
	Props
> = ({ stroke, onClick }, ref) => {
	return (
		<IconPlusContiner1
			ref={ref}
			onClick={onClick}
		>
			<IconAdd stroke={stroke} />
		</IconPlusContiner1>
	);
};
export const AddButtonFixed =
	forwardRef(AddButton1);

export default forwardRef(AddButton);
