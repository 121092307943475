import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import useAuth from "../../hooks/useAuth/useAuth";

const AuthRoute: FC = ({ children }) => {
  const auth = useAuth();

  return (
    <>{auth ? children : <Redirect to="/" />}</>
  );
};

export default AuthRoute;

export const NotAllowedToAuthRoute: FC<{
  to?: string;
}> = ({ to = "/dashboard", children }) => {
  const auth = useAuth();

  return (
    <>{!auth ? children : <Redirect to={to} />}</>
  );
};
