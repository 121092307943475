import { IS_PROD } from "../config";

const trackUser = (event: string, value: any) => {
	(window as any).gtag("event", event, value);
	if (!IS_PROD) {
		console.log("tracking data", event, value);
	}
};

export default trackUser;
