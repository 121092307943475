const getFirstError = (data: any) => {
  if ("graphQLErrors" in data) {
    if (data.graphQLErrors.length) {
      if (data.graphQLErrors[0].extensions) {
        if (
          data.graphQLErrors[0].extensions
            .exception
        ) {
          if (
            data.graphQLErrors[0].extensions
              .exception.data
          ) {
            if (
              data.graphQLErrors[0].extensions
                .exception.data.data
            ) {
              if (
                data.graphQLErrors[0].extensions
                  .exception.data.data.length
              ) {
                if (
                  data.graphQLErrors[0].extensions
                    .exception.data.data[0]
                    .messages
                ) {
                  if (
                    data.graphQLErrors[0]
                      .extensions.exception.data
                      .data[0].messages.length
                  ) {
                    if (
                      data.graphQLErrors[0]
                        .extensions.exception.data
                        .data[0].messages[0]
                    ) {
                      return data.graphQLErrors[0]
                        .extensions.exception.data
                        .data[0].messages[0]
                        .message;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } else if ("message" in data) {
    return data.message;
  }
  return "Something went wrong";
};

export default getFirstError;
