import { useSharedStateChange } from "@tinyd/usesharedstate";
import { useEffect, useState } from "react";
import useRoute from "../../hooks/useRoute/useRoute";

const OpenSubscription = () => {
  const [loaded, setLoaded] = useState(false);
  const change = useSharedStateChange(
    "@subscription"
  );
  const { goBack } = useRoute();
  useEffect(() => {
    console.log(loaded, "loaded");
    if (!loaded) {
      setLoaded(true);
      change(true);
      goBack();
    }
  }, [loaded, change, goBack]);
  return null;
};

export default OpenSubscription;
