import { FC } from "react";
import { DS } from "@workshore/nyaari-ds";
import styled from "styled-components";

const Line = styled.div<Props>`
	${({ height = 1, color = "doveGrey" }) => `
    height: ${height}px;
    background-color: ${DS.colors[color]};
  `}
`;

interface Props {
	height?: number;
	color?: keyof typeof DS.colors;
}

const Seperator: FC<Props> = ({
	height,
	color,
}) => {
	return <Line height={height} color={color} />;
};

export default Seperator;
