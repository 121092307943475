import React from "react";
import styled from "styled-components";
import { useScreenClass } from "react-grid-system";
import {
	DS,
	IconBack,
	Body2,
} from "@workshore/nyaari-ds";
// import IconFB from "../Icons/IconFB";
// import IconPin from "../Icons/IconPin";
// import IconYoutube from "../Icons/IconYoutube";
import phone from "../../assets/icons/phone.svg";
import email from "../../assets/icons/email.svg";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import { Link } from "react-router-dom";

const {
	colors,
	fontSize,
	breakPoints,
	fontWeight,
} = DS;

const FooterBottom = styled.div<{
	screenClass?: string | any;
}>`
	background-color: ${colors.redRibbon};
	display: flex;
	flex-wrap: wrap;
	white-space: nowrap;
	font-weight: ${fontWeight.medium};
	justify-content: center;
	position: relative;

	& > div {
		display: flex;
		align-items: center;
		color: ${colors.white};
		cursor: pointer;

		${({ screenClass }) => `
      margin: ${
				["lg", "xl", "xxl"].includes(screenClass)
					? `0 40px 0 0`
					: `0 10px 0 0`
			};
      & > div {
        font-size: ${
					["lg", "xl", "xxl"].includes(
						screenClass,
					)
						? `${fontSize.body1}px`
						: `${fontSize.body2}px`
				};
      }
      
      `}
	}
`;

// const Circle = styled.a<{ center?: boolean }>`
// 	height: 45px;
// 	width: 45px;
// 	background-color: ${DS.colors.redRibbon};
// 	border-radius: 23px;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;

// 	${({ center }) =>
// 		center &&
// 		`
//     margin: 0 30px;
//   `};
// `;

const ArrowDiv = styled.div<{
	isMobile: boolean;
}>`
	height: 57px;
	width: 57px;
	border-radius: 32px;
	box-shadow: 0 3px 6px #00000029;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: rotate(90deg);

	${({ isMobile }) =>
		isMobile &&
		`
    height: 30px;
    width: 30px;
  `};
`;

const StyledBody2 = styled(Body2)`
	/* margin-top: 16px; */
	/* margin: 16px 0px; */
`;

const StyledIcon = styled.img`
	margin-right: 10px;
`;

const FooterContainer = styled.div<{
	screenClass?: string | any;
}>`
	background-color: ${colors.wildSand};
	width: 100%;
	height: 230px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${({ screenClass }) => `
     
        height: ${
					["lg", "xl", "xxl"].includes(
						screenClass,
					)
						? `230px`
						: `130px`
				};
        font-size: ${
					["lg", "xl", "xxl"].includes(
						screenClass,
					)
						? `${fontSize.body1}px`
						: `${fontSize.body3}px`
				};
      
      
      `}
`;

// const IconWrapper = styled.div<{
// 	screenClass?: string | any;
// }>`
// 	display: flex;
// 	justify-content: space-around;
// 	align-items: center;
// 	& > div {
// 		cursor: pointer;
// 	}

// 	${({ screenClass }) => `
//         font-size: ${
// 					["lg", "xl", "xxl"].includes(
// 						screenClass,
// 					)
// 						? `40px`
// 						: `35px`
// 				};
//       & > div{
//         margin: ${
// 					["lg", "xl", "xxl"].includes(
// 						screenClass,
// 					)
// 						? `0 40px 0 0`
// 						: `0 20px 0 0`
// 				};
//       }
//     `}
// `;

const TextWrapper = styled.div<{
	screenClass?: string | any;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	cursor: pointer;
	margin-top: 24px;

	${({ screenClass }) => `    
        font-size: ${
					["lg", "xl", "xxl"].includes(
						screenClass,
					)
						? `${fontSize.body1}px;`
						: `${fontSize.body3}px;`
				};
      
    `}
`;

const Line = styled.div`
	width: 0;
	height: 23px;
	border-right: 1px solid ${colors.redRibbon};
	margin: 0 27px;

	@media screen and (max-width: ${breakPoints.m}px) {
		height: 17px;
		margin: 0 13px;
	}
`;

const ScrollTopIcon = styled.div`
	position: absolute;
	font-size: 52px;
	right: 100px;
	top: -25px;

	@media screen and (max-width: ${breakPoints.m}px) {
		font-size: 30px;
		right: 50px;
		top: -15px;
	}
`;

const Footer = () => {
	const screenClass = useScreenClass();
	const isMobile = useIsMobile();

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<>
			<FooterContainer screenClass={screenClass}>
				{/* <IconWrapper screenClass={screenClass}>
					<Circle href="https://www.facebook.com/">
						<IconFB />
					</Circle>
					<Circle
						center
						href="https://www.pinterest.com/"
					>
						<IconPin />
					</Circle>
					<Circle href="https://youtube.com/">
						<IconYoutube />
					</Circle>
				</IconWrapper> */}

				<TextWrapper screenClass={screenClass}>
					<Link to="/contactus">
						<StyledBody2>Contact us</StyledBody2>
					</Link>
					<Line />
					<a href="/terms-conditions">
						<StyledBody2>
							Terms and Conditions
						</StyledBody2>
					</a>
					<Line />
					<a href="/privacy-policy">
						<StyledBody2>
							Privacy Policy
						</StyledBody2>
					</a>
				</TextWrapper>
			</FooterContainer>

			<FooterBottom screenClass={screenClass}>
				<ScrollTopIcon onClick={scrollToTop}>
					<ArrowDiv isMobile={isMobile}>
						<IconBack />
					</ArrowDiv>
				</ScrollTopIcon>
				<div>
					<StyledIcon src={phone} alt="" />
					<p>+1-770-815-3604</p>
				</div>

				<div>
					<StyledIcon src={email} alt="" />
					<p>emailus@nyaari.com</p>
				</div>

				<div>
					<p>
						&copy; 2021 Nyaari, All rights
						reserved.
					</p>
				</div>
			</FooterBottom>
		</>
	);
};

export default Footer;
