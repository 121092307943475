import { useState, useEffect } from "react";
import {
	useLocation,
	useParams,
} from "react-router-dom";
import axios from "axios";
import { Body1 } from "@workshore/nyaari-ds";

import { setAuthToken } from "../../services/authToken";
import useRoute from "../../hooks/useRoute/useRoute";
import Loader from "../Loader/Loader";
import { ENDPOINT_URL } from "../../config";

const LoginRedirect = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<any>();
	const { goTo } = useRoute();
	const location = useLocation();
	const { provider } =
		useParams<{ provider: string }>();

	const fetchData = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(
				`${ENDPOINT_URL}/auth/${provider}/callback/${location.search}`,
			);
			// console.log(data);
			setAuthToken(data.jwt || "");
			goTo("/dashboard", "replace");
		} catch (error) {
			setError(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (loading)
		return (
			<div
				style={{
					height: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Loader />
			</div>
		);
	if (error)
		return (
			<div
				style={{
					height: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{/* {error.message} */}
				<Body1>
					Error logging in! Please try again
					later!
				</Body1>
			</div>
		);

	return (
		<div
			style={{
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Body1>Redirecting you to dashboard</Body1>
		</div>
	);
};

export default LoginRedirect;
