import React, { FC } from "react";

const IconClose: FC<{ strokeWidth?: number }> = ({
	strokeWidth = 1,
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13.093"
			height="13.183"
			viewBox="0 0 13.093 13.183"
		>
			<path
				id="Icon_ionic-ios-close"
				data-name="Icon ionic-ios-close"
				d="M18.707,17.287,22.993,13a1,1,0,0,0-1.42-1.42l-4.286,4.286L13,11.581A1,1,0,1,0,11.581,13l4.286,4.286-4.286,4.286A1,1,0,0,0,13,22.993l4.286-4.286,4.286,4.286a1,1,0,0,0,1.42-1.42Z"
				transform="translate(-10.785 -10.695)"
				fill="#333"
				stroke="#333"
				strokeWidth={strokeWidth}
			/>
		</svg>
	);
};

export default IconClose;
