import { FC, useState, useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
	H1,
	H2,
	H3,
	Body1,
	Body2,
	Subtitle1,
	Caption,
	Tag,
	Wrapper,
	DS,
	Button,
	Box,
	BulletText,
	IconMantra,
	IconMeals,
	IconMovement,
	IconMeditation,
	IconMasti,
} from "@workshore/nyaari-ds";
import BulletList from "../../components/BulletList/BulletList";
import Slider, {
	CustomArrowProps,
} from "react-slick";
import ScrollContainer from "react-indiana-drag-scroll";

import "./style.css";
import { useGetLimitedBlogsQuery } from "../../hooks/graphql/graphql";
import Loader from "../../components/Loader/Loader";
import home_hero from "../../assets/images/home_hero.jpg";
import Image_1 from "../../assets/images/Image_1.png";
import Image_2 from "../../assets/images/Image_2.png";
import Image_3 from "../../assets/images/Image_3.png";
import blue_heart from "../../assets/icons/blue_heart.svg";
import red_heart from "../../assets/icons/red_heart.svg";
// import orange_heart from "../../assets/icons/orange_heart.svg";
import double_quote from "../../assets/icons/double_qoute.svg";
import about_us_image from "../../assets/images/collaborators.png";
import IconRightArrow from "../../components/Icons/IconRightArrow";
import Footer from "../../components/Footer/Footer";
import Slider1 from "./Slider1";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import Subscribe from "../../components/Subscribe/Subscribe";
import LabeledIcon from "../../components/LabeledIcon/LabeledIcon";
import IconUser from "../../components/Icons/IconUser";
import IconCalender from "../../components/Icons/IconCalender";
import ShowIf from "../../components/ShowIf/ShowIf";
import getDate from "../../utils/getDate";

// Hero section
const HeaderContainer = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	align-items: row;
	height: 700px;
	background-color: #e2f4f9;
	width: 100%;
	position: relative;

	${({ isMobile }) =>
		isMobile &&
		`
      flex-direction: column-reverse;
  `}
`;

const HeroWrapper = styled.div<{
	isMobile: boolean;
}>`
	width: 508px;
	margin-right: 40px;

	${({ isMobile }) =>
		isMobile &&
		`
    width: 100%;
    margin-right: 0px;
  `};
`;

const StyledImage = styled.img<{
	isMobile: boolean;
	isTablet: boolean;
}>`
	width: 55vw;
	height: 700px;
	position: absolute;
	right: 0;
	top: 0;
	object-fit: cover;

	${({ isTablet }) =>
		isTablet &&
		`
    width: calc(100% - 638px);
  `};

	${({ isMobile }) =>
		isMobile &&
		`
    width: 100%;
    height: 308px;
  `};
`;

// nyaari details
const NyaariDetails = styled.div`
	width: 100%;
	background-color: ${DS.colors.wildSand};
	position: relative;
`;

// const OrangeHeart = styled.img<{
// 	isMobile: boolean;
// 	isTablet: boolean;
// }>`
// 	position: absolute;
// 	height: 235px;
// 	width: 166px;
// 	top: 600px;
// 	right: 0;
// 	transform: scaleX(-1);
// 	margin-right: -20px;

// 	${({ isTablet }) =>
// 		isTablet &&
// 		`
//     top: 650px;
//   `};

// 	${({ isMobile }) =>
// 		isMobile &&
// 		`
//     width: 80px;
//     height: 72px;
//     top: 580px;
//     margin-right: -28px;
//   `};
// `;

const NyaariHeader = styled.div`
	text-align: center;
`;

const StyledBox = styled(Box)`
	@media screen and (max-width: 768px) {
		margin-top: 0px;
	}
`;

const Details = styled.div<{
	isTablet: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	img {
		width: 700px;
		height: 458px;
		border-radius: 5px;
		object-fit: cover;
	}
	padding-bottom: 100px;

	${({ isTablet }) =>
		isTablet &&
		`
      img{
        width: 50%;
      }
  `}

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			width: 50%;
			margin-right: 50px;
		}
	}

	@media screen and (max-width: 480px) {
		padding-bottom: 50px;
		flex-direction: column;
		align-items: flex-start;

		img {
			width: 300px;
			height: 190px;
			margin-bottom: 20px;
		}
	}
`;

const Details1 = styled.div<{
	isTablet: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	img {
		width: 700px;
		height: 458px;
		border-radius: 5px;
		object-fit: cover;
	}
	padding-bottom: 100px;

	${({ isTablet }) =>
		isTablet &&
		`
      img{
        width: 50%;
      }
  `}

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			margin-left: 50px;
			width: 50%;
		}
	}

	@media screen and (max-width: 480px) {
		padding-bottom: 50px;
		flex-direction: column-reverse;
		align-items: flex-end;

		img {
			width: 300px;
			height: 190px;
		}
	}
`;

const BlueHeart = styled.img<{
	isMobile: boolean;
}>`
	position: absolute;
	bottom: 0;
	left: 0;
	height: 197px;
	width: 217px;
	margin-left: -30px;
	margin-bottom: 20px;

	${({ isMobile }) =>
		isMobile &&
		`
    position: absolute;
    top: 0;
    left: 0;
    width: 62px;
    height: 56px;
    z-index: -1;
    margin-left: -15px;
    margin-top: 10px;
  `};
`;

// quote
const Quote = styled.div`
	width: 100%;
	position: relative;
`;

// about us
const AboutUs = styled.div<{ isMobile: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 687px;
	width: 100%;
	background-size: cover;
	position: relative;
	background-color: #ecf7f0 !important;

	${({ isMobile }) =>
		isMobile &&
		`
      height: 400px;
      img{
        height: 29px;
        width: 76px;
        margin-right: 5px;
      }
  `};
`;

const AboutusImg = styled.img<{
	isMobile: boolean;
	isTablet: boolean;
}>`
	width: 55vw;
	height: 687px;
	position: absolute;
	right: 0;
	top: 0;
	object-fit: cover;

	${({ isTablet }) =>
		isTablet &&
		`
    width: calc(100% - 638px);
  `};

	${({ isMobile }) =>
		isMobile &&
		`
    width: 100%;
    height: 308px;
  `};
`;

// freemium gyan
const FreemiumGyan = styled.div<{
	isMobile: boolean;
}>`
	padding: 147px 0px;
	width: 100%;
	background-color: ${DS.colors.wildSand};
	position: relative;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 50px 0px;
  `};
`;

const FreemiumGyanHeader = styled.div<{
	isMobile: boolean;
}>`
	width: 387px;
	margin-right: 100px;

	${({ isMobile }) =>
		isMobile &&
		`
    width: 100%;
  `};
`;

// features
const Features = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
`;
const FeaturesWrapper = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 851px;

	${({ isMobile }) =>
		isMobile &&
		`
      /* width: 325px; */
      padding: 0px 20px;
  `};
`;
const RedHeart = styled.img<{
	isMobile: boolean;
}>`
	position: absolute;
	bottom: 0;
	left: 0;
	height: 197px;
	width: 217px;
	transform: scaleX(-1);
	margin-left: -50px;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 56px;
    width: 62px;
    margin-left: -10px;
    margin-bottom: -6px;
  `};
`;

// slider
const Story = styled.div<{
	active?: boolean;
	isMobile: boolean;
}>`
	padding: 40px;
	background: ${DS.colors.wildSand};
	height: 448px;
	width: 438px;
	border-radius: 5px;
	opacity: 0.6;

	${({ active }) =>
		active &&
		`
    background: #fde4d6;
		opacity: 1;
  `};

	${({ isMobile }) =>
		isMobile &&
		`
    img {
      height: 40px;
      width: 40px;
    }
    padding: 30px;
    height: 100%;
    width: 100%;
  `};
`;

const StoriesWrapper = styled(Wrapper)<{
	isMobile: boolean;
}>`
	padding: 100px 0;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 50px 0;
  `};
`;

const RedHeart1 = styled.img<{
	isMobile: boolean;
}>`
	position: absolute;
	bottom: -20px;
	right: 0;
	height: 137px;
	width: 157px;
	z-index: 1;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 56px;
    width: 49px;
    margin-right: -10px;
    margin-bottom: -25px;
  `};
`;

const StoriesHeader = styled.div`
	text-align: center;
`;

const StyledBody1 = styled(Body1)<{
	isMobile: boolean;
}>`
	line-height: 36px;
	font-weight: 400;

	${({ isMobile }) =>
		isMobile &&
		`
    line-height: 23px;
  `};
`;

// article
const ArticleContainer = styled.div<{
	isMobile: boolean;
	isTablet: boolean;
}>`
	position: absolute;
	right: 0;
	top: 147px;
	width: 60vw;

	${({ isMobile }) =>
		isMobile &&
		`
		position: static;
		margin: 0 20px;		
  `};

	${({ isTablet }) =>
		isTablet &&
		`
		width: 50vw;
  `};

	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

const Article = styled.div<{ isMobile: boolean }>`
	width: 421px;
	margin-right: 30px;

	${({ isMobile }) =>
		isMobile &&
		`
    width: 265px;
		margin-right: 22px;
  `};
`;

const ArticleImg = styled.img<{
	isMobile: boolean;
}>`
	height: 249px;
	width: 421px;
	object-fit: cover;
	margin-bottom: 22px;
	border-radius: 5px;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 152px;
    width: 265px;
    margin-bottom: 16px;
  `};
`;

const ArticleInfo1 = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin-right: 34px;
	}
	> :not(:first-child) {
		background: none;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    >*{
      margin-right: 6px;
    }
  `};
`;

// arrow component
const ArrowDiv = styled.div<{
	isMobile: boolean;
}>`
	height: 64px;
	width: 64px;
	border-radius: 32px;
	box-shadow: 0 3px 6px #00000029;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 25px;
    width: 25px;
  `};
`;

const Left = styled.div`
	transform: scaleX(-1);
	> * {
		height: 16px;
		width: 8px;
	}
`;

const Right = styled.div`
	> * {
		height: 16px;
		width: 8px;
	}
`;

interface CustomProps extends CustomArrowProps {
	left?: boolean;
}

// transform: scaleX(-1);

const Arrow: FC<CustomProps> = ({
	className,
	style,
	onClick,
	left,
}) => {
	const isMobile = useIsMobile();
	return (
		<ArrowDiv
			isMobile={isMobile}
			className={className}
			style={{
				...style,
				background: "white",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			onClick={onClick}
		>
			{left ? (
				<Left>
					<IconRightArrow />
				</Left>
			) : (
				<Right>
					<IconRightArrow />
				</Right>
			)}
		</ArrowDiv>
	);
};

// five m
const FiveMHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const FiveMIcons = styled.div<{
	isMobile: boolean;
}>`
	margin-top: 50px;
	display: flex;
	justify-content: center;

	* {
		width: 50px;
		height: 50px;
	}

	> :not(:last-child) {
		margin-right: 72px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    * {
      width: 30px;
      height: 30px;
    }
    > :not(:last-child) {
      margin-right: 32px;
    }
  `};
`;

const HomePage = () => {
	const [activeSlide1, setActiveSlide1] =
		useState(0);
	const [activeSlide2, setActiveSlide2] =
		useState(0);
	const { data, loading, error } =
		useGetLimitedBlogsQuery({
			variables: {
				limit: 3,
			},
		});
	// console.log(data);

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const history = useHistory();

	const settings = {
		// dots: true,
		// centerPadding: "280px",
		centerMode: isMobile ? true : false,
		infinite: true,
		speed: 500,
		slidesToShow: isMobile ? 1 : 3,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: <Arrow left />,
		nextArrow: <Arrow />,
	};

	const handleChange1 = useCallback(
		(current: number) => {
			setActiveSlide1(current);
		},
		[setActiveSlide1],
	);

	const handleChange = useCallback(
		(current: number, next: number) => {
			setActiveSlide2(next);
		},
		[setActiveSlide2],
	);

	return (
		<>
			<HeaderContainer isMobile={isMobile}>
				<Wrapper>
					<HeroWrapper isMobile={isMobile}>
						<H1
							style={
								isMobile
									? { marginTop: 50 }
									: { marginTop: 166 }
							}
						>
							Make YOU, your priority.
						</H1>
						<StyledBody1
							isMobile={isMobile}
							style={
								isMobile
									? {
											marginTop: 25,
											marginBottom: 33,
									  }
									: {
											marginTop: 25,
											marginBottom: 50,
									  }
							}
						>
							Star employee, Businesswoman, Doting
							wife, Supermom, 2 AM friend. When
							will you make time for yourself?
							Prioritize your health with Nyaari’s
							easy, actionable, wellness plan
							created for busy moms.
						</StyledBody1>
						<Button
							handleButtonClick={() =>
								history.push("/signup")
							}
							width={isMobile ? "100%" : "249px"}
							height="60px"
						>
							Try For Free
						</Button>
						<div
							style={
								isMobile
									? { height: 50 }
									: { height: 0 }
							}
						></div>
					</HeroWrapper>
				</Wrapper>
				<StyledImage
					isTablet={isTablet}
					isMobile={isMobile}
					// width={638 + gap}
					src={home_hero}
					alt=""
				/>
			</HeaderContainer>

			<Wrapper
				style={
					isMobile
						? { padding: "54px 0 34px 0" }
						: { padding: "100px 0" }
				}
			>
				<FiveMHeader>
					<H2>5 Ms to a Fabulous You</H2>
					<StyledBody1
						isMobile={isMobile}
						style={
							isMobile
								? {
										marginBottom: 30,
										marginTop: 18,
										textAlign: "center",
								  }
								: {
										marginBottom: 46,
										marginTop: 25,
										textAlign: "center",
								  }
						}
					>
						Meals ~ Movement ~ Meditation ~ Mantra
						~ Masti <br /> Get a Mind-Body
						Makeover with a holistic plan that
						integrates into your lifestyle.
					</StyledBody1>
				</FiveMHeader>
				<Slider1
					changeActiveSlide={handleChange1}
				/>
				<FiveMIcons isMobile={isMobile}>
					<IconMeals
						active={activeSlide1 === 0}
					/>
					<IconMovement
						active={activeSlide1 === 1}
					/>
					<IconMeditation
						active={activeSlide1 === 2}
					/>
					<IconMantra
						active={activeSlide1 === 3}
					/>
					<IconMasti
						active={activeSlide1 === 4}
					/>
				</FiveMIcons>
			</Wrapper>

			<NyaariDetails>
				{/* <OrangeHeart
					isTablet={isTablet}
					isMobile={isMobile}
					src={orange_heart}
					alt=""
				/> */}
				<Wrapper>
					<NyaariHeader>
						<H2
							style={
								isMobile
									? { paddingTop: 50 }
									: { paddingTop: 100 }
							}
						>
							Why’s Nyaari worth your time & dime?
						</H2>
						<StyledBody1
							isMobile={isMobile}
							style={
								isMobile
									? {
											marginTop: 19,
											marginBottom: 59,
									  }
									: {
											marginTop: 25,
											marginBottom: 75,
									  }
							}
						>
							Nyaari is conceived keeping in mind
							the needs & challenges of busy moms.
							We understand that it is not the
							lack of desire or knowledge, but
							time and ease that prevents us from
							leading a healthy lifestyle. It is
							with this in mind; we’ve aim to
							offer a platform that is - Easy.
							Practical. Accessible.
						</StyledBody1>
					</NyaariHeader>
					<Details isTablet={isTablet}>
						<img src={Image_1} alt="" />
						<StyledBox
							marginTop={isMobile ? 20 : 0}
							marginLeft={isMobile ? 0 : 108}
							style={
								isMobile
									? { width: 300 }
									: { width: "100%" }
							}
						>
							<H3>Educate, delegate, elevate</H3>
							<StyledBody1
								isMobile={isMobile}
								marginTop={isMobile ? 8 : 27}
							>
								Run your home as a family, not a
								one-woman show. Nyaari makes it
								easy for you to delegate tasks and
								elevate your Me-time AND family
								time. Be it grocery shopping,
								cooking, or meal prep, our
								instructions are so detailed &amp;
								easy, even your boys can do it!
								Involving your family increases
								bonding and teaches your kids
								essential life skills. And you’ve
								no more excuses for skipping
								workouts or breakfast.
							</StyledBody1>
						</StyledBox>
					</Details>
					<Details1 isTablet={isTablet}>
						<Box
							marginTop={isMobile ? 20 : 0}
							marginRight={isMobile ? 0 : 110}
							style={
								isMobile
									? { width: 300 }
									: { width: "100%" }
							}
						>
							<H3>Ditch decision fatigue </H3>
							<StyledBody1
								isMobile={isMobile}
								marginTop={isMobile ? 8 : 27}
							>
								Aaj khane mein kya banau? Did you
								know that you make 226.7 decisions
								per day only about food choices?!
								Limit your mundane decisions and
								conserve your brain power for
								what’s important. Make memories,
								break that corporate glass
								ceiling! Simply open the app,
								search the ingredients you already
								have, and we’ll give you quick
								&amp; easy-to-cook recipes with
								easy tweaks and variations.
							</StyledBody1>
						</Box>
						<img src={Image_2} alt="" />
					</Details1>
					<Details isTablet={isTablet}>
						<img src={Image_3} alt="" />
						<StyledBox
							marginLeft={isMobile ? 0 : 108}
							marginTop={isMobile ? 20 : 0}
							style={
								isMobile
									? { width: 300 }
									: { width: "100%" }
							}
						>
							<H3>Start ‘em young</H3>
							<StyledBody1
								isMobile={isMobile}
								marginTop={isMobile ? 8 : 27}
							>
								Your kids may learn about
								nutrition and P.E. at school, but
								knowledge becomes wisdom only when
								you put it to practical use. Teach
								your kids about nutrition and
								healthy lifestyle choices through
								our resources. No matter what
								choices they make from our app,
								you can rest assured that they’ll
								be healthy – so it’s a win-win.
								Educate them about nutrition with
								our food insights, give them the
								power to create your family’s meal
								&amp; exercise plans, and get your
								Zen with them following our short
								and easy routines.
							</StyledBody1>
						</StyledBox>
					</Details>
				</Wrapper>
			</NyaariDetails>

			<Quote>
				<BlueHeart
					isMobile={isMobile}
					src={blue_heart}
					alt=""
				/>
				<Wrapper
					type="md"
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						textAlign: "center",
					}}
				>
					<H2
						style={
							isMobile
								? {
										marginBottom: 31,
										marginTop: 50,
								  }
								: {
										marginBottom: 46,
										marginTop: 95,
								  }
						}
					>
						{isMobile ? (
							<H2 fontColor="redRibbon">
								Busy supermom?{" "}
							</H2>
						) : (
							<>Busy supermom?</>
						)}{" "}
						Make YOUR health your priority, join
						our 5-in-1 Wellness Plan.
					</H2>
					<Button
						handleButtonClick={() =>
							history.push("/signup")
						}
						width={isMobile ? "241px" : "207px"}
						height="50px"
					>
						Try For Free
					</Button>
					<div
						style={
							isMobile
								? { height: 83 }
								: { height: 100 }
						}
					></div>
				</Wrapper>
			</Quote>

			<AboutUs
				style={
					isMobile
						? {
								background: `linear-gradient(to right,rgba(0,0,0,0.7),rgba(51,51,51,0.9)), url(${about_us_image}) no-repeat`,
								backgroundSize: "cover",
						  }
						: { background: "#e2f4f9" }
				}
				isMobile={isMobile}
			>
				<Wrapper>
					<HeroWrapper isMobile={isMobile}>
						<H2
							fontColor="redRibbon"
							style={
								isMobile
									? {
											marginTop: 7,
											display: "flex",
											alignItems: "center",
									  }
									: {
											marginTop: 24,
											display: "flex",
											alignItems: "center",
									  }
							}
						>
							Meet Our Experts
						</H2>
						<StyledBody1
							isMobile={isMobile}
							fontColor={
								isMobile ? "white" : "mineShaft"
							}
							style={
								isMobile
									? {
											marginTop: 12,
											marginBottom: 29,
									  }
									: {
											width: 508,
											marginTop: 25,
											marginBottom: 50,
									  }
							}
						>
							Nyaari gives you an actionable
							health plan AND expert support with
							integrating our plan in your
							lifestyles. Way beyond just 1-min
							gyaan and Insta reels! Get your
							queries answered and your fundas
							clear at one place, without internet
							info-overload. Our experts are women
							who are trailblazers in their field,
							who’ve battled adversities and
							thrived, women who walk the talk.
							And they’d love to guide you and
							cheer you on!
						</StyledBody1>
						<Button
							handleButtonClick={() =>
								history.push("/collaborators")
							}
							width={isMobile ? "234px" : "279px"}
							height="50px"
						>
							Meet Our Team
						</Button>
					</HeroWrapper>
				</Wrapper>
				{!isMobile && (
					<AboutusImg
						isTablet={isTablet}
						isMobile={isMobile}
						src={about_us_image}
						alt=""
					/>
				)}
			</AboutUs>

			{/* slider */}
			<div style={{ position: "relative" }}>
				<StoriesWrapper isMobile={isMobile}>
					<RedHeart1
						isMobile={isMobile}
						src={red_heart}
						alt=""
					/>
					<StoriesHeader>
						<H2>What’s Your Why?</H2>
						<StyledBody1
							isMobile={isMobile}
							style={
								isMobile
									? {
											marginTop: 15,
											marginBottom: 40,
									  }
									: {
											marginTop: 25,
											marginBottom: 40,
									  }
							}
						>
							The first step to success is knowing
							why it matters. If something is
							important to you, you’ll find a way.
							If not, you’ll find an excuse.
						</StyledBody1>
					</StoriesHeader>
					<div className="custom_slide2">
						<Slider
							{...settings}
							beforeChange={handleChange}
						>
							<Story
								isMobile={isMobile}
								active={activeSlide2 === 0}
							>
								<img src={double_quote} alt="" />
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? {
													marginTop: 20,
													marginBottom: 15,
											  }
											: {
													marginTop: 56,
													marginBottom: 100,
											  }
									}
								>
									I want to be fit & healthy,
									every single day of my life! I
									want to be free of medications,
									free of dependance on anyone
									when I grow old. For as long as
									I live, I want to be fit &
									healthy.
								</StyledBody1>
								<Body2>Riddhi</Body2>
							</Story>

							<Story
								isMobile={isMobile}
								active={activeSlide2 === 1}
							>
								<img src={double_quote} alt="" />
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? {
													marginTop: 20,
													marginBottom: 15,
											  }
											: {
													marginTop: 56,
													marginBottom: 100,
											  }
									}
								>
									­As my kids grow, I want to keep
									up with them and be there all
									the way. I want to keep working
									and giving back to the community
									and that’s only possible if I’m
									healthy.
								</StyledBody1>
								<Body2>Rashmi</Body2>
							</Story>
							<Story
								isMobile={isMobile}
								active={activeSlide2 === 2}
							>
								<img src={double_quote} alt="" />
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? {
													marginTop: 20,
													marginBottom: 15,
											  }
											: {
													marginTop: 56,
													marginBottom: 100,
											  }
									}
								>
									I want to have the mental and
									physical energy to do all the
									things I enjoy. And, there’s a
									wee bit of vanity. I want to
									enjoy my shopping experience and
									look fabulous in fitted outfits!
								</StyledBody1>
								<Body2>Nirzari</Body2>
							</Story>
							<Story
								isMobile={isMobile}
								active={activeSlide2 === 3}
							>
								<img src={double_quote} alt="" />
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? {
													marginTop: 20,
													marginBottom: 15,
											  }
											: {
													marginTop: 56,
													marginBottom: 100,
											  }
									}
								>
									­I started for health reasons,
									but now I am addicted to a
									healthy lifestyle, it gives me
									immense confidence! My
									16-year-old leads a super
									healthy lifestyle, he has even
									learned to cook healthy meals.
								</StyledBody1>
								<Body2>Khadija</Body2>
							</Story>
							<Story
								isMobile={isMobile}
								active={activeSlide2 === 4}
							>
								<img src={double_quote} alt="" />
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? {
													marginTop: 20,
													marginBottom: 15,
											  }
											: {
													marginTop: 56,
													marginBottom: 100,
											  }
									}
								>
									A better quality of life now and
									when I get older is my why. My
									choices will teach my kids how
									to be healthy so that they can
									enjoy their life to its fullest.
								</StyledBody1>
								<Body2>Priti</Body2>
							</Story>
							<Story
								isMobile={isMobile}
								active={activeSlide2 === 5}
							>
								<img src={double_quote} alt="" />
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? {
													marginTop: 20,
													marginBottom: 15,
											  }
											: {
													marginTop: 56,
													marginBottom: 100,
											  }
									}
								>
									The MAIN reason I want to be fit
									& healthy is to avoid taking
									unnecessary medications. With
									all the viruses & infections
									spreading across the world, I
									want to have a stronger immune
									system.
								</StyledBody1>
								<Body2>Rina</Body2>
							</Story>
							<Story
								isMobile={isMobile}
								active={activeSlide2 === 6}
							>
								<img src={double_quote} alt="" />
								<StyledBody1
									isMobile={isMobile}
									style={
										isMobile
											? {
													marginTop: 20,
													marginBottom: 15,
											  }
											: {
													marginTop: 56,
													marginBottom: 100,
											  }
									}
								>
									­What parents do, kids emulate!
									I want to be a good role model
									for my kids and family. And I
									want to feel confident,
									attractive & beautiful.
								</StyledBody1>
								<Body2>Shivi</Body2>
							</Story>
						</Slider>
					</div>
				</StoriesWrapper>
			</div>

			<FreemiumGyan isMobile={isMobile}>
				<Wrapper>
					<FreemiumGyanHeader isMobile={isMobile}>
						<H2>Freemium</H2>
						<StyledBody1
							isMobile={isMobile}
							style={
								isMobile
									? {
											marginTop: 20,
											marginBottom: 34,
									  }
									: {
											marginTop: 20,
											marginBottom: 30,
									  }
							}
						>
							Multitasking Supermom? Get going
							with Nyaari’s easy, holistic health
							plan.
							<ul
								style={{
									paddingLeft: 20,
									marginTop: 0,
								}}
							>
								<li>
									ONE plan for your
									physical+mental wellbeing.
								</li>
								<li>
									Easy to adopt into your
									lifestyle.
								</li>
								<li>
									Support & motivation from moms
									around the world.
								</li>
							</ul>
						</StyledBody1>
						{!isMobile && (
							<Button
								handleButtonClick={() =>
									history.push("/freemium-gyan")
								}
								width={
									isMobile ? "235px" : "168px"
								}
								height="50px"
							>
								View All Blog
							</Button>
						)}
					</FreemiumGyanHeader>
				</Wrapper>
				<ArticleContainer
					isTablet={isTablet}
					isMobile={isMobile}
				>
					<ShowIf value={loading}>
						<Loader />
					</ShowIf>
					{!loading && !error && (
						<ShowIf value={data?.blogs?.length}>
							<ScrollContainer
								className="scroll-container"
								horizontal
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{data?.blogs?.map((blog) => (
									<Article
										key={blog!.id}
										isMobile={isMobile}
									>
										<ArticleImg
											isMobile={isMobile}
											src={
												blog?.banner_image?.url
											}
											alt=""
										/>
										<ArticleInfo1
											isMobile={isMobile}
										>
											<div
												style={
													isMobile
														? { fontSize: 12 }
														: {}
												}
											>
												<Tag background="#FAE2E8">
													{
														blog?.blog_category
															?.title
													}
												</Tag>
											</div>
											<LabeledIcon
												icon={
													<IconUser color="redRibbon" />
												}
												text={
													blog?.collaborator
														?.name!
												}
											/>
											<LabeledIcon
												icon={<IconCalender />}
												text={getDate(
													blog!.createdAt,
												)}
											/>
										</ArticleInfo1>
										<Subtitle1
											style={{
												cursor: "pointer",
											}}
											marginTop="16px"
											onClick={() =>
												history.push(
													`/freemium-gyan/${blog?.id}`,
												)
											}
										>
											{blog?.title}
										</Subtitle1>
									</Article>
								))}
							</ScrollContainer>
						</ShowIf>
					)}
				</ArticleContainer>
				{isMobile && (
					<Box margin="0 20px">
						<Button
							handleButtonClick={() =>
								history.push("/freemium-gyan")
							}
							width={isMobile ? "235px" : "168px"}
							height="50px"
							marginTop="36px"
						>
							View All Blog
						</Button>
					</Box>
				)}
			</FreemiumGyan>

			<Features>
				<RedHeart
					isMobile={isMobile}
					src={red_heart}
					alt=""
				/>
				<FeaturesWrapper isMobile={isMobile}>
					<H3
						style={
							isMobile
								? {
										textAlign: "center",
										marginTop: 50,
										marginBottom: 30,
								  }
								: {
										textAlign: "center",
										marginTop: 103,
										marginBottom: 55,
								  }
						}
					>
						{isMobile ? (
							<H3 fontColor="redRibbon">
								Multitasking Supermom?{" "}
							</H3>
						) : (
							<>Multitasking Supermom?</>
						)}{" "}
						Get going with Nyaari’s easy, holistic
						health plan.
					</H3>
					<BulletList
						num={2}
						items={[
							<BulletText>
								ONE plan for your physical+mental
								wellbeing.
							</BulletText>,
							<BulletText>
								Created & vetted by qualified
								professionals.
							</BulletText>,
							<BulletText>
								Easy to adopt into your lifestyle.
							</BulletText>,
							<BulletText>
								Support & motivation from moms
								around the world.
							</BulletText>,
						]}
					/>
					<div
						style={
							isMobile
								? { height: 40 }
								: { height: 50 }
						}
					></div>
					<Button
						handleButtonClick={() =>
							history.push("/signup")
						}
						width={isMobile ? "100%" : "390px"}
						height="50px"
					>
						Try It For Free
					</Button>
					<Caption
						style={
							isMobile
								? {
										textAlign: "center",
										marginTop: 12,
										marginBottom: 50,
								  }
								: {
										textAlign: "center",
										marginTop: 16,
										marginBottom: 100,
								  }
						}
						fontColor="boulder"
					>
						No Credit Card Required
					</Caption>
				</FeaturesWrapper>
			</Features>

			<Subscribe />
			<Footer />
		</>
	);
};

export default HomePage;
