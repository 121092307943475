import React from "react";
import { Route, Switch } from "react-router-dom";
import PremiumBlog from "../../components/PremiumBlog/PremiumBlog";
import PremiumBlogDetails from "../../components/PremiumBlogDetails/PremiumBlogDetails";
import ProtectedSwitch from "../../components/ProtectedSwitch/ProtectedSwitch";

const PremiumBlogPage = () => {
	return (
		<ProtectedSwitch>
			<Route path="/dashboard/premium-gyan">
				<PremiumBlog />
				<Switch>
					<Route
						path="/dashboard/premium-gyan/:id"
						exact
					>
						<PremiumBlogDetails />
					</Route>
				</Switch>
			</Route>
		</ProtectedSwitch>
	);
};

export default PremiumBlogPage;
