import { FC } from "react";
import styled from "styled-components";
import {
	Wrapper,
	DS,
} from "@workshore/nyaari-ds";

import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import bg from "../../assets/images/login_bg.png";
import water_mark from "../../assets/images/water_mark.svg";

const HeaderContainer = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	overflow: hidden;
`;

const HeroWrapper = styled.div<{
	isMobile: boolean;
}>`
	margin-left: auto;
	width: 500px;
	${({ isMobile }) =>
		isMobile &&
		`
    width: 100%;
    margin-right: 0px;
  `};
`;

const StyledWaterMark = styled.img`
	position: absolute;
	right: -15px;
	top: 89px;
	z-index: -1;
	@media screen and (max-width: ${DS.breakPoints
			.m}px) {
		display: none;
	}
`;

const StyledImage = styled.img<{
	isMobile: boolean;
	isTablet: boolean;
}>`
	height: 981px;
	width: 50vw;
	position: absolute;
	left: 0;
	top: 0;
	object-fit: cover;

	${({ isTablet }) =>
		isTablet &&
		`
    width: calc(100% - 638px);
  `};

	${({ isMobile }) =>
		isMobile &&
		`
    display: none;
  `};
`;

interface Props {
	isHeart?: boolean;
}

const SplitView1: FC<Props> = ({
	isHeart,
	children,
}) => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	return (
		<HeaderContainer>
			{isHeart && (
				<StyledWaterMark
					src={water_mark}
					alt="water mark"
				/>
			)}
			<Wrapper>
				<HeroWrapper isMobile={isMobile}>
					{children}
				</HeroWrapper>
			</Wrapper>
			<StyledImage
				isTablet={isTablet}
				isMobile={isMobile}
				src={bg}
				alt=""
			/>
		</HeaderContainer>
	);
};

export default SplitView1;
