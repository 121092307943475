import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconEdit: FC<{
	color?: keyof typeof DS.colors;
}> = ({ color = "redRibbon" }) => {
	return (
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="22.112"
				height="22.112"
				viewBox="0 0 22.112 22.112"
			>
				<g
					id="Group_10089"
					data-name="Group 10089"
					transform="translate(1 1.188)"
				>
					<g id="edit">
						<path
							id="Path_1467"
							data-name="Path 1467"
							d="M10.912,4H3.98A1.98,1.98,0,0,0,2,5.98V19.843a1.98,1.98,0,0,0,1.98,1.98H17.843a1.98,1.98,0,0,0,1.98-1.98V12.912"
							transform="translate(-2 -1.899)"
							fill="none"
							stroke={DS.colors[color]}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
						<path
							id="Path_1468"
							data-name="Path 1468"
							d="M18.4,2.494a2.1,2.1,0,0,1,2.971,2.971l-9.407,9.407L8,15.862,8.99,11.9Z"
							transform="translate(-2.059 -1.879)"
							fill="none"
							stroke={DS.colors[color]}
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default IconEdit;
