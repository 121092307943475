import {
	Box,
	Wrapper,
	H3,
	Spacing,
	Body2,
	Input,
	ListItem,
	Body1,
	Caption,
	DS,
	FlexBetween,
	FlexCenter,
} from "@workshore/nyaari-ds";
import React, {
	useCallback,
	useState,
} from "react";
import {
	Redirect,
	useParams,
} from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import {
	useGetPrioritiesQuery,
	useUpdateMyPrioritiesMutation,
} from "../../hooks/graphql/graphql";
import useRoute from "../../hooks/useRoute/useRoute";
import storage from "../../services/storage";
import {
	getEmptyIndexOnPriorities,
	minimalPriorityType,
	priorityFactory,
	replacePriority,
} from "../../utils/priorityHelper";
import IconAdd from "../Icons/IconAdd";
import IconRemove from "../Icons/IconRemove";
import IconSearch from "../Icons/IconSearch";
import Loader from "../Loader/Loader";
import ShowIf from "../ShowIf/ShowIf";
import TypedIcon from "../TypedIcon/TypedIcon";

const StyledButton = styled(Body1)`
	cursor: pointer;
`;

const EditPriorities = () => {
	const { addToast } = useToasts();
	const { goTo } = useRoute();
	const params = useParams<{ index: string }>();
	const [filledIndex, setFilledIndex] =
		useState(false);
	const allPriorities = useGetPrioritiesQuery({
		fetchPolicy: "no-cache",
	});
	const updateMyPriorities =
		useUpdateMyPrioritiesMutation();
	const [keyword, setKeyword] = useState("");
	const [localPriority, setLocalPriority] =
		useState(
			storage.get<minimalPriorityType[]>(
				"myPriorities",
				[],
			).value,
		);

	const selectPriority = useCallback(
		(data: minimalPriorityType) => {
			const newIndex = filledIndex
				? getEmptyIndexOnPriorities(localPriority)
				: parseInt(params.index) || 0;
			if (newIndex !== -1) {
				setLocalPriority(
					replacePriority(
						newIndex,
						data,
						localPriority,
					),
				);
			} else {
				addToast(
					"You can select 3 priorities at once",
					{
						appearance: "warning",
						autoDismiss: true,
					},
				);
			}
			setFilledIndex(true);
		},
		[
			localPriority,
			filledIndex,
			params,
			addToast,
		],
	);
	const removePriority = useCallback(
		(data: minimalPriorityType) => {
			const findIndex = localPriority
				.map((d, index) =>
					d.title === data.title &&
					d.description === data.description
						? index
						: null,
				)
				.filter((i) => i !== null);
			if (findIndex.length)
				setLocalPriority(
					replacePriority(
						findIndex[0] || 0,
						{
							description: "",
							title: "",
							type: "",
							checkin: null,
						},
						localPriority,
					),
				);

			setFilledIndex(true);
		},
		[localPriority],
	);
	const filterPriorities = useCallback(() => {
		let finalPriorities = priorityFactory(
			(
				(allPriorities.data?.priorities ||
					[]) as any as minimalPriorityType[]
			).map((d) => ({
				...d,
				type: (d as any).category,
			})),
			allPriorities.data?.priorities || [],
		).filter(
			(d) =>
				d.title
					.toLocaleLowerCase()
					.includes(keyword) ||
				d.description
					.toLocaleLowerCase()
					.includes(keyword),
		);

		return finalPriorities.map((d) => ({
			...d,
			selected: localPriority.filter(
				(j) =>
					j.title === d.title &&
					j.description === d.description,
			).length
				? true
				: false,
		}));
	}, [allPriorities, keyword, localPriority]);

	const updatePriorities = useCallback(() => {
		if (updateMyPriorities[1].loading) return;
		updateMyPriorities[0]({
			variables: {
				priorities: localPriority.map((d) => ({
					title: d.title,
					description: d.description,
					type: d.type,
					checkin: d.checkin,
				})),
			},
		})
			.then((res) => {
				console.log(res);
				addToast("You've set your priorities", {
					autoDismiss: true,
					appearance: "success",
				});
				goTo("/dashboard/priorities");
			})
			.catch((e) => {
				console.log(e);
			});
	}, [
		localPriority,
		updateMyPriorities,
		goTo,
		addToast,
	]);

	if (!localPriority.length) {
		return (
			<Redirect to="/dashboard/priorities" />
		);
	}
	return (
		<>
			<Box paddingTop={50} paddingBottom={90}>
				<Wrapper type="fluid">
					<FlexBetween
						style={{ alignItems: "center" }}
					>
						<H3>Choose Your Top 3 Priorities</H3>
						<ShowIf value={filledIndex}>
							<StyledButton
								fontColor="redRibbon"
								onClick={updatePriorities}
							>
								{updateMyPriorities[1].loading
									? "Updating..."
									: "Commit"}
							</StyledButton>
						</ShowIf>
					</FlexBetween>
					<Spacing type="s8" />
					<Body2
						fontColor="boulder"
						fontWeight="normal"
					>
						Revisit your priorities every week.
					</Body2>
					<Spacing type="s16" />
					<ShowIf
						value={
							allPriorities.loading ||
							!allPriorities.data
						}
					>
						<Loader>
							<Body2>
								Loading all priorities..
							</Body2>
						</Loader>
					</ShowIf>
					<ShowIf value={!allPriorities.loading}>
						<Input
							leftIcon={<IconSearch />}
							placeholder="Search"
							value={keyword}
							handleInputChange={(keyword) => {
								if (
									updateMyPriorities[1].loading
								) {
									return;
								}
								setKeyword(
									keyword.target.value.toLocaleLowerCase(),
								);
							}}
						/>
						<Spacing type="s24" />
						{filterPriorities().map(
							(data, index) => {
								return (
									<Box
										paddingTop={DS.spacing.s16}
										key={index}
										style={{
											cursor: "pointer",
										}}
										onClick={() => {
											if (
												updateMyPriorities[1]
													.loading
											) {
												return;
											}
											if (!data.selected) {
												selectPriority({
													type: data.type,
													title: data.title,
													description:
														data.description,
													checkin: data.checkin,
												});
											} else {
												removePriority({
													type: data.type,
													title: data.title,
													description:
														data.description,
													checkin: data.checkin,
												});
											}
										}}
									>
										<ListItem
											leftIcon={
												<TypedIcon
													type={data.type || ""}
													active={data.selected}
												/>
											}
											title={
												<Body1>
													{data.title}
												</Body1>
											}
											subTitle={
												<Caption fontColor="boulder">
													{data.description}
												</Caption>
											}
											rightIcon={
												data.selected ? (
													<IconRemove />
												) : (
													<IconAdd />
												)
											}
										/>

										<Box
											paddingBottom={
												DS.spacing.s16
											}
										/>
										<div
											style={{
												height: 1,
												backgroundColor:
													DS.colors.mercury,
											}}
										/>
									</Box>
								);
							},
						)}
						{filterPriorities().length === 0 && (
							<FlexCenter>
								<Body1>
									No Priorities Found with{" "}
									{keyword}
								</Body1>
							</FlexCenter>
						)}
					</ShowIf>
				</Wrapper>
			</Box>
		</>
	);
};

export default EditPriorities;
