import { useScreenWidth } from "@tinyd/usescreensize";
import {
	Body1,
	BottomNavigation,
	Box,
	DS,
	Spacing,
} from "@workshore/nyaari-ds";
import React, {
	FC,
	useCallback,
	useEffect,
	useState,
} from "react";
import {
	Link,
	useHistory,
} from "react-router-dom";
import styled from "styled-components";
import IconRightArrow from "../Icons/IconRightArrow";

export type DashboardMenuItem = {
	url: string;
	text: string;
	icon: JSX.Element;
	activeColor?: keyof typeof DS.colors;
	color: keyof typeof DS.colors;
	activeIcon?: JSX.Element;
	onClick?: () => void;
};

const DashboardMenuContainer = styled.div`
	width: 100%;
`;

const MenuItemContainer = styled.div`
	display: flex;
	align-items: center;
	& * {
		transition: all ${DS.transition.fast};
	}
`;

const DashboardMenu: FC<{
	items: (DashboardMenuItem & {
		subMenu?: DashboardMenuItem[];
	})[];
}> = ({ items }) => {
	const {
		location: { pathname },
		listen,
	} = useHistory();
	const [url, setUrl] = useState(
		pathname.replace(/\/$/, ""),
	);
	const hasActiveLink = useCallback(
		(
			link: string,
			isSubMenu: boolean = false,
		) => {
			if (
				!isSubMenu
					? url === link
					: url.includes(link)
			) {
				return true;
			}
			return false;
		},
		[url],
	);
	useEffect(() => {
		const sub = listen((location) => {
			setUrl(
				location.pathname.replace(/\/$/, ""),
			);
		});
		return () => sub();
	}, [listen]);
	return (
		<DashboardMenuContainer>
			{items.map(
				(
					{
						url,
						text,
						icon,
						activeIcon,
						subMenu = [],
						color,
						activeColor,
						onClick,
					},
					index,
				) => {
					const isActive = hasActiveLink(
						url,
						subMenu.length > 0,
					);
					return (
						<Box
							key={index}
							marginBottom={DS.spacing.s24}
						>
							<MenuItemContainer>
								{!isActive
									? icon
									: activeIcon || icon}
								<Spacing type="s24" />
								<Link
									to={
										subMenu.length
											? subMenu[0].url
											: url
									}
									onClick={onClick}
								>
									<Body1
										fontColor={
											isActive
												? activeColor || color
												: color
										}
										fontWeight={
											isActive ? 600 : 500
										}
									>
										{text}
									</Body1>
								</Link>
								<div style={{ flex: 1 }}></div>
								<div
									style={{
										height: 50,
										width: 4,
										backgroundColor: isActive
											? DS.colors[
													activeColor || color
											  ]
											: "transparent",
									}}
								></div>
							</MenuItemContainer>
							{subMenu.length && isActive ? (
								<>
									<Spacing type="s32" />
									{subMenu.map(
										(
											{
												url,
												text,
												icon,
												activeIcon,
												color,
												activeColor,
											},
											index,
										) => {
											const isActive =
												hasActiveLink(url);
											return (
												<Box
													key={index}
													marginBottom={32}
													paddingLeft={50}
												>
													<MenuItemContainer>
														{!isActive
															? icon
															: activeIcon ||
															  icon}
														<Spacing type="s24" />
														<Link to={url}>
															<Body1
																fontColor={
																	isActive
																		? activeColor ||
																		  color
																		: color
																}
																fontWeight={
																	isActive
																		? 600
																		: 500
																}
															>
																{text}
															</Body1>
														</Link>
														{isActive ? (
															<>
																<div
																	style={{
																		flex: 1,
																	}}
																></div>
																<div>
																	<IconRightArrow />
																</div>
																<Spacing type="s24" />
															</>
														) : null}
													</MenuItemContainer>
												</Box>
											);
										},
									)}
								</>
							) : null}
						</Box>
					);
				},
			)}
		</DashboardMenuContainer>
	);
};

export default DashboardMenu;

const BottomLink = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const DashboardBottomMenu: FC<{
	items: DashboardMenuItem[];
}> = ({ items }) => {
	const screenWidth = useScreenWidth();
	const isSmall = screenWidth < 725;
	const {
		location: { pathname },
		listen,
	} = useHistory();
	const [url, setUrl] = useState(
		pathname.replace(/\/$/, ""),
	);
	const hasActiveLink = useCallback(
		(
			link: string,
			isSubMenu: boolean = false,
		) => {
			if (
				!isSubMenu
					? url === link
					: url.includes(link)
			) {
				return true;
			}
			return false;
		},
		[url],
	);
	useEffect(() => {
		const sub = listen((location) => {
			setUrl(
				location.pathname.replace(/\/$/, ""),
			);
		});
		return () => sub();
	}, [listen]);
	return (
		<BottomNavigation
			menuItems={items.map(
				(
					{
						url,
						icon,
						activeIcon,
						text,
						activeColor,
						color,
					},
					index,
				) => {
					const isActive = hasActiveLink(
						url,
						true,
					);
					return (
						<BottomLink to={url} key={index}>
							{!isSmall ? (
								<>
									{isActive
										? activeIcon || icon
										: icon}
									<Spacing type="s16" />
									<Body1
										fontColor={
											isActive
												? activeColor || color
												: color
										}
									>
										{text}
									</Body1>
								</>
							) : isActive ? (
								activeIcon || icon
							) : (
								icon
							)}
						</BottomLink>
					);
				},
			)}
		/>
	);
};
