import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

const IconRightArrow: FC<{
	color?: keyof typeof DS.colors;
}> = ({ color = "mineShaft" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="6.854"
			height="11.708"
			viewBox="0 0 6.854 11.708"
		>
			<g
				id="Group_26"
				data-name="Group 26"
				transform="translate(-9.685 1.414)"
			>
				<g
					id="Icon_feather-arrow-right"
					data-name="Icon feather-arrow-right"
					transform="translate(11.099)"
				>
					<path
						id="Path_56"
						data-name="Path 56"
						d="M18,7.5l4.44,4.44L18,16.379"
						transform="translate(-18 -7.5)"
						fill="none"
						stroke={DS.colors[color]}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconRightArrow;
