import { FC } from "react";
import ReactPlayer from "react-player";
import "./style.css";

const VideoPlayer1: FC<{ url: string }> = ({
	url,
}) => {
	return (
		<div className="wrapper">
			<ReactPlayer
				controls
				width="100%"
				height="100%"
				className="react-player"
				url={url}
			/>
		</div>
	);
};

export default VideoPlayer1;
