import {
	forwardRef,
	ForwardRefRenderFunction,
	CSSProperties,
} from "react";
import styled from "styled-components";
import Calendar, {
	OnChangeDateCallback,
	OnChangeDateRangeCallback,
} from "react-calendar";
import { DS } from "@workshore/nyaari-ds";
import "react-calendar/dist/Calendar.css";
import { Button } from "@workshore/nyaari-ds";
import { useScreenWidth } from "@tinyd/usescreensize";

const StyledDiv = styled.div<{
	isMobile: boolean;
}>`
	width: 335px;
	box-shadow: 0 3px 6px #00000016;
	padding: 20px;

	${({ isMobile }) =>
		isMobile &&
		`
		width: calc(100% - 40px);
	`}
`;

const StyledCalendar = styled(Calendar)<{
	accentColor: keyof typeof DS.colors;
}>`
	font-family: ${DS.fontFamilies.primary};
	color: ${DS.colors.mineShaft};
	border: none !important;
	.react-calendar__navigation {
		margin-bottom: 0;
	}
	.react-calendar__month-view__weekdays__weekday {
		background-color: ${DS.colors.wildSand};
	}
	.react-calendar__month-view__weekdays__weekday
		abbr[title] {
		text-decoration: none;
	}
	.react-calendar__month-view__weekdays__weekday {
		margin: 2px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.react-calendar__month-view__days__day {
		border: 1px solid ${DS.colors.wildSand};
		margin: 2px;
	}
	.react-calendar__month-view__days {
		display: grid !important;
		grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

		.react-calendar__tile {
			max-width: initial !important;
		}
	}
	.react-calendar__month-view__weekdays {
		display: grid !important;
		grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

		.react-calendar__tile {
			max-width: initial !important;
		}
	}
	.react-calendar__month-view__days__day--weekend {
		color: ${DS.colors.mineShaft};
	}
	/* current date */
	.react-calendar__tile--now {
		background: white;
	}
	.react-calendar__tile--now:hover {
		background: white;
	}
	.react-calendar__tile--active {
		background: ${({ accentColor }) =>
			accentColor && DS.colors[accentColor]};
		color: ${DS.colors.mineShaft};
	}
	.react-calendar__tile--active:enabled:hover,
	.react-calendar__tile--active:enabled:focus {
		background: ${({ accentColor }) =>
			accentColor && DS.colors[accentColor]};
	}
	.react-calendar__month-view__weekdays {
		font-weight: normal;
	}
`;

interface Props {
	style?: CSSProperties;
	date: Date | Date[] | null | undefined;
	setDate:
		| OnChangeDateCallback
		| OnChangeDateRangeCallback
		| undefined;
	handleButtonClick?:
		| ((
				e: React.MouseEvent<
					HTMLButtonElement | MouseEvent,
					MouseEvent
				>,
		  ) => void)
		| undefined;
	accentColor: keyof typeof DS.colors;
	isSubmitting?: boolean;
	children?: JSX.Element;
}

const CustomCalendar: ForwardRefRenderFunction<
	HTMLDivElement,
	Props
> = (
	{
		style,
		date,
		setDate,
		handleButtonClick,
		accentColor = "meals",
		isSubmitting,
		children,
	},
	ref,
) => {
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth <= 578;
	return (
		<StyledDiv
			ref={ref}
			style={style}
			isMobile={isMobile}
		>
			<StyledCalendar
				accentColor={accentColor}
				onChange={setDate}
				value={date}
			/>
			<div
				style={{
					marginTop: 16,
					marginBottom: 16,
				}}
			>
				{children}
			</div>
			<Button
				handleButtonClick={handleButtonClick}
			>
				{isSubmitting
					? "Submitting..."
					: "Add to the Weekly Planner"}
			</Button>
		</StyledDiv>
	);
};

export default forwardRef(CustomCalendar);
