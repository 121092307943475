import {
	Body1,
	Body2,
	Box,
	Button,
	DS,
	FlexCenter,
	H2,
	H3,
	Spacing,
	Tag,
	Wrapper,
} from "@workshore/nyaari-ds";
import React, {
	Fragment,
	useEffect,
	useRef,
	useState,
} from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import LayoutContainer from "../LayoutContainer/LayoutContainer";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
	GetAllPremiumBlogsQuery,
	useGetAllPremiumBlogsQuery,
	useGetBlogCategoriesQuery,
	useGetPremiumBlogCategoriesQuery,
} from "../../hooks/graphql/graphql";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import getDate from "../../utils/getDate";
import IconCalender from "../Icons/IconCalender";
import IconUser from "../Icons/IconUser";
import LabeledIcon from "../LabeledIcon/LabeledIcon";
import Loader from "../Loader/Loader";
import Seperator from "../Seperator/Seperator";
import ShowIf from "../ShowIf/ShowIf";
import { useElementWidth } from "../../hooks/useElementSize/useElementSize";

const Header = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 80px;
`;

// main article
const MainArticle = styled.div`
	width: 603px;
	margin-right: 50px;

	@media screen and (max-width: ${DS.breakPoints
			.l}px) {
		width: 548px;
	}
`;

const StyledImg = styled.img`
	height: 356px;
	width: 603px;
	margin-bottom: 25px;
	object-fit: cover;
	border-radius: 5px;

	@media screen and (max-width: ${DS.breakPoints
			.l}px) {
		width: 548px;
	}
`;

// secondary article
const SecondaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const SecondaryArticle = styled.div`
	display: flex;
	flex-direction: row;
`;

const SecondaryImageDiv = styled.div`
	height: 165px;
	width: 232px;
	margin-right: 28px;
`;

const StyledImg1 = styled.img`
	height: 165px;
	width: 232px;
	object-fit: cover;
	border-radius: 5px;
`;

const SecondaryContent = styled.div`
	display: flex;
	flex-direction: column;
`;

const SecondaryArticleInfo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	> * {
		margin-right: 27px;
	}
`;

const SecondaryBody1 = styled(Body1)<{
	isMobile: boolean;
}>`
	line-height: 24px;

	${({ isMobile }) =>
		isMobile &&
		`
    line-height: 23px;
  `};
`;

const StyledBody2 = styled(Body2)`
	line-height: 26px;
	font-weight: 400;
`;

// article
const Article = styled.div<{ isMobile: boolean }>`
	display: flex;
	flex-direction: row;
	margin: 24px 0;

	${({ isMobile }) =>
		isMobile &&
		`
    margin: 20px 0;
    flex-direction: column;
  `};
`;

const ArticleContent = styled.div<{
	isMobile: boolean;
}>`
	width: 658px;

	${({ isMobile }) =>
		isMobile &&
		`
    width: 100%;
  `};
`;

const ArticleInfo = styled.div<{
	isMobile: boolean;
	isTablet: boolean;
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin-right: 35px;
	}

	${({ isTablet }) =>
		isTablet &&
		`
    > * {
      margin-right: 15px;
    }
  `};

	${({ isMobile }) =>
		isMobile &&
		`
    margin-top: 17px;
    >*{
			font-size: 12px;
      margin-right: 10px;
    }
  `};
`;

const ArticleImageDiv = styled.div<{
	isTablet: boolean;
	isMobile: boolean;
}>`
	width: 508px;
	margin-right: 72px;

	${({ isTablet }) =>
		isTablet &&
		`
    margin-right: 40px;
  `};

	${({ isMobile }) =>
		isMobile &&
		`
		height: 197px;
    margin-right: 0px;
    width: 100%;
  `};
`;

const StyledImg2 = styled.img<{
	isMobile: boolean;
}>`
	border-radius: 5px;
	width: 508px;
	/* height: 298px; */
	object-fit: cover;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 197px;
    width: 100%;
  `};
`;

const StyledBody1 = styled(Body1)<{
	isMobile: boolean;
}>`
	line-height: 36px;
	font-weight: 400;

	${({ isMobile }) =>
		isMobile &&
		`
    line-height: 23px;
  `};
`;

// tags
const Tags = styled.div<{ isMobile: boolean }>`
	display: flex;
	justify-content: row;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 50px;
	> * {
		padding: 7px 24px;
		margin-right: 38px;
	}

	${({ isMobile }) =>
		isMobile &&
		`
    margin-top: 14px;
    margin-bottom: 23px;
    > *{
			font-size: 12px;
			padding: 4px 10px;
      margin-right: 11px;
    }
  `};
`;

const PremiumBlog = () => {
	const ref = useRef<HTMLDivElement>(null);

	const width = useElementWidth(ref);

	const isSmall = width <= 750;

	const [initialCategory, setInitialCategory] =
		useState<string[] | undefined>([]);
	const [
		selectedCategories,
		setSelectedCategories,
	] = useState<string[] | undefined>([]);
	const [filteredData, setFilteredData] =
		useState<
			GetAllPremiumBlogsQuery | undefined
		>();
	const [allActive, setAllActive] =
		useState(true);

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const history = useHistory();

	const { data, loading, error, refetch } =
		useGetAllPremiumBlogsQuery({
			nextFetchPolicy: "no-cache",
		});
	const blogCategories =
		useGetPremiumBlogCategoriesQuery();

	useEffect(() => {
		setFilteredData(data);
	}, [data]);

	// initial categories
	useEffect(() => {
		const categories =
			blogCategories?.data?.premiumBlogCategories?.map(
				(blog) => blog!.id,
			);
		setInitialCategory(categories);
		setSelectedCategories(categories);
	}, [blogCategories]);

	// filtering data based on suggestions
	useEffect(() => {
		if (
			selectedCategories &&
			data?.premiumBlogs
		) {
			const premiumBlogs = (
				data?.premiumBlogs as any
			).filter((blog: any) =>
				blog?.blog_category
					? selectedCategories.includes(
							blog?.blog_category.id,
					  )
					: selectedCategories.length ===
					  (blogCategories.data
							?.premiumBlogCategories?.length ||
							0),
			);
			// console.log(blogs);
			const filtered = { premiumBlogs };
			setFilteredData(filtered);
		}
	}, [selectedCategories, data, blogCategories]);

	// all tag select
	const handleAllSelect = () => {
		setAllActive(true);
		setSelectedCategories(initialCategory);
		setFilteredData(data);
	};

	// tag select
	const handleSelect = (id: any) => {
		setAllActive(false);
		if (allActive) {
			setSelectedCategories([id]);
		} else if (selectedCategories) {
			if (
				selectedCategories.includes(id) &&
				selectedCategories.length === 1
			) {
				setAllActive(true);
				setSelectedCategories(initialCategory);
			} else if (
				selectedCategories.includes(id)
			) {
				const updated = selectedCategories.filter(
					(category) => category !== id,
				);
				setSelectedCategories(updated);
			} else {
				setSelectedCategories([
					...selectedCategories,
					id,
				]);
			}
		}
	};

	if (!filteredData)
		return (
			<ShowIf value={loading}>
				<Loader>
					<Body1>Loading Premium Gyan..</Body1>
				</Loader>
			</ShowIf>
		);

	return (
		<LayoutContainer
			ref={ref}
			backgroundColor="wildSand"
		>
			<ShowIf value={loading}>
				<Loader>
					<Body1>Loading Premium Gyan..</Body1>
				</Loader>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<FlexCenter>
						<Body1>Failed to load Blogs</Body1>
					</FlexCenter>
					<Spacing type="s24" />
					<FlexCenter>
						<Button width="100px">
							<Body2
								as="span"
								fontColor="white"
								onClick={() => {
									refetch();
								}}
							>
								Retry
							</Body2>
						</Button>
					</FlexCenter>
				</div>
			</ShowIf>
			<Box
				paddingTop={isTablet ? 15 : 50}
				paddingBottom={90}
			>
				<ShowIf value={!loading && !error}>
					<Wrapper type="fluid">
						<div
							style={{
								display: isSmall
									? "block"
									: "flex",
								alignItems: "center",
							}}
						>
							<H3>Freemium Gyaan</H3>
						</div>
					</Wrapper>
					<Wrapper type="fluid">
						<ScrollContainer
							className="scroll-container"
							horizontal
						>
							<ShowIf
								value={
									blogCategories?.data
										?.premiumBlogCategories
										?.length
								}
							>
								<Tags isMobile={isMobile}>
									<Tag
										background={
											allActive
												? "#FAE2E8"
												: "#F6F6F6"
										}
										onClick={() => {
											handleAllSelect();
										}}
									>
										All
									</Tag>
									{blogCategories?.data?.premiumBlogCategories?.map(
										(category, index) => (
											<Tag
												key={index}
												onClick={() =>
													handleSelect(
														(category as any).id,
													)
												}
												background={
													selectedCategories?.includes(
														(category as any).id,
													) && allActive === false
														? "#FAE2E8"
														: "#F6F6F6"
												}
											>
												{(category as any).title}
											</Tag>
										),
									)}
								</Tags>
							</ShowIf>
						</ScrollContainer>
						{filteredData?.premiumBlogs!
							.length === 0 && !loading ? (
							<Body1>Not Found</Body1>
						) : null}
						{filteredData?.premiumBlogs!?.map(
							(blog: any, index) => (
								<Fragment key={blog.id}>
									<Article isMobile={isMobile}>
										<ArticleImageDiv
											isTablet={isTablet}
											isMobile={isMobile}
										>
											<StyledImg2
												isMobile={isMobile}
												src={
													blog.banner_image.url
												}
												alt=""
											/>
										</ArticleImageDiv>
										<ArticleContent
											isMobile={isMobile}
										>
											<ArticleInfo
												isTablet={isTablet}
												isMobile={isMobile}
											>
												{blog.blog_category ? (
													<Tag background="#FAE2E8">
														{
															blog.blog_category
																.title
														}
													</Tag>
												) : null}
												{blog.collaborator ? (
													<LabeledIcon
														icon={
															<IconUser color="redRibbon" />
														}
														text={
															"By " +
															blog.collaborator
																.name
														}
													/>
												) : null}
												<LabeledIcon
													icon={<IconCalender />}
													text={getDate(
														blog.createdAt,
													)}
												/>
											</ArticleInfo>
											<H3
												style={
													isMobile
														? {
																marginTop: "17px",
														  }
														: {
																marginTop: "23px",
																marginBottom:
																	"21px",
																cursor: "pointer",
														  }
												}
												onClick={() =>
													history.push(
														`/dashboard/premium-gyan/${blog.id}`,
													)
												}
											>
												{blog.title}
											</H3>
											{!isMobile && (
												<StyledBody1
													isMobile={isMobile}
												>
													{blog.short_description}
												</StyledBody1>
											)}
										</ArticleContent>
									</Article>
									{!isTablet &&
										filteredData!.premiumBlogs!.slice(
											isTablet ? 0 : 4,
										).length -
											1 !==
											index && <Seperator />}
									{isTablet &&
										filteredData!.premiumBlogs!
											.length -
											1 !==
											index && <Seperator />}
								</Fragment>
							),
						)}
						<Spacing type="s32" multiplier={2} />
					</Wrapper>
				</ShowIf>
			</Box>
		</LayoutContainer>
	);
};

export default PremiumBlog;
