const IconQuantity = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 64 80"
		>
			<g>
				<path d="M3.1,52.9c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6C5.8,46.9,3.1,49.6,3.1,52.9z M13.1,52.9c0,1.1-0.4,2.1-1.2,2.8   c-0.7,0.7-1.7,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2C5.6,55,5.1,54,5.1,52.9c0-1.1,0.4-2.1,1.2-2.8C7,49.4,8,48.9,9.1,48.9   c1.1,0,2.1,0.4,2.8,1.2C12.7,50.8,13.1,51.8,13.1,52.9z" />
				<path d="M18.4,52.9c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6C21.1,46.9,18.4,49.6,18.4,52.9z M28.4,52.9   c0,1.1-0.4,2.1-1.2,2.8c-0.7,0.7-1.7,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.7-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8   c0.7-0.7,1.7-1.2,2.8-1.2c1.1,0,2.1,0.4,2.8,1.2C27.9,50.8,28.4,51.8,28.4,52.9z" />
				<path d="M39.6,58.9c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6C33.6,56.2,36.3,58.9,39.6,58.9z M36.8,50.1   c0.7-0.7,1.7-1.2,2.8-1.2c1.1,0,2.1,0.4,2.8,1.2c0.7,0.7,1.2,1.7,1.2,2.8c0,1.1-0.4,2.1-1.2,2.8c-0.7,0.7-1.7,1.2-2.8,1.2   c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.7-1.2-2.8C35.6,51.8,36.1,50.8,36.8,50.1z" />
				<path d="M10.9,39c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6C13.6,33,10.9,35.7,10.9,39z M20.9,39c0,1.1-0.4,2.1-1.2,2.8   C19,42.5,18,43,16.9,43c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.7-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.7-0.7,1.7-1.2,2.8-1.2   c1.1,0,2.1,0.4,2.8,1.2C20.4,36.9,20.9,37.9,20.9,39z" />
				<path d="M38.1,39c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6C35.4,45,38.1,42.3,38.1,39z M28.1,39c0-1.1,0.4-2.1,1.2-2.8   C30,35.4,31,35,32.1,35c1.1,0,2.1,0.4,2.8,1.2c0.7,0.7,1.2,1.7,1.2,2.8c0,1.1-0.4,2.1-1.2,2.8c-0.7,0.7-1.7,1.2-2.8,1.2   c-1.1,0-2.1-0.4-2.8-1.2C28.6,41.1,28.1,40.1,28.1,39z" />
				<path d="M47.4,33c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6C53.4,35.7,50.7,33,47.4,33z M50.2,41.8   c-0.7,0.7-1.7,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.7-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.7-0.7,1.7-1.2,2.8-1.2   c1.1,0,2.1,0.4,2.8,1.2c0.7,0.7,1.2,1.7,1.2,2.8C51.4,40.1,50.9,41.1,50.2,41.8z" />
				<path d="M18.5,25c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6C21.2,19,18.5,21.7,18.5,25z M28.5,25c0,1.1-0.4,2.1-1.2,2.8   c-0.7,0.7-1.7,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.7-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.7-0.7,1.7-1.2,2.8-1.2   c1.1,0,2.1,0.4,2.8,1.2C28.1,22.9,28.5,23.9,28.5,25z" />
				<path d="M39.8,19c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6C45.7,21.7,43.1,19,39.8,19z M42.6,27.9   c-0.7,0.7-1.7,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.7-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.7-0.7,1.7-1.2,2.8-1.2   c1.1,0,2.1,0.4,2.8,1.2c0.7,0.7,1.2,1.7,1.2,2.8C43.7,26.1,43.3,27.1,42.6,27.9z" />
				<path d="M32.1,5.1c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6C38.1,7.8,35.4,5.1,32.1,5.1z M35,13.9   c-0.7,0.7-1.7,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.7-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8C30,7.5,31,7.1,32.1,7.1   c1.1,0,2.1,0.4,2.8,1.2c0.7,0.7,1.2,1.7,1.2,2.8C36.1,12.2,35.7,13.2,35,13.9z" />
				<path d="M48.9,52.9c0,3.3,2.7,6,6,6c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6C51.6,46.9,48.9,49.6,48.9,52.9z M52,50.1   c0.7-0.7,1.7-1.2,2.8-1.2c1.1,0,2.1,0.4,2.8,1.2c0.7,0.7,1.2,1.7,1.2,2.8c0,1.1-0.4,2.1-1.2,2.8c-0.7,0.7-1.7,1.2-2.8,1.2   c-1.1,0-2.1-0.4-2.8-1.2c-0.7-0.7-1.2-1.7-1.2-2.8C50.9,51.8,51.3,50.8,52,50.1z" />
			</g>
		</svg>
	);
};

export default IconQuantity;
