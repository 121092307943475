const IconArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.494"
      height="11"
      viewBox="0 0 16.494 11"
    >
      <path
        id="Icon_ionic-ios-arrow-round-forward"
        data-name="Icon ionic-ios-arrow-round-forward"
        d="M18.393,11.462a.749.749,0,0,0-.006,1.054l3.483,3.489H8.614a.745.745,0,0,0,0,1.49H21.865l-3.483,3.489a.754.754,0,0,0,.006,1.054.742.742,0,0,0,1.048-.006l4.721-4.755h0a.836.836,0,0,0,.155-.235.711.711,0,0,0,.057-.286.747.747,0,0,0-.212-.521l-4.721-4.755A.73.73,0,0,0,18.393,11.462Z"
        transform="translate(-7.875 -11.252)"
        fill="#fff"
      />
    </svg>
  );
};

export default IconArrow;
