import { useSharedStateValue } from "@tinyd/usesharedstate";
import PubSub from "pubsub-js";
import storage from "../storage";
export const ME_SHARED_KEY = "@me";
export type meType = {
	id: string;
	email: string;
	name: string;
	dob?: string;
	country?: string;
	city?: string;
	state?: string;
	timezone?: string;
	bio?: string;
	createdAt: string;
	avatar?: {
		id: string;
		url: string;
	};
	what?: string;
	why?: string;
	trialDays?: number;
	subEnd?: number;
	subPlanId?: string;
	subStatus?: string;
	interests?: string;
	profession?: string;
};

let _me: meType = null as any;

if (!_me) {
	// _me = storage.get<meType>("me").value;
}

const hasMe = () => {
	return _me !== null;
};

const get = () => {
	return _me;
};

const remove = () => {
	_me = null as any;
	storage.remove("me");
};

const set = (me: meType) => {
	_me = me;
	PubSub.publish(ME_SHARED_KEY, _me);
	// storage.set("me", me);
};

export const useMeValue = () => {
	const _meShared = useSharedStateValue(
		ME_SHARED_KEY,
		_me,
	);

	return _meShared || _me;
};

const me = { get, set, hasMe, remove };

export default me;
