import { useLayoutEffect } from "react";
import Footer from "../../components/Footer/Footer";

const TermsConditions = () => {
	useLayoutEffect(() => {
		(function (d, s, id) {
			var js: any,
				tjs: any = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src =
				"https://app.termly.io/embed-policy.min.js";
			tjs.parentNode.insertBefore(js, tjs);
		})(document, "script", "termly-jssdk");
	}, []);
	return (
		<>
			<div
				style={{
					minHeight: "100vh",
				}}
				{...({
					name: "termly-embed",
				} as any)}
				data-id="713be09f-80a3-462e-a619-2d74137eeb23"
				data-type="iframe"
			></div>
			<Footer />
		</>
	);
};

export default TermsConditions;
