import { FC, useEffect, useState } from "react";
import {
	Body1,
	Body2,
	Box,
	Button,
	DS,
	FlexBetween,
	FlexCenter,
	H3,
	IconMantra,
	IconMasti,
	IconMeals,
	IconMeditation,
	IconMovement,
	Spacing,
	Subtitle1,
	Wrapper,
} from "@workshore/nyaari-ds";
import styled from "styled-components";
import {
	useHistory,
	useParams,
} from "react-router-dom";
import dayjs from "dayjs";

import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import IconPrint from "../../components/Icons/IconPrint";
import {
	EventsFieldsFragment,
	Maybe,
	useListWeeklyPlannerEventsPrintFriendlyQuery,
} from "../../hooks/graphql/graphql";
import ShowIf from "../../components/ShowIf/ShowIf";
import Loader from "../../components/Loader/Loader";

const IconContainer = styled.div`
	display: flex;

	@media print {
		display: none;
	}
`;
const IconContainer1 = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 14px;
	margin-top: 25px;
`;
const StyledSpan = styled.span`
	color: ${DS.colors.boulder};
`;
const DetailsWrapper = styled.div`
	> :not(:last-child) {
		margin-bottom: 8px;
	}
`;

const WeeklyPlannerPrint = () => {
	const [startDate, setStartDate] =
		useState<Date>();
	const [endDate, setEndDate] = useState<Date>();
	const isMobile = useIsMobile();
	const history = useHistory();
	const { startDate: startDateParam } =
		useParams<{ startDate: string }>();

	const { data, loading, error, refetch } =
		useListWeeklyPlannerEventsPrintFriendlyQuery({
			fetchPolicy: "no-cache",
			variables: {
				day1: dayjs(startDate).format(
					"YYYY-MM-DD",
				),
				day2: dayjs(startDate)
					.add(1, "day")
					.format("YYYY-MM-DD"),
				day3: dayjs(startDate)
					.add(2, "day")
					.format("YYYY-MM-DD"),
				day4: dayjs(startDate)
					.add(3, "day")
					.format("YYYY-MM-DD"),
				day5: dayjs(startDate)
					.add(4, "day")
					.format("YYYY-MM-DD"),
				day6: dayjs(startDate)
					.add(5, "day")
					.format("YYYY-MM-DD"),
				day7: dayjs(startDate)
					.add(6, "day")
					.format("YYYY-MM-DD"),
			},
		});

	useEffect(() => {
		if (
			startDateParam &&
			typeof startDateParam === "string"
		) {
			const date = dayjs(startDateParam).toDate();
			setStartDate(date);
			setEndDate(
				dayjs(date).add(6, "day").toDate(),
			);
		}
	}, [startDateParam]);

	return (
		<div>
			<ShowIf value={loading}>
				<div
					style={{
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Loader />
				</div>
			</ShowIf>
			<ShowIf value={error}>
				<div
					style={{
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{error &&
					error!.message.includes(
						"Cast to ObjectId failed",
					) ? (
						<div>
							<Body1>
								Oops! Page does not exist.
							</Body1>
							<Spacing type="s24" />
							<FlexCenter>
								<Button
									width="200px"
									handleButtonClick={() =>
										history.push("/dashboard")
									}
								>
									<Body2
										as="span"
										fontColor="white"
									>
										Go to Dashboard
									</Body2>
								</Button>
							</FlexCenter>
						</div>
					) : (
						<>
							<FlexCenter>
								<Body1>
									Failed to load Weekly Planner
									Print
								</Body1>
							</FlexCenter>
							<Spacing type="s24" />
							<FlexCenter>
								<Button width="100px">
									<Body2
										as="span"
										fontColor="white"
										onClick={() => {
											refetch();
										}}
									>
										Retry
									</Body2>
								</Button>
							</FlexCenter>
						</>
					)}
				</div>
			</ShowIf>
			<ShowIf value={!loading && !error}>
				<Box
					paddingTop={isMobile ? 25 : 50}
					paddingBottom={100}
				>
					<Wrapper type="fluid">
						<FlexBetween>
							<H3>
								Weekly Planner (
								{dayjs(startDate).format("MMM D")}{" "}
								-{dayjs(endDate).format("MMM D")})
							</H3>
							<IconContainer
								style={{ cursor: "pointer" }}
								onClick={() => window.print()}
							>
								<IconPrint />
								{!isMobile ? (
									<Body2
										style={{ marginLeft: 10 }}
									>
										Print
									</Body2>
								) : null}
							</IconContainer>
						</FlexBetween>

						{/* day1 */}
						<RenderDay
							title={dayjs(startDate).format(
								"dddd, MMM D",
							)}
							day={data?.day1}
						/>
						{/* day2 */}
						<RenderDay
							title={dayjs(startDate)
								.add(1, "day")
								.format("dddd, MMM D")}
							day={data?.day2}
						/>
						{/* day3 */}
						<RenderDay
							title={dayjs(startDate)
								.add(2, "day")
								.format("dddd, MMM D")}
							day={data?.day3}
						/>
						{/* day4 */}
						<RenderDay
							title={dayjs(startDate)
								.add(3, "day")
								.format("dddd, MMM D")}
							day={data?.day4}
						/>
						{/* day5 */}
						<RenderDay
							title={dayjs(startDate)
								.add(4, "day")
								.format("dddd, MMM D")}
							day={data?.day5}
						/>
						{/* day6 */}
						<RenderDay
							title={dayjs(startDate)
								.add(5, "day")
								.format("dddd, MMM D")}
							day={data?.day6}
						/>
						{/* day7 */}
						<RenderDay
							title={dayjs(startDate)
								.add(6, "day")
								.format("dddd, MMM D")}
							day={data?.day7}
						/>
					</Wrapper>
				</Box>
			</ShowIf>
		</div>
	);
};

export default WeeklyPlannerPrint;

interface Props {
	title: string;
	day?: Maybe<
		{
			__typename?: "listWeeklyPlannerEventsData";
		} & EventsFieldsFragment
	>;
}
const RenderDay: FC<Props> = ({ title, day }) => {
	const dayEvents = day?.events;
	const dayMeals = dayEvents?.filter(
		(event) => event.type === "MEALS",
	);
	const dayMovement = dayEvents?.filter(
		(event) => event.type === "MOVEMENT",
	);
	const dayMeditation = dayEvents?.filter(
		(event) => event.type === "MEDITATION",
	);
	const dayMantra = dayEvents?.filter(
		(event) => event.type === "MANTRA",
	);
	const dayMasti = dayEvents?.filter(
		(event) => event.type === "MASTI",
	);
	return (
		<div>
			<Subtitle1 marginTop={25}>
				{title}
			</Subtitle1>

			{/* meals */}
			<IconContainer1>
				<IconMeals />
				<Body2 marginLeft={10}>Meals</Body2>
			</IconContainer1>
			<DetailsWrapper>
				<Body1>
					PRE-BREAKFAST -{" "}
					{(dayMeals as any)?.filter(
						(meal: any) =>
							meal.slot === "PREBREAKFAST",
					)?.length > 0 ? (
						dayMeals?.filter(
							(meal) =>
								meal.slot === "PREBREAKFAST",
						)[0].recipe?.Title
					) : (
						<StyledSpan> Not Planned </StyledSpan>
					)}
				</Body1>
				<Body1>
					BREAKFAST -{" "}
					{(dayMeals as any)?.filter(
						(meal: any) =>
							meal.slot === "BREAKFAST",
					)?.length > 0 ? (
						dayMeals?.filter(
							(meal) => meal.slot === "BREAKFAST",
						)[0].recipe?.Title
					) : (
						<StyledSpan> Not Planned </StyledSpan>
					)}
				</Body1>
				<Body1>
					AM SNACK -{" "}
					{(dayMeals as any)?.filter(
						(meal: any) =>
							meal.slot === "AMSNACK",
					)?.length > 0 ? (
						dayMeals?.filter(
							(meal) => meal.slot === "AMSNACK",
						)[0].recipe?.Title
					) : (
						<StyledSpan> Not Planned </StyledSpan>
					)}
				</Body1>
				<Body1>
					Lunch -{" "}
					{(dayMeals as any)?.filter(
						(meal: any) => meal.slot === "LUNCH",
					)?.length > 0 ? (
						dayMeals?.filter(
							(meal) => meal.slot === "LUNCH",
						)[0].recipe?.Title
					) : (
						<StyledSpan> Not Planned </StyledSpan>
					)}
				</Body1>
				<Body1>
					PM SNACK -{" "}
					{(dayMeals as any)?.filter(
						(meal: any) =>
							meal.slot === "PMSNACK",
					)?.length > 0 ? (
						dayMeals?.filter(
							(meal) => meal.slot === "PMSNACK",
						)[0].recipe?.Title
					) : (
						<StyledSpan> Not Planned </StyledSpan>
					)}
				</Body1>
				<Body1>
					DINNER -{" "}
					{(dayMeals as any)?.filter(
						(meal: any) => meal.slot === "DINNER",
					)?.length > 0 ? (
						dayMeals?.filter(
							(meal) => meal.slot === "DINNER",
						)[0].recipe?.Title
					) : (
						<StyledSpan> Not Planned </StyledSpan>
					)}
				</Body1>
			</DetailsWrapper>

			{/* movement */}
			<IconContainer1>
				<IconMovement />
				<Body2 marginLeft={10}>Movement</Body2>
			</IconContainer1>
			<Body1>
				{dayMovement?.length ? (
					dayMovement[0].movement?.title
				) : (
					<StyledSpan> Not Planned </StyledSpan>
				)}
			</Body1>

			{/* meditation */}
			<IconContainer1>
				<IconMeditation />
				<Body2 marginLeft={10}>Meditation</Body2>
			</IconContainer1>
			<Body1>
				{dayMeditation?.length ? (
					dayMeditation[0].meditation?.title
				) : (
					<StyledSpan> Not Planned </StyledSpan>
				)}
			</Body1>

			{/* mantra */}
			<IconContainer1>
				<IconMantra />
				<Body2 marginLeft={10}>Mantra</Body2>
			</IconContainer1>
			<Body1>
				{dayMantra?.length ? (
					dayMantra[0].mantra?.title
				) : (
					<StyledSpan> Not Planned </StyledSpan>
				)}
			</Body1>

			{/* masti */}
			<IconContainer1>
				<IconMasti />
				<Body2 marginLeft={10}>Masti</Body2>
			</IconContainer1>
			<Body1>
				{dayMasti?.length ? (
					dayMasti[0].masti?.title
				) : (
					<StyledSpan> Not Planned </StyledSpan>
				)}
			</Body1>
		</div>
	);
};
