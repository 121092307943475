const IconRoundYoutube = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="27"
			height="27"
			viewBox="0 0 27 27"
		>
			<g
				id="Group_9899"
				data-name="Group 9899"
				transform="translate(0.333 0)"
			>
				<g
					id="Group_9898"
					data-name="Group 9898"
					transform="translate(0 0)"
				>
					<g
						id="Ellipse_18"
						data-name="Ellipse 18"
						transform="translate(-0.333 0)"
						fill="#fcfcfc"
						stroke="#ed1651"
						strokeWidth="1.5"
					>
						<circle
							cx="13.5"
							cy="13.5"
							r="13.5"
							stroke="none"
						/>
						<circle
							cx="13.5"
							cy="13.5"
							r="12.75"
							fill="none"
						/>
					</g>
				</g>
				<g
					id="youtube"
					transform="translate(6.083 8.172)"
				>
					<path
						id="Path_1483"
						data-name="Path 1483"
						d="M15.262,5.6a1.841,1.841,0,0,0-1.285-1.324A47.681,47.681,0,0,0,8.283,4a43.57,43.57,0,0,0-5.694.3A1.841,1.841,0,0,0,1.3,5.629a19.2,19.2,0,0,0-.3,3.5,19.2,19.2,0,0,0,.3,3.529,1.841,1.841,0,0,0,1.285,1.271,43.57,43.57,0,0,0,5.694.3,43.57,43.57,0,0,0,5.694-.3,1.841,1.841,0,0,0,1.285-1.324,19.2,19.2,0,0,0,.3-3.476,19.2,19.2,0,0,0-.3-3.529Z"
						transform="translate(-0.999 -4)"
						fill="none"
						stroke="#ed1651"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
					<path
						id="Path_1484"
						data-name="Path 1484"
						d="M9.75,12.81l3.807-2.165L9.75,8.48Z"
						transform="translate(-3.956 -5.514)"
						fill="none"
						stroke="#ed1651"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconRoundYoutube;
