import { DS } from "@workshore/nyaari-ds";
import React, { FC } from "react";

import styled from "styled-components";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
const SplitViewContainer = styled.div<{
	bgImgUrl: string;
	height?: string;
}>`
	width: 100vw;
	overflow-x: hidden;
	min-height: calc(
		100vh - ${DS.spacing.s24 * 2}px
	);
	${({ height }) => `
    min-height: ${height};
  `}

	overflow: hidden;
	display: flex;
	& > div {
		flex: 1;
		position: relative;
		padding: ${DS.spacing.s24 * 2}px
			${DS.spacing.s24}px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& > .bg_img {
		${({ bgImgUrl }) => `
    background-image: url(${bgImgUrl});
    
    `}
		background-position: center;
		background-size: cover;
	}
`;

const SplitView: FC<{
	bgImgUrl: string;
	height?: string;
}> = ({ bgImgUrl, children, height }) => {
	const isSmall = useIsMobile();
	return (
		<SplitViewContainer
			bgImgUrl={bgImgUrl}
			height={height}
		>
			{!isSmall ? (
				<div className="bg_img"></div>
			) : null}
			<div>{children}</div>
		</SplitViewContainer>
	);
};

export default SplitView;
