import { useScreenWidth } from "@tinyd/usescreensize";
import { DS } from "@workshore/nyaari-ds";

function useIsTablet() {
	const screenWidth = useScreenWidth();
	const isTablet =
		screenWidth <=
		DS.customBreakpoints.drawerBreakpoint;

	return isTablet;
}

export default useIsTablet;
