import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { setConfiguration } from "react-grid-system";
import { client } from "./services/apollo";
import { ToastProvider } from "react-toast-notifications";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
setConfiguration({
	breakpoints: [600, 600, 960, 1280, 1920],
});

ReactDOM.render(
	// <React.StrictMode>
	<ToastProvider>
		<ApolloProvider client={client}>
			<Router>
				<ScrollToTop />
				<App />
			</Router>
		</ApolloProvider>
	</ToastProvider>,
	// </React.StrictMode>,
	document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
