import {
	IconMantra,
	IconMasti,
	IconMeals,
	IconMeditation,
	IconMovement,
} from "@workshore/nyaari-ds";
import React, { FC } from "react";

const TypedIcon: FC<{
	type: string;
	active?: boolean;
}> = ({ type, active = false }) => {
	switch (type.toLocaleLowerCase()) {
		case "movement":
			return <IconMovement active={active} />;
		case "meditation":
			return <IconMeditation active={active} />;
		case "mantra":
			return <IconMantra active={active} />;
		case "masti":
			return <IconMasti active={active} />;
		default:
			return <IconMeals active={active} />;
	}
};

export default TypedIcon;
