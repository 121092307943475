import { useState, useEffect } from "react";
import {
	H2,
	Wrapper,
	DS,
	Modal,
	Button,
	H3,
	Body2,
} from "@workshore/nyaari-ds";

import Footer from "../../components/Footer/Footer";
import RegisteredMembersForm from "./components/RegisteredMembersForm";
import VisitorsForm from "./components/VisitorsForm";
import CollaboratorsForm from "./components/CollaboratorsForm";
import bg from "../../assets/images/Contact_us.jpg";
import styled from "styled-components";
import useIsMobile from "../../hooks/useIsMobile/useIsMobile";
import IconAdd from "../../components/Icons/IconAdd";
import IconRemove from "../../components/Icons/IconRemove";
import contact_success from "../../assets/images/contact_success.png";
import useIsTablet from "../../hooks/useIsTablet/useIsTablet";
import { useScreenWidth } from "@tinyd/usescreensize";
import ContactAccordion from "./components/ContactAccordian";

const StyledWrapper = styled(Wrapper)<{
	isMobile: boolean;
}>`
	padding: 85px 0;
	display: flex;
	flex-direction: row-reverse;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 23px 0;
  `};
`;

const StyledImg = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 50vw;
	object-fit: cover;
	height: 100%;
`;

// modal
const ModalDiv = styled.div<{
	isMobile: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 64px 98px;

	${({ isMobile }) =>
		isMobile &&
		`
    padding: 35px 55px;
  `};
`;

const ModalImg = styled.img<{
	isMobile: boolean;
}>`
	height: 316px;
	width: 316px;

	${({ isMobile }) =>
		isMobile &&
		`
    height: 223px;
    width: 223px;
  `};
`;

const ContactUs = () => {
	const [width, setWidth] = useState("100%");
	const [modal, setModal] = useState(false);

	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const screenWidth = useScreenWidth();

	useEffect(() => {
		if (screenWidth >= DS.breakPoints.xl)
			return setWidth("580px");
		if (
			screenWidth >= DS.breakPoints.l &&
			screenWidth < DS.breakPoints.xl
		)
			return setWidth("550px");
		return setWidth("100%");
	}, [screenWidth]);

	const handleModalToggle = () => {
		setModal(!modal);
	};

	const data = [
		{
			title: "All Visitors",
			view: (
				<VisitorsForm
					handleModalToggle={handleModalToggle}
				/>
			),
		},
		{
			title: "Prospective Collaborators",
			view: (
				<CollaboratorsForm
					handleModalToggle={handleModalToggle}
				/>
			),
		},
		{
			title: "Registered members",
			view: (
				<RegisteredMembersForm
					handleModalToggle={handleModalToggle}
				/>
			),
		},
	];

	return (
		<>
			<div
				style={{
					width: "100%",
					position: "relative",
				}}
			>
				<StyledWrapper isMobile={isMobile}>
					{isTablet ? (
						<></>
					) : (
						<StyledImg src={bg} alt="" />
					)}
					<div style={{ width }}>
						<H2
							marginBottom={
								isMobile ? "0px" : "18px"
							}
						>
							Get In Touch
						</H2>
						<ContactAccordion
							AccordionData={data}
							activeIndex={0}
							plusIcon={<IconAdd />}
							minusIcon={<IconRemove />}
						/>
					</div>
				</StyledWrapper>
			</div>
			<Footer />
			<Modal
				show={modal}
				handleClose={handleModalToggle}
				borderRadius="5"
				width={isMobile ? "80vw" : "565px"}
				placement="center"
				dismissible
			>
				<ModalDiv isMobile={isMobile}>
					<ModalImg
						isMobile={isMobile}
						src={contact_success}
						alt=""
					/>
					<H3
						marginTop={isMobile ? "26px" : "30px"}
					>
						We Got You!
					</H3>
					<Body2
						style={{ textAlign: "center" }}
						marginTop={isMobile ? "9px" : "15px"}
						marginBottom={
							isMobile ? "24px" : "41px"
						}
					>
						We appreciate you taking the time to
						write us, we'll get back to you within
						24 hours.
					</Body2>
					<Button
						handleButtonClick={handleModalToggle}
						width={isMobile ? "225px" : "335px"}
						height="50px"
					>
						Done
					</Button>
				</ModalDiv>
			</Modal>
		</>
	);
};

export default ContactUs;
