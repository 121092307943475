/* eslint-disable no-useless-escape */
import React, { FC, useState } from "react";
import {
	Input,
	TextArea,
	Button,
	Spacing,
	Caption,
} from "@workshore/nyaari-ds";
import {
	Row,
	Col,
	useScreenClass,
} from "react-grid-system";

import validateEmail from "../../../utils/validateEmail";
import { InputDiv } from "../ContactUs.styles";
import getFirstError from "../../../utils/getFirstError";
import { useContactMutation } from "../../../hooks/graphql/graphql";

const initialInputs = {
	name: "",
	email: "",
	mobile: "",
	message: "",
};

interface Props {
	handleModalToggle: () => void;
}

const VisitorsForm: FC<Props> = ({
	handleModalToggle,
}) => {
	const screenClass = useScreenClass();
	const [inputs, setInputs] = useState(
		initialInputs,
	);
	const [errors, setErrors] = useState({
		name: "",
		email: "",
		mobile: "",
		message: "",
	});
	const [serverError, setServerError] =
		useState("");
	const [contactMutation, { loading }] =
		useContactMutation();

	const handleSubmit = async (
		e: React.MouseEvent<
			HTMLButtonElement | MouseEvent
		>,
	) => {
		e.preventDefault();
		const errors = {
			name: "",
			email: "",
			mobile: "",
			message: "",
		};
		if (inputs.name.length < 1) {
			errors.name = "Name is required";
		}
		if (!validateEmail(inputs.email)) {
			errors.email = "Invalid email address";
		}
		const mobileRegex =
			/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
		if (
			inputs.mobile.length &&
			!inputs.mobile.match(mobileRegex)
		) {
			errors.mobile = "Invalid mobile number";
		}
		if (inputs.message.length < 1) {
			errors.message = "Message is required";
		}

		// check if errors are there
		if (
			Object.values(errors).filter(
				(d) => d.length,
			).length
		) {
			setErrors(errors);
		} else {
			// console.log(inputs);
			try {
				const { data } = await contactMutation({
					variables: {
						type: "Visitor",
						name: inputs.name,
						email: inputs.email,
						phone: inputs.mobile,
						message: inputs.message,
					},
				});
				if (data) handleModalToggle();
			} catch (error) {
				setServerError(getFirstError(error));
			}
			setInputs(initialInputs);
			setErrors({
				name: "",
				email: "",
				mobile: "",
				message: "",
			});
		}
	};

	return (
		<Row>
			<Col xs={12}>
				<form>
					<InputDiv>
						<Input
							handleInputBlur={() => {}}
							handleInputChange={(e) => {
								setInputs({
									...inputs,
									name: e.target.value,
								});
							}}
							handleInputFocus={() => {}}
							value={inputs.name}
							error={errors.name}
							placeholder="Name"
							disabled={loading}
						/>
					</InputDiv>
					<Row
						gutterWidth={
							["lg", "xl", "xxl"].includes(
								screenClass,
							)
								? 20
								: 6
						}
					>
						<Col xs={6}>
							<InputDiv>
								<Input
									handleInputBlur={() => {}}
									handleInputChange={(e) => {
										setInputs({
											...inputs,
											email: e.target.value,
										});
									}}
									handleInputFocus={() => {}}
									value={inputs.email}
									error={errors.email}
									type="email"
									placeholder="Email"
									disabled={loading}
								/>
							</InputDiv>
						</Col>
						<Col xs={6}>
							<InputDiv>
								<Input
									handleInputBlur={() => {}}
									handleInputChange={(e) => {
										setInputs({
											...inputs,
											mobile: e.target.value,
										});
									}}
									handleInputFocus={() => {}}
									value={inputs.mobile}
									error={errors.mobile}
									placeholder="Mobile"
									disabled={loading}
								/>
							</InputDiv>
						</Col>
					</Row>
					<InputDiv>
						<TextArea
							value={inputs.message}
							error={errors.message}
							handleTextAreaBlur={() => {}}
							handleTextAreaChange={(value) => {
								setInputs({
									...inputs,
									message: value,
								});
							}}
							handleTextAreaFocus={() => {}}
							placeholder="write your message here"
						/>
					</InputDiv>
					<Row>
						<Col xs={12} sm={12} md={12} lg={6}>
							<InputDiv>
								<Button
									handleButtonClick={handleSubmit}
									disabled={loading}
								>
									{loading
										? "Submitting.."
										: "Submit"}
								</Button>
								<Spacing />
								<Caption fontColor="red">
									{serverError}
								</Caption>
							</InputDiv>
						</Col>
					</Row>
				</form>
			</Col>
		</Row>
	);
};

export default VisitorsForm;
